//Audio
export const SET_ACTX = 'SET_ACTX'
export const SET_THEME = 'SET_THEME'
export const SET_SNARE = 'SET_SNARE'
export const SET_KICK = 'SET_KICK'
export const SET_HIHAT = 'SET_HIHAT'
export const SET_RIDE = 'SET_RIDE'
export const SET_METRONOME = 'SET_METRONOME'
export const SET_METRONOMEONE = 'SET_METRONOMEONE'
export const SET_VOLUME = 'SET_VOLUME'
export const SET_MARK_ONE = 'SET_MARK_ONE'

//Rendering
export const SET_PLATFORM = 'SET_PLATFORM'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_TRANSLATION_SETTINGS = 'SET_TRANSLATION_SETTINGS'
export const SET_TRANSLATION_MODE = 'SET_TRANSLATION_MODE'
export const SET_APPLICATION_TEXT = 'SET_APPLICATION_TEXT'
export const SET_RENDERING_MODE = 'SET_RENDERING_MODE'
export const SET_SHOW_FILTERS = 'SET_SHOW_FILTERS'
export const SET_SHOW_SETTINGS = 'SET_SHOW_SETTINGS'
export const SET_ANALYTICS_PROMPT = 'SET_ANALYTICS_PROMPT'
export const SET_DONATION_PROMPT = 'SET_DONATION_PROMPT'
export const TOGGLE_SHOW_SETTINGS = 'TOGGLE_SHOW_SETTINGS'
export const TOGGLE_SHOW_FILTERS = 'TOGGLE_SHOW_FILTERS'
export const TOGGLE_ANALYTICS_PROMPT = 'TOGGLE_ANALYTICS_PROMPT'
export const TOGGLE_DONATION_PROMPT = 'TOGGLE_DONATION_PROMPT'
export const SET_RENDERED_ARRAY = 'SET_RENDERED_ARRAY'
export const SET_SHOW_GROOVE_MODAL = 'SET_SHOW_GROOVE_MODAL'
export const SET_SHOW_SWING = 'SET_SHOW_SWING'
export const SET_MODAL_HH_ARRAY = 'SET_MODAL_HH_ARRAY'
export const SET_SELECTED = 'SET_SELECTED'
export const SET_HIGHLIGHTED = 'SET_HIGHLIGHTED'
export const SET_BAR_STYLE = 'SET_BAR_STYLE'
export const SET_SAVEABLE = 'SET_SAVEABLE'
export const SET_LOADABLE = 'SET_LOADABLE'
export const SET_SHOW_LOAD_MODAL = 'SET_SHOW_LOAD_MODAL'
export const SET_SHOW_FEEDBACK_MODAL = 'SET_SHOW_FEEDBACK_MODAL'
export const SET_SHOW_HELPER_MODAL = 'SET_SHOW_HELPER_MODAL'
export const TOGGLE_PRACTICE_MODAL = 'TOGGLE_PRACTICE_MODAL'
export const SET_SHOW_PRACTICE_MODAL = 'SET_SHOW_PRACTICE_MODAL'
export const SET_SHOW_ERROR = 'SET_SHOW_ERROR'
export const SET_ERROR_TEXT = 'SET_ERROR_TEXT'
export const SET_RHYTHM_NAME = 'SET_RHYTHM_NAME'
export const SET_SHOW_SOUNDBANK_MODAL = 'SET_SHOW_SOUNDBANK_MODAL'
export const SET_UPDATE_PROMPT = 'SET_UPDATE_PROMPT'
export const SET_FEATURES_LIST = 'SET_FEATURES_LIST'
export const SET_MOBILE_TIME_SIGNATURE_CONTROLS = 'SET_MOBILE_TIME_SIGNATURE_CONTROLS'
export const SET_MOBILE_TIME_SIGNATURE_SEEN = 'SET_MOBILE_TIME_SIGNATURE_SEEN'
export const SET_MOBILE_CONTROLS_DOWN = 'SET_MOBILE_CONTROLS_DOWN'
export const MANUAL_TOGGLE_SCREEN_LOCK = 'MANUAL_TOGGLE_SCREEN_LOCK'
export const SET_SCREEN_LOCK = 'SET_SCREEN_LOCK'
export const SET_SCREEN_LOCK_OBJECT = 'SET_SCREEN_LOCK_OBJECT'
export const SET_LOGIN_OVERLAY = 'SET_LOGIN_OVERLAY'
export const SET_SIGNUP_OVERLAY = 'SET_SIGNUP_OVERLAY'
export const SET_SHOW_CLICK_MODAL = 'SET_SHOW_CLICK_MODAL'
export const SET_CLICK_MODAL_HAS_OPENED = 'SET_CLICK_MODAL_HAS_OPENED' // When the click is modified by query params, I need to set 'hasOpened' to true
export const SET_SHOW_RV_PERMUTATIONS_MODAL = 'SET_SHOW_RV_PERMUTATIONS_MODAL'

//Playback
export const SET_BARS = 'SET_BARS'
export const SET_TIME = 'SET_TIME'
export const SET_TIME_NAME = 'SET_TIME_NAME'
export const SET_PLAYBACK_TIME = 'SET_PLAYBACK_TIME'
export const SET_RANDOM = 'SET_RANDOM'
export const SET_PLAY_THIS = 'SET_PLAY_THIS'
export const SET_BPM = 'SET_BPM'
export const SET_SWING = 'SET_SWING'
export const SET_MODE = 'SET_MODE'
export const SET_PLAYING = 'SET_PLAYING'
export const SET_USE_METRONOME = 'SET_USE_METRONOME'
export const SET_USE_COUNT_IN = 'SET_USE_COUNT_IN'
export const SET_USE_GHOSTS = 'SET_USE_GHOSTS'
export const SET_PLAYBACK_AS = 'SET_PLAYBACK_AS'
export const SET_GROOVE_CYMBAL_PATTERN = 'SET_GROOVE_CYMBAL_PATTERN'
export const SET_GROOVE_CYMBAL_SOUND = 'SET_GROOVE_CYMBAL_SOUND'
export const SET_GROOVE_GHOSTS = 'SET_GROOVE_GHOSTS'
export const SET_GROOVE_BACKBEAT = 'SET_GROOVE_BACKBEAT'
export const SET_GROOVE_MIX = 'SET_GROOVE_MIX'
export const SET_CUSTOM = 'SET_CUSTOM'
export const SET_CUSTOM_ARRAY = 'SET_CUSTOM_ARRAY'
export const SET_SELECTED_BARS = 'SET_SELECTED_BARS'
export const SET_ISOLATED_ARRAY = 'SET_ISOLATED_ARRAY'
export const SET_ISOLATED = 'SET_ISOLATED'
export const SET_PLAY_ENABLED = 'SET_PLAY_ENABLED'
export const SET_LEVELS_ARRAY = 'SET_LEVELS_ARRAY'
export const SET_LEVEL = 'SET_LEVEL'
export const TOGGLE_TRADE_DIRECTION = 'TOGGLE_TRADE_DIRECTION'
export const SET_TRADE_DIRECTION = 'SET_TRADE_DIRECTION'
export const TOGGLE_RHYTHM_LOCK = 'TOGGLE_RHYTHM_LOCK'
export const TOGGLE_GROOVE_LOCK = 'TOGGLE_GROOVE_LOCK'
export const SET_RHYTHM_LOCK = 'SET_RHYTHM_LOCK'
export const SET_GROOVE_LOCK = 'SET_GROOVE_LOCK'
export const SET_SPACE = 'SET_SPACE'
export const SET_TIME_SIGNATURE = 'SET_TIME_SIGNATURE'
export const CONFIRM_TIME_SIGNATURE = 'CONFIRM_TIME_SIGNATURE'
export const SET_TIME_SIGNATURE_TOP = 'SET_TIME_SIGNATURE_TOP'
export const SET_TIME_SIGNATURE_BOTTOM = 'SET_TIME_SIGNATURE_BOTTOM'
export const SET_PRACTICE_MODE = 'SET_PRACTICE_MODE'
export const SET_RHYTHM_TIMINGS = 'SET_RHYTHM_TIMINGS'
export const SET_RHYTHM_START_TIME = 'SET_RHYTHM_START_TIME'
export const SET_USER_HITS = 'SET_USER_HITS'
export const ADD_USER_HIT = 'ADD_USER_HIT'
export const ADD_USER_MISS = 'ADD_USER_MISS'
export const SET_ACCURACY = 'SET_ACCURACY'
export const CLEAR_RHYTHM_HISTORY = 'CLEAR_RHYTHM_HISTORY'
export const ADD_RHYTHM_HISTORY = 'ADD_RHYTHM_HISTORY'
export const RESET_CLICK = 'RESET_CLICK'
export const SET_CLICK_VOLUME = 'SET_CLICK_VOLUME'
export const SET_EXACT_CLICK_RATE = 'SET_EXACT_CLICK_RATE'
export const SET_CLICK_RATE = 'SET_CLICK_RATE'
export const SET_CLICK_RATE_CUSTOM = 'SET_CLICK_RATE_CUSTOM'
export const SET_CLICK_RATE_AMOUNT = 'SET_CLICK_RATE_AMOUNT'
export const SET_CLICK_RATE_NOTE = 'SET_CLICK_RATE_NOTE'
export const SET_EXACT_CLICK_OFFSET = 'SET_EXACT_CLICK_OFFSET'
export const SET_CLICK_OFFSET_AMOUNT = 'SET_CLICK_OFFSET_AMOUNT'
export const SET_CLICK_OFFSET_RATE = 'SET_CLICK_OFFSET_RATE'
export const SWITCH_CLICK_OFFSET_RATE = 'SWITCH_CLICK_OFFSET_RATE'
export const SET_EXACT_CLICK_GAP = 'SET_EXACT_CLICK_GAP'
export const SWITCH_GAP_CLICK_OFF_FIRST = 'SWITCH_GAP_CLICK_OFF_FIRST'
export const SET_GAP_CLICK_MATCH_RHYTHM = 'SET_GAP_CLICK_MATCH_RHYTHM'
export const SET_GAP_CLICK = 'SET_GAP_CLICK'
export const SET_CLICK_MARK_ONE = 'SET_CLICK_MARK_ONE'
export const RESET_PRESET = 'RESET_PRESET'
export const SET_PRESET = 'SET_PRESET'
export const SET_PRESET_SHUFFLE_ALL = 'SET_PRESET_SHUFFLE_ALL'
export const SET_PRESET_CONSTANTS = 'SET_PRESET_CONSTANTS'

// Rhythmic vocabulary permutations
export const RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_START_POINTS = 'RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_START_POINTS'
export const RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_GROUPING = 'RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_GROUPING'

//User
export const SET_PRO = 'SET_PRO'
export const LOGIN = 'LOGIN'

//Reset
export const RESET = 'RESET'

//Ionic
export const ION_SET_FILTERS_OPEN = 'ION_SET_FILTERS_OPEN'
export const ION_SET_PRESETS_OPEN = 'ION_SET_PRESETS_OPEN'
export const ION_SET_COMPLEXITY_OPEN = 'ION_SET_COMPLEXITY_OPEN'
export const ION_SET_COMPLEXITY_CUSTOM_OPEN = 'ION_SET_COMPLEXITY_CUSTOM_OPEN'
export const ION_SET_TIMESIGNATURE_OPEN = 'ION_SET_TIMESIGNATURE_OPEN'
