import React from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { IonIcon, IonToggle, IonLabel, IonItem } from '@ionic/react'
import { cogOutline } from 'ionicons/icons'

import { setUseMetronome } from '../../../../../../../redux/actions'
import { getUsingClick } from '../../../../../../../redux/selectors/playback'
import { tabs } from '../../../../../../../utils/ionic/tabs'

const Metronome = ({ dismiss, usingClick, setUseMetronome }) => {
	const history = useHistory()

	const toggle = () => {
		setUseMetronome(!usingClick)
	}
	const getLabel = () => get(tabs, [`metronome`, `title`])

	const navigateToClick = () => {
		dismiss()
		history.push(get(tabs, [`metronome`, `href`]))
	}

	const getToggleContent = () => (
		<IonToggle
			checked={usingClick}
			onClick={(e) => {
				e.stopPropagation()
				toggle()
			}}
		/>
	)

	return (
		<IonItem button onClick={navigateToClick}>
			<IonLabel>{getLabel()}</IonLabel>
			<IonIcon icon={cogOutline} />
			{getToggleContent()}
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const usingClick = getUsingClick(state)
	return {
		usingClick,
	}
}

export default connect(mapStateToProps, { setUseMetronome })(Metronome)
