import { createSelector } from 'reselect'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import forEach from 'lodash/forEach'
import takeRight from 'lodash/takeRight'

import { getRenderingState } from './rendering'
import RenderingModes from '../../utils/constants/enum/renderingMode'
import ClickRates from '../../utils/constants/enum/click'
import TimeEnum from '../../utils/constants/enum/time'
import ModeEnum from '../../utils/constants/enum/mode'
import { initPlayback } from '../reducers/initStates'

export const getPlaybackState = (store) => store.playback

export const getPlayThis = createSelector(getPlaybackState, (playback) => get(playback, 'playThis'))
export const getTime = createSelector(getPlaybackState, (playback) => get(playback, 'time'))
export const getSwing = createSelector(getPlaybackState, (playback) => get(playback, 'swing'))
export const getBars = createSelector(getPlaybackState, (playback) => get(playback, 'bars'))
export const getBpm = createSelector(getPlaybackState, (playback) => get(playback, 'bpm'))
export const getMode = createSelector(getPlaybackState, (playback) => get(playback, 'mode'))
export const getPlaybackAs = createSelector(getPlaybackState, (playback) => get(playback, 'playbackAs'))
export const getClickSettings = createSelector(getPlaybackState, (playback) => get(playback, 'click'))
export const getUsingClick = createSelector(getPlaybackState, (playback) => get(playback, [`click`, `on`]))
export const getClickVolume = createSelector(getClickSettings, (click) => get(click, 'volume'))
export const getIsCustomClick = createSelector(getClickSettings, (click) => !isEqual(click, get(initPlayback, `click`)))
export const getTradeDirection = createSelector(getPlaybackState, (playback) => get(playback, 'tradeDirection'))

export const getTimeSignature = createSelector(getPlaybackState, (playback) => get(playback, 'timeSignature'))
export const getCrotchetTime = createSelector(getBpm, (bpm) => 60 / bpm)

export const getIsPlaying = createSelector(getPlaybackState, (playback) => get(playback, 'playing'))
export const getIsIsolated = createSelector(getPlaybackState, (playback) => get(playback, 'isolated'))
export const getIsolatedArray = createSelector(getPlaybackState, (playback) => get(playback, 'isolatedArray'))
export const getIsRandom = createSelector(getPlaybackState, (playback) => get(playback, 'random'))
export const getComplexityLevel = createSelector(getPlaybackState, (playback) => get(playback, 'level'))
export const getIsCustom = createSelector(getPlaybackState, (playback) => get(playback, 'custom'))
export const getCustomArray = createSelector(getPlaybackState, (playback) => get(playback, 'customArray'))
export const getUsingCountIn = createSelector(getPlaybackState, (playback) => get(playback, [`click`, `countIn`]))
export const getUsingGhosts = createSelector(getPlaybackState, (playback) => get(playback, [`useGhosts`]))
export const getLevel = createSelector(getPlaybackState, (playback) => get(playback, [`level`]))
export const getSpace = createSelector(getPlaybackState, (playback) => get(playback, [`space`]))

export const getSelectedBars = createSelector(getPlaybackState, (playback) => get(playback, 'selectedBars'))

const isOdd = (signature) => signature.top % 2 === 1
const is44 = (signature) => signature.top / signature.bottom === 1
const isSixTwelveEight = (signature) => signature.bottom === 8 && signature.top % 6 === 0

export const getIsTimeSignatureOdd = createSelector(getTimeSignature, (timeSignature) => isOdd(timeSignature))
export const getIsSixTwelveEight = createSelector(getTimeSignature, (timeSignature) => isSixTwelveEight(timeSignature))
export const getTimeSignatureHasBackbeat = createSelector(getTimeSignature, (timeSignature) => {
	// 6/8, 12/8, 7/8, 9/8, 5/8 all can have custom backbeats
	const bottom = get(timeSignature, 'bottom')
	if (bottom !== 8) {
		return false
	} else {
		const top = get(timeSignature, 'top')
		switch (top) {
			case 6:
			case 12:
			case 7:
			case 5:
				return true
			default:
				return false
		}
	}
})

export const clickVolumeBoost = createSelector(getClickSettings, getIsTimeSignatureOdd, (click, isOdd) => {
	if (get(click, `rate.custom`)) {
		return 1.6
	}
	if (isOdd) {
		return 1.2
	}
	return 1.0
})

export const getIsTimeSignature44 = createSelector(getTimeSignature, (timeSignature) => is44(timeSignature))

export const getTimeToUse = createSelector(getPlaybackState, (playback) => (playback.playbackTime.options ? playback.playbackTime : playback.time))
export const getTimeOptions = createSelector(getPlaybackState, (playback) => get(playback, [`time`, `options`], TimeEnum.STRAIGHT))
export const getPlaybackTime = createSelector(getPlaybackState, (playback) => get(playback, [`playbackTime`]))
export const getPlaybackTimeOptions = createSelector(getPlaybackTime, (playbackTime) => get(playbackTime, [`options`], TimeEnum.STRAIGHT))

export const getSubdivisionBarDuration = createSelector(
	getTimeToUse,
	getTimeSignature,
	(time, timeSignature) => get(timeSignature, 'top') * (get(time, 'highestNoteRate') / get(timeSignature, 'bottom'))
)

export const getSixteenthsPerBar = createSelector(getTimeSignature, (timeSignature) => get(timeSignature, 'top') * (16 / get(timeSignature, 'bottom')))

/**
 * Get the number of sixteenths in the short beat of a bar
 */
export const getShortBeatLength = createSelector(getSixteenthsPerBar, (sixteenthsPerBar) => sixteenthsPerBar % 4)

export const getNoOfBeatsToRender = createSelector(getIsSixTwelveEight, getTimeSignature, getTime, getBars, (sixTwelveEight, timeSignature, time, bars) => {
	// if (sixTwelveEight) {
	// 	if (get(timeSignature, 'top') === 6) {
	// 		return 2 * bars
	// 	}
	// 	return 4 * bars
	// }
	if (time.options !== TimeEnum.MIXED) {
		return Math.ceil((get(timeSignature, 'top') * (get(time, 'highestNoteRate') / get(timeSignature, 'bottom'))) / (get(time, 'highestNoteRate') / 4)) * bars
	}
	return get(timeSignature, 'top') * bars
})

export const getBeatsPerBar = createSelector(getNoOfBeatsToRender, getBars, (getNoOfBeatsToRender, bars) => getNoOfBeatsToRender / bars)
// if (rendering.renderedArray) {
// 	return getNoOfBeatsToRender / bars
// } else {
// 	return false
// }

export const getMsBarDuration = createSelector(
	getTimeSignature,
	getBpm,
	(timeSignature, bpm) => ((get(timeSignature, 'top') * 4) / get(timeSignature, 'bottom')) * (60 / bpm) * 1000
)

export const getNoOfBars = createSelector(getPlaybackState, (playback) => (get(playback, 'isolated') ? get(playback, 'isolatedArray.0.length') : get(playback, 'bars')))

export const getMsRhythmDuration = createSelector(getMsBarDuration, getNoOfBars, (msBarDuration, bars) => msBarDuration * bars)

// TODO for 6/8 improvements
export const getGroupingDuration = createSelector(getIsSixTwelveEight, getTimeToUse, (sixTwelveEight, timeToUse) => {
	if (sixTwelveEight) {
		return 6
	}
	return get(timeToUse, 'highestNoteRate') / 4
})

export const getTimeOptions_back = createSelector(getIsSixTwelveEight, getTimeToUse, (sixTwelveEight, timeToUse) => {
	if (sixTwelveEight) {
		return 64
	}
	return get(timeToUse, 'options')
})
// EOTODO

export const getClickRate = ({ countIn = false }) =>
	createSelector(getClickSettings, getTimeSignature, getCrotchetTime, (click, timeSignature, crotchetTime) => {
		const custom = get(click, 'rate.custom')
		if (custom && !countIn) {
			let rate = crotchetTime
			switch (get(click, 'rate.time')) {
				case ClickRates.EIGHTH:
					rate /= 2
					break
				case ClickRates.SIXTEENTH:
					rate /= 4
					break
				case ClickRates.TRIPLETS:
					rate /= 3
					break
				default:
					break
			}
			return get(click, 'rate.amount') * rate
		}
		if (get(timeSignature, 'bottom') === 16 && get(timeSignature, 'top') % 2 === 0) {
			return crotchetTime / 2
		}
		return crotchetTime * (4 / get(timeSignature, 'bottom'))
	})

/**
 * In six or telve eight - accent every 3 clicks
 */
export const getClickAccentPattern = ({ countIn = false }) =>
	createSelector(getClickSettings, getIsSixTwelveEight, (click, sixTwelveEight) => {
		const custom = get(click, 'rate.custom')
		if (custom && !countIn) {
			return 1
		}
		if (sixTwelveEight) {
			return 3
		}
		return 2
	})

export const getIsStraightTime = createSelector(getTimeToUse, (timeToUse) => timeToUse.options === TimeEnum.STRAIGHT || timeToUse.options === TimeEnum.EIGHTH)
export const getIsTripletTime = createSelector(getTimeToUse, (timeToUse) => timeToUse.options === TimeEnum.TRIPLETS)
export const getIsMixedTime = createSelector(getTimeToUse, (timeToUse) => timeToUse.options === TimeEnum.MIXED)

export const isEightNoteTime = createSelector(getTimeToUse, (timeToUse) => timeToUse.options === 4)

export const isTripletTimeSignature = createSelector(getTimeSignature, (timeSignature) => timeSignature.bottom === 4)

export const toSwingClick = createSelector(getClickSettings, getTimeSignature, getTimeToUse, getIsStraightTime, (click, timeSignature, timeToUse, straightTime) => {
	if (!straightTime) {
		return false
	}
	const custom = get(click, 'rate.custom')
	const bottom = get(timeSignature, 'bottom')
	const top = get(timeSignature, 'top')
	const options = get(timeToUse, 'options')
	const swingSixteenths = bottom === 16 && options === 16 && top % 2 !== 0
	const swingEighths = bottom === 8 && options === 4
	if (!custom) {
		return swingSixteenths || swingEighths
	}
	switch (get(click, 'rate.time')) {
		case ClickRates.QUARTER:
			return false
		case ClickRates.EIGHTH:
			return swingEighths && get(click, 'rate.amount') === 1
		case ClickRates.SIXTEENTH:
			return swingSixteenths && get(click, 'rate.amount') === 1
		default:
			break
	}
})

export const unSwingable = createSelector(getTimeSignature, getTimeToUse, (timeSignature, timeToUse) => {
	const tsTop = get(timeSignature, 'top')
	const tsBottom = get(timeSignature, 'bottom')
	return get(timeToUse, 'options') === 4 && tsBottom === 8 && (tsTop === 6 || tsTop === 12)
})

/**
 * You can't swing 6 or 12/8 in 8th not time
 * Same check as above but without the playbackAs - for use in the
 * 'tunrOnGrooveMode' function
 * @returns {boolean}
 */
export const swingable = createSelector(unSwingable, (unSwingable) => {
	return !unSwingable
})

/**
 * You can't swing 6 or 12/8 in 8th not time
 * @returns {boolean}
 */
export const swingableGroove = createSelector(unSwingable, getPlaybackAs, (unSwingable, playbackAs) => {
	const unSwingableGroove = unSwingable && playbackAs === 2
	return !unSwingableGroove
})

export const isTradeMode = createSelector(getMode, (mode) => mode === ModeEnum.TRADE || mode === ModeEnum.TRADEREADING)

/**
 * Determine whether the time signature can currently be updated
 * @returns {boolean}
 */
export const isTimeSignatureUpdateAllowed = createSelector(getPlaybackState, getRenderingState, getTimeToUse, (playback, rendering, timeToUse) => {
	const rMode = get(rendering, 'mode')
	let disallowedByRenderingMode = false
	switch (rMode) {
		case RenderingModes.SD.TRIPLETS.ONE:
		case RenderingModes.SD.TRIPLETS.TWO:
		case RenderingModes.SD.TRIPLETS.THREE:
		case RenderingModes.SD.TRIPLETS.FOUR:
		case RenderingModes.SD.TRIPLETS.FIVE:
		case RenderingModes.FIRSTGROOVES.ALL:
		case RenderingModes.FIRSTGROOVES.ONE:
		case RenderingModes.FIRSTGROOVES.TWO:
		case RenderingModes.FIRSTGROOVES.THREE:
		case RenderingModes.RHYTHMIC_VOCABULARY_PERMUTATIONS.STRAIGHT:
			disallowedByRenderingMode = true
			break
		default:
			break
	}
	return !get(playback, 'playing') && get(timeToUse, 'options') !== 22 && !disallowedByRenderingMode
})

export const getPracticeState = createSelector(getPlaybackState, (playback) => get(playback, 'practice'))
export const getIsPracticeModeOn = createSelector(getPracticeState, (practice) => get(practice, 'on'))
export const getUserAccuracy = createSelector(getPracticeState, (practice) => get(practice, [`user`, `accuracy`]))
export const getHasInteractedWithPractice = createSelector(
	getPracticeState,
	(practice) => get(practice, 'user.hits.length') > 0 || get(practice, 'user.misses.length') > 0
)

export const getPracticeHistory = createSelector(getPracticeState, (practice) => get(practice, 'history'))
export const getLatestHistory = createSelector(getPracticeHistory, (history) => takeRight(history, 5))
export const getNumberOfPracticeLoops = createSelector(getPracticeHistory, (history) => get(history, 'length'))
export const getAveragePracticeAccuracy = createSelector(getPracticeHistory, getNumberOfPracticeLoops, (history, loops) => {
	let total = 0
	forEach(history, (h) => (total += get(h, `practice.user.accuracy`)))
	return Math.floor(total / loops)
})

export const getIsReadingMode = createSelector(getMode, (mode) => [ModeEnum.CLICKREADING, ModeEnum.LOOPREADING, ModeEnum.TRADEREADING].includes(mode))

export const getPresetConstants = createSelector(getPlaybackState, (playback) => get(playback, [`preset`, `constants`, `presets`]))
export const getRhythmLock = createSelector(getPlaybackState, (playback) => get(playback, [`rhythmLock`]))

// Groove selectors
export const getGrooveSettings = createSelector(getPlaybackState, (playback) => get(playback, [`groove`]))
export const getGrooveLock = createSelector(getGrooveSettings, (groove) => get(groove, [`lock`]))
export const getGrooveBackbeat = createSelector(getGrooveSettings, (groove) => get(groove, [`backbeat`]))
export const getGrooveMix = createSelector(getGrooveSettings, (groove) => get(groove, [`mix`]))
export const getGrooveCymbalSettings = createSelector(getGrooveSettings, (groove) => get(groove, [`cymbal`]))
export const getGrooveCymbalSound = createSelector(getGrooveCymbalSettings, (cymbal) => get(cymbal, [`sound`]))
export const getGrooveCymbalPattern = createSelector(getGrooveCymbalSettings, (cymbal) => get(cymbal, [`pattern`]))

// Rhythmic Vocabulary Permutations
export const getRVPermutationsSettings = createSelector(getPlaybackState, (playback) => playback.rhythmicVocabularyPermutations)
export const getRVPermutationsGroupingIndex = createSelector(getRVPermutationsSettings, (rvSettings) => rvSettings.groupingIndex)
export const getRVPermutationsStartingPoints = createSelector(getRVPermutationsSettings, (rvSettings) => rvSettings.startingPoints)
