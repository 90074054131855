import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import findIndex from 'lodash/findIndex'

import { useIonPicker } from '@ionic/react'

import { getIsPlaying, getLevel } from '../../../../../../redux/selectors/playback'
import { getRenderingMode } from '../../../../../../redux/selectors/rendering'

import RenderingMode from '../../../../../../utils/constants/enum/renderingMode'
import useComplexityLevel from '../../../../../../hooks/useComplexityLevel'
import { setRenderingMode } from '../../../../../../redux/actions'

const useComplexityPicker = () => {
	const dispatch = useDispatch()
	const [present] = useIonPicker()
	const { updateLevel } = useComplexityLevel()

	const level = useSelector(getLevel)
	const playing = useSelector(getIsPlaying)
	const renderingMode = useSelector(getRenderingMode)

	const standardPicker = async () => {
		if (playing) {
			return
		}
		const options = []
		for (let i = 0; i < 5; i++) {
			options.push({
				text: `${i + 1}`,
				value: i + 1,
			})
		}
		const selectedIndex = findIndex(options, (o) => get(o, `value`) === level)

		present({
			columns: [
				{
					name: 'level',
					selectedIndex,
					options,
				},
			],
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'Confirm',
					handler: (value) => {
						updateLevel({ level: get(value, [`level`, `value`]), clearCustom: true })
					},
				},
			],
		})
	}

	const renderingModePicker = async () => {
		if (playing) {
			return
		}

		let options
		switch (renderingMode) {
			case RenderingMode.FIRSTGROOVES.ALL:
			case RenderingMode.FIRSTGROOVES.ONE:
			case RenderingMode.FIRSTGROOVES.TWO:
			case RenderingMode.FIRSTGROOVES.THREE:
				options = [
					{ text: '1', value: RenderingMode.FIRSTGROOVES.ONE },
					{ text: '2', value: RenderingMode.FIRSTGROOVES.TWO },
					{ text: '3', value: RenderingMode.FIRSTGROOVES.THREE },
					{ text: 'All', value: RenderingMode.FIRSTGROOVES.ALL },
				]
				break
			case RenderingMode.SD.TRIPLETS.ONE:
			case RenderingMode.SD.TRIPLETS.TWO:
			case RenderingMode.SD.TRIPLETS.THREE:
			case RenderingMode.SD.TRIPLETS.FOUR:
			case RenderingMode.SD.TRIPLETS.FIVE:
				options = [
					{ text: '1', value: RenderingMode.SD.TRIPLETS.ONE },
					{ text: '2', value: RenderingMode.SD.TRIPLETS.TWO },
					{ text: '3', value: RenderingMode.SD.TRIPLETS.THREE },
					{ text: '4', value: RenderingMode.SD.TRIPLETS.FOUR },
					{ text: '5', value: RenderingMode.SD.TRIPLETS.FIVE },
				]
				break
			default:
				break
		}
		const selectedIndex = findIndex(options, (o) => get(o, `value`) === renderingMode)

		present({
			columns: [
				{
					name: 'renderingMode',
					selectedIndex,
					options,
				},
			],
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'Confirm',
					handler: (value) => {
						dispatch(setRenderingMode(get(value, [`renderingMode`, `value`])))
					},
				},
			],
		})
	}

	const openPicker = () => {
		switch (renderingMode) {
			case RenderingMode.FIRSTGROOVES.ALL:
			case RenderingMode.FIRSTGROOVES.ONE:
			case RenderingMode.FIRSTGROOVES.TWO:
			case RenderingMode.FIRSTGROOVES.THREE:
			case RenderingMode.SD.TRIPLETS.ONE:
			case RenderingMode.SD.TRIPLETS.TWO:
			case RenderingMode.SD.TRIPLETS.THREE:
			case RenderingMode.SD.TRIPLETS.FOUR:
			case RenderingMode.SD.TRIPLETS.FIVE:
				return renderingModePicker()
			default:
		}
		standardPicker()
	}

	return openPicker
}
export default useComplexityPicker
