import React from 'react'
import { connect } from 'react-redux'

import { IonToggle, IonLabel, IonItem } from '@ionic/react'

import { setUseGhosts } from '../../../../../../../redux/actions'
import { getUsingGhosts } from '../../../../../../../redux/selectors/playback'

const GhostNotes = ({ setUseGhosts, usingGhosts }) => {
	const toggle = () => {
		setUseGhosts(!usingGhosts)
	}

	const getLabel = () => `Ghost Notes`

	const getToggleContent = () => <IonToggle checked={usingGhosts} onClick={toggle} />

	return (
		<IonItem>
			<IonLabel>{getLabel()}</IonLabel>
			{getToggleContent()}
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const usingGhosts = getUsingGhosts(state)
	return {
		usingGhosts,
	}
}

export default connect(mapStateToProps, { setUseGhosts })(GhostNotes)
