//Volume Levels
const metronomeOneLevel = 1.275
const metronomeLevel = 0.8125
const metronomeGhostLevel = 0.3125

const kickLevel = 0.62
const snareGhostLevel = 0.032
const hhAccentLevel = 0.22
const hhGhostLevel = 0.066
const rideAccentLevel = 0.21
const rideGhostLevel = 0.07

export { metronomeOneLevel, metronomeLevel, metronomeGhostLevel, kickLevel, snareGhostLevel, hhAccentLevel, hhGhostLevel, rideAccentLevel, rideGhostLevel }
