import get from 'lodash/get'
import some from 'lodash/some'
import isEmpty from 'lodash/isEmpty'

/**
 * Determine whether the practice redux state has been interacted with
 * @param {Object} practiceState
 * @returns {Boolean}
 */
export const hasInteractedWithLatestLoop = (practiceState) => {
	if (get(practiceState, 'user.hits.length') > 0 || get(practiceState, 'user.misses.length') > 0) {
		return true
	}
}

/**
 * Determine whether the practice redux state has been interacted with
 * @param {Object} practiceState
 * @returns {Boolean}
 */
export const hasInteractedWithPracticeState = (practiceState) => {
	if (get(practiceState, 'user.hits.length') > 0 || get(practiceState, 'user.misses.length') > 0) {
		return true
	}
	const history = get(practiceState, 'history', [])
	if (isEmpty(history)) {
		return false
	}
	return some(history, (h) => {
		return get(h, 'practice.user.hits.length') > 0 || get(h, 'practice.user.misses.length') > 0
	})
}
