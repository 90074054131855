import { LanguageEnum } from '../constants/enum/language'

import { triggerTranslation as filters } from './filters'
import { triggerTranslation as canvas } from './canvas'
import { triggerTranslation as credits } from './credits'

export const triggerTranslation = ({ language = LanguageEnum.ENGLISH }) => {
	return {
		filters: filters({ language }),
		canvas: canvas({ language }),
		credits: credits({ language }),
	}
}
