const SixEightSounds = {
	sounds: [
		{
			id: 1,
			arr: [0, 0, 0, 0, 0, 0],
		},
		{
			id: 2,
			arr: [0, 0, 0, 0, 1, 0],
		},
		{
			id: 3,
			arr: [0, 0, 1, 0, 0, 0],
		},
		{
			id: 4,
			arr: [0, 0, 1, 0, 1, 0],
		},
		{
			id: 5,
			arr: [1, 0, 0, 0, 0, 0],
		},
		{
			id: 6,
			arr: [1, 0, 0, 0, 1, 0],
		},
		{
			id: 7,
			arr: [1, 0, 1, 0, 0, 0],
		},
		{
			id: 8,
			arr: [1, 0, 1, 0, 1, 0],
		},
		{
			id: 9,
			arr: [0, 0, 0, 0, 1, 1],
		},
		{
			id: 10,
			arr: [0, 0, 0, 1, 0, 0],
		},
		{
			id: 11,
			arr: [0, 0, 1, 0, 1, 1],
		},
		{
			id: 12,
			arr: [0, 0, 1, 1, 1, 1],
		},
		{
			id: 13,
			arr: [1, 0, 0, 0, 1, 1],
		},
		{
			id: 14,
			arr: [1, 0, 0, 1, 0, 0],
		},
		{
			id: 15,
			arr: [1, 0, 1, 0, 1, 1],
		},
		{
			id: 16,
			arr: [1, 0, 1, 1, 1, 0],
		},
		{
			id: 17,
			arr: [1, 0, 1, 1, 1, 1],
		},
		{
			id: 18,
			arr: [1, 1, 1, 0, 1, 0],
		},
		{
			id: 19,
			arr: [1, 1, 1, 1, 1, 0],
		},
		{
			id: 20,
			arr: [1, 1, 1, 1, 1, 1],
		},
		{
			id: 21,
			arr: [0, 0, 1, 1, 1, 0],
		},
		{
			id: 22,
			arr: [0, 1, 1, 1, 1, 1],
		},
		{
			id: 23,
			arr: [1, 0, 0, 0, 0, 1],
		},
		{
			id: 24,
			arr: [1, 0, 0, 1, 0, 1],
		},
		{
			id: 25,
			arr: [1, 0, 0, 1, 1, 0],
		},
		{
			id: 26,
			arr: [1, 0, 0, 1, 1, 1],
		},
		{
			id: 27,
			arr: [1, 0, 1, 0, 0, 1],
		},
		{
			id: 28,
			arr: [1, 0, 1, 1, 0, 1],
		},
		{
			id: 29,
			arr: [1, 1, 0, 0, 0, 0],
		},
		{
			id: 30,
			arr: [1, 1, 0, 1, 0, 0],
		},
		{
			id: 31,
			arr: [1, 1, 0, 1, 0, 1],
		},
		{
			id: 32,
			arr: [1, 1, 0, 1, 1, 0],
		},
		{
			id: 33,
			arr: [1, 1, 1, 0, 1, 1],
		},
		{
			id: 34,
			arr: [1, 1, 1, 1, 0, 0],
		},
		{
			id: 35,
			arr: [0, 0, 0, 1, 0, 1],
		},
		{
			id: 36,
			arr: [0, 0, 0, 1, 1, 1],
		},
		{
			id: 37,
			arr: [0, 0, 1, 0, 0, 1],
		},
		{
			id: 38,
			arr: [0, 0, 1, 1, 0, 0],
		},
		{
			id: 39,
			arr: [0, 0, 1, 1, 0, 1],
		},
		{
			id: 40,
			arr: [0, 1, 0, 1, 0, 0],
		},
		{
			id: 41,
			arr: [0, 1, 0, 1, 0, 1],
		},
		{
			id: 42,
			arr: [0, 1, 1, 0, 1, 0],
		},
		{
			id: 43,
			arr: [1, 0, 1, 1, 0, 0],
		},
		{
			id: 44,
			arr: [1, 1, 0, 0, 1, 1],
		},
		{
			id: 45,
			arr: [1, 1, 1, 0, 0, 0],
		},
		{
			id: 46,
			arr: [1, 1, 1, 0, 0, 1],
		},
		{
			id: 47,
			arr: [1, 1, 1, 1, 0, 1],
		},
		{
			id: 48,
			arr: [0, 0, 0, 0, 0, 1],
		},
		{
			id: 49,
			arr: [0, 0, 0, 1, 1, 0],
		},
		{
			id: 50,
			arr: [0, 1, 0, 0, 0, 0],
		},
		{
			id: 51,
			arr: [0, 1, 0, 0, 0, 1],
		},
		{
			id: 52,
			arr: [0, 1, 0, 0, 1, 0],
		},
		{
			id: 53,
			arr: [0, 1, 0, 0, 1, 1],
		},
		{
			id: 54,
			arr: [0, 1, 0, 1, 1, 0],
		},
		{
			id: 55,
			arr: [0, 1, 0, 1, 1, 1],
		},
		{
			id: 56,
			arr: [0, 1, 1, 0, 0, 0],
		},
		{
			id: 57,
			arr: [0, 1, 1, 0, 0, 1],
		},
		{
			id: 58,
			arr: [0, 1, 1, 0, 1, 1],
		},
		{
			id: 59,
			arr: [0, 1, 1, 1, 0, 0],
		},
		{
			id: 60,
			arr: [0, 1, 1, 1, 0, 1],
		},
		{
			id: 61,
			arr: [0, 1, 1, 1, 1, 0],
		},
		{
			id: 62,
			arr: [1, 1, 0, 0, 0, 1],
		},
		{
			id: 63,
			arr: [1, 1, 0, 0, 1, 0],
		},
		{
			id: 64,
			arr: [1, 1, 0, 1, 1, 1],
		},
	],
}
export default SixEightSounds
