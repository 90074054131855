import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { IonItem, IonLabel, IonRange } from '@ionic/react'

import { getIsPlaying, getSpace } from '../../../../../../redux/selectors/playback'
import { setSpace } from '../../../../../../redux/actions'
import { getRenderingMode } from '../../../../../../redux/selectors/rendering'

import RenderingMode from '../../../../../../utils/constants/enum/renderingMode'

const Space = ({ playing, space, renderingMode, setSpace }) => {
	const getLabel = () => `Space`

	const disabled = () => {
		if (playing) return true
		switch (renderingMode) {
			case RenderingMode.FIRSTGROOVES.ALL:
			case RenderingMode.FIRSTGROOVES.ONE:
			case RenderingMode.FIRSTGROOVES.TWO:
			case RenderingMode.FIRSTGROOVES.THREE:
			case RenderingMode.SD.TRIPLETS.ONE:
			case RenderingMode.SD.TRIPLETS.TWO:
			case RenderingMode.SD.TRIPLETS.THREE:
			case RenderingMode.SD.TRIPLETS.FOUR:
			case RenderingMode.SD.TRIPLETS.FIVE:
				return true
			default:
				break
		}
		return false
	}

	return (
		<>
			<IonItem disabled={disabled()}>
				<IonLabel>{getLabel()}</IonLabel>
				<IonRange
					value={space}
					onIonChange={(e) => {
						setSpace(get(e, [`detail`, `value`]))
					}}
				></IonRange>
			</IonItem>
		</>
	)
}

const mapStateToProps = (state) => {
	const space = getSpace(state)
	const playing = getIsPlaying(state)
	const renderingMode = getRenderingMode(state)
	return {
		space,
		playing,
		renderingMode,
	}
}

export default connect(mapStateToProps, { setSpace })(Space)
