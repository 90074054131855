import React from 'react'
import { connect } from 'react-redux'

import get from 'lodash/get'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import RBToggleButton from '../ToggleButton.js'
import Slider from '@mui/material/Slider'

import { getGrooveSettings, getPlaybackState, getTimeSignatureHasBackbeat, swingableGroove } from '../../redux/selectors/playback'
import { getRenderingState, getThemeName } from '../../redux/selectors/rendering'
import { setGrooveCymbalPattern, setGrooveCymbalSound, setGrooveBackbeat, setGrooveGhosts, setSwing, setGrooveMix, setSpace } from '../../redux/actions'

import { percentageValueText, desktop } from '../../utils/functions'
import { GrooveCymbal } from '../../utils/constants/radio-buttons'
import { swingSliderMarks, swingSliderMarksMobile, mainSliderMarks } from '../../utils/constants/sliders'
import TimeEnum from '../../utils/constants/enum/time'

const GrooveModal = (props) => {
	const getBackbeatOptions = () => {
		let options = [0, 1, 2, 3, 4]
		if (get(props, 'defaultBackbeat')) {
			options.push(5)
		}
		return options
	}

	const swingSliderChange = (_, newValue) => props.setSwing(newValue)

	const mainSliderChange = (_, newValue) => props.setGrooveMix(newValue)

	return (
		<>
			<Modal animation={false} show={props.show} onHide={props.toggle} keyboard={false} dialogClassName={`${get(props, `themeName`, `light`)} ${props.device}`}>
				<Modal.Header closeButton>
					<div className="header">
						<Modal.Title>{`Configure Groove`}</Modal.Title>
					</div>
				</Modal.Header>
				<Modal.Body className={`${get(props, `themeName`, `light`)} ${props.device}`}>
					<div className="groove">
						<div className="control-group-modal cymbal">
							<div className="header-row">
								<RBToggleButton
									playEnabled={true}
									grooveModal={true}
									defaultVal={props.playback.groove.cymbal.sound}
									radios={GrooveCymbal}
									callback={props.setGrooveCymbalSound}
								></RBToggleButton>
							</div>

							<div className="grid-container modal-cymbal">
								{props.rendering.modalHHArray.map((item, index) => (
									<img
										key={index}
										alt="notation"
										className={props.groove.cymbal.pattern === item ? 'selected cymbal' : 'cymbal'}
										onClick={() => {
											props.setGrooveCymbalPattern(item)
										}}
										src={require(`../../images/cymbal/${item}.png`)}
									/>
								))}
							</div>
						</div>
						<div className="control-group-modal">
							<div className="text bold size-21">{`Backbeat`}</div>
							<div className="grid-container modal-backbeat">
								{getBackbeatOptions().map((item, index) => (
									<img
										key={index}
										alt="notation"
										className={props.groove.backbeat === item ? 'selected backbeat' : 'backbeat'}
										onClick={() => {
											props.setGrooveBackbeat(item)
										}}
										src={require(`../../images/backbeat/${item}.png`)}
									/>
								))}
							</div>
						</div>
						<div className="control-group-modal">
							<div className="text bold size-21">Main Rhythm</div>
							<div className="content swing">
								<Slider
									size={`${desktop(get(props, 'device')) ? `small` : `medium`}`}
									className="main-rhythm"
									key={`slider-${props.groove.mix}`}
									defaultValue={props.groove.mix}
									getAriaValueText={percentageValueText}
									valueLabelFormat={percentageValueText}
									onChangeCommitted={mainSliderChange}
									aria-labelledby="discrete-slider-custom"
									step={1}
									valueLabelDisplay="auto"
									marks={mainSliderMarks}
								/>
							</div>
						</div>
						{get(props, 'rendering.showSwing') && get(props, 'isSwingable') && (
							<div className="control-group-modal">
								<div className="text bold size-21">Swing Level</div>
								<div className="content swing">
									<Slider
										size={`${desktop(get(props, 'device')) ? `small` : `medium`}`}
										className="swing"
										key={`slider-${props.playback.swing}`}
										defaultValue={props.playback.swing}
										getAriaValueText={percentageValueText}
										valueLabelFormat={percentageValueText}
										onChangeCommitted={swingSliderChange}
										aria-labelledby="discrete-slider-custom"
										step={1}
										valueLabelDisplay="auto"
										marks={desktop(props.device) ? swingSliderMarks : swingSliderMarksMobile}
									/>
								</div>
							</div>
						)}
					</div>
				</Modal.Body>
				<Modal.Footer className={`${get(props, `themeName`, `light`)} ${props.device}`}>
					<div className="left">
						<Button
							onClick={() => {
								if ([TimeEnum.EIGHTH, TimeEnum.STRAIGHT].includes(props.playback.time.options)) {
									props.setSwing(0)
								}
								props.setGrooveMix(0)
								props.turnOnGrooveMode({ random: false, time: props.playback.time.options })
							}}
							className="select action-2"
						>
							Default
						</Button>
						<Button
							className="select random action-2"
							onClick={() => {
								props.turnOnGrooveMode({ random: true, time: props.playback.time.options })
							}}
						>
							Random
						</Button>
					</div>
					<div className="right">
						<Button
							className="action-3"
							onClick={() => {
								props.toggle()
							}}
						>
							Confirm
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const playback = getPlaybackState(state)
	const defaultBackbeat = getTimeSignatureHasBackbeat(state)
	const isSwingable = swingableGroove(state)
	const themeName = getThemeName(state)
	const groove = getGrooveSettings(state)

	return { rendering, playback, defaultBackbeat, isSwingable, themeName, groove }
}

export default connect(mapStateToProps, {
	setGrooveCymbalPattern,
	setGrooveCymbalSound,
	setGrooveBackbeat,
	setGrooveGhosts,
	setSwing,
	setGrooveMix,
	setSpace,
})(GrooveModal)
