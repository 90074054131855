import set from 'lodash/set'
import get from 'lodash/get'
import includes from 'lodash/includes'
import split from 'lodash/split'

import Times from '../constants/enum/time'
import RenderingModes from '../constants/enum/renderingMode'
import Modes from '../constants/enum/mode'
import { Sounds } from '../constants/enum/sounds'
import { timeSignatureLimits } from '../functions'

/**
 * Validate all the main settings in the query
 * @returns {Object} an object containing useable versions of all the
 * core settings for use in the rest of the function
 */
export const querySettingsValidation = ({ time, renderingMode, playbackMode, playbackAs, timeSignature }) => {
	// An object to return validated versions of each core setting
	let ret = {
		time,
		renderingMode,
		playbackMode,
		playbackAs,
		timeSignature: false,
	}
	switch (time) {
		case false:
		case Times.STRAIGHT:
		case Times.EIGHTH:
		case Times.TRIPLETS:
		case Times.MIXED:
			break
		default:
			set(ret, `time`, false)
			break
	}
	switch (renderingMode) {
		case false:
		case RenderingModes.NORMAL:
		case RenderingModes.DOWNUP.STRAIGHT:
		case RenderingModes.DOWNUP.TRIPLETS:
		case RenderingModes.SD.TRIPLETS.ONE:
		case RenderingModes.SD.TRIPLETS.TWO:
		case RenderingModes.SD.TRIPLETS.THREE:
		case RenderingModes.SD.TRIPLETS.FOUR:
		case RenderingModes.SD.TRIPLETS.FIVE:
		case RenderingModes.FIRSTGROOVES.ONE:
		case RenderingModes.FIRSTGROOVES.TWO:
		case RenderingModes.FIRSTGROOVES.THREE:
		case RenderingModes.FIRSTGROOVES.ALL:
		case RenderingModes.RHYTHMIC_VOCABULARY_PERMUTATIONS.STRAIGHT:
			break
		default:
			set(ret, `renderingMode`, RenderingModes.NORMAL)
			break
	}
	switch (playbackMode) {
		case false:
		case Modes.ONCE:
		case Modes.LOOP:
		case Modes.LOOPREADING:
		case Modes.TRADE:
		case Modes.TRADEREADING:
		case Modes.CLICK:
		case Modes.CLICKREADING:
			break
		default:
			set(ret, `playbackMode`, false)
			break
	}
	switch (playbackAs) {
		case false:
		case Sounds.SNARE:
		case Sounds.KICK:
		case Sounds.GROOVE:
			break
		default:
			set(ret, `playbackAs`, false)
			break
	}

	switch (renderingMode) {
		// These modes don't listen to time signature anyway
		case RenderingModes.SD.TRIPLETS.ONE:
		case RenderingModes.SD.TRIPLETS.TWO:
		case RenderingModes.SD.TRIPLETS.THREE:
		case RenderingModes.SD.TRIPLETS.FOUR:
		case RenderingModes.SD.TRIPLETS.FIVE:
		case RenderingModes.FIRSTGROOVES.ONE:
		case RenderingModes.FIRSTGROOVES.TWO:
		case RenderingModes.FIRSTGROOVES.THREE:
		case RenderingModes.FIRSTGROOVES.ALL:
		case RenderingModes.RHYTHMIC_VOCABULARY_PERMUTATIONS.STRAIGHT:
			return ret
		default:
	}
	if (timeSignature === false) {
		return ret
	}
	const ts = split(timeSignature, ':')
	if (get(ts, 'length') !== 2) {
		return ret
	}
	let validBottoms
	let validTops = false
	switch (true) {
		case renderingMode === RenderingModes.DOWNUP.STRAIGHT:
		case renderingMode === RenderingModes.DOWNUP.TRIPLETS:
			validBottoms = [4]
			validTops = [2, 4]
			break
		case time === Times.TRIPLETS:
			validBottoms = [4]
			break
		case time === Times.MIXED:
			validBottoms = [4]
			validTops = [4]
			break
		case time === Times.EIGHTH:
			validBottoms = [4, 8]
			break
		default:
			validBottoms = [4, 8, 16]
			break
	}
	const tsBottom = Number(ts[1])
	if (!includes(validBottoms, tsBottom)) {
		return ret
	}
	const tsTop = Number(ts[0])
	if (validTops && !includes(validTops, tsTop)) {
		return ret
	}
	const tsLimits = timeSignatureLimits(tsBottom)
	if (tsTop >= get(tsLimits, `limits.lower`) && tsTop <= get(tsLimits, `limits.upper`)) {
		// This time signature is now valid
		set(ret, `timeSignature`, timeSignature)
	}
	return ret
}

export const queryIsNumber = (val) => val && isFinite(val)

/**
 * Determine if the provided query params are encoded
 * @param {String} queryParams
 * @returns {Object} whether the string is encoded and it's decoded version
 */
export const containsEncodedComponents = ({ queryParams = false }) => {
	if (!queryParams) return {}
	const decoded = decodeURIComponent(queryParams)
	return {
		contains: decodeURI(queryParams) !== decoded,
		decoded,
	}
}
