import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import map from 'lodash/map'
import chunk from 'lodash/chunk'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import some from 'lodash/some'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import forEach from 'lodash/forEach'
import flattenDeep from 'lodash/flattenDeep'
import isUndefined from 'lodash/isUndefined'
import find from 'lodash/find'

import ReactEcharts from 'echarts-for-react'

import Bar from '../Bar'
import Modal from 'react-bootstrap/Modal'
import backArrow from '../../images/left-arrow.png'

import { getRenderingState, getThemeName } from '../../redux/selectors/rendering'
import { getPlaybackState, getNumberOfPracticeLoops, getAveragePracticeAccuracy, getLatestHistory } from '../../redux/selectors/playback'

import { getBarImage } from '../../utils/images'
import TimeEnum from '../../utils/constants/enum/time'
import { arrayEquals } from '../../utils/functions'

const PracticeModal = (props) => {
	const className = `${get(props, `device`)} ${get(props, `themeName`, `light`)} practice`

	const [runFocus, setRunFocus] = useState(false)

	const [chartOptions, setChartOptions] = useState(false)
	const [xAxis, setXAxis] = useState(false)
	const [yAxis] = useState({ type: 'value', min: 0, max: 100 })
	const [series, setSeries] = useState(false)

	const overviewChartEvents = {
		click: (bar) => {
			get(bar, 'event.event').preventDefault()
			setRunFocus(get(bar, 'dataIndex') + 1)
		},
	}

	// Update the chart options as things change
	useEffect(() => {
		setChartOptions({
			xAxis,
			yAxis,
			series,
			animationDuration: 350,
		})
	}, [xAxis, series, yAxis])

	// Populate the overview chart on first render
	useEffect(() => {
		const history = get(props, `history`, [])
		if (isEmpty(history)) {
			return get(props, `toggle`)()
		}
		if (get(history, `length`) === 1) {
			return setRunFocus(1)
		}
		let xAxis = {
			type: 'category',
			data: [],
		}
		let series = {
			type: 'bar',
			data: [],
		}
		const noOfLoops = get(props, [`history`, `length`])
		let i = 0
		while (i < noOfLoops) {
			get(xAxis, 'data').push(i + 1 === noOfLoops ? 'Latest' : `${i + 1}`)
			get(series, 'data').push({
				value: Math.floor(get(props, [`history`, i, 'practice', 'user', 'accuracy'])),
				itemStyle: { color: '#008a8f' },
			})
			i++
		}
		setXAxis(xAxis)
		setSeries(series)
	}, [])

	/**
	 * Render the modal body
	 * @returns {JSX}
	 */
	const renderBody = () => {
		if (!runFocus) {
			return renderChart()
		}
		return renderRunStats()
	}

	/**
	 * Get the text summary of a run
	 * @param {Number} index of the run
	 * @returns {String}
	 */
	const getRunSummary = (index) => {
		const userStats = get(props, [`history`, index, 'practice', 'user'])
		const appState = get(props, [`history`, index, 'appState'])

		const accuracy = Math.floor(get(userStats, ['accuracy']))
		const hits = get(userStats, ['hits', 'length'])
		const misses = get(userStats, ['misses', 'length'])
		const timeSignature = `${get(appState, ['timeSignature', 'top'])}/${get(appState, ['timeSignature', 'bottom'])}`

		return `${accuracy}% | ${hits} ${hits > 1 ? `hits` : `hit`} | ${misses} ${misses === 0 || misses > 1 ? `misses` : `miss`} | ${timeSignature}`
	}

	/**
	 * Get the statistics of a run
	 * @param {Number} index of the run
	 * @returns
	 */
	const getRunData = (index) => get(props, [`history`, index])

	const getBarSettings = () => {
		if (!runFocus) return
		return get(getRunData(runFocus - 1), 'appState.barSettings')
	}

	/**
	 * Render the header for a specific run
	 * @returns {JSX}
	 */
	const renderRunHeader = () => {
		const noOfLoops = get(props, [`history`, `length`])

		const header = runFocus === noOfLoops ? `Latest Loop:` : `Loop ${runFocus}:`
		const stats = getRunSummary(runFocus - 1)
		const textContent = (
			<span className="text medium size-18">
				{`${header} `}
				<span className="text light size-18">{stats}</span>
			</span>
		)
		if (get(props, `noOfLoops`) === 1) {
			return textContent
		}
		return (
			<>
				<div className="split-title loop-header">
					<img
						key={`back-arrow`}
						alt="back-arrow"
						className={`back-arrow`}
						onClick={() => {
							setRunFocus(false)
						}}
						src={backArrow}
					></img>
					{textContent}
				</div>
			</>
		)
	}

	/**
	 * Render stats for specific run
	 * @returns {JSX}
	 */
	const renderRunStats = () => {
		if (!runFocus) return
		return (
			<>
				{renderRunHeader()}
				{renderRhythms()}
			</>
		)
	}

	/**
	 * @returns {JSX}
	 */
	const renderRhythms = () => {
		const playedRhythm = renderPlayedRhythm()
		const playedRhythmCorrect = get(playedRhythm, `correct`, false)
		const playedRhythmElement = get(playedRhythm, `element`, false)
		if (playedRhythmCorrect) {
			return <div className="rhythm-results correct">{playedRhythmElement}</div>
		}
		return (
			<div className="rhythm-results">
				{renderCorrectRhythm()}
				{playedRhythmElement}
			</div>
		)
	}

	/**
	 * Get the correct bars (e.g. what should have been played)
	 * @returns {Array} Image keys to use
	 */
	const getCorrectBars = () => {
		if (!runFocus) return
		const history = getRunData(runFocus - 1)
		const rhythm = get(history, [`appState`, `imageKeys`], [])
		if (isEmpty(rhythm)) {
			return
		}
		const chunkSize = get(history, [`appState`, `beatsPerBar`], 4)
		return chunk(rhythm, chunkSize)
	}

	/**
	 * Get the played bars (e.g. what the user played)
	 * @returns {Array} Image keys to use
	 */
	const getPlayedBars = () => {
		if (!runFocus) return
		const history = getRunData(runFocus - 1)

		// Gather user stats
		const userPositionsPlayed = get(history, ['practice', 'user', 'played', 'positions'], [])
		const userHits = get(history, ['practice', 'user', 'hits'], [])
		const userMisses = get(history, ['practice', 'user', 'misses'], [])
		const noOfUserMisses = get(userMisses, ['length'], 0)
		const userAccuracy = get(history, ['practice', 'user', 'accuracy'], 0)

		if (noOfUserMisses === 0 && userAccuracy >= 100) {
			// If this loop is perfectly accurate just return the correct bars
			return { correct: true, bars: getCorrectBars() }
		}

		const time = get(history, ['appState', 'time'])

		const isSixTwelveEight = get(history, ['appState', 'isSixTwelveEight'])
		const subdivisionBarDuration = get(history, ['appState', 'subdivisionBarDuration'], 16)
		const correctOnesAndZeros = get(history, ['appState', 'onesAndZeros'])
		const beatsPerBar = get(history, ['appState', 'beatsPerBar'])

		const noOfBars = get(history, ['appState', 'noOfBars'])
		const numberOfSubdivisions = get(correctOnesAndZeros, ['length'])
		const shortBeatLength = get(history, ['appState', 'shortBeatLength'])

		let playedOnesAndZeros = JSON.parse(JSON.stringify(correctOnesAndZeros))

		// Rhythms are either evaluated using the position or timingIndex system
		const usingPositions = some(userHits, (h) => isNumber(get(h, 'position'))) || some(userMisses, (h) => isNumber(get(h, 'position')))
		switch (usingPositions) {
			case true:
				playedOnesAndZeros = []
				for (let i = 0; i < numberOfSubdivisions; i++) {
					if (includes(userPositionsPlayed, i)) {
						playedOnesAndZeros.push(1)
						continue
					}
					playedOnesAndZeros.push(0)
				}
				break
			default:
				const hits = map(userHits, (h) => get(h, 'stats'))
				const misses = map(userMisses, (h) => get(h, 'stats'))

				const beatLimit = isSixTwelveEight ? 6 : 4
				const bars = []
				for (let bar = 0; bar < noOfBars; bar++) {
					let barArray = []
					for (let beat = 0; beat < beatsPerBar; beat++) {
						const playedWithinBeat = [
							...filter(misses, (mm) => get(mm, `bar`) === bar && get(mm, `beat`) === beat),
							...filter(hits, (mm) => get(mm, `bar`) === bar && get(mm, `beat`) === beat),
						]

						if (isEmpty(playedWithinBeat)) {
							if (beat !== beatsPerBar - 1 || shortBeatLength === 0) {
								let emptyBeat = new Array(beatLimit + 1).fill(0)
								emptyBeat[0] = 4
								barArray = [...barArray, ...emptyBeat]
								continue
							}
							let emptyBeat = new Array(shortBeatLength + 1).fill(0)
							emptyBeat[0] = 4
							barArray = [...barArray, ...emptyBeat]
							continue
						}
						const straightHits = filter(playedWithinBeat, (p) => get(p, 'straight'))
						const tripletHits = filter(playedWithinBeat, (p) => !get(p, 'straight'))
						let straightArray = new Array(beatLimit + 1).fill(0)
						straightArray[0] = 4
						if (beat === beatsPerBar - 1 && shortBeatLength !== 0) {
							straightArray = new Array(shortBeatLength + 1).fill(0)
							straightArray[0] = 4
						}
						let tripletArray = [4, 2, 2, 2]
						let noOfStraightHits = 0
						let noOfTripletHits = 0
						forEach(straightHits, (sh) => {
							straightArray[get(sh, 'beatPosition') + 1] = 1
							if (get(sh, 'beatPosition') === 0) {
								tripletArray[get(sh, 'beatPosition') + 1] = 3
								return
							}
							noOfStraightHits++
						})
						forEach(tripletHits, (th) => {
							tripletArray[get(th, 'beatPosition') + 1] = 3
							if (get(th, 'beatPosition') === 0) {
								straightArray[get(th, 'beatPosition') + 1] = 1
								return
							}
							noOfTripletHits++
						})
						if ((beat === beatsPerBar - 1 && shortBeatLength !== 0) || noOfStraightHits > noOfTripletHits || isSixTwelveEight) {
							// if (straightArray[2] === 0 && tripletArray[2] === 3) {
							// 	straightArray[2] = 1
							// }
							// if (straightArray[4] === 0 && tripletArray[3] === 3) {
							// 	straightArray[4] = 1
							// }
							barArray = [...barArray, ...straightArray]
							continue
						}
						// if (tripletArray[2] === 2 && straightArray[3] === 1) {
						// 	tripletArray[2] = 3
						// }
						// if (tripletArray[3] === 2 && straightArray[4] === 1) {
						// 	tripletArray[3] = 3
						// }
						barArray = [...barArray, ...tripletArray]
					}
					bars.push(barArray)
				}
				playedOnesAndZeros = flattenDeep(bars)
				break

			// const isEighthNoteTime = time === TimeEnum.EIGHTH
			// if (isEighthNoteTime) {
			// 	playedOnesAndZeros = new Array(get(playedOnesAndZeros, 'length')).fill(0)
			// 	forEach(userHits, (h) => {
			// 		const position = Math.max(0, Math.round((get(h, 'timing') - rhythmStartTime) / (crotchetTime / 4)))
			// 		playedOnesAndZeros[position] = 1
			// 	})
			// 	forEach(userMisses, (m) => {
			// 		const position = Math.max(0, Math.round((get(m, 'timing') - rhythmStartTime) / (crotchetTime / 4)))
			// 		playedOnesAndZeros[position] = 1
			// 	})
			// } else {
			// 	let currentHit, currentMiss
			// 	currentHit = currentMiss = 0
			// 	playedOnesAndZeros = map(playedOnesAndZeros, (po) => {
			// 		if (po === 4) {
			// 			return po
			// 		}
			// 		const miss = po !== 1 && po !== 3
			// 		if (miss) {
			// 			currentMiss++
			// 			if (currentMiss % 1 !== 0) {
			// 				return po
			// 			}
			// 			if (!includes(positionsMissed, currentMiss - 1)) {
			// 				return po
			// 			}
			// 			if (po === 2 || po === 3) {
			// 				return 3
			// 			} else {
			// 				return 1
			// 			}
			// 		}
			// 		currentHit++
			// 		if (currentHit % 1 !== 0) {
			// 			return po
			// 		}
			// 		if (includes(positionsHit, currentHit - 1)) {
			// 			return po
			// 		}
			// 		if (po === 1) {
			// 			return 0
			// 		} else {
			// 			return 2
			// 		}
			// 	})
			// }

			// if (mixedMisses) {
			// 	let chunkSize = 4
			// 	if (time === TimeEnum.TRIPLETS) {
			// 		chunkSize = 3
			// 	}
			// 	// Now the whole rhythm must become mixed
			// 	let bars = chunk(playedOnesAndZeros, subdivisionBarDuration)
			// 	bars = map(bars, (b) => map(chunk(b, chunkSize), (chunk) => [4, ...chunk]))

			// 	// TODO if it's mixed time it'll already be in the state
			// 	// with 4's

			// 	const beatsToChange = {}

			// 	forEach(mixedMisses, (mm) => {
			// 		if (isUndefined(mm)) {
			// 			return
			// 		}
			// 		const bar = get(mm, `bar`)
			// 		const beat = get(mm, `beat`)
			// 		const straight = get(mm, `straight`)

			// 		let beatToChange = get(bars, [`${bar}`, `${beat}`])
			// 		let straightPositions = get(beatsToChange, [`${bar}`, `${beat}`, 'straightPositions'], [])
			// 		let tripletPositions = get(beatsToChange, [`${bar}`, `${beat}`, 'tripletPositions'], [])
			// 		const position = get(mm, `beatPosition`)
			// 		if (straight) {
			// 			straightPositions.push(position)
			// 		} else {
			// 			tripletPositions.push(position)
			// 		}

			// 		set(beatsToChange, [`${bar}`, `${beat}`], { ...get(beatsToChange, [`${bar}`, `${beat}`]), beatToChange, straightPositions, tripletPositions })
			// 	})

			// 	forEach(beatsToChange, (btc, bar) => {
			// 		forEach(btc, (stats, position) => {
			// 			if (isUndefined(stats)) {
			// 				return
			// 			}
			// 			const beatToChange = get(stats, `beatToChange`)

			// 			const currentlyStraight = time === TimeEnum.STRAIGHT || get(beatToChange, 'length') === 5

			// 			const hasTripletHits = !isEmpty(get(stats, `tripletPositions`))
			// 			const hasStraightHits = !isEmpty(get(stats, `straightPositions`))
			// 			let newBeat = []
			// 			if (hasTripletHits && hasStraightHits) {
			// 				errorOccurred(`PracticeModal.getPlayedBars - both straight and triplet missed hits`)
			// 			}
			// 			if (currentlyStraight && hasTripletHits) {
			// 				newBeat = [4, 2, 2, 2]
			// 				forEach(get(stats, `tripletPositions`), (tp) => {
			// 					set(newBeat, tp + 1, 3)
			// 				})
			// 				if (beatToChange[1] === 1) {
			// 					set(newBeat, 1, 3)
			// 				}
			// 				set(bars, [bar, position], newBeat)
			// 				return
			// 			}
			// 			if (hasStraightHits) {
			// 				// newBeat = [4, 0, 0, 0, 0]
			// 				// forEach(get(stats, `straightPostions`), (sp) => {
			// 				// 	set(newBeat, sp + 1, 1)
			// 				// })
			// 				// if (beatToChange[1] === 3) {
			// 				// 	set(newBeat, 1, 1)
			// 				// }
			// 				// console.log(newBeat)
			// 				// set(bars, [bar, position], newBeat)
			// 				// return
			// 			}
			// 		})
			// 	})

			// 	playedOnesAndZeros = flattenDeep(bars)

			// 	// let newOnesAndZeros
			// 	// map(bars, (b) => {
			// 	// 	map(b, (bb) => [4, ...bb]}
			// 	// })
			// 	// console.log(bars)
			// }

			// return getCorrectBars()

			// let straightPositions = []
			// let tripletPositions = []
			// forEach(userTimings, (ut) => {
			// 	// Each of these is a time that the user presses the screen
			// 	// This time minus the start time is when in the rhythm was played
			// 	const rhythmTime = ut - startTime
			// 	switch (time) {
			// 		case TimeEnum.STRAIGHT:
			// 		case TimeEnum.EIGHTH:
			// 			timingPositions.push(Math.round(rhythmTime / (crotchetTime / 4)))
			// 			break
			// 		case TimeEnum.TRIPLETS:
			// 			timingPositions.push(Math.round(rhythmTime / (crotchetTime / 3)))
			// 			break
			// 		case TimeEnum.MIXED:
			// 			// TODO
			// 			const closestTriplet = rhythmTime / (crotchetTime / 3)
			// 			const closestStraight = rhythmTime / (crotchetTime / 4)
			// 			console.log(closestStraight)
			// 			console.log(closestTriplet)
			// 			if (closestTriplet % 1 < closestStraight % 1) {
			// 				tripletPositions.push(Math.floor(closestTriplet))
			// 				break
			// 			}
			// 			straightPositions.push(Math.floor(closestStraight))
			// 			break
			// 	}
			// })
			// if (time === TimeEnum.MIXED) {
			// 	// return getCorrectBars()
			// 	// console.log(straightPositions)
			// 	// console.log(tripletPositions)
			// 	console.warn('TODO - Implement MIXED time')
			// 	return getCorrectBars()
			// }
		}

		if (arrayEquals(correctOnesAndZeros, playedOnesAndZeros)) {
			return { correct: true, bars: getCorrectBars() }
		}

		// Generate bars for what was played
		let bars = []
		let beatInfo = []
		const pushToBeatInfo = (latestBeat) => {
			if (isEmpty(latestBeat)) {
				return
			}
			if (get(latestBeat, 'length') === 6) {
				beatInfo.push({ class: `straight`, straight: true })
			} else if (arrayEquals(latestBeat, [4, 3, 2, 2])) {
				beatInfo.push({ class: `straight`, straight: true })
			} else if (includes(latestBeat, 2) || includes(latestBeat, 3)) {
				beatInfo.push({ class: `mixed`, straight: false })
			} else {
				beatInfo.push({ class: `straight`, straight: true })
			}
		}
		let latestBeat = []
		if (time === TimeEnum.MIXED || includes(playedOnesAndZeros, 4)) {
			let noOfFours = 0
			let b = []
			forEach(playedOnesAndZeros, (p) => {
				if (p === 4) {
					pushToBeatInfo(latestBeat)
					noOfFours++
					latestBeat = []
				}
				if (noOfFours === beatsPerBar + 1) {
					noOfFours = 1
					bars.push(b)
					b = []
				}
				latestBeat.push(p)
				b.push(p)
			})
			pushToBeatInfo(latestBeat)
			bars.push(b)
			b = []
			latestBeat = []
		} else {
			bars = chunk(playedOnesAndZeros, subdivisionBarDuration)
		}

		return { correct: false, beatInfo, bars: map(bars, (b) => get(props, 'playThisToImages')({ bars: [b], allowSixEight: true })) }
	}

	/**
	 * Render the correct rhythm
	 * @returns {JSX}
	 */
	const renderCorrectRhythm = () => {
		const bars = getCorrectBars()
		const barSettings = getBarSettings()

		return (
			<div className="result correct-rhythm">
				<div className="text bold size-18">{`Correct Rhythm`}</div>
				<div className="bars-container practice-summary">
					{map(bars, (bar, i) => {
						const beats = map(bar, (b) => getBarImage({ imageNumber: b, path: get(barSettings, `path`, `straight`) }))
						return <Bar highlighted={``} beats={beats} key={i + 1} barNo={i + 1} className={get(barSettings, `style`, `straight`)} />
					})}
				</div>
			</div>
		)
	}

	/**
	 * Render what the user played
	 * @returns {JSX}
	 */
	const renderPlayedRhythm = () => {
		const history = getRunData(runFocus - 1)
		const isSixTwelveEight = get(history, ['appState', 'isSixTwelveEight'])

		const playedBars = getPlayedBars()
		const correct = get(playedBars, 'correct')
		const beatInfo = get(playedBars, 'beatInfo')
		const containsStraight = !isUndefined(find(beatInfo, (b) => get(b, `straight`)))
		const containsTriplets = !isUndefined(find(beatInfo, (b) => !get(b, `straight`)))
		const barSettings = getBarSettings()

		let className = get(barSettings, `style`, `straight`)
		if (containsStraight && containsTriplets) {
			className = `mixed`
		} else if (containsStraight) {
			className = `straight`
		} else if (containsTriplets) {
			className = `swung`
		}

		let beatCounter = -1
		return {
			correct,
			element: (
				<div className={`result played-rhythm`}>
					<div className="text bold size-18">{correct ? `You Correctly Played` : `You Played`}</div>
					<div className="bars-container practice-summary">
						{map(get(playedBars, 'bars'), (bar, i) => {
							const beats = map(bar, (b) => {
								beatCounter++
								return getBarImage({
									imageNumber: b,
									path: isSixTwelveEight ? get(barSettings, `path`) : get(beatInfo, [beatCounter, `class`], `straight`),
								})
							})
							return <Bar highlighted={``} beats={beats} key={i + 1} barNo={i + 1} className={className} />
						})}
					</div>
				</div>
			),
		}
	}

	/**
	 * Render overview chart showing stats for the latest <=5 loops
	 * @returns {JSX}
	 */
	const renderChart = () => {
		if (!chartOptions) return
		if (!get(chartOptions, 'series') || !get(chartOptions, 'xAxis') || !get(chartOptions, 'yAxis')) {
			return
		}
		return (
			<>
				<div className="text bold size-20">{`Latest Loops`}</div>
				<div id="chart" className="chart">
					<ReactEcharts onEvents={overviewChartEvents} option={chartOptions} />
				</div>
			</>
		)
	}

	return (
		<>
			<Modal animation={false} show={props.show} onHide={props.toggle} keyboard={false} dialogClassName={className}>
				<Modal.Header closeButton>
					<div className="header">
						<Modal.Title>{`Practice Summary`}</Modal.Title>
						<span className="subtitle">{`${get(props, `noOfLoops`)} ${get(props, `noOfLoops`) > 1 ? `loops` : `loop`}, average accuracy ${get(
							props,
							`averageAccuracy`
						)}%`}</span>
					</div>
				</Modal.Header>
				<Modal.Body className={className}>{renderBody()}</Modal.Body>
			</Modal>
		</>
	)
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const playback = getPlaybackState(state)
	const themeName = getThemeName(state)
	const history = getLatestHistory(state)
	const noOfLoops = getNumberOfPracticeLoops(state)
	const averageAccuracy = getAveragePracticeAccuracy(state)
	return { rendering, playback, themeName, noOfLoops, averageAccuracy, history }
}

export default connect(mapStateToProps, {})(PracticeModal)
