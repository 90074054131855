import React from 'react'

import Play from './play'
import Refresh from './refresh'
import Shuffle from './shuffle'

const ActionButtons = ({ practiceRefs, shuffle, play, generateRhythm, stop }) => {
	return (
		<div className={`ion-action-buttons`}>
			<Play practiceRefs={practiceRefs} stop={stop} play={play} />
			<Refresh generateRhythm={generateRhythm} />
			{/* <Shuffle shuffle={shuffle} /> */}
		</div>
	)
}

export default ActionButtons
