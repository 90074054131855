import Bowser from 'bowser'

const useBowser = () => {
	const browser = Bowser.getParser(window.navigator.userAgent)

	const osName = browser.getOSName()
	const isWebkit = browser.getEngine().name === 'WebKit'
	const browserName = browser.getBrowserName()
	const platformType = browser.getPlatformType()
	const isFirefox = browserName === 'Firefox'
	const isSafari = browserName === 'Safari'
	const isIOS = osName === 'iOS'
	const isAndroid = osName === 'Android'
	const isDesktop = platformType === 'desktop'
	const isMobileOnly = platformType === 'mobile'
	const isTablet = platformType === 'tablet'
	const isMobile = isTablet || isMobileOnly

	return {
		isWebkit,
		isIOS,
		isAndroid,
		isMobile,
		browserName,
		isTablet,
		isFirefox,
		isSafari,
		isDesktop,
	}
}

export default useBowser
