import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { IonItem, IonLabel } from '@ionic/react'

import useComplexityPicker from '../Hooks/useComplexityPicker'

import { getIsPlaying, getLevel, getTime } from '../../../../../../redux/selectors/playback'
import { getRenderingMode } from '../../../../../../redux/selectors/rendering'
import { setRenderingMode } from '../../../../../../redux/actions'

import RenderingMode from '../../../../../../utils/constants/enum/renderingMode'
import TimeEnum from '../../../../../../utils/constants/enum/time'

const Level = ({ playing, level, renderingMode, time }) => {
	const openPicker = useComplexityPicker()

	const getLabel = () => {
		switch (renderingMode) {
			case RenderingMode.FIRSTGROOVES.ALL:
				return `All Grooves`
			case RenderingMode.FIRSTGROOVES.ONE:
			case RenderingMode.FIRSTGROOVES.TWO:
			case RenderingMode.FIRSTGROOVES.THREE:
				return `Level ${renderingMode - 7}`
			case RenderingMode.SD.TRIPLETS.ONE:
			case RenderingMode.SD.TRIPLETS.TWO:
			case RenderingMode.SD.TRIPLETS.THREE:
			case RenderingMode.SD.TRIPLETS.FOUR:
			case RenderingMode.SD.TRIPLETS.FIVE:
				return `Level ${renderingMode - 2}`
			default:
				break
		}
		return `Level ${level}`
	}

	const disabled = () => {
		if (playing) return true
		if (renderingMode === RenderingMode.NORMAL && get(time, `options`) === TimeEnum.EIGHTH) return true
		return false
	}

	return (
		<IonItem detail button disabled={disabled()} onClick={openPicker}>
			<IonLabel>{getLabel()}</IonLabel>
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const level = getLevel(state)
	const playing = getIsPlaying(state)
	const renderingMode = getRenderingMode(state)
	const time = getTime(state)

	return {
		level,
		playing,
		renderingMode,
		time,
	}
}

export default connect(mapStateToProps, { setRenderingMode })(Level)
