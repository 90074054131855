import React from 'react'
import { get, findIndex, set } from 'lodash'
import { connect } from 'react-redux'

import { useIonPicker, IonItem, IonLabel } from '@ionic/react'

import { getIsPlaying, getLevel, getPlaybackAs, getPlaybackTimeOptions, getTime, isTripletTimeSignature } from '../../../../../../../redux/selectors/playback'
import { setCustom, setCustomArray, setLevelsArray, setPlaybackTime, setPresetConstants, setTime, setTimeSignature } from '../../../../../../../redux/actions'
import { Sounds } from '../../../../../../../utils/constants/enum/sounds'
import TimeEnum from '../../../../../../../utils/constants/enum/time'
import { errorOccurred } from '../../../../../../../utils/functions'
import { StraightPresets, TripletPresets } from '../../../../../../../utils/constants/presets'
import { getRenderingMode } from '../../../../../../../redux/selectors/rendering'
import RenderingMode from '../../../../../../../utils/constants/enum/renderingMode'
import useClearRhythm from '../../../../../../../hooks/useClearRhythm'
import useComplexityLevel from '../../../../../../../hooks/useComplexityLevel'

const Subdivision = ({
	setCustomArray,
	tripletTimeSignature,
	setPlaybackTime,
	setTime,
	playing,
	time,
	playbackAs,
	playbackTimeOptions,
	setLevelsArray,
	setCustom,
	level,
	setPresetConstants,
	renderingMode,
	//pages.RhythmBot props
	turnOnGrooveMode,
}) => {
	const [present] = useIonPicker()
	const { clearRhythm } = useClearRhythm()
	const { updateLevel } = useComplexityLevel()

	const getLabel = () => {
		switch (get(time, `options`)) {
			case TimeEnum.STRAIGHT:
				return `16th Notes`
			case TimeEnum.EIGHTH:
				return `8th Notes`
			case TimeEnum.TRIPLETS:
				return `Triplets`
			case TimeEnum.MIXED:
				return `Mixed Subdivisions`
			default:
				return errorOccurred(`ionicUI.Pages.Practice.Filters.Controls.Pickers.Subdivision - Time not compatible ${time}`)
		}
	}

	const openPicker = async () => {
		const opts = {
			columns: [
				{
					name: 'subdivision',
					options: [
						{
							text: '16th Notes',
							value: TimeEnum.STRAIGHT,
						},
						{
							text: '8th Notes',
							value: TimeEnum.EIGHTH,
						},
						{
							text: 'Triplets',
							value: TimeEnum.TRIPLETS,
						},
						{
							text: 'Mixed',
							value: TimeEnum.MIXED,
						},
					],
				},
			],
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'Confirm',
					handler: (value) => {
						updateTime(get(value, [`subdivision`, `value`]))
					},
				},
			],
		}
		const i = findIndex(get(opts, [`columns`, 0, `options`]), (o) => get(o, `value`) === get(time, `options`))
		set(opts, [`columns`, 0, `selectedIndex`], i)

		present(opts)
	}

	/**
	 * Update the current time (subdivision) - handle rhythm & time signature
	 * changes if required
	 * @param {number} time the new time selected
	 */
	const updateTime = (time) => {
		if (playbackAs === Sounds.GROOVE) {
			if (playbackTimeOptions !== time) {
				// Clear rhythm if rendered rhythm won't be compatible with groove playback in new time
				if (!(playbackTimeOptions / time === 4) && !(time / playbackTimeOptions === 4)) {
					clearRhythm()
					setPlaybackTime(time)
					switch (time) {
						case TimeEnum.TRIPLETS:
						case TimeEnum.MIXED:
							// Reset if the current time signature isn't compatible
							!tripletTimeSignature && setTimeSignature('4:4')
							break
						default:
							break
					}
				}
				turnOnGrooveMode({ random: false, time })
			}
		}
		setTime(time)
		setCustomArray([])
		switch (time) {
			//Each time change - decide what to render, set the time name and update the level
			case TimeEnum.STRAIGHT:
				updateLevel({ level, time, clearCustom: true })
				setPresetConstants({ constants: StraightPresets() })
				break
			case TimeEnum.EIGHTH:
				setLevelsArray(false)
				setCustom(false)
				break
			case TimeEnum.TRIPLETS:
				updateLevel({ level, time, clearCustom: true })
				setPresetConstants({ constants: TripletPresets })
				break
			default:
				updateLevel({ level, time, clearCustom: true })
				break
		}
	}
	const disabled = () => {
		if (playing) return true
		switch (renderingMode) {
			case RenderingMode.FIRSTGROOVES.ONE:
			case RenderingMode.FIRSTGROOVES.TWO:
			case RenderingMode.FIRSTGROOVES.THREE:
			case RenderingMode.FIRSTGROOVES.ALL:
			case RenderingMode.DOWNUP.STRAIGHT:
			case RenderingMode.DOWNUP.TRIPLETS:
			case RenderingMode.SD.TRIPLETS.ONE:
			case RenderingMode.SD.TRIPLETS.TWO:
			case RenderingMode.SD.TRIPLETS.THREE:
			case RenderingMode.SD.TRIPLETS.FOUR:
			case RenderingMode.SD.TRIPLETS.FIVE:
				return true
			default:
				break
		}
		return false
	}

	return (
		<IonItem disabled={disabled()} detail button onClick={openPicker}>
			<IonLabel>{getLabel()}</IonLabel>
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const playbackAs = getPlaybackAs(state)
	const playing = getIsPlaying(state)
	const time = getTime(state)
	const playbackTimeOptions = getPlaybackTimeOptions(state)
	const tripletTimeSignature = isTripletTimeSignature(state)
	const level = getLevel(state)
	const renderingMode = getRenderingMode(state)
	return {
		playing,
		time,
		playbackAs,
		playbackTimeOptions,
		tripletTimeSignature,
		level,
		renderingMode,
	}
}

export default connect(mapStateToProps, { setCustom, setTime, setPlaybackTime, setTimeSignature, setCustomArray, setLevelsArray, setPresetConstants })(Subdivision)
