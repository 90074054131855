// Utilities to be used with the Bar component

import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'

/**
 * Determine whether a bar is highlighted
 * @param {Number} barNo
 * @param {Number} highlighted
 * @param {Boolean} isPlaying
 * @param {Boolean} isIsolated
 * @returns {String} className to be added
 */
export const isHighlighted = ({ barNo, highlighted, isPlaying, isIsolated }) => {
	if (!isPlaying || highlighted === 0) {
		return ''
	}
	if (highlighted === barNo + 1) {
		return 'highlighted'
	}
	if (!isIsolated) {
		return 'slight-fade'
	}
	return ''
}

/**
 * Determine whether the bars are currently clickable
 * @param {number} noOfBars the current number of bars
 * @param {Boolean} isIsolated
 * @param {Boolean} isPlaying
 * @returns {string} for use in className
 */
export const isClickable = ({ noOfBars, isIsolated, isPlaying }) => {
	const clickable = noOfBars > 1 && !isIsolated && !isPlaying
	if (clickable) {
		return `clickable`
	}
	return ``
}

/**
 * Determine whether a bar is faded
 * @param {Number} barNo the bar number in question
 * @param {Array} selectedBars
 * @param {Boolean} isIsolated
 * @returns {String} for use in className
 */
export const isFaded = ({ barNo, selectedBars, isIsolated }) => {
	const emptySelection = isEmpty(selectedBars)
	if (!isIsolated || emptySelection || includes(selectedBars, barNo)) {
		return ''
	}
	return 'faded'
}
