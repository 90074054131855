import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import Switch from '@mui/material/Switch'

import { ThemeEnum } from '../../utils/constants/enum/theme'

import { setTheme } from '../../redux/actions'
import { getIsDarkTheme } from '../../redux/selectors/rendering'

const Theme = (props) => {
	const labelSize = get(props, 'labelSize', 'size-14')

	const toggleTheme = () => {
		if (get(props, `isDarkTheme`)) {
			get(props, `setTheme`)(ThemeEnum.LIGHT)
			return
		}
		get(props, `setTheme`)(ThemeEnum.DARK)
	}

	return (
		<>
			<span className={`text medium ${labelSize}`}>{`Dark Mode`}</span>
			<Switch size="small" checked={get(props, `isDarkTheme`)} onChange={toggleTheme} name="theme" color="primary" />
		</>
	)
}

const mapStateToProps = (state) => {
	const isDarkTheme = getIsDarkTheme(state)

	return {
		isDarkTheme,
	}
}

export default connect(mapStateToProps, { setTheme })(Theme)
