import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import toUpper from 'lodash/toUpper'

import logo from '../../images/navbar-title.png'

import { setFeaturesList } from '../../redux/actions'
import { getRenderingState, getIsApp } from '../../redux/selectors/rendering'

import { FeaturesListEnum } from '../../utils/constants/enum/featuresList'
import { getPlatformName } from '../../utils/constants/enum/platform'
import { prod } from '../../utils/functions'

import useWindowResize from '../../hooks/useWindowResize'

const Navbar = (props) => {
	const [device] = useWindowResize()

	const renderExtraText = () => {
		const isApp = get(props, `isApp`, false)
		if (prod({ isApp })) {
			return
		}
		const platform = get(props, 'rendering.platform')
		let text = toUpper(getPlatformName(platform))
		if (!isApp) {
			text = 'BETA'
		}
		return <span className="text beta bold red size-16">{text}</span>
	}

	return (
		<header className="navbar">
			<nav className={`navbar-content flex ${device}`}>
				<div className={`nav-section ${device}`}>
					<div className={process.env.REACT_APP_FEATURES_LIST !== 'false' ? 'app-name clickable' : 'app-name'}>
						<img
							alt="app-name"
							onClick={() => {
								if (process.env.REACT_APP_FEATURES_LIST === 'false') {
									return
								}
								props.setFeaturesList(FeaturesListEnum.ON)
								localStorage.setItem('featuresList', FeaturesListEnum.ON)
							}}
							src={logo}
						></img>
						{renderExtraText()}
					</div>
				</div>
			</nav>
		</header>
	)
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const isApp = getIsApp(state)

	return {
		rendering,
		isApp,
	}
}

export default connect(mapStateToProps, { setFeaturesList })(Navbar)
