import set from 'lodash/set'
import get from 'lodash/get'

import store from '../../redux/store'
import { initialText } from '../../redux/reducers/initStates'
import { LanguageEnum } from '../constants/enum/language'

/**
 * Handle a german translation
 */
const german = ({ filtersText }) => {
	// Mode
	set(filtersText, `mode.title`, `Modus`)
	set(filtersText, `mode.once`, `Einmalig`)
	set(filtersText, `mode.reading`, `Lesen`)
	set(filtersText, `mode.trade.title`, `Tausch`)
	// set(filtersText, `mode.trade.standard.desktop.you`, `	Klicken Sie zuerst, um die Reihenfolge zu ändern`)
	// set(filtersText, `mode.trade.standard.desktop.rb`, `RhythmBot zuerst klicken, um die Reihenfolge zu ändern`)
	// set(filtersText, `mode.trade.standard.mobile.you`, `Tippen Sie zuerst, um die Reihenfolge zu ändern`)
	// set(filtersText, `mode.trade.standard.mobile.rb`, `RhythmBot zuerst tippen, um die Reihenfolge zu ändern`)
	// set(filtersText, `mode.trade.reading.you`, `Du zuerst, bei jeder Wiederholung einen neuen Rhythmus`)
	// set(filtersText, `mode.trade.reading.rb`, `RhythmBot zuerst, ein neuer Rhythmus bei jeder Wiederholung`)
	set(filtersText, `mode.infinite`, `Unendlich neue Rhythmen`)
	// Subdivision
	set(filtersText, `subdivision.title`, `Subdivision/Unterteilung`)
	set(filtersText, `subdivision.straight`, `Gerade`)
	set(filtersText, `subdivision.triplets`, `Triolisch`)
	set(filtersText, `subdivision.mixed`, `Gemischt`)
	set(filtersText, `subdivision.sixteenth`, `16tel`)
	set(filtersText, `subdivision.eighth`, `8tel`)
	// Playback
	// set(filtersText, `playback.title`, `Wiedergabe`)
	// set(filtersText, `playback.snare`, `Kleine`)
	// set(filtersText, `playback.kick`, `Trete`)
	// set(filtersText, `playback.groove.title`, `Rille`)
	set(filtersText, `playback.groove.subtitle.desktop.full`, `Klicke hier um Becken, Backbeat & Swing zu editieren`)
	set(filtersText, `playback.groove.subtitle.desktop.short`, `Klicken hier um Becken & Backbeat zu editieren`)
	set(filtersText, `playback.groove.subtitle.mobile.full`, `Tippen hier um Becken, Backbeat & Swing zu editieren`)
	set(filtersText, `playback.groove.subtitle.mobile.short`, `Tippen hier um Becken & Backbeat zu editieren`)
	// set(filtersText, `clear`, `Klar`)

	set(filtersText, `presets.threes`, `3er Gruppen`)
	set(filtersText, `presets.fives`, `5er Gruppen`)

	set(filtersText, `bars`, `Takte`)
	set(filtersText, `random`, `Zufälliger Rhythmus`)

	set(filtersText, `complexity.title`, `Komplexität`)
	// set(filtersText, `complexity.custom`, `Brauch`)
	set(filtersText, `complexity.moreRests`, `Mehr Pausen`)

	set(filtersText, `controls.title`, `Einstellungen`)
	// set(filtersText, `controls.count`, `Mitzählen`)
	// set(filtersText, `controls.ghost`, `Geisternotizen`)
	// set(filtersText, `controls.swing`, `Schwingen`)
	// set(filtersText, `controls.metronome`, `Metronom`)
	return filtersText
}

const triggerTranslation = ({ language = LanguageEnum.ENGLISH }) => {
	const state = store.getState()
	switch (true) {
		case language === LanguageEnum.GERMAN:
			return german({
				filtersText: {
					...get(state, `rendering.text.filters`),
				},
			})
		default:
			return get(initialText, `filters`)
	}
}

export { triggerTranslation }
