import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import get from 'lodash/get'
import includes from 'lodash/includes'

import { useLongPress } from 'use-long-press'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import { getPlaybackState, getIsSixTwelveEight } from '../../redux/selectors/playback'
import { getRenderingState, getIsApp, getThemeName } from '../../redux/selectors/rendering'
import { setCustom, setCustomArray } from '../../redux/actions'

import { copyShareableURL } from '../../utils/functions'
import RenderingModes from '../../utils/constants/enum/renderingMode'
import Times from '../../utils/constants/enum/time'
import useBowser from '../../hooks/useBowser'

const CustomModal = (props) => {
	const { isWebkit } = useBowser()
	const className = `${props.themeName} ${props.device}`

	const [imageArray, setImageArray] = useState(Array(props.playback.time.options).fill(0))
	const [imagePath, setImagePath] = useState('straight/')

	const [blocker, setBlocker] = useState(false)
	const [confirmButton, setConfirmButton] = useState(['Confirm', 'action-3'])

	// Setting the image path and array
	useEffect(() => {
		const options = get(props, 'playback.time.options')
		const renderingMode = get(props, 'rendering.mode')
		setImageArray(Array(options).fill(0))
		switch (renderingMode) {
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
				setImagePath('first-grooves/')
				setImageArray(Array(28).fill(0))
				return
			default:
				break
		}
		switch (options) {
			case Times.STRAIGHT:
				if (get(props, 'isSixTwelveEight')) {
					setImagePath('six-eight/')
					setImageArray(Array(64).fill(0))
				} else {
					setImagePath('straight/')
				}
				break
			case Times.EIGHTH:
				setImagePath('straight-8/')
				break
			case Times.TRIPLETS:
				setImagePath('swung/')
				break
			default:
				setImagePath('mixed/')
				break
		}
	}, [props.playback.time.options, props.playback.timeSignature, props.rendering.mode])

	/**
	 * Create and copy a link loading the selected custom groupings
	 */
	const copyCustomLink = () => {
		copyShareableURL({ isApp: get(props, 'isApp', false), queryParams: props.settingsQueryParams({ modal: true }) })
		setConfirmButton(['Link Copied', 'action-2'])
		setBlocker(true)
		setTimeout(() => {
			setConfirmButton(['Confirm', 'action-3'])
			setBlocker(false)
		}, 2000)
	}

	const copyEvent = useLongPress(
		() => {
			copyCustomLink()
		},
		{
			threshold: 1000,
			onFinish: () => {
				if (!isWebkit) {
					return
				}
				copyCustomLink()
			},
		}
	)

	/**
	 * Loads an image
	 * @param {number} index of the image to load
	 * @returns
	 */
	const getImage = (index) => require(`../../images/rhythms/${imagePath}${index}.png`)

	/**
	 * Determine the className for an image
	 * @param {number} index of the image
	 * @returns
	 */
	const imgClassName = (index) => {
		if (includes(get(props, 'playback.customArray'), index)) {
			return `selected levels`
		}
		return `levels`
	}

	/**
	 * Select an image, adding it to the custom array
	 * @param {number} img index
	 */
	const select = (img) => {
		const customArrayTmp = get(props, 'playback.customArray')
		if (!includes(customArrayTmp, img)) {
			customArrayTmp.push(img)
		} else {
			customArrayTmp.splice(customArrayTmp.indexOf(img), 1)
		}

		if (customArrayTmp.length === 0) {
			props.setCustom(false)
		}
		props.setCustomArray(customArrayTmp)
	}

	/**
	 * Clear all selected groupings
	 */
	const clearSelection = () => props.setCustomArray([])

	/**
	 * Select all groupings
	 */
	const selectAll = () => {
		let amount = get(props, 'playback.time.options')
		const renderingMode = get(props, 'rendering.mode')

		if (get(props, 'isSixTwelveEight')) {
			amount = 64
		}
		switch (renderingMode) {
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
				amount = 28
				break
			default:
				break
		}
		let temp = []
		for (let i = 0; i < amount; i++) {
			temp.push(i + 1)
		}
		props.setCustomArray(temp)
	}

	/**
	 * Select groupings at random
	 */
	const selectRandom = () => {
		const renderingMode = get(props, 'rendering.mode')
		let amount = get(props, 'playback.time.options')
		if (get(props, 'isSixTwelveEight')) {
			amount = 64
		}
		switch (renderingMode) {
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
				amount = 28
				break
			default:
				break
		}
		let temp = []
		for (let i = 0; i < amount; i++) {
			if (Math.floor(Math.random() * 10) > 5) {
				temp.push(i + 1)
			}
		}
		props.setCustomArray(temp)
	}

	/**
	 * Extra class to add to the top grid-container
	 * @returns {String}
	 */
	const additionalGridClass = () => {
		const renderingMode = get(props, 'rendering.mode')
		switch (renderingMode) {
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
				return `first-grooves`
			default:
				return ``
		}
	}

	/**
	 * @returns {String}
	 */
	const getSubtitle = () => {
		const renderingMode = get(props, 'rendering.mode')
		let term = `groupings`
		switch (renderingMode) {
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
				term = `grooves`
				break
			default:
				break
		}

		return `When enabled, random rhythms will be generated using only the selected ${term}`
	}

	return (
		<>
			<Modal animation={false} show={props.show} onHide={props.toggle} keyboard={false} dialogClassName={className}>
				<Modal.Header closeButton>
					<div className="header">
						<Modal.Title>Select Groupings</Modal.Title>
						<span className="contact-text subtitle text size-14">{getSubtitle()}</span>
					</div>
				</Modal.Header>
				<Modal.Body className={className}>
					<div className="levels">
						<div className={`grid-container modal-levels ${additionalGridClass()}`}>
							{imageArray.map((_, index) => (
								<img key={index} alt="notation" className={imgClassName(index + 1)} src={getImage(index + 1)} onClick={() => select(index + 1)} />
							))}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className={className}>
					<div className="left">
						<Button className="clear action-2" onClick={clearSelection} disabled={!get(props, 'playback.customArray.length') > 0}>
							{`Clear`}
						</Button>
						<Button
							className="select random action-2"
							onClick={() => {
								clearSelection()
								selectRandom()
							}}
						>
							{`Random`}
						</Button>
						<Button
							className="select action-2"
							onClick={() => {
								clearSelection()
								selectAll()
							}}
							disabled={get(props, 'playback.customArray.length') >= get(props, 'playback.time.options')}
						>
							{`Select All`}
						</Button>
					</div>
					<div className="right">
						{get(props, 'playback.custom') && (
							<Button
								className="off action-2"
								onClick={() => {
									props.setCustom(false)
									props.toggle()
								}}
							>
								{`Turn Off`}
							</Button>
						)}
						<Button
							className={`confirm ${get(confirmButton, [1])}`}
							{...copyEvent}
							onClick={() => {
								if (!blocker) {
									props.setCustom(true)
									props.toggle()
								}
							}}
							disabled={!get(props, 'playback.customArray.length') > 0}
						>
							{get(confirmButton, [0])}
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const playback = getPlaybackState(state)
	const isSixTwelveEight = getIsSixTwelveEight(state)
	const isApp = getIsApp(state)
	const themeName = getThemeName(state)

	return { rendering, playback, isSixTwelveEight, isApp, themeName }
}

export default connect(mapStateToProps, { setCustom, setCustomArray })(CustomModal)
