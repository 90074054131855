import RenderingModes from '../constants/enum/renderingMode'
import Time from '../constants/enum/time'

const getInteractionString = ({ desktop = true }) => {
	if (!desktop) {
		return `Tap`
	}
	return `Click`
}

/**
 * Return play along toggle tooltip text.
 * @returns {JSX}
 */
const renderPracticeToggleTooltip = () => {
	return <span>{`Play along with rhythms and assess your accuracy`}</span>
}

/**
 * Return play along tooltip text.
 * @returns {JSX}
 */
const renderPracticeTooltip = ({ desktop = true }) => {
	// TODO enable if needed
	return
	// const interaction = getInteractionString({ desktop })
	// return <span>{`${interaction} for summary`}</span>
}

/**
 * @returns
 */
const renderClickTooltip = ({ desktop = true }) => {
	const interaction = getInteractionString({ desktop })

	return <span>{`${interaction} to configure`}</span>
}

/**
 * @returns
 */
const renderClickOffsetTooltip = ({ time = Time.STRAIGHT }) => {
	switch (time) {
		case Time.TRIPLETS:
			return <span>{`In eighth note triplets`}</span>
		default:
			return <span>{`In sixteenth notes`}</span>
	}
}

/**
 * Return bpm tooltip text.
 * @returns {JSX}
 */
const renderBpmTooltip = (increment = true) => {
	const text = increment ? `increase` : `decrease`
	return <span>{`Long press to ${text} by one`}</span>
}

/**
 * Return time signature tooltip text.
 * @returns {JSX}
 */
const renderTimeSignatureToolTip = ({ options, renderingMode = RenderingModes.NORMAL }) => {
	switch (renderingMode) {
		case RenderingModes.SD.TRIPLETS.ONE:
		case RenderingModes.SD.TRIPLETS.TWO:
		case RenderingModes.SD.TRIPLETS.THREE:
		case RenderingModes.SD.TRIPLETS.FOUR:
		case RenderingModes.SD.TRIPLETS.FIVE:
		case RenderingModes.FIRSTGROOVES.ALL:
		case RenderingModes.FIRSTGROOVES.ONE:
		case RenderingModes.FIRSTGROOVES.TWO:
		case RenderingModes.FIRSTGROOVES.THREE:
			return <span>{`Time signature locked`}</span>
		case RenderingModes.DOWNUP.STRAIGHT:
		case RenderingModes.DOWNUP.TRIPLETS:
			return <span>{`Only 2/4 and 4/4 are available in down/up mode`}</span>
		default:
			break
	}
	if (options === Time.MIXED) {
		return <span>{`Time signatures not yet available in mixed subdivisions`}</span>
	}
	return (
		<span>
			{`Click to increase, right click to decrease`}
			<br></br>
			{`Long press to reset`}
		</span>
	)
}

export { renderPracticeTooltip, renderPracticeToggleTooltip, renderTimeSignatureToolTip, renderBpmTooltip, renderClickTooltip, renderClickOffsetTooltip }
