import React, { useEffect, useState, useRef } from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { get as idbGet } from 'idb-keyval'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { getRenderingState, getThemeName } from '../../redux/selectors/rendering'
import { copyToClipboard } from '../../utils/functions'
import useKeyboard from '../../hooks/useKeyboard'

const LoadModal = (props) => {
	//Rendering
	const [rhythmsArray, setRhythmsArray] = useState([])
	const [renderArray, setRenderArray] = useState([])
	const [zeroRhythms, setZeroRhythms] = useState('')
	//Renaming
	const [newName, setNewName] = useState(false)
	const [renaming, setRenaming] = useState(false)
	//Deleting
	const [toDelete, setToDelete] = useState(false)
	//Selecting
	const [selected, setSelected] = useState([])
	//Sharing
	const [shareAllButton, setShareAllButton] = useState(['Share All', 'action-3'])
	const [shareSelectedButton, setShareSelectedButton] = useState(['Share', 'action-3'])
	//Search
	const [searchTerms, setSearchTerms] = useState([])
	const searchRef = useRef(null)

	const enterRef = useRef(null)

	useKeyboard({
		keyCodes: [13],
		props,
		callback: () => {
			//Enter to confirm renaming
			if (enterRef.current !== null) {
				enterRef.current.click()
			}
		},
	})

	useEffect(() => {
		idbGet('savedRhythms').then((savedRhythms) => {
			if (savedRhythms && savedRhythms.length > 0) {
				setRhythmsArray(savedRhythms.reverse())
				let tempArray = new Array(savedRhythms.length).fill([false, false])
				if (tempArray.length === 1) {
				}
				setRenderArray(tempArray)
			} else {
				setZeroRhythms('No saved rhythms')
			}
		})
	}, [])

	useEffect(() => {
		let tempArr = [...renderArray]
		if (typeof renaming === 'number') {
			//Renaming
			let tempItem = [...tempArr[renaming]]
			tempItem.splice(0, 1, true)
			tempArr.splice(renaming, 1, tempItem)
			for (let i = 0; i < tempArr.length; i++) {
				if (i !== renaming) {
					tempArr[i][0] = false
				}
			}
		} else {
			// Not renaming
			for (let i = 0; i < tempArr.length; i++) {
				tempArr[i][0] = false
			}
		}
		setRenderArray(tempArr)
	}, [renaming])

	useEffect(() => {
		if (rhythmsArray.length > 0) {
			setZeroRhythms('')
			if (rhythmsArray.length === 1) {
				setShareAllButton(['Share', 'action-3'])
			}
		} else {
			setZeroRhythms('No Saved Rhythms')
		}
	}, [rhythmsArray])

	const reDraw = (array) => {
		setRenderArray(new Array(array.length).fill([false, false]))
		setRhythmsArray(array)
		setSelected([])
		setToDelete(false)
		setRenaming(false)
	}

	const search = (search) => {
		let lowerSearch = search.toLowerCase()
		if (lowerSearch.charAt(lowerSearch.length - 1) === ' ') {
			lowerSearch = lowerSearch.slice(0, -1)
		}
		if (lowerSearch.length > 0) {
			if (lowerSearch.split(' ').length > 1) {
				setSearchTerms(new RegExp(lowerSearch.split(' ').join('|')))
			} else {
				setSearchTerms(new RegExp([lowerSearch].join('|')))
			}
		} else {
			setSearchTerms([])
		}
	}

	const deleteRhythm = (deleteSelected = false) => {
		let tempArr = [...rhythmsArray]
		if (!deleteSelected) {
			//Only deleting one
			tempArr.splice(toDelete, 1)
		} else {
			//Deleting the current selection
			let newArr = []
			for (let i = 0; i < tempArr.length; i++) {
				if (!selected.includes(i)) {
					newArr.push(tempArr[i])
				}
			}
			tempArr = newArr
		}

		reDraw(tempArr)
	}

	const renameRhythm = (idx, newName) => {
		if (typeof idx === 'number') {
			let tempArr = [...rhythmsArray]
			let tempItem = rhythmsArray[idx]
			tempItem.rhythmName = newName
			tempArr.splice(idx, 1, tempItem)
			setNewName(false)
			reDraw(tempArr)
		}
	}

	const share = (shareSelected) => {
		let shareString = ''
		for (let i = 0; i < rhythmsArray.length; i++) {
			if (!shareSelected || (shareSelected && selected.includes(i))) {
				shareString += window.location.origin
				shareString += '?bars=' + rhythmsArray[i].bars + '&'
				shareString += 'time=' + rhythmsArray[i].playbackTime + '&'
				for (let k = 0; k < rhythmsArray[i].renderedArray.length; k++) {
					shareString += 'beat=' + rhythmsArray[i].renderedArray[k]
					if (k < rhythmsArray[i].renderedArray.length - 1) {
						shareString += '&'
					}
				}
			}
			shareString += '\n'
		}
		if (shareSelected) {
			setShareSelectedButton(['Links Copied', 'action-2'])
			setTimeout(() => {
				setShareSelectedButton(['Share', 'action-3'])
			}, 3000)
		} else {
			setShareAllButton(['Links Copied', 'action-2'])
			setTimeout(() => {
				if (rhythmsArray.length === 1) {
					setShareAllButton(['Share', 'action-3'])
				} else {
					setShareAllButton(['Share All', 'action-3'])
				}
			}, 3000)
		}
		copyToClipboard({ text: shareString })
	}

	const clearSelection = () => {
		let tempArr = [...renderArray]
		for (let i = 0; i < tempArr.length; i++) {
			tempArr[i][1] = false
		}
		setRenderArray(tempArr)
		setSelected([])
	}

	const select = (idx) => {
		let tempArr = [...renderArray]
		if (typeof idx !== 'number' && idx) {
			//Select all
			let tempSelected = []
			for (let i = 0; i < tempArr.length; i++) {
				tempArr[i][1] = true
				tempSelected.push(i)
			}
			setSelected(tempSelected)
		} else {
			//Select Specific
			let tempItem = [...renderArray[idx]]
			tempItem.splice(1, 1, !tempItem[1])
			tempArr.splice(idx, 1, tempItem)
			if (selected.includes(idx)) {
				selected.splice(selected.indexOf(idx), 1)
			} else {
				selected.push(idx)
			}
		}
		setRenderArray(tempArr)
	}

	return (
		<>
			<Modal
				animation={false}
				show={props.show}
				onHide={(e) => {
					props.toggle(rhythmsArray, false)
				}}
				keyboard={false}
				dialogClassName={props.device + ' ' + get(props, `themeName`, `light`)}
			>
				<Modal.Header
					closeButton
					onClick={(e) => {
						setToDelete(false)
					}}
				>
					<div className="header">
						<Modal.Title>Load Rhythm</Modal.Title>
					</div>
				</Modal.Header>
				<Modal.Body className={'saved-rhythms ' + get(props, `themeName`, `light`) + ' ' + props.device}>
					{renderArray.length > 0 && (
						<>
							<div className="search-bar">
								<input
									ref={searchRef}
									type="text"
									name="search"
									placeholder="Search..."
									onChange={(e) => {
										search(e.target.value)
									}}
									className="input search"
								></input>
								<input
									onClick={(e) => {
										setSearchTerms([])
										searchRef.current.value = ''
									}}
									disabled={searchTerms.length === 0}
									type="submit"
									value="x"
								></input>
							</div>
							<div className="divider"></div>
						</>
					)}
					<div className="grid-container modal-load">
						{renderArray.map(
							(item, index) =>
								(searchTerms.length === 0 ||
									searchTerms.test(rhythmsArray[index].rhythmName.toLowerCase()) ||
									searchTerms.test(rhythmsArray[index].date.toLowerCase())) && (
									<div
										key={index}
										onClick={(e) => {
											if (renderArray.length > 1) {
												select(index)
											}
										}}
										className={item[1] ? 'selected rhythm ' + index : selected.length > 0 ? 'faded rhythm ' + index : 'rhythm ' + index}
									>
										<div
											className="description"
											onClick={(e) => {
												setToDelete(false)
											}}
										>
											{item[0] ? (
												<input
													type="text"
													name="rename"
													placeholder={rhythmsArray[index].rhythmName}
													onChange={(e) => {
														setNewName(e.target.value)
													}}
													className="input title"
												></input>
											) : (
												<div className="title text bold size-22">{rhythmsArray[index].rhythmName}</div>
											)}
											{!item[0] && (
												<div className="date-time">
													<div className="text medium size-18">
														{rhythmsArray[index].bars +
															' Bar | ' +
															rhythmsArray[index].timeName +
															' | ' +
															rhythmsArray[index].date +
															' ' +
															rhythmsArray[index].time}
													</div>
												</div>
											)}
										</div>
										<div className="actions">
											{item[0] ? (
												<Button
													ref={enterRef}
													onClick={(e) => {
														e.stopPropagation()
														renameRhythm(index, newName)
													}}
													className="rename-confirm action-3"
												>
													Confirm
												</Button>
											) : (
												<Button
													onClick={(e) => {
														e.stopPropagation()
														setToDelete(false)
														setRenaming(index)
														setNewName(rhythmsArray[index].rhythmName)
													}}
													className="rename action-2"
												>
													Rename
												</Button>
											)}

											{toDelete === index ? (
												<Button
													onClick={(e) => {
														e.stopPropagation()
														deleteRhythm()
													}}
													className="delete-confirm action-3"
												>
													Confirm
												</Button>
											) : item[0] ? (
												<Button
													onClick={(e) => {
														e.stopPropagation()
														setRenaming(false)
													}}
													className="cancel action-2"
												>
													Cancel
												</Button>
											) : (
												<Button
													onClick={(e) => {
														e.stopPropagation()
														setToDelete(index)
														setRenaming(false)
													}}
													className="delete action-2"
												>
													Delete
												</Button>
											)}
											<Button
												onClick={(e) => {
													e.stopPropagation()
													props.toggle(rhythmsArray, rhythmsArray[index])
												}}
												className="load action-3"
											>
												Load
											</Button>
										</div>
									</div>
								)
						)}
					</div>
					<p className="text bold size-20">{zeroRhythms}</p>
				</Modal.Body>
				{renderArray.length > 0 && (
					<Modal.Footer className={get(props, `themeName`, `light`) + ' ' + props.device}>
						<div className="left">
							{renderArray.length > 1 && searchTerms.length === 0 && (
								<>
									<Button
										className="selectall action-2"
										onClick={(e) => {
											select(true)
										}}
									>
										Select All
									</Button>
									{selected.length > 0 && (
										<Button
											className="clear action-2"
											onClick={(e) => {
												clearSelection()
											}}
										>
											Clear
										</Button>
									)}
								</>
							)}
						</div>
						<div className="right">
							{selected.length > 0 ? (
								<>
									<Button
										className={'share ' + shareSelectedButton[1]}
										onClick={(e) => {
											setToDelete(false)
											share(true)
										}}
									>
										{shareSelectedButton[0]}
									</Button>
									{toDelete ? (
										<Button
											className={'delete action-3'}
											onClick={(e) => {
												deleteRhythm(true)
											}}
										>
											Confirm
										</Button>
									) : (
										<Button
											className="delete action-3"
											onClick={(e) => {
												setToDelete(true)
											}}
										>
											Delete
										</Button>
									)}
								</>
							) : (
								searchTerms.length === 0 && (
									<Button
										className={'share ' + shareAllButton[1]}
										onClick={(e) => {
											share(false)
										}}
									>
										{shareAllButton[0]}
									</Button>
								)
							)}
						</div>
					</Modal.Footer>
				)}
			</Modal>
		</>
	)
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const themeName = getThemeName(state)
	return { rendering, themeName }
}

export default connect(mapStateToProps)(LoadModal)
