export const ThemeEnum = {
	DARK: 0,
	LIGHT: 1,
}

const ThemeMap = new Map([
	[ThemeEnum.LIGHT, 'light'],
	[ThemeEnum.DARK, 'dark'],
])

export const themeName = (theme) => ThemeMap.get(theme)
