import React from 'react'

import Bar from './bar'
import Buttons from './buttons'

const Navbar = () => {
	return (
		<>
			<Bar />
			<Buttons />
		</>
	)
}

export default Navbar
