import React from 'react'
import { connect } from 'react-redux'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import { getRVPermutationsGroupingIndex } from '../../redux/selectors/playback'
import { rhythmicVocabPermutationsSetGrouping } from '../../redux/actions'

import { straightOptions } from '../../utils/rhythmic-vocabulary-permutations'
import { getRandomInRangeExclude } from '../../utils/functions'

const RVPermutationsModal = (props) => {
	const className = `${props.device}`

	const getImage = (image) => require(`../../images/rhythms/straight/${image}.png`)

	const select = (groupingIndex) => props.rhythmicVocabPermutationsSetGrouping(groupingIndex)
	const selectRandom = () => select(getRandomInRangeExclude(0, straightOptions.length - 1, props.groupingIndex))

	const isSelected = (index) => props.groupingIndex === index

	return (
		<>
			<Modal animation={false} show={props.show} onHide={props.toggle} backdrop="static" keyboard={false} dialogClassName={className}>
				<Modal.Header closeButton>
					<div className="header">
						<Modal.Title>{`Select Grouping`}</Modal.Title>
					</div>
				</Modal.Header>
				<Modal.Body className={className}>
					<div className="rv-grouping">
						<div className={`grid-container modal-rv-grouping`}>
							{straightOptions.map((option, combinedIndex) => (
								<div onClick={() => select(combinedIndex)} key={combinedIndex} className={`option ${isSelected(combinedIndex) ? 'selected' : ''}`}>
									{option.images.map((image, imageIndex) => (
										<img key={imageIndex} alt="notation" src={getImage(image)} />
									))}
								</div>
							))}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className={className}>
					<div className="left"></div>
					<div className="right">
						<Button className="select random action-2" onClick={selectRandom}>
							{`Random`}
						</Button>
						<Button className={`confirm action-3`} onClick={props.toggle}>
							{`Confirm`}
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

const mapStateToProps = (state) => {
	const groupingIndex = getRVPermutationsGroupingIndex(state)

	return { groupingIndex }
}

export default connect(mapStateToProps, { rhythmicVocabPermutationsSetGrouping })(RVPermutationsModal)
