import React, { useEffect, useState, useRef } from 'react'
import { IonToolbar, IonTitle, IonContent, IonModal, IonButtons, IonButton } from '@ionic/react'
import { openPlaceholderModal } from '../../../utils/ionic/modals'

const Placeholder = ({ title, page }) => {
	const modal = useRef(null)
	const [presentingElement, setPresentingElement] = useState(null)

	useEffect(() => {
		setPresentingElement(page.current)
	}, [])

	const dismiss = () => {
		modal.current.dismiss()
	}

	return (
		<IonModal ref={modal} trigger={openPlaceholderModal} presentingElement={presentingElement} canDismiss={true}>
			<IonToolbar color={`dark`}>
				<IonButtons slot="end">
					<IonButton onClick={dismiss}>{`Close`}</IonButton>
				</IonButtons>
				<IonTitle color={`canvas`}>{title || `Huh??`}</IonTitle>
			</IonToolbar>
			<IonContent fullscreen>
				<h3>TBD...</h3>
			</IonContent>
		</IonModal>
	)
}

export default Placeholder
