import React from 'react'
import { connect } from 'react-redux'

import { IonRippleEffect, IonCard, IonCardContent } from '@ionic/react'

import Sections from './sections'

import { getIsPlaying, getIsPracticeModeOn } from '../../../../../../redux/selectors/playback'
import { getUsingPlaceholders } from '../../../../../../redux/selectors/ionic'

const FiltersCard = ({ isPracticeModeOn, playing }) => {
	const getClass = () => {
		if (playing && isPracticeModeOn) return `hide`
		return ``
	}

	return (
		<IonCard button className={`ion-filters-card ${getClass()}`}>
			<IonCardContent>
				<Sections />
				<IonRippleEffect />
			</IonCardContent>
		</IonCard>
	)
}

const mapStateToProps = (state) => {
	return {
		playing: getIsPlaying(state),
		isPracticeModeOn: getIsPracticeModeOn(state),
		usingPlaceholders: getUsingPlaceholders(state),
	}
}

export default connect(mapStateToProps, {})(FiltersCard)
