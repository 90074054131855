import { useEffect } from 'react'
import includes from 'lodash/includes'

/**
 *
 * @param {Array} keyCodes
 * @param {Function} callback
 * @param {Object} props
 */
export default function useKeyboard({ keyCodes, callback, props = {} }) {
	useEffect(() => {
		const handler = (event) => {
			if (!includes(keyCodes, event.keyCode)) {
				return
			}
			event.preventDefault()
			callback()
		}
		if (props.loading) {
			return
		}
		window.addEventListener('keydown', handler)
		return () => {
			window.removeEventListener('keydown', handler)
		}
	}, [props, callback, keyCodes])
}
