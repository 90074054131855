export const getBarImage = ({ path, imageNumber }) => {
	const extension = `.png`
	let image
	try {
		image = require(`../../images/rhythms/${path}/${imageNumber}${extension}`)
	} catch {
		//Default to a straight rhythm image
		image = require(`../../images/rhythms/straight/${imageNumber}${extension}`)
	}
	return image
}
