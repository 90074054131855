export const TripletDownUpImages = {
	DOWN: 1,
	UP: 2,
	SPACE: 3,
}

// Straight time down ups are restricted to certain notation
export const tripletDownUpsOptions = {
	start: [1, 3],
}

// In down up mode only certain notation can follow others
export const tripletDownUpsKey = {
	1: [3, 2],
	2: [1, 3],
	3: [1, 2],
}

// Every possible 1/4 note construction. 13 = image 1 followed by image 3 etc
export const tripletDownUpsImageToSoundKey = {
	13: [1, 0, 0],
	12: [1, 0, 1],
	32: [0, 0, 1],
}

export const tripletDownUpsOnesAndZerosToSoundKey = {
	ozz: [1, 3],
	ozo: [1, 2],
	zzo: [3, 2],
}
