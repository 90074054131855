import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { IonToggle, IonLabel, IonItem } from '@ionic/react'

import { getIsApp, getScreenLock } from '../../../../../redux/selectors/rendering'
import { setScreenLockObject, toggleScreenLock } from '../../../../../redux/actions'
import { localhost } from '../../../../../utils/functions'
import useScreenLock from '../../../../../hooks/useScreenLock'

const ScreenLock = ({ screenLock, isApp }) => {
	const { toggle } = useScreenLock()

	if (!localhost() && !isApp) {
		return null
	}

	return (
		<IonItem>
			<IonLabel>{`Screen Lock`}</IonLabel>
			<IonToggle checked={get(screenLock, `state`, false)} onClick={toggle} />
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const isApp = getIsApp(state)
	const screenLock = getScreenLock(state)

	return {
		isApp,
		screenLock,
	}
}

export default connect(mapStateToProps, { toggleScreenLock, setScreenLockObject })(ScreenLock)
