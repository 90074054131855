const BackbeatPatterns = {
	TWO_AND_FOUR: 0,
	THREE: 1,
	FOUR: 2,
	CROTCHETS: 3,
	OFF: 4,
	TIMESIG_DEFAULT: 5,
}

const CymbalPatterns = {
	CROTCHETS: 0,
	UP_BEATS: 1,
	EIGHTH: 2,
	EIGHTH_ACCENT_DOWN: 3,
	EIGHTH_ACCENT_UP: 4,
	SIXTEENTH: 5,
	SIXTEENTH_ACCENT_EIGHTH: 6,
	SIXTEENTH_ACCENT_DOWN: 7,
	SIXTEENTH_ACCENT_UP: 8,
	ONE_AND_A: 9,
	ONE_E_AND: 10,
	MATCH_KICK: 11,
	UP_DOWN: 12,
	// Triplets
	TRIP_ONE_A: 13,
	TRIP_ONE_AND: 14,
	TRIP_AND_A: 15,
	TRIP_SPANG_A_LANG: 16,
}

export { BackbeatPatterns, CymbalPatterns }
