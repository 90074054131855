import { SET_ACTX, SET_SNARE, SET_KICK, SET_HIHAT, SET_RIDE, SET_METRONOME, SET_METRONOMEONE, SET_VOLUME, SET_MARK_ONE } from '../actionTypes'

import { initAudio } from './initStates'

export default function audio(state = initAudio, action) {
	switch (action.type) {
		case SET_ACTX: {
			const { audioContext } = action.payload
			return {
				...state,
				audioContext: audioContext,
			}
		}
		case SET_SNARE: {
			const { snare } = action.payload
			return {
				...state,
				snare: snare,
			}
		}
		case SET_KICK: {
			const { kick } = action.payload

			return {
				...state,
				kick: kick,
			}
		}
		case SET_HIHAT: {
			const { hihat } = action.payload
			return {
				...state,
				hihat: hihat,
			}
		}
		case SET_RIDE: {
			const { ride } = action.payload
			return {
				...state,
				ride: ride,
			}
		}
		case SET_METRONOME: {
			const { metronome } = action.payload
			return {
				...state,
				metronome: metronome,
			}
		}
		case SET_METRONOMEONE: {
			const { metronomeOne } = action.payload
			return {
				...state,
				metronomeOne: metronomeOne,
			}
		}
		case SET_VOLUME: {
			const { volumeBoost } = action.payload
			return {
				...state,
				volumeBoost,
			}
		}
		case SET_MARK_ONE: {
			const { markOne } = action.payload
			return {
				...state,
				markOne,
			}
		}
		default:
			return state
	}
}
