import { createSelector } from 'reselect'
import get from 'lodash/get'

export const getIonicState = (store) => get(store, `ionic`)
export const getMenuID = createSelector(getIonicState, (ionic) => get(ionic, 'menuID'))
export const getUsingPlaceholders = createSelector(getIonicState, (ionic) => get(ionic, [`pages`, `usePlaceholders`]))

export const getAreFiltersOpen = createSelector(getIonicState, (ionic) => get(ionic, [`pages`, `practice`, `filters`, `open`]))
export const getArePresetsOpen = createSelector(getIonicState, (ionic) => get(ionic, [`pages`, `practice`, `filters`, `presets`, `open`]))
export const getIsComplexityOpen = createSelector(getIonicState, (ionic) => get(ionic, [`pages`, `practice`, `filters`, `complexity`, `open`]))
export const getIsComplexityCustomOpen = createSelector(getIonicState, (ionic) => get(ionic, [`pages`, `practice`, `filters`, `complexity`, `custom`, `open`]))
export const getIsTimeSignatureOpen = createSelector(getIonicState, (ionic) => get(ionic, [`pages`, `practice`, `filters`, `timeSignature`, `open`]))
