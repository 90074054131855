import React from 'react'
import { IonCard, IonCardContent } from '@ionic/react'
import Isolate from '../Isolate'

const ActionCard = () => {
	return (
		<IonCard className={`ion-action-card`}>
			<IonCardContent>
				<Isolate />
			</IonCardContent>
		</IonCard>
	)
}

export default ActionCard
