import React, { useRef } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { IonPage, IonContent, IonHeader, IonFooter } from '@ionic/react'
import FiltersModal from './Filters'
import FiltersCard from './Filters/Card'
import Bars from '../../Bars'
import ActionButtons from '../../ActionButtons'
import TapCard from './TapCard'
import PlaceholderModal from '../../PlaceHolder/modal'
import ProgressCard from './ProgressCard'
import ActionCard from './ActionCard'

import { getPlatformClass, getThemeName } from '../../../../redux/selectors/rendering'
import { getUsingPlaceholders } from '../../../../redux/selectors/ionic'
import useWindowResize from '../../../../hooks/useWindowResize'
import { tabs } from '../../../../utils/ionic/tabs'
import { getIsPlaying, getIsPracticeModeOn } from '../../../../redux/selectors/playback'

const Practice = ({
	usingPlaceholders,
	themeName,
	platformClass,
	playing,
	isPracticeModeOn,
	// pages.Rhythmbot props
	isolate,
	shuffle,
	stop,
	play,
	generateRhythm,
	turnOnGrooveMode,
	updateLevel,
	changePreset,
	changeTimeSignatureTop,
	changeTimeSignatureBottom,
	practiceTap,
	practiceRefs,
}) => {
	const [device] = useWindowResize()
	const page = useRef(null)

	const getFiltersModal = () => {
		if (usingPlaceholders) {
			return <PlaceholderModal page={page} title={get(tabs, [`filters`, `title`])} />
		}
		return (
			<FiltersModal
				page={page}
				changePreset={changePreset}
				updateLevel={updateLevel}
				turnOnGrooveMode={turnOnGrooveMode}
				generateRhythm={generateRhythm}
				changeTimeSignatureTop={changeTimeSignatureTop}
				changeTimeSignatureBottom={changeTimeSignatureBottom}
			/>
		)
	}

	const getFooter = () => {
		if (playing && !isPracticeModeOn) return null
		return (
			<IonFooter className="ion-no-border" no-border translucent>
				<TapCard practiceRefs={practiceRefs} isolate={isolate} practiceTap={practiceTap} />
			</IonFooter>
		)
	}

	const getHeader = () => {
		return (
			<IonHeader>
				{playing && isPracticeModeOn && <ProgressCard />}
				<FiltersCard />
			</IonHeader>
		)
	}

	return (
		<IonPage ref={page} className={`rhythmbot ion-practice-page ${platformClass} ${themeName} ${device}`}>
			{getHeader()}
			<IonContent className="ion-practice-content" color={`canvas`}>
				<div className="content">
					<Bars />
					<ActionCard />
				</div>
				{getFiltersModal()}
			</IonContent>
			{/* {getFooter()} */}
			<ActionButtons practiceRefs={practiceRefs} shuffle={shuffle} stop={stop} play={play} generateRhythm={generateRhythm} />
		</IonPage>
	)
}

const mapStateToProps = (state) => {
	const themeName = getThemeName(state)
	const platformClass = getPlatformClass(state)
	const usingPlaceholders = getUsingPlaceholders(state)
	const playing = getIsPlaying(state)
	const isPracticeModeOn = getIsPracticeModeOn(state)
	return {
		themeName,
		platformClass,
		usingPlaceholders,
		playing,
		isPracticeModeOn,
	}
}

export default connect(mapStateToProps, {})(Practice)
