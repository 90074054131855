import React, { useState } from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'

import Button from 'react-bootstrap/Button'

import { getIsApp, getRenderingState } from '../../redux/selectors/rendering'
import { getPlaybackState } from '../../redux/selectors/playback'

import { copyShareableURL } from '../../utils/functions'
import RenderingModes from '../../utils/constants/enum/renderingMode'

const Share = (props) => {
	const [content, setContent] = useState({ text: `Share Rhythm`, className: `action-3` })

	/**
	 * Create and copy a shareable link to the current rhythm using query strings
	 */
	const shareRhythm = () => {
		const renderedLength = get(props, [`rendering`, `renderedArray`, `length`])

		let barsStr = `bars=${get(props, [`playback`, `bars`])}&`
		let timeStr = `time=${get(props, [`playback`, `playbackTime`, `options`])}&`
		let timeSignatureString = `timeSignature=${get(props, [`playback`, `timeSignature`, `top`])}:${get(props, [`playback`, `timeSignature`, `bottom`])}&`
		let beatStr = ``
		for (let i = 0; i < renderedLength; i++) {
			beatStr += `beat=${get(props, [`rendering`, `renderedArray`, i])}`
			if (i < renderedLength - 1) {
				beatStr += '&'
			}
		}

		const queryParams = `?${barsStr}${timeStr}${timeSignatureString}${beatStr}`
		copyShareableURL({ isApp: get(props, `isApp`), queryParams })
		setContent({ text: `Link Copied`, className: `action-2` })
		setTimeout(() => {
			setContent({ text: `Share Rhythm`, className: `action-3` })
		}, 3000)
	}

	const disabled = isEmpty(get(props, [`rendering`, `renderedArray`])) || get(props, [`rendering`, `mode`]) !== RenderingModes.NORMAL
	return (
		<Button onClick={shareRhythm} className={'share ' + get(content, `className`, ``)} disabled={disabled}>
			{get(content, `text`, `Share Rhythm`)}
		</Button>
	)
}

const mapStateToProps = (state) => {
	const isApp = getIsApp(state)
	const rendering = getRenderingState(state)
	const playback = getPlaybackState(state)
	return {
		isApp,
		rendering,
		playback,
	}
}

export default connect(mapStateToProps, {})(Share)
