import ReactGA from 'react-ga'
import { set as idbSet } from 'idb-keyval'

import store from '../../redux/store'
import { setAnalyticsPrompt } from '../../redux/actions'
import { mobileTimeSignatureAnimation, prod } from '../functions'

/**
 * Respond to a users analytics preference.
 * Only initialise analytics if on prod and the user has agreed
 */
export const analyticsResponse = ({ ack, isApp }) => {
	if (ack && prod({ isApp })) {
		idbSet('analyticsAck', true)
			.then(() => console.log('analyticsAck True'))
			.catch((err) => console.error('analyticsAck True error', err))
		console.log('Initialising analytics')
		ReactGA.initialize(process.env.REACT_APP_GA_KEY)
		ReactGA.pageview(window.location.pathname + window.location.search)
	} else {
		idbSet('analyticsAck', false)
			.then(() => console.log('analyticsAck False'))
			.catch((err) => console.error('analyticsAck False error', err))
	}
	store.dispatch(setAnalyticsPrompt(false))
	mobileTimeSignatureAnimation()
}
