import React from 'react'
import { findIndex, get } from 'lodash'
import { connect } from 'react-redux'

import { useIonPicker, IonItem, IonLabel } from '@ionic/react'

import { getIsPlaying, getSwing, getTime } from '../../../../../../../redux/selectors/playback'
import { setSwing } from '../../../../../../../redux/actions'
import TimeEnum from '../../../../../../../utils/constants/enum/time'

const Swing = ({ playing, swing, setSwing, time }) => {
	const [present] = useIonPicker()

	const getLabel = () => `${swing}% Swing`

	const openPicker = async () => {
		if (playing) {
			return
		}
		let options = []
		for (let i = 0; i <= 100; i++) {
			if (i % 5 !== 0) {
				continue
			}
			options.push({
				text: `${i}%`,
				value: i,
			})
		}
		const selectedIndex = findIndex(options, (o) => get(o, `value`) === swing)

		present({
			columns: [
				{
					name: 'swing',
					selectedIndex,
					options,
				},
			],
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'Confirm',
					handler: (value) => {
						updateSwing(get(value, [`swing`, `value`]))
					},
				},
			],
		})
	}

	const updateSwing = (swing) => {
		if (playing) {
			return
		}
		return setSwing(swing)
	}

	if (get(time, `options`) === TimeEnum.MIXED || get(time, `options`) === TimeEnum.TRIPLETS) return null
	return (
		<IonItem detail button disabled={playing} onClick={openPicker}>
			<IonLabel>{getLabel()}</IonLabel>
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const playing = getIsPlaying(state)
	const swing = getSwing(state)
	const time = getTime(state)
	return {
		playing,
		swing,
		time,
	}
}

export default connect(mapStateToProps, { setSwing })(Swing)
