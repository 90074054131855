import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { setRhythmName } from '../redux/actions'
import { getRenderingState } from '../redux/selectors/rendering'

import RenderingModes from '../utils/constants/enum/renderingMode'
import { getPlaybackState } from '../redux/selectors/playback'
import ClickRateEnum from '../utils/constants/enum/click'
import { isIonic } from '../utils/functions'
import { title as rvPermutationsTitle } from '../utils/rhythmic-vocabulary-permutations'

const useRhythmName = () => {
	const dispatch = useDispatch()
	const playback = useSelector(getPlaybackState)
	const rendering = useSelector(getRenderingState)

	/**
	 * Update the rhythm description as the click changes
	 */
	useEffect(() => {
		if (isIonic()) {
			return
		}
		if (!get(rendering, `showModals.click.hasOpened`)) {
			return
		}

		const cD = getClickDescription()
		if (cD) {
			// If there is a valid click description to display
			set({ name: cD })
			return
		}

		if (get(rendering, `rhythmName.preset`)) {
			// If the description describes a preset, don't replace it
			return
		}
		set({ name: getRhythmName({ fromClick: true, renderingMode: get(rendering, `mode`) }) })
	}, [
		playback.click.volume,
		playback.click.rate.custom,
		playback.click.rate.selectedImage,
		playback.click.rate.amount,
		playback.click.rate.time,
		playback.click.rate.markTheOne,
		playback.click.offset.amount,
		playback.click.offset.rate,
		playback.click.gap.on,
		playback.click.gap.off,
		playback.click.gap.matchRhythmLength,
		playback.click.gap.startOff,
	])

	const set = (value) => dispatch(setRhythmName(value))

	/**
	 * Get a suitable name for the current rhythm
	 * @param {Number} renderingMode
	 * @param {Boolean} customOverride
	 * @returns
	 */
	const getRhythmName = ({ fromClick = false, renderingMode = RenderingModes.NORMAL, customOverride = false }) => {
		const clickDescription = getClickDescription()
		if (clickDescription) {
			return clickDescription
		}
		if (fromClick && isEmpty(get(rendering, `renderedArray`))) {
			return false
		}

		const isCustom = customOverride || get(playback, 'custom')
		const customStr = isCustom ? ' - Custom Groupings' : ' - Complexity ' + get(playback, `level`)
		const spaceStr = get(playback, `space`) > 0 ? ' - More Space' : ''

		let rhythmName = `${get(rendering, 'timeName')} Rhythm ${customStr} ${spaceStr}`
		switch (renderingMode) {
			case RenderingModes.DOWNUP.STRAIGHT:
				rhythmName = `16th Note Down/Ups`
				break
			case RenderingModes.DOWNUP.TRIPLETS:
				rhythmName = `Triplet Down/Ups`
				break
			case RenderingModes.SD.TRIPLETS.ONE:
				rhythmName = `Triplet SD Notation - Complexity 1`
				break
			case RenderingModes.SD.TRIPLETS.TWO:
				rhythmName = `Triplet SD Notation - Complexity 2`
				break
			case RenderingModes.SD.TRIPLETS.THREE:
				rhythmName = `Triplet SD Notation - Complexity 3`
				break
			case RenderingModes.SD.TRIPLETS.FOUR:
				rhythmName = `Triplet SD Notation - Complexity 4`
				break
			case RenderingModes.SD.TRIPLETS.FIVE:
				rhythmName = `Triplet SD Notation - Complexity 5`
				break
			case RenderingModes.FIRSTGROOVES.ALL:
				rhythmName = `8th Note Grooves${isCustom ? ` - Custom` : ``}`
				break
			case RenderingModes.FIRSTGROOVES.ONE:
				rhythmName = `8th Note Grooves - ${isCustom ? `Custom` : `Complexity 1`}`
				break
			case RenderingModes.FIRSTGROOVES.TWO:
				rhythmName = `8th Note Grooves - ${isCustom ? `Custom` : `Complexity 2`}`
				break
			case RenderingModes.FIRSTGROOVES.THREE:
				rhythmName = `8th Note Grooves - ${isCustom ? `Custom` : `Complexity 3`}`
				break
			case RenderingModes.RHYTHMIC_VOCABULARY_PERMUTATIONS.STRAIGHT:
				rhythmName = rvPermutationsTitle
				break
			default:
				break
		}
		return rhythmName
	}

	/**
	 * Suitable description for the current click setup
	 * @returns
	 */
	const getClickDescription = () => {
		const render =
			get(playback, 'click.volume') === 0 || get(playback, 'click.gap.on') || get(playback, 'click.rate.custom') || get(playback, 'click.offset.amount') > 0
		if (!render) {
			return false
		}
		let gapStr, rateStr, matchingStr, ret
		gapStr = rateStr = matchingStr = ``
		let multiple, tStr

		if (get(playback, 'click.volume') === 0) {
			ret = `Click Muted`
			return ret
		}
		if (get(playback, 'click.gap.on')) {
			let s
			switch (get(playback, 'click.gap.startOff')) {
				case true:
					s = `${get(playback, 'click.gap.off')}/${get(playback, 'click.gap.on')}`
					break
				default:
					s = `${get(playback, 'click.gap.on')}/${get(playback, 'click.gap.off')}`
					break
			}
			switch (get(playback, 'click.gap.matchRhythmLength')) {
				case true:
					matchingStr = `- Matching Rhythm`
					break
				default:
					break
			}
			gapStr = `- Gap ${s} ${matchingStr}`
		}
		if (get(playback, 'click.rate.custom')) {
			multiple = get(playback, 'click.rate.amount') > 1
			switch (get(playback, 'click.rate.time')) {
				case ClickRateEnum.QUARTER:
					tStr = `Quarter Note`
					if (multiple) {
						tStr += `s`
					}
					break
				case ClickRateEnum.EIGHTH:
					tStr = `8th`
					if (multiple) {
						tStr += `'s`
					}
					break
				case ClickRateEnum.SIXTEENTH:
					tStr = `16th`
					if (multiple) {
						tStr += `'s`
					}
					break
				case ClickRateEnum.TRIPLETS:
					tStr = `8th Note Triplet`
					if (multiple) {
						tStr += `s`
					}
					break
				default:
					break
			}
			rateStr = `Every ${multiple ? get(playback, 'click.rate.amount') : ``} ${tStr}`
		}
		ret = `${rateStr} ${gapStr}`

		if (!isEmpty(rateStr) && !isEmpty(gapStr)) {
			return ret
		}
		if (get(playback, 'click.offset.amount') === 0) {
			return ret
		}
		multiple = get(playback, 'click.offset.amount') > 1
		switch (get(playback, 'click.offset.rate')) {
			case ClickRateEnum.SIXTEENTH:
				tStr = `16th`
				if (multiple) {
					tStr += `'s`
				}
				break
			default:
				tStr = `Triplet`
				if (multiple) {
					tStr += `s`
				}
				break
		}
		return (ret += ` - Offset ${get(playback, 'click.offset.amount')} ${tStr}`)
	}

	return { getClickDescription, getRhythmName, set }
}

export default useRhythmName
