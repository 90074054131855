import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { IonModal, IonButtons, IonButton, IonToolbar, IonTitle, IonContent, IonIcon } from '@ionic/react'
import { arrowBackOutline } from 'ionicons/icons'

import Presets from './Presets'
import List from './list'
import Complexity from './Complexity'
import TimeSignature from './TimeSignature'

import { getPlatformClass, getThemeName } from '../../../../../redux/selectors/rendering'
import { ionSetComplexityOpen, ionSetFiltersOpen, ionSetPresetsOpen, ionSetTimeSignatureOpen } from '../../../../../redux/ionicActions'

import { tabs } from '../../../../../utils/ionic/tabs'
import { getArePresetsOpen, getIsComplexityCustomOpen, getIsComplexityOpen, getIsTimeSignatureOpen } from '../../../../../redux/selectors/ionic'
import { getTime } from '../../../../../redux/selectors/playback'
import TimeEnum from '../../../../../utils/constants/enum/time'
import { StraightPresets, TripletPresets } from '../../../../../utils/constants/presets'
import { setPresetConstants } from '../../../../../redux/actions'
import { openModal } from '../../../../../utils/ionic/modals'

const Filters = ({
	changePreset,
	presetsOpen,
	complexityOpen,
	complexityCustomOpen,
	timeSignatureOpen,
	updateLevel,
	turnOnGrooveMode,
	changeTimeSignatureBottom,
	changeTimeSignatureTop,
	render,
	// themeName,
	// platformClass,
	ionSetFiltersOpen,
	ionSetPresetsOpen,
	ionSetComplexityOpen,
	ionSetTimeSignatureOpen,
	time,
	setPresetConstants,
	// filtersOpen,
	page,
}) => {
	const modal = useRef(null)
	const [presentingElement, setPresentingElement] = useState(null)

	useEffect(() => {
		setPresentingElement(page.current)
	}, [])

	const dismiss = () => {
		ionSetFiltersOpen(false)
		modal.current.dismiss()
	}

	const present = () => {
		ionSetFiltersOpen(true)
	}

	useEffect(() => {
		if (get(time, `options`) === TimeEnum.TRIPLETS) {
			return setPresetConstants({ constants: TripletPresets })
		}
		setPresetConstants({ constants: StraightPresets() })
	}, [time])

	// const enterAnimation = (baseEl) => {
	// 	const root = baseEl.shadowRoot

	// 	const backdropAnimation = createAnimation().addElement(root.querySelector('ion-backdrop')).fromTo('opacity', '0.01', 'var(--backdrop-opacity)')

	// 	const wrapperAnimation = createAnimation()
	// 		.addElement(root.querySelector('.modal-wrapper'))
	// 		.keyframes([
	// 			{ offset: 0, opacity: '0', transform: 'scale(0)' },
	// 			{ offset: 1, opacity: '0.99', transform: 'scale(1)' },
	// 		])

	// 	return createAnimation().addElement(baseEl).easing('ease-out').duration(500).addAnimation([backdropAnimation, wrapperAnimation])
	// }

	// const leaveAnimation = (baseEl) => {
	// 	return enterAnimation(baseEl).direction('reverse')
	// }

	const getContent = () => {
		if (presetsOpen) {
			return <Presets dismiss={dismiss} changePreset={changePreset} />
		}
		if (complexityOpen) {
			return <Complexity updateLevel={updateLevel} />
		}
		if (timeSignatureOpen) {
			return <TimeSignature changeTimeSignatureBottom={changeTimeSignatureBottom} changeTimeSignatureTop={changeTimeSignatureTop} />
		}
		return <List dismiss={dismiss} updateLevel={updateLevel} turnOnGrooveMode={turnOnGrooveMode} />
	}

	const getTitle = () => {
		if (presetsOpen) {
			return `Presets`
		}
		if (complexityOpen) {
			if (complexityCustomOpen) {
				return `Custom Groupings`
			}
			return `Complexity`
		}
		if (timeSignatureOpen) {
			return `Time Signature`
		}
		return get(tabs, [`filters`, `title`])
	}

	const getBackButton = () => {
		if (!presetsOpen && !complexityOpen && !timeSignatureOpen) {
			return null
		}
		return (
			<IonButtons slot="start">
				<IonButton
					onClick={() => {
						if (!complexityCustomOpen) {
							ionSetComplexityOpen(false)
							ionSetPresetsOpen(false)
							ionSetTimeSignatureOpen(false)
							return
						}
						ionSetPresetsOpen(false)
						ionSetTimeSignatureOpen(false)
						ionSetComplexityOpen(true)
					}}
				>
					<IonIcon icon={arrowBackOutline} />
				</IonButton>
			</IonButtons>
		)
	}
	return (
		<IonModal
			// enterAnimation={enterAnimation} leaveAnimation={leaveAnimation}
			ref={modal}
			trigger={openModal}
			presentingElement={presentingElement}
			onDidPresent={present}
			canDismiss={true}
		>
			<IonToolbar color={`dark`}>
				{getBackButton()}
				<IonButtons slot="end">
					<IonButton onClick={dismiss}>{`Close`}</IonButton>
				</IonButtons>
				<IonTitle color={`canvas`}>{getTitle()}</IonTitle>
			</IonToolbar>
			<IonContent fullscreen>{getContent()}</IonContent>
		</IonModal>
		// <IonPage className={`rhythmbot ion-practice-filters ${platformClass} ${themeName} ${device}`}>
		// 	<IonHeader>
		// <IonToolbar color={`dark`}>
		// 	{getBackButton()}
		// 	<IonButtons slot="end">
		// 		<IonButton onClick={() => ionSetFiltersOpen(false)}>{`Close`}</IonButton>
		// 	</IonButtons>
		// 	<IonTitle color={`canvas`}>{getTitle()}</IonTitle>
		// </IonToolbar>
		// 	</IonHeader>
		// 	<IonContent fullscreen>
		// 		<IonModal enterAnimation={enterAnimation} leaveAnimation={leaveAnimation} isOpen={true}>
		// 			{getContent()}
		// 		</IonModal>
		// 	</IonContent>
		// </IonPage>
	)
}

const mapStateToProps = (state) => {
	const themeName = getThemeName(state)
	const platformClass = getPlatformClass(state)
	const presetsOpen = getArePresetsOpen(state)
	const timeSignatureOpen = getIsTimeSignatureOpen(state)
	const complexityOpen = getIsComplexityOpen(state)
	const complexityCustomOpen = getIsComplexityCustomOpen(state)
	const time = getTime(state)
	return {
		themeName,
		platformClass,
		presetsOpen,
		time,
		complexityOpen,
		timeSignatureOpen,
		complexityCustomOpen,
	}
}

export default connect(mapStateToProps, { ionSetFiltersOpen, ionSetPresetsOpen, ionSetTimeSignatureOpen, setPresetConstants, ionSetComplexityOpen })(Filters)
