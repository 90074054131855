import React from 'react'
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/react'

const Placeholder = ({ title }) => {
	return (
		<IonPage className={`rhythmbot ion-placeholder`}>
			<IonHeader>
				<IonToolbar color={`dark`}>
					<IonTitle color={`canvas`}>{title || `Huh??`}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen></IonContent>
		</IonPage>
	)
}

export default Placeholder
