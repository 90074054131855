import React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'

import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/react'
import { getPlatformClass, getThemeName } from '../../../../redux/selectors/rendering'
import useWindowResize from '../../../../hooks/useWindowResize'
import { tabs } from '../../../../utils/ionic/tabs'

const Groove = ({ themeName, platformClass }) => {
	const [device] = useWindowResize()
	return (
		<IonPage className={`rhythmbot ion-groove ${platformClass} ${themeName} ${device}`}>
			<IonHeader>
				<IonToolbar color={`dark`}>
					<IonTitle color={`canvas`}>{get(tabs, [`groove`, `title`])}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent color={`white`} fullscreen>
				<h1>TBD123</h1>
			</IonContent>
		</IonPage>
	)
}

const mapStateToProps = (state) => {
	const themeName = getThemeName(state)
	const platformClass = getPlatformClass(state)
	return {
		themeName,
		platformClass,
	}
}

export default connect(mapStateToProps, {})(Groove)
