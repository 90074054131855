import { set as idbSet } from 'idb-keyval'

import { setSnare, setHihat, setKick, setMetronome, setMetronomeOne, setRide, setPlayEnabled, setMarkOne } from '../redux/actions'
import store from '../redux/store'
import { errorOccurred } from '../utils/functions'

const state = store.getState()

const loadSound = (idx, idb, source) => {
	let setter, key, bufferLength
	switch (idx) {
		case 0:
			setter = setSnare
			key = 'snareAudio'
			bufferLength = 61103
			break
		case 1:
			setter = setKick
			key = 'kickAudio'
			bufferLength = 66692
			break
		case 2:
			setter = setHihat
			key = 'hihatAudio'
			bufferLength = 8211
			break
		case 3:
			setter = setRide
			key = 'rideAudio'
			bufferLength = 95892
			break
		case 4:
			setter = setMetronome
			key = 'metronomeAudio'
			bufferLength = 36366
			break
		case 5:
			setter = setMetronomeOne
			key = 'metronomeOneAudio'
			bufferLength = 28864
			break
		case 6:
			setter = setMarkOne
			key = 'markOneAudio'
			bufferLength = 65404
			break
		default:
			break
	}
	try {
		if (!idb) {
			if (typeof source === 'string') {
				//Getting sound from original source
				var getSound = new XMLHttpRequest()
				getSound.open('GET', source, true)
				getSound.responseType = 'arraybuffer'
				getSound.onload = () => {
					console.log('Getsound ' + key + ' ' + getSound.response)
					state.audio.audioContext.decodeAudioData(getSound.response, (buffer) => {
						let audio = []
						audio.push(buffer.getChannelData(0))
						audio.push(buffer.getChannelData(1))
						idbSet(key, audio)
							.then(() => console.log(key + ' saved in IDB'))
							.catch((err) => console.error(key + ' error in IDB', err))
						store.dispatch(setter(buffer))
					})
				}
				getSound.send()
			} else {
				//Loading from buffer - for user sound upload
				state.audio.audioContext.decodeAudioData(source, (buffer) => {
					let audio = []
					audio.push(buffer.getChannelData(0))
					audio.push(buffer.getChannelData(1))
					idbSet(key, audio)
						.then(() => console.log(key + ' saved in IDB'))
						.catch((err) => console.error(key + ' error in IDB', err))
					store.dispatch(setter(buffer))
				})
			}
		} else {
			var buffer = state.audio.audioContext.createBuffer(2, bufferLength, state.audio.audioContext.sampleRate)
			if (idb.length === 2) {
				try {
					buffer.copyToChannel(idb[0], 0)
					buffer.copyToChannel(idb[1], 1)
					store.dispatch(setter(buffer))
				} catch {
					try {
						//Safari
						buffer.getChannelData(0).set(idb[0])
						buffer.getChannelData(1).set(idb[1])
						store.dispatch(setter(buffer))
					} catch {
						//Mobile Safari/iOS
						try {
							var channelOne = buffer.getChannelData(0)
							var channelTwo = buffer.getChannelData(1)
							for (var i = 0; i < buffer.length; i++) {
								channelOne[i] = idb[0][i]
								channelTwo[i] = idb[1][i]
							}
							store.dispatch(setter(buffer))
						} catch (error) {
							errorOccurred('Error loading sound ' + key + ' IDB: ' + error)
							console.error(key + ' IDB audio error: ' + error)
							store.dispatch(setPlayEnabled(false))
							loadSound(idx, false, source)
						}
					}
				}
			} else {
				console.log('Invalid IDB audio ' + key + '. Loading from original source')
				loadSound(idx, false, source)
			}
		}
	} catch (error) {
		store.dispatch(setPlayEnabled(false))
		store.dispatch(errorOccurred('Error loading' + key + ': ' + error))
		console.error('Error loading ' + key + ' sound: ' + error)
	}
}

export { loadSound }
