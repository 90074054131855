import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { IonIcon } from '@ionic/react'

import { cogOutline } from 'ionicons/icons'

import useBarPicker from '../Hooks/useBarPicker'
import useBpmPicker from '../Hooks/useBpmPicker'
import useComplexityPicker from '../Hooks/useComplexityPicker'

import { getBars, getBpm, getComplexityLevel, getIsCustom } from '../../../../../../redux/selectors/playback'
import { getRenderingMode } from '../../../../../../redux/selectors/rendering'
import { getUsingPlaceholders } from '../../../../../../redux/selectors/ionic'
import { openModal, openPlaceholderModal } from '../../../../../../utils/ionic/modals'

import RenderingMode from '../../../../../../utils/constants/enum/renderingMode'

const Sections = ({ bars, bpm, complexity, isCustomComplexity, renderingMode, usingPlaceholders }) => {
	const openBarPicker = useBarPicker()
	const openBpmPicker = useBpmPicker()
	const openComplexityPicker = useComplexityPicker()

	const modalID = () => {
		if (usingPlaceholders) {
			return openPlaceholderModal
		}
		return openModal
	}

	const getComplexity = () => {
		if (isCustomComplexity) return `Custom`
		switch (renderingMode) {
			case RenderingMode.FIRSTGROOVES.ALL:
				return `All Grooves`
			case RenderingMode.FIRSTGROOVES.ONE:
			case RenderingMode.FIRSTGROOVES.TWO:
			case RenderingMode.FIRSTGROOVES.THREE:
				return `${renderingMode - 7}`
			case RenderingMode.SD.TRIPLETS.ONE:
			case RenderingMode.SD.TRIPLETS.TWO:
			case RenderingMode.SD.TRIPLETS.THREE:
			case RenderingMode.SD.TRIPLETS.FOUR:
			case RenderingMode.SD.TRIPLETS.FIVE:
				return `${renderingMode - 2}`
			default:
				break
		}
		return complexity
	}

	const sections = [
		{
			title: 'All',
			id: modalID(),
			details: {
				className: 'filters-grid-cog',
				contents: <IonIcon icon={cogOutline} />,
			},
		},
		{
			title: 'Bars',
			details: {
				className: 'description text bold size-22',
				contents: `${bars}`,
			},
			onClick: openBarPicker,
		},
		{
			title: 'Complexity',
			details: {
				className: 'description text bold size-22',
				contents: getComplexity(),
			},
			onClick: openComplexityPicker,
		},
		{
			title: 'BPM',
			details: {
				className: 'description text bold size-22',
				contents: `${bpm}`,
			},
			onClick: openBpmPicker,
		},
	]

	return (
		<div className="filters-grid">
			{sections.map((section, idx) => {
				return (
					<div id={get(section, 'id', '')} onClick={get(section, 'onClick', null)} className="section" key={`filters-section-${idx}`}>
						<div className={'description text light size-16'}> {section.title} </div>
						<div className={section.details.className}> {section.details.contents} </div>
					</div>
				)
			})}
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		bars: getBars(state),
		bpm: getBpm(state),
		complexity: getComplexityLevel(state),
		isCustomComplexity: getIsCustom(state),
		renderingMode: getRenderingMode(state),
		usingPlaceholders: getUsingPlaceholders(state),
	}
}

export default connect(mapStateToProps, {})(Sections)
