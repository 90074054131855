import React from 'react'
import { get, findIndex, set } from 'lodash'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useIonPicker, IonItem, IonLabel, IonIcon } from '@ionic/react'
import { cogOutline } from 'ionicons/icons'

import { getIsPlaying, getPlaybackAs, getPlaybackTimeOptions } from '../../../../../../../redux/selectors/playback'
import { setPlaybackAs } from '../../../../../../../redux/actions'
import { getSoundName, Sounds } from '../../../../../../../utils/constants/enum/sounds'
import { getRenderingMode } from '../../../../../../../redux/selectors/rendering'
import RenderingMode from '../../../../../../../utils/constants/enum/renderingMode'
import { tabs } from '../../../../../../../utils/ionic/tabs'

const Playback = ({ dismiss, renderingMode, playing, playbackTimeOptions, turnOnGrooveMode, setPlaybackAs, playbackAs }) => {
	const history = useHistory()
	const [present] = useIonPicker()

	const getLabel = () => `Playback as ${getSoundName(playbackAs)}`

	const updatePlayback = (selected) => {
		setPlaybackAs(selected)
		if (selected === Sounds.GROOVE) {
			turnOnGrooveMode({ random: false, time: playbackTimeOptions, fromClick: true })
		}
	}

	const openPicker = async () => {
		const opts = {
			columns: [
				{
					name: 'playback',
					options: [
						{
							text: 'Snare',
							value: Sounds.SNARE,
						},
						{
							text: 'Kick',
							value: Sounds.KICK,
						},
						{
							text: 'Groove',
							value: Sounds.GROOVE,
						},
					],
				},
			],
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'Confirm',
					handler: (value) => {
						updatePlayback(get(value, [`playback`, `value`]))
					},
				},
			],
		}

		const i = findIndex(get(opts, [`columns`, 0, `options`]), (o) => get(o, `value`) === playbackAs)
		set(opts, [`columns`, 0, `selectedIndex`], i)

		present(opts)
	}

	const navigateToGroove = () => {
		dismiss()
		history.push(get(tabs, [`groove`, `href`]))
	}

	const showCog = () => {
		if (playbackAs !== Sounds.GROOVE) {
			return
		}
		return (
			<IonIcon
				icon={cogOutline}
				onClick={(e) => {
					e.stopPropagation()
					navigateToGroove()
				}}
			/>
		)
	}

	const disabled = () => {
		if (playing) return true
		switch (renderingMode) {
			case RenderingMode.FIRSTGROOVES.ONE:
			case RenderingMode.FIRSTGROOVES.TWO:
			case RenderingMode.FIRSTGROOVES.THREE:
			case RenderingMode.FIRSTGROOVES.ALL:
				return true
			default:
				break
		}
		return false
	}

	return (
		<IonItem disabled={disabled()} detail button onClick={openPicker}>
			<IonLabel>{getLabel()}</IonLabel>
			{showCog()}
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const playbackAs = getPlaybackAs(state)
	const playbackTimeOptions = getPlaybackTimeOptions(state)
	const playing = getIsPlaying(state)
	const renderingMode = getRenderingMode(state)
	return {
		playbackAs,
		playbackTimeOptions,
		playing,
		renderingMode,
	}
}

export default connect(mapStateToProps, { setPlaybackAs })(Playback)
