import get from 'lodash/get'
import concat from 'lodash/concat'

import { useDispatch, useSelector } from 'react-redux'

import { setCustom, setLevel, setLevelsArray } from '../redux/actions'
import Times from '../utils/constants/enum/time'
import { getIsSixTwelveEight, getTimeToUse } from '../redux/selectors/playback'
import SixEightLevels from '../utils/constants/levels/six-eight'
import StraightLevels from '../utils/constants/levels/straight'
import TripletLevels from '../utils/constants/levels/triplets'
import MixedLevels from '../utils/constants/levels/mixed'

const useComplexityLevel = () => {
	const dispatch = useDispatch()
	const timeToUse = useSelector(getTimeToUse)
	const isSixTwelveEight = useSelector(getIsSixTwelveEight)

	/**
	 * @param {number} level the complexity level selected
	 * @param {number} time override the current timeToUse
	 */
	const updateLevel = ({ level, time = false, clearCustom = false }) => {
		if (clearCustom) {
			dispatch(setCustom(false))
		}
		const t = time || get(timeToUse, 'options')
		if (t === Times.EIGHTH) {
			return
		}

		let levels = false
		if (level < 5 && level > 0) {
			switch (t) {
				case Times.STRAIGHT:
					if (isSixTwelveEight) {
						levels = SixEightLevels
						break
					}
					levels = StraightLevels
					break
				case Times.TRIPLETS:
					levels = TripletLevels
					break
				case Times.MIXED:
					levels = MixedLevels
					break
				default:
					break
			}
			let builtLevels = []
			for (let i = 0; i < level; i++) {
				builtLevels = concat(builtLevels, get(levels, ['levels', [i], 'arr']))
			}
			levels = builtLevels
		}

		dispatch(setLevelsArray(levels))
		dispatch(setLevel(level < 5 && level > 0 ? level : 5))
	}

	return { updateLevel }
}

export default useComplexityLevel
