import get from 'lodash/get'
import set from 'lodash/set'

import store from '../../redux/store'
import { initialText } from '../../redux/reducers/initStates'
import { LanguageEnum } from '../constants/enum/language'

/**
 * Handle a german translation
 */
const german = ({ canvasText }) => {
	set(canvasText, `isolate`, `Isolieren`)
	return canvasText
}

const triggerTranslation = ({ language = LanguageEnum.ENGLISH }) => {
	const state = store.getState()
	switch (true) {
		case language === LanguageEnum.GERMAN:
			return german({
				canvasText: {
					...get(state, `rendering.text.canvas`),
				},
			})
		default:
			return get(initialText, `canvas`)
	}
}

export { triggerTranslation }
