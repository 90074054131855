import get from 'lodash/get'
import store from '../../redux/store'

const groupsOfThree = () => get(store.getState(), `rendering.text.filters.presets.threes`)
const groupsOfFive = () => get(store.getState(), `rendering.text.filters.presets.fives`)

const StraightPresets = () => {
	return {
		presets: [
			{
				preset: 'Singles',
				val: {
					presets: [
						{
							preset: 'ABAB',
							arr: [3, 3, 3, 3],
							grouping: 4,
							variant: 'info',
							final: true,
						},
						{
							preset: 'BABA',
							arr: [14, 14, 14, 14],
							grouping: 4,
							variant: 'info',
							final: true,
						},
					],
				},
				variant: 'primary',
			},
			{
				preset: 'Doubles',
				val: {
					presets: [
						{
							preset: 'AABB',
							arr: [11, 11, 11, 11],
							grouping: 4,
							variant: 'info',
							final: true,
						},
						{
							preset: 'BBAA',
							arr: [10, 10, 10, 10],
							grouping: 4,
							variant: 'info',
							final: true,
						},
						{
							preset: 'Inverted ABBA',
							arr: [9, 9, 9, 9],
							grouping: 4,
							variant: 'info',
							final: true,
						},
						{
							preset: 'Inverted BAAB',
							arr: [12, 12, 12, 12],
							grouping: 4,
							variant: 'info',
							final: true,
						},
					],
				},
				variant: 'primary',
			},
			{
				preset: 'Paradiddles',
				val: {
					presets: [
						{
							preset: '1 - ABAABABB',
							arr: [6, 15, 6, 15],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '2 - ABBABAAB',
							arr: [9, 12, 9, 12],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '3 - AABABBAB',
							arr: [8, 4, 8, 4],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '4 - ABABBABA',
							arr: [3, 14, 3, 14],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: 'All Four',
							arr: [6, 15, 6, 15, 9, 12, 9, 12, 8, 4, 8, 4, 3, 14, 3, 14],
							grouping: 64,
							bars: 4,
							variant: 'info',
							final: true,
						},
						{
							preset: 'Shuffle All',
							shuffleAll: {
								on: true,
								options: [
									[6, 15, 6, 15],
									[9, 12, 9, 12],
									[8, 4, 8, 4],
									[3, 14, 3, 14],
								],
							},
							bars: 4,
							grouping: 64,
							variant: 'info',
							final: true,
						},
					],
				},
				variant: 'primary',
			},
			{
				preset: groupsOfThree(),
				val: {
					presets: [
						{
							preset: 'Dotted 8ths',
							arr: [9, 4, 15, 9, 4, 15, 9, 4, 15, 9, 4, 15],
							grouping: 3,
							bars: 3,
							variant: 'info',
							final: true,
						},
						{
							preset: 'Dotted Doubles',
							arr: [8, 6, 12, 8, 6, 12, 8, 6, 12, 8, 6, 12],
							grouping: 3,
							bars: 3,
							variant: 'info',
							final: true,
						},
						{
							preset: 'Shuffle All',
							shuffleAll: {
								on: true,
								options: [
									[9, 4, 15, 9, 4, 15, 9, 4, 15, 9, 4, 15],
									[8, 6, 12, 8, 6, 12, 8, 6, 12, 8, 6, 12],
								],
							},
							bars: 3,
							grouping: 3,
							variant: 'info',
							final: true,
						},
					],
				},
				variant: 'primary',
			},
			{
				preset: groupsOfFive(),
				val: {
					presets: [
						{
							preset: 'ABBBB',
							arr: [2, 15, 4, 16, 1, 2, 15, 4, 16, 1, 2, 15, 4, 16, 1, 2, 15, 4, 16, 1],
							grouping: 5,
							bars: 5,
							variant: 'info',
							final: true,
						},
						{
							preset: 'ABABB',
							arr: [3, 14, 4, 9, 15, 3, 14, 4, 9, 15, 3, 14, 4, 9, 15, 3, 14, 4, 9, 15],
							grouping: 5,
							bars: 5,
							variant: 'info',
							final: true,
						},
						{
							preset: 'ABAAB',
							arr: [6, 14, 3, 8, 12, 6, 14, 3, 8, 12, 6, 14, 3, 8, 12, 6, 14, 3, 8, 12],
							grouping: 5,
							bars: 5,
							variant: 'info',
							final: true,
						},
						{
							preset: 'Shuffle All',
							shuffleAll: {
								on: true,
								options: [
									[2, 15, 4, 16, 1, 2, 15, 4, 16, 1, 2, 15, 4, 16, 1, 2, 15, 4, 16, 1],
									[3, 14, 4, 9, 15, 3, 14, 4, 9, 15, 3, 14, 4, 9, 15, 3, 14, 4, 9, 15],
									[6, 14, 3, 8, 12, 6, 14, 3, 8, 12, 6, 14, 3, 8, 12, 6, 14, 3, 8, 12],
								],
							},
							bars: 4,
							grouping: 5,
							variant: 'info',
							final: true,
						},
					],
				},
				variant: 'primary',
			},
			{
				preset: '16 Invisible Rhythms',
				val: {
					presets: [
						{
							preset: '1 - ABBABBAB',
							arr: [9, 4, 9, 4],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '2 - BABBABBA',
							arr: [15, 9, 15, 9],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '3 - ABABBABB',
							arr: [3, 15, 3, 15],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '4 - BABABBAB',
							arr: [14, 4, 14, 4],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '5 - BBABABBA',
							arr: [4, 9, 4, 9],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '6 - ABBABABB',
							arr: [9, 15, 9, 15],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '7 - BABBABAB',
							arr: [15, 3, 15, 3],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '8 - BBABBABA',
							arr: [4, 14, 4, 14],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '9 - AABAABAB',
							arr: [8, 3, 8, 3],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '10 - BAABAABA',
							arr: [12, 8, 12, 8],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '11 - ABAABAAB',
							arr: [6, 12, 6, 12],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '12 - BABAABAA',
							arr: [14, 6, 14, 6],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '13 - ABABAABA',
							arr: [3, 8, 3, 8],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '14 - AABABAAB',
							arr: [8, 12, 8, 12],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '15 - BAABABAA',
							arr: [12, 6, 12, 6],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: '16 - ABAABABA',
							arr: [6, 14, 6, 14],
							grouping: 8,
							variant: 'info',
							final: true,
						},
						{
							preset: 'Shuffle All',
							shuffleAll: {
								on: true,
								options: [
									[9, 4, 9, 4],
									[15, 9, 15, 9],
									[3, 15, 3, 15],
									[14, 4, 14, 4],
									[4, 9, 4, 9],
									[9, 15, 9, 15],
									[15, 3, 15, 3],
									[4, 14, 4, 14],
									[8, 3, 8, 3],
									[12, 8, 12, 8],
									[6, 12, 6, 12],
									[14, 6, 14, 6],
									[3, 8, 3, 8],
									[8, 12, 8, 12],
									[12, 6, 12, 6],
									[6, 14, 6, 14],
								],
							},
							bars: 4,
							grouping: 64,
							variant: 'info',
							final: true,
						},
					],
				},
				variant: 'primary',
			},
		],
	}
}

const LongerMelodies2 = {
	presets: [
		{ preset: 'Second Half', variant: 'secondary', helper: `true` },
		{ preset: 'BABABB', arr: [8, 2], grouping: 12, variant: 'info', final: true },
		{ preset: 'BABABA', arr: [8, 4], grouping: 12, variant: 'info', final: true },
		{ preset: 'BABAAB', arr: [8, 5], grouping: 12, variant: 'info', final: true },
		{ preset: 'BBAABB', arr: [7, 2], grouping: 12, variant: 'info', final: true },
		{ preset: 'BBABBA', arr: [7, 7], grouping: 12, variant: 'info', final: true },
		{ preset: 'BBAABA', arr: [7, 4], grouping: 12, variant: 'info', final: true },
	],
}
const LongerMelodies1 = [
	{ preset: 'First Half', variant: 'secondary', helper: `true` },
	{
		preset: 'ABBABA',
		first: true,
		arr: [2, 4],
		val: LongerMelodies2,
		variant: 'info',
	},
	{
		preset: 'ABAABA',
		first: true,
		arr: [4, 4],
		val: LongerMelodies2,
		variant: 'info',
	},
	{
		preset: 'AABABA',
		first: true,
		arr: [5, 4],
		val: LongerMelodies2,
		variant: 'info',
	},
]
const TripletPresets = {
	presets: [
		{
			preset: 'Short Melodies',
			val: {
				presets: [
					{
						preset: '1 - ABBABB',
						arr: [2, 2, 2, 2],
						grouping: 3,
						variant: 'info',
						final: true,
					},
					{
						preset: '2 - ABAABB',
						arr: [4, 2, 4, 2],
						grouping: 6,
						variant: 'info',
						final: true,
					},
					{
						preset: '3 - AABABB',
						arr: [5, 2, 5, 2],
						grouping: 6,
						variant: 'info',
						final: true,
					},
					{
						preset: '4 - ABBABA',
						arr: [2, 4, 2, 4],
						grouping: 6,
						variant: 'info',
						final: true,
					},
					{
						preset: '5 - ABAABA',
						arr: [4, 4, 4, 4],
						grouping: 3,
						variant: 'info',
						final: true,
					},
					{
						preset: '6 - AABABA',
						arr: [5, 4, 5, 4],
						grouping: 6,
						variant: 'info',
						final: true,
					},
					{
						preset: '7 - ABABAB',
						arr: [4, 8, 4, 8],
						grouping: 6,
						variant: 'info',
						final: true,
					},
					{
						preset: '8 - ABBAAB',
						arr: [2, 5, 2, 5],
						grouping: 6,
						variant: 'info',
						final: true,
					},
					{
						preset: '9 - AABAAB',
						arr: [5, 5, 5, 5],
						grouping: 3,
						variant: 'info',
						final: true,
					},
					{
						preset: 'Shuffle All',
						final: true,
						shuffleAll: {
							on: true,
							options: [
								[2, 2, 2, 2],
								[4, 2, 4, 2],
								[5, 2, 5, 2],
								[2, 4, 2, 4],
								[4, 4, 4, 4],
								[5, 4, 5, 4],
								[4, 8, 4, 8],
								[2, 5, 2, 5],
								[5, 5, 5, 5],
							],
						},
						bars: 4,
						grouping: 3,
						variant: 'info',
					},
				],
			},
			variant: 'primary',
		},
		{
			preset: 'Longer Melodies',
			val: {
				presets: LongerMelodies1,
			},
			variant: 'primary',
		},
		{
			preset: 'Swung Down-Ups',
			val: {
				presets: [
					{
						preset: '1 - ABBABBABABBA',
						arr: [2, 2, 4, 7],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: '2 - ABBABABBABBA',
						arr: [2, 4, 7, 7],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: '3 - ABBABABBAABB',
						arr: [2, 4, 7, 2],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: '4 - ABABBABBABBA',
						arr: [4, 7, 7, 7],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: '5 - ABABBABBAABB',
						arr: [4, 7, 7, 2],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: '6 - ABABBAABBABB',
						arr: [4, 7, 2, 2],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: 'Shuffle All',
						final: true,
						shuffleAll: {
							on: true,
							options: [
								[2, 2, 4, 7],
								[2, 4, 7, 7],
								[2, 4, 7, 2],
								[4, 7, 7, 7],
								[4, 7, 7, 2],
								[4, 7, 2, 2],
							],
						},
						bars: 4,
						grouping: 12,
						variant: 'info',
					},
				],
			},
			variant: 'primary',
		},
		{
			preset: 'Afro-Cuban Down-Ups',
			val: {
				presets: [
					{
						preset: '1 - ABABAABABABA',
						arr: [4, 6, 8, 4],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: '2 - AABABAABABAB',
						arr: [5, 4, 4, 8],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: '3 - BAABABAABABA',
						arr: [6, 8, 5, 4],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: '4 - ABAABABAABAB',
						arr: [4, 4, 6, 8],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: '5 - BABAABABAABA',
						arr: [8, 5, 4, 4],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: '6 - ABABAABABAAB',
						arr: [4, 6, 8, 5],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: '7 - BABABAABABAA',
						arr: [8, 4, 4, 6],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: '8 - ABABABAABABA',
						arr: [4, 8, 5, 4],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: '9 - AABABABAABAB',
						arr: [5, 4, 6, 8],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: '10 - BAABABABAABA',
						arr: [6, 8, 4, 4],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: '11 - ABAABABABAAB',
						arr: [4, 4, 8, 5],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: '12 - BABAABABABAA',
						arr: [8, 5, 4, 6],
						grouping: 12,
						variant: 'info',
						final: true,
					},
					{
						preset: 'Shuffle All',
						final: true,
						shuffleAll: {
							on: true,
							options: [
								[4, 6, 8, 4],
								[5, 4, 4, 8],
								[6, 8, 5, 4],
								[4, 4, 6, 8],
								[8, 5, 4, 4],
								[4, 6, 8, 5],
								[8, 4, 4, 6],
								[4, 8, 5, 4],
								[5, 4, 6, 8],
								[6, 8, 4, 4],
								[4, 4, 8, 5],
								[8, 5, 4, 6],
							],
						},
						bars: 4,
						grouping: 12,
						variant: 'info',
					},
				],
			},
			variant: 'primary',
		},
		{
			preset: 'Doubles',
			val: {
				presets: [
					{
						preset: '1 - AABBAABBAABB',
						arr: [5, 6, 7, 2],
						grouping: 4,
						variant: 'info',
						final: true,
					},
					{
						preset: '2 - BBAABBAABBAA',
						arr: [7, 2, 5, 6],
						grouping: 4,
						variant: 'info',
						final: true,
					},
					{
						preset: '3 - ABBAABBAABBA',
						arr: [2, 5, 6, 7],
						grouping: 4,
						variant: 'info',
						final: true,
					},
					{
						preset: '4 - BAABBAABBAAB',
						arr: [6, 7, 2, 5],
						grouping: 4,
						variant: 'info',
						final: true,
					},
					{
						preset: 'Shuffle All',
						final: true,
						shuffleAll: {
							on: true,
							options: [
								[5, 6, 7, 2],
								[7, 2, 5, 6],
								[2, 5, 6, 7],
								[6, 7, 2, 5],
							],
						},
						bars: 4,
						grouping: 4,
						variant: 'info',
					},
				],
			},
			variant: 'primary',
		},
		{
			preset: 'Every 4',
			val: {
				presets: [
					{
						preset: '1 - ABBBABBBABBB',
						arr: [2, 8, 7, 1],
						grouping: 4,
						variant: 'info',
						final: true,
					},
					{
						preset: '2 - BABBBABBBABB',
						arr: [8, 7, 1, 2],
						grouping: 4,
						variant: 'info',
						final: true,
					},
					{
						preset: '3 - BBABBBABBBAB',
						arr: [7, 1, 2, 8],
						grouping: 4,
						variant: 'info',
						final: true,
					},
					{
						preset: '4 - BBBABBBABBBA',
						arr: [1, 2, 8, 7],
						grouping: 4,
						variant: 'info',
						final: true,
					},
					{
						preset: 'Shuffle All',
						shuffleAll: {
							on: true,
							options: [
								[2, 8, 7, 1],
								[8, 7, 1, 2],
								[7, 1, 2, 8],
								[1, 2, 8, 7],
							],
						},
						bars: 4,
						grouping: 4,
						variant: 'info',
						final: true,
					},
				],
			},
			variant: 'primary',
		},
		{
			preset: 'Groups of 5',
			val: {
				presets: [
					{
						preset: 'ABABB',
						arr: [4, 7, 8, 8, 2, 4, 7, 8, 8, 2, 4, 7, 8, 8, 2, 4, 7, 8, 8, 2],
						grouping: 5,
						bars: 5,
						variant: 'info',
						final: true,
					},
					{
						preset: 'ABAAB',
						arr: [4, 4, 6, 8, 5, 4, 4, 6, 8, 5, 4, 4, 6, 8, 5, 4, 4, 6, 8, 5],
						grouping: 5,
						bars: 5,
						variant: 'info',
						final: true,
					},
					{
						preset: 'Shuffle All',
						shuffleAll: {
							on: true,
							options: [
								[4, 7, 8, 8, 2, 4, 7, 8, 8, 2, 4, 7, 8, 8, 2, 4, 7, 8, 8, 2],
								[4, 4, 6, 8, 5, 4, 4, 6, 8, 5, 4, 4, 6, 8, 5, 4, 4, 6, 8, 5],
							],
						},
						bars: 4,
						grouping: 5,
						variant: 'info',
						final: true,
					},
				],
			},
			variant: 'primary',
		},
	],
}

export { StraightPresets, TripletPresets }
