import React from 'react'
import { connect } from 'react-redux'

import get from 'lodash/get'
import map from 'lodash/get'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { getHelperModalContent, getThemeName } from '../../redux/selectors/rendering'

const HelperModal = ({ content, toggle, show, device, themeName }) => {
	const className = `${device} ${themeName} helper`

	let footerContent = null
	if (get(content, `footer`)) {
		footerContent = (
			<Modal.Footer className={className}>
				<div className="left"></div>
				<div className="right">
					<Button
						size={`small`}
						className={'action-3'}
						onClick={() => {
							get(content, `footer.action`)()
							toggle()
						}}
					>
						{get(content, `footer.title`, `OK`)}
					</Button>
				</div>
			</Modal.Footer>
		)
	}

	const renderBody = () => {
		const description = get(content, `text`, `Text`)
		return map(description, (d, i) => {
			let br = <br></br>
			if (i === get(description, `length`) - 1) {
				br = null
			}
			return (
				<>
					<div key={i} className="text size-14">
						{d}
					</div>
					{br}
				</>
			)
		})
	}

	return (
		<>
			<Modal animation={false} show={show} onClose={toggle} onHide={toggle} keyboard={false} dialogClassName={className}>
				<Modal.Header closeButton>
					<div className="header">
						<Modal.Title>
							<div className="text size-18">{get(content, `title`, `Title`)}</div>
						</Modal.Title>
					</div>
				</Modal.Header>
				<Modal.Body className={className}>{renderBody()}</Modal.Body>
				{footerContent}
			</Modal>
		</>
	)
}

const mapStateToProps = (state) => {
	const content = getHelperModalContent(state)
	const themeName = getThemeName(state)
	return { content, themeName }
}

export default connect(mapStateToProps)(HelperModal)
