import get from 'lodash/get'
import chunk from 'lodash/chunk'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import split from 'lodash/split'
import take from 'lodash/take'

import { useDispatch, useSelector } from 'react-redux'

import { setPlayThis } from '../redux/actions'
import SixEightSounds from '../utils/constants/sounds/6-8-sounds'
import RenderingModes from '../utils/constants/enum/renderingMode'
import { straightDownUpsImageToSoundKey } from '../utils/downups/straight'
import { tripletDownUpsImageToSoundKey } from '../utils/downups/triplets'
import { sdSounds } from '../utils/s-d/triplets'
import { firstGrooveSounds } from '../utils/first-grooves'
import Times from '../utils/constants/enum/time'
import TripSounds from '../utils/constants/sounds/trip-sounds'
import EighthSounds from '../utils/constants/sounds/8th-sounds'
import SixteenthSounds from '../utils/constants/sounds/16th-sounds'
import { getBars, getTimeSignature } from '../redux/selectors/playback'

const usePopulatePlay = () => {
	const dispatch = useDispatch()
	const timeSignature = useSelector(getTimeSignature)
	const bars = useSelector(getBars)

	const direct = ({ onesAndZeros }) => setPlayThis(onesAndZeros)

	/**
	 * Set playThis
	 * an array of 1's & 0's - 1 = sound, 0 = silence
	 * @param {array} arr array of image IDs being rendered
	 * @param time the current time (subdivision)
	 */
	const populatePlay = ({ arr, time, sixEightOverride = false, renderingMode = RenderingModes.NORMAL, setPlay = true }) => {
		if (isEmpty(arr)) {
			setPlay && dispatch(setPlayThis([]))
			return null
		}

		let tempArr = []
		switch (true) {
			case sixEightOverride:
				forEach(arr, (entry) => {
					tempArr.push(...get(SixEightSounds, `sounds.${entry - 1}.arr`))
				})
				break
			case renderingMode === RenderingModes.DOWNUP.STRAIGHT:
				forEach(arr, (entry) => {
					tempArr.push(get(straightDownUpsImageToSoundKey, `${entry}`))
				})
				break
			case renderingMode === RenderingModes.DOWNUP.TRIPLETS:
				const quarterNotes = chunk(arr, 2)
				let key = ``
				forEach(quarterNotes, (entry) => {
					forEach(entry, (eighthNote) => {
						key += eighthNote
					})
					tempArr.push(...get(tripletDownUpsImageToSoundKey, `${key}`))
					key = ``
				})
				break
			case renderingMode === RenderingModes.SD.TRIPLETS.ONE:
			case renderingMode === RenderingModes.SD.TRIPLETS.TWO:
			case renderingMode === RenderingModes.SD.TRIPLETS.THREE:
			case renderingMode === RenderingModes.SD.TRIPLETS.FOUR:
			case renderingMode === RenderingModes.SD.TRIPLETS.FIVE:
				forEach(arr, (entry) => {
					const keys = split(entry, '/', 2)
					tempArr.push(...get(sdSounds, `${keys[0]}.${keys[1]}`))
				})
				break
			case renderingMode === RenderingModes.FIRSTGROOVES.ALL:
			case renderingMode === RenderingModes.FIRSTGROOVES.ONE:
			case renderingMode === RenderingModes.FIRSTGROOVES.TWO:
			case renderingMode === RenderingModes.FIRSTGROOVES.THREE:
				forEach(arr, (entry) => {
					tempArr.push(...get(firstGrooveSounds, `${entry}`))
				})
				break
			default:
				switch (parseInt(time)) {
					case Times.TRIPLETS:
						forEach(arr, (entry) => {
							tempArr.push(...get(TripSounds, `sounds.${entry - 1}.arr`))
						})
						break
					case Times.EIGHTH:
						forEach(arr, (entry) => {
							const soundArray = get(EighthSounds, `sounds.${entry - 1}.arr`) || get(SixteenthSounds, `sounds.${entry - 1}.arr`)
							tempArr.push(...soundArray)
						})
						break
					case Times.MIXED:
						forEach(arr, (entry) => {
							tempArr.push(...[4]) // The start of every crotchet beat is denoted by a 4
							if (entry < 17) {
								tempArr.push(...get(SixteenthSounds, `sounds.${entry - 1}.arr`))
							} else {
								tempArr.push(...get(TripSounds, `sounds.${entry - 15}.arrMix`))
							}
						})
						break
					default:
						forEach(arr, (entry) => {
							tempArr.push(...get(SixteenthSounds, `sounds.${entry - 1}.arr`))
						})
						break
				}
				break
		}

		const subdivisionRhythmDuration = get(timeSignature, 'top') * (get(time, 'highestNoteRate') / get(timeSignature, 'bottom')) * bars
		if (get(tempArr, 'length') > subdivisionRhythmDuration) {
			tempArr = take(tempArr, subdivisionRhythmDuration)
		}
		setPlay && dispatch(setPlayThis(tempArr))
		return tempArr
	}

	return { direct, populatePlay }
}

export default usePopulatePlay
