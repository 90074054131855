import React from 'react'
import { connect } from 'react-redux'

import { IonItem, IonLabel } from '@ionic/react'

import useBpmPicker from '../../Hooks/useBpmPicker'
import { getBpm, getIsPlaying } from '../../../../../../../redux/selectors/playback'

const Bpm = ({ playing, bpm }) => {
	const openPicker = useBpmPicker()
	const getLabel = () => `${bpm} BPM`

	return (
		<IonItem detail button disabled={playing} onClick={openPicker}>
			<IonLabel>{getLabel()}</IonLabel>
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const playing = getIsPlaying(state)
	const bpm = getBpm(state)

	return {
		bpm,
		playing,
	}
}

export default connect(mapStateToProps, {})(Bpm)
