import React from 'react'
import { connect } from 'react-redux'

import { IonToggle, IonLabel, IonItem } from '@ionic/react'

import { getIsApp } from '../../../../../redux/selectors/rendering'
import { setPractice } from '../../../../../redux/actions'
import { beta, localhost } from '../../../../../utils/functions'
import { getIsPracticeModeOn } from '../../../../../redux/selectors/playback'

const Practice = ({ isPracticeModeOn, isApp, setPractice }) => {
	const toggle = () => {
		setPractice(!isPracticeModeOn)
	}

	if (!localhost() && (!beta() || !isApp)) {
		return null
	}
	return (
		<IonItem>
			<IonLabel>{`Practice Mode`}</IonLabel>
			<IonToggle checked={isPracticeModeOn} onClick={toggle} />
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const isApp = getIsApp(state)
	const isPracticeModeOn = getIsPracticeModeOn(state)

	return {
		isApp,
		isPracticeModeOn,
	}
}

export default connect(mapStateToProps, { setPractice })(Practice)
