import { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'

import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'

import { get as idbGet } from 'idb-keyval'

import { getAudioContext, getAudioState } from '../redux/selectors/audio'
import { getModalHHArray, getPlatform, getShowSwing, getTheme, getTimeName } from '../redux/selectors/rendering'
import {
	getBars,
	getBpm,
	getClickVolume,
	getGrooveSettings,
	getIsPracticeModeOn,
	getMode,
	getPlaybackAs,
	getTimeOptions,
	getTradeDirection,
	getUsingClick,
	getUsingCountIn,
	getUsingGhosts,
} from '../redux/selectors/playback'
import {
	setBars,
	setBpm,
	setClickVolume,
	setMode,
	setPlaybackAs,
	setPractice,
	setTheme,
	setTime,
	setTimeName,
	setTradeDirection,
	setUseCountIn,
	setUseGhosts,
	setUseMetronome,
	setGrooveCymbalPattern,
	setGrooveCymbalSound,
	setGrooveBackbeat,
	setGrooveGhosts,
	setModalHHArray,
	setShowSwing,
	setLoadable,
	setAudioContext,
} from '../redux/actions'

import TimeEnum from '../utils/constants/enum/time'
import { ThemeEnum } from '../utils/constants/enum/theme'
import ModeEnum from '../utils/constants/enum/mode'
import { Cymbal, Sounds } from '../utils/constants/enum/sounds'
import { BackbeatPatterns, CymbalPatterns } from '../utils/constants/enum/groove'
import GrooveOptions from '../utils/constants/groove-elements'
import unmute from '../utils/unmute'
import { loadSound } from '../utils/audioLoader'
import { PlatformEnum } from '../utils/constants/enum/platform'

import useBowser from '../hooks/useBowser'

/**
 * Provides common useEffects used across web and Ionic
 */
const EffectsProvider = ({
	mode,
	bpm,
	audioContext,
	audio,
	platform,
	theme,
	bars,
	timeOptions,
	timeName,
	playbackAs,
	practiceOn,
	click,
	usingGhosts,
	tradeDirection,
	groove,
	modalHHArray,
	showSwing,
	// Setters
	setTheme,
	setBars,
	setTime,
	setTimeName,
	setMode,
	setBpm,
	setPlaybackAs,
	setUseMetronome,
	setUseGhosts,
	setUseCountIn,
	setTradeDirection,
	setPractice,
	setClickVolume,
	setGrooveCymbalPattern,
	setGrooveCymbalSound,
	setGrooveBackbeat,
	setGrooveGhosts,
	setModalHHArray,
	setShowSwing,
	setLoadable,
	setAudioContext,
}) => {
	const { isWebkit, isIOS, isMobile } = useBowser()

	/**
	 * Listen for audiocontext failure, re-initialise if nullified
	 */
	const audioContextCheck = useCallback(() => {
		if (!audioContext || audioContext === null) {
			return false
		}
		return true
	}, [audioContext])

	useEffect(() => {
		if (audioContextCheck()) {
			return
		}
		setAudioContext(new audio.audioConstructor())
	}, [audioContext, audio.audioConstructor, audioContextCheck, setAudioContext])

	/**
	 * Load sounds from file or IDB
	 */
	useEffect(() => {
		idbGet('snareAudio').then((value) => {
			loadSound(0, value, 'sounds/accent.mp3')
		})
		idbGet('kickAudio').then((value) => {
			loadSound(1, value, 'sounds/kick.wav')
		})
		idbGet('hihatAudio').then((value) => {
			loadSound(2, value, 'sounds/hihat.wav')
		})
		idbGet('rideAudio').then((value) => {
			loadSound(3, value, 'sounds/ride.wav')
		})
		idbGet('metronomeAudio').then((value) => {
			loadSound(4, value, 'sounds/metronome.wav')
		})
		idbGet('metronomeOneAudio').then((value) => {
			loadSound(5, value, 'sounds/metronome-1.wav')
		})
		idbGet('markOneAudio').then((value) => {
			loadSound(6, value, 'sounds/mark-one.wav')
		})
		if (platform !== PlatformEnum.IOS && isMobile && isIOS && isWebkit) {
			// Enable audio playback when ios device is on silent, on web
			unmute({ context: audioContext })
		}
	}, [])

	/**
	 * Retaining and reacting to saved application settings
	 */
	useEffect(() => {
		//Core settings
		const savedTheme = localStorage.getItem('theme') || ThemeEnum.LIGHT
		if (isNaN(savedTheme)) {
			// Make backward compatible with themes saved as strings
			if (includes(savedTheme, 'l')) {
				setTheme(ThemeEnum.LIGHT)
			} else {
				setTheme(ThemeEnum.DARK)
			}
		} else {
			setTheme(Number(savedTheme))
		}

		const savedMode = Number(localStorage.getItem('mode') || ModeEnum.ONCE)
		setMode(savedMode)

		const savedBars = Number(localStorage.getItem('bars') || 1)
		if ([ModeEnum.LOOPREADING, ModeEnum.TRADEREADING, ModeEnum.CLICKREADING].includes(savedMode)) {
			setBars(Math.max(savedBars, 2))
		} else {
			setBars(savedBars > 4 ? 4 : savedBars)
		}

		let savedTime = Number(localStorage.getItem('time') || TimeEnum.STRAIGHT)
		setTime(savedTime)

		const savedTimeName = localStorage.getItem('timeName') || '16th Note'
		if (!savedTimeName.includes('a')) {
			setTimeName(savedTimeName)
		}

		const savedPlaybackAs = Number(localStorage.getItem('playbackAs') || Sounds.SNARE)
		setPlaybackAs(savedPlaybackAs)

		//Playback options
		const savedBpm = Number(localStorage.getItem('bpm') || 85)
		setBpm(savedBpm)

		const savedUseMetronome = localStorage.getItem('useMetronome') || true
		try {
			setUseMetronome(JSON.parse(savedUseMetronome))
		} catch {
			setUseMetronome(true)
		}

		const savedUseCountIn = localStorage.getItem('useCountIn') || true
		try {
			setUseCountIn(JSON.parse(savedUseCountIn))
		} catch {
			setUseCountIn(true)
		}

		const savedUseGhosts = localStorage.getItem('useGhosts') || true
		try {
			setUseGhosts(JSON.parse(savedUseGhosts))
		} catch {
			setUseGhosts(true)
		}

		const savedTradeDirection = localStorage.getItem('tradeDirection') || false
		try {
			setTradeDirection(JSON.parse(savedTradeDirection))
		} catch {
			setTradeDirection(false)
		}

		const savedPractice = localStorage.getItem('practice') || false
		try {
			setPractice(JSON.parse(savedPractice))
		} catch {
			setPractice(false)
		}

		let savedClickVolume = Number(localStorage.getItem('clickVolume')) || 0.8
		if (savedClickVolume === 0) {
			// Don't save a muted click, that could be confusing
			savedClickVolume = 0.8
		}
		setClickVolume({ volume: savedClickVolume })

		//Groove elements
		const savedGrooveCymbal = JSON.parse(localStorage.getItem('grooveCymbal')) || {
			pattern: CymbalPatterns.EIGHTH,
			sound: Cymbal.HH,
		}
		try {
			setGrooveCymbalPattern(get(savedGrooveCymbal, `pattern`))
			setGrooveCymbalSound(get(savedGrooveCymbal, `sound`))
		} catch {
			setGrooveCymbalPattern(2)
			setGrooveCymbalSound(Cymbal.HH)
		}

		let savedGrooveBackbeat = Number(localStorage.getItem('grooveBackbeat') || BackbeatPatterns.TWO_AND_FOUR)
		if (savedGrooveBackbeat === BackbeatPatterns.TIMESIG_DEFAULT) {
			savedGrooveBackbeat = BackbeatPatterns.TWO_AND_FOUR
		}
		setGrooveBackbeat(savedGrooveBackbeat)

		const savedGrooveGhosts = Number(localStorage.getItem('grooveGhosts') || 0)
		setGrooveGhosts(savedGrooveGhosts)

		const savedModalHHArray = localStorage.getItem('modalHHArray') || GrooveOptions.options[0].hh
		if (JSON.parse('[' + savedModalHHArray + ']').length) {
			setModalHHArray(JSON.parse('[' + savedModalHHArray + ']'))
		}

		const savedShowGrooveModalSwing = localStorage.getItem('showSwing') || true
		try {
			setShowSwing(JSON.parse(savedShowGrooveModalSwing))
		} catch {
			setShowSwing(true)
		}

		// Handle any saved rhythms
		idbGet('savedRhythms').then((savedRhythms) => {
			if (!isEmpty(savedRhythms)) {
				setLoadable(true)
			}
		})
	}, [
		setBars,
		setBpm,
		setGrooveBackbeat,
		setGrooveGhosts,
		setGrooveCymbalPattern,
		setLoadable,
		setModalHHArray,
		setMode,
		setPlaybackAs,
		setShowSwing,
		setTheme,
		setTime,
		setTimeName,
		setUseCountIn,
		setUseGhosts,
		setUseMetronome,
		setGrooveCymbalSound,
		setTradeDirection,
	])

	/**
	 * Save settings to localstorage
	 */
	useEffect(() => {
		localStorage.setItem('theme', theme)
	}, [theme])
	useEffect(() => {
		localStorage.setItem('bars', bars)
	}, [bars])
	useEffect(() => {
		localStorage.setItem('time', timeOptions)
		switch (timeOptions) {
			case TimeEnum.STRAIGHT:
				setTimeName('16th Note')
				break
			case TimeEnum.EIGHTH:
				setTimeName('8th Note')
				break
			case TimeEnum.TRIPLETS:
				setTimeName('Triplet')
				break
			default:
				setTimeName('Mixed')
				break
		}
	}, [timeOptions])
	useEffect(() => {
		localStorage.setItem('timeName', timeName)
	}, [timeName])
	useEffect(() => {
		localStorage.setItem('mode', mode)
	}, [mode])
	useEffect(() => {
		localStorage.setItem('playbackAs', playbackAs)
	}, [playbackAs])
	useEffect(() => {
		localStorage.setItem('bpm', bpm)
	}, [bpm])
	useEffect(() => {
		localStorage.setItem('useMetronome', click.on)
	}, [click.on])
	useEffect(() => {
		localStorage.setItem('useCountIn', click.countIn)
	}, [click.countIn])
	useEffect(() => {
		localStorage.setItem('clickVolume', click.volume)
	}, [click.volume])
	useEffect(() => {
		localStorage.setItem('useGhosts', usingGhosts)
	}, [usingGhosts])
	useEffect(() => {
		localStorage.setItem('practice', practiceOn)
	}, [practiceOn])
	useEffect(() => {
		localStorage.setItem('tradeDirection', tradeDirection)
	}, [tradeDirection])
	useEffect(() => {
		localStorage.setItem('grooveBackbeat', groove.backbeat)
	}, [groove.backbeat])
	useEffect(() => {
		localStorage.setItem('grooveCymbal', JSON.stringify(groove.cymbal))
	}, [groove.cymbal])
	useEffect(() => {
		localStorage.setItem('grooveGhosts', groove.ghosts)
	}, [groove.ghosts])
	useEffect(() => {
		localStorage.setItem('modalHHArray', modalHHArray)
	}, [modalHHArray])
	useEffect(() => {
		localStorage.setItem('showSwing', showSwing)
	}, [showSwing])

	return null
}

const mapStateToProps = (state) => {
	const audioContext = getAudioContext(state)
	const platform = getPlatform(state)
	const theme = getTheme(state)
	const bars = getBars(state)
	const timeOptions = getTimeOptions(state)
	const timeName = getTimeName(state)
	const mode = getMode(state)
	const playbackAs = getPlaybackAs(state)
	const bpm = getBpm(state)
	const click = {
		on: getUsingClick(state),
		contIn: getUsingCountIn(state),
		volume: getClickVolume(state),
	}
	const groove = getGrooveSettings(state)
	const usingGhosts = getUsingGhosts(state)
	const practiceOn = getIsPracticeModeOn(state)
	const tradeDirection = getTradeDirection(state)
	const modalHHArray = getModalHHArray(state)
	const showSwing = getShowSwing(state)
	const audio = getAudioState(state)

	return {
		audioContext,
		audio,
		platform,
		theme,
		bars,
		timeOptions,
		timeName,
		mode,
		playbackAs,
		bpm,
		click,
		groove,
		usingGhosts,
		practiceOn,
		tradeDirection,
		modalHHArray,
		showSwing,
	}
}

export default connect(mapStateToProps, {
	setTradeDirection,
	setUseGhosts,
	setUseCountIn,
	setTimeName,
	setTheme,
	setBars,
	setTime,
	setMode,
	setPlaybackAs,
	setBpm,
	setUseMetronome,
	setPractice,
	setClickVolume,
	setGrooveCymbalPattern,
	setGrooveCymbalSound,
	setGrooveBackbeat,
	setGrooveGhosts,
	setModalHHArray,
	setShowSwing,
	setLoadable,
	setAudioContext,
})(EffectsProvider)
