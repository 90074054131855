import React, { useState, useEffect } from 'react'
import { isEmpty, get } from 'lodash'
import { connect } from 'react-redux'

import { deviceDetect } from 'react-device-detect'

import { IonGrid, IonRow, IonCol, IonButton, IonNote, IonItem, IonLabel, IonInput, IonTextarea, useIonViewDidEnter } from '@ionic/react'
import { Network } from '@capacitor/network'
import { Device } from '@capacitor/device'
import { Capacitor } from '@capacitor/core'

import { getIsApp, getPlatform, getError } from '../../../../redux/selectors/rendering'
import { localhost, prod } from '../../../../utils/functions'
import { getPlatformName } from '../../../../utils/constants/enum/platform'
import { emailValidator } from '../../../../utils/validation/email'
import { authToken } from '../../../../utils/api/auth'

const Form = ({ isApp, platform, errorText }) => {
	const fieldErrorClass = `ion-invalid`

	const [name, setName] = useState(``)
	const [email, setEmail] = useState(``)
	const [message, setMessage] = useState(``)
	const [nameError, setNameError] = useState(false)
	const [emailError, setEmailError] = useState(false)
	const [messageError, setMessageError] = useState(false)
	const [sendButton, setSendButton] = useState({})

	useIonViewDidEnter(async () => {
		await networkChecks()
	})

	useEffect(() => {
		if (!isEmpty(errorText)) {
			setMessage(`Error: ${errorText}`)
			return
		}
		if (prod({ isApp }) || !localhost()) {
			return
		}
		setName(`Rhythm Bot`)
		setEmail(`rhythmbot@test.com`)
		setMessage(`${getPlatformName(platform)} test`)
	}, [isApp, platform, errorText])

	const networkChecks = async () => {
		if (!(await online())) {
			return setSendButton({
				status: 'No Internet',
				disabled: true,
			})
		}
		if (!(await enabled())) {
			return setSendButton({
				status: 'Disabled',
				disabled: true,
			})
		}
		return setSendButton({
			status: 'Send',
		})
	}
	Network.addListener('networkStatusChange', networkChecks)
	const onLine = get(window, `navigator.onLine`)
	useEffect(() => networkChecks(), [onLine])

	const online = async () => get(window, `navigator.onLine`, false) || get(await Network.getStatus(), `connected`, false)
	const enabled = async () => (await online()) && process.env.REACT_APP_FEEDBACK_API.includes('https')

	const clearForm = () => {
		setName(``)
		setEmail(``)
		setMessage(``)
		setNameError(false)
		setEmailError(false)
		setMessageError(false)
	}

	const submit = async (e) => {
		if (get(sendButton, 'disabled', false)) {
			return
		}
		e.preventDefault()
		if (!(await online())) {
			return setSendButton({
				status: 'No Internet',
				disabled: true,
			})
		}
		if (!(await enabled())) {
			return setSendButton({
				status: 'Disabled',
				disabled: true,
			})
		}
		const emailError = !emailValidator.test(email)
		const nameError = !get(name, 'length') > 0
		const messageError = !get(message, 'length') > 0
		const noError = !emailError && !nameError && !messageError
		if (noError) {
			setSendButton({
				status: '...',
				disabled: true,
			})

			let device = JSON.stringify(deviceDetect())
			if (Capacitor.isNativePlatform()) {
				device = JSON.stringify(await Device.getInfo())
			}

			const body = JSON.stringify({
				name,
				email,
				message,
				//Add device, OS and browser info
				device: `\n\tPlatform: ${getPlatformName(platform)}\n\t${device}\n`,
				error: !isEmpty(errorText) ? errorText : 'No error thrown',
				isProduction: prod({ isApp: true }),
			})
			let headers = new Headers()
			headers.append('Content-Type', 'application/json')
			headers.append('Authorization', `${authToken}`)

			let requestOptions = {
				method: 'POST',
				headers,
				body,
				redirect: 'follow',
			}

			fetch(process.env.REACT_APP_FEEDBACK_API, requestOptions)
				.then((response) => response.text())
				.then((result) => {
					console.log(result)
					setSendButton({
						status: 'Thank you!',
						disabled: true,
					})
				})
				.catch((error) => {
					console.error('Error Sending Feedback', error)
				})
		}
		setNameError(nameError)
		setEmailError(emailError)
		setMessageError(messageError)
	}

	return (
		<form id="feedback" className="ion-feedback-form" method="post">
			<IonItem className={nameError ? fieldErrorClass : ``} error={true}>
				<IonLabel position="floating">{`Name`}</IonLabel>
				<IonInput type="text" value={name} onIonChange={(e) => setName(e.detail.value)} />
				<IonNote slot="error">{`Please enter your name`}</IonNote>
			</IonItem>
			<IonItem className={emailError ? fieldErrorClass : ``}>
				<IonLabel position="floating">{`Email`}</IonLabel>
				<IonInput type="email" value={email} onIonChange={(e) => setEmail(e.detail.value)} />
				<IonNote slot="error">{`Please enter your email address`}</IonNote>
			</IonItem>
			<IonItem className={messageError ? fieldErrorClass : ``}>
				<IonLabel position="floating">{`Message`}</IonLabel>
				<IonTextarea type="text" value={message} onIonChange={(e) => setMessage(e.detail.value)} rows={10} autoGrow={true} />
				<IonNote slot="error">{`Please enter a message`}</IonNote>
			</IonItem>

			<IonGrid>
				<IonRow>
					<IonCol className="text-right ion-align-items-center ion-justify-content-center">
						<IonButton autoCapitalize={false} color={`darkbutton`} onClick={clearForm}>
							{`Clear`}
						</IonButton>
						<IonButton
							autoCapitalize={false}
							color={`button`}
							onClick={(e) => {
								submit(e)
							}}
						>
							{get(sendButton, 'status', '')}
						</IonButton>
					</IonCol>
				</IonRow>
			</IonGrid>
		</form>
	)
}

const mapStateToProps = (state) => {
	const isApp = getIsApp(state)
	const platform = getPlatform(state)
	const errorText = getError(state)
	return {
		isApp,
		platform,
		errorText,
	}
}

export default connect(mapStateToProps, {})(Form)
