import React from 'react'
import { isEmpty, get, chunk, forEach } from 'lodash'
import { connect } from 'react-redux'

import { IonIcon, IonButton } from '@ionic/react'
import { shuffleOutline } from 'ionicons/icons'
import { getBars, getIsIsolated, getIsolatedArray, getIsPlaying, getIsRandom, getSelectedBars } from '../../../redux/selectors/playback'
import { getIsRenderedArrayEmpty, getIsSelected, getRenderedArray } from '../../../redux/selectors/rendering'
import { arrayEquals } from '../../../utils/functions'

const Shuffle = ({ shuffle, isolatedArray, playing, bars, renderedArray, isRenderedArrayEmpty, isolated, selected, selectedBars, random }) => {
	const disabled = () => {
		if (playing) return true
		if (!random) return true
		if (isRenderedArrayEmpty) return true
		if (bars <= 1) return true
		const shuffleAble = !selected || (isolated && !isEmpty(selectedBars))
		if (!shuffleAble) return true

		let b = bars
		let rArray = renderedArray
		if (isolated) {
			b = get(isolatedArray, [0, `length`])
			rArray = get(isolatedArray, [1])
		}
		const notesInBars = chunk(rArray, get(rArray, 'length') / b)
		let previousNotes = notesInBars[0]
		let forceDisabled = true
		// Run a check to see if all the bars are the same, disable
		// shuffle if so
		forEach(notesInBars, (a) => {
			if (!arrayEquals(a, previousNotes)) {
				forceDisabled = false
				return
			}
			previousNotes = a
		})

		return forceDisabled
	}

	return (
		<span className="ion-shuffle-button">
			<IonButton onClick={shuffle} disabled={disabled()} color={`dark`} shape="circle">
				<IonIcon color="canvas" icon={shuffleOutline} />
			</IonButton>
		</span>
	)
}

const mapStateToProps = (state) => {
	return {
		playing: getIsPlaying(state),
		bars: getBars(state),
		renderedArray: getRenderedArray(state),
		isRenderedArrayEmpty: getIsRenderedArrayEmpty(state),
		isolated: getIsIsolated(state),
		isolatedArray: getIsolatedArray(state),
		selected: getIsSelected(state),
		selectedBars: getSelectedBars(state),
		random: getIsRandom(state),
	}
}

export default connect(mapStateToProps, {})(Shuffle)
