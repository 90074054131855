import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import slice from 'lodash/slice'
import concat from 'lodash/concat'

import { useIonPicker } from '@ionic/react'
import { getBars, getIsPlaying, getIsRandom, getIsSixTwelveEight, getTimeToUse } from '../../../../../../redux/selectors/playback'
import { setBars, setRandom } from '../../../../../../redux/actions'
import { getRenderedArray, getRenderingMode } from '../../../../../../redux/selectors/rendering'
import usePopulatePlay from '../../../../../../hooks/usePopulatePlay'
import useRenderer from '../../../../../../hooks/useRenderer'

const useBarPicker = () => {
	const dispatch = useDispatch()
	const [present] = useIonPicker()
	const { populatePlay } = usePopulatePlay()
	const { render } = useRenderer()

	const renderingMode = useSelector(getRenderingMode)
	const bars = useSelector(getBars)
	const isSixTwelveEight = useSelector(getIsSixTwelveEight)
	const random = useSelector(getIsRandom)
	const playing = useSelector(getIsPlaying)
	const timeToUse = useSelector(getTimeToUse)
	const renderedArray = useSelector(getRenderedArray)

	/**
	 * Update the number of bars and handle changes to rendering and playback required
	 * @param value boolean or number - If boolean `true` means increment
	 * or decrement, if a number bars are set directly to this value
	 */
	const updateBars = (value) => {
		const setter = (bars) => {
			dispatch(setBars(bars))
			if (!random || bars === 1) {
				return false
			}
			return true
		}
		const sixEightOverride = isSixTwelveEight
		const time = get(timeToUse, 'options')
		const beatsPerBar = get(renderedArray, 'length') / bars

		if (playing) {
			return
		}
		if (isEmpty(renderedArray)) {
			return
		}
		const barsDifference = bars - value
		if (barsDifference === 0) {
			return
		}
		let newArr
		if (barsDifference < 0) {
			// Adding some bars
			const lastBarIndex = get(renderedArray, 'length') - beatsPerBar
			const addBar = slice(renderedArray, lastBarIndex)
			newArr = renderedArray
			for (let i = 0; i < Math.abs(barsDifference); i++) {
				newArr = concat(newArr, addBar)
			}
			populatePlay({ arr: newArr, time, sixEightOverride, renderingMode })
			render({ images: newArr, sixEightOverride, renderingMode })
			dispatch(setRandom(setter(value)))
			return
		}
		// Taking bars away
		if (get(renderedArray, 'length') <= beatsPerBar) {
			return
		}
		const cutOffIndex = get(renderedArray, 'length') - beatsPerBar * Math.abs(barsDifference)
		newArr = slice(renderedArray, 0, cutOffIndex)
		populatePlay({ arr: newArr, time, sixEightOverride, renderingMode })
		render({ images: newArr, sixEightOverride, renderingMode })
		dispatch(setRandom(setter(value)))
	}

	const openPicker = async () => {
		let options = []
		if (bars <= 4) {
			options = [
				{
					text: '1',
					value: 1,
				},
				{
					text: '2',
					value: 2,
				},
				{
					text: '3',
					value: 3,
				},
				{
					text: '4',
					value: 4,
				},
			]
		} else {
			for (let i = 0; i < bars; i++) {
				options.push({
					text: `${i + 1}`,
					value: i + 1,
				})
			}
		}
		present({
			columns: [
				{
					name: 'bars',
					selectedIndex: bars - 1,
					options,
				},
			],
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'Confirm',
					handler: (value) => {
						updateBars(get(value, [`bars`, `value`]))
					},
				},
			],
		})
	}

	return openPicker
}
export default useBarPicker
