import get from 'lodash/get'
import { initialText } from '../../redux/reducers/initStates'
import { LanguageEnum } from '../constants/enum/language'

const triggerTranslation = ({ language = LanguageEnum.ENGLISH }) => {
	switch (language) {
		case LanguageEnum.GERMAN:
			return 'Erstellt von Jamie Howard und JP Bouvet'
		default:
			return get(initialText, `credits`)
	}
}

export { triggerTranslation }
