import { useLayoutEffect, useState } from 'react'

/**
 * @returns {Array} device info
 */
export default function useWindowResize() {
	const [size, setSize] = useState([0, 0])

	useLayoutEffect(() => {
		function updateSize() {
			//Setting reliable viewport height and width sass variables
			document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
			document.documentElement.style.setProperty('--vw', `${window.innerWidth * 0.01}px`)
			setSize([window.innerWidth, window.innerHeight])
		}
		window.addEventListener('resize', updateSize)
		updateSize()
		return () => window.removeEventListener('resize', updateSize)
	}, [])

	if (size[0] < 760 && size[1] > size[0] * 1.13) {
		// Mobile in screen size and portrait aspect ratio
		return ['mobile']
	}
	return ['desktop']
}
