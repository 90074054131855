import React from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'

import { IonItem, IonLabel } from '@ionic/react'

import { getIsPlaying, getTime } from '../../../../../../../redux/selectors/playback'
import { ionSetPresetsOpen } from '../../../../../../../redux/ionicActions'
import TimeEnum from '../../../../../../../utils/constants/enum/time'
import { getRenderingMode } from '../../../../../../../redux/selectors/rendering'
import RenderingMode from '../../../../../../../utils/constants/enum/renderingMode'

const Presets = ({ playing, ionSetPresetsOpen, time, renderingMode }) => {
	const getLabel = () => `Presets`

	const disabled = () => {
		if (playing) return true
		const tOpts = get(time, `options`)
		if (tOpts !== TimeEnum.STRAIGHT && tOpts !== TimeEnum.TRIPLETS) {
			return true
		}
		switch (renderingMode) {
			case RenderingMode.DOWNUP.STRAIGHT:
			case RenderingMode.DOWNUP.TRIPLETS:
			case RenderingMode.SD.TRIPLETS.ONE:
			case RenderingMode.SD.TRIPLETS.TWO:
			case RenderingMode.SD.TRIPLETS.THREE:
			case RenderingMode.SD.TRIPLETS.FOUR:
			case RenderingMode.SD.TRIPLETS.FIVE:
			case RenderingMode.FIRSTGROOVES.ALL:
			case RenderingMode.FIRSTGROOVES.ONE:
			case RenderingMode.FIRSTGROOVES.TWO:
			case RenderingMode.FIRSTGROOVES.THREE:
				return true
			default:
				break
		}

		return false
	}
	return (
		<IonItem
			detail
			button
			disabled={disabled()}
			onClick={() => {
				ionSetPresetsOpen(true)
			}}
		>
			<IonLabel>{getLabel()}</IonLabel>
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const playing = getIsPlaying(state)
	const time = getTime(state)
	const renderingMode = getRenderingMode(state)
	return {
		playing,
		time,
		renderingMode,
	}
}

export default connect(mapStateToProps, { ionSetPresetsOpen })(Presets)
