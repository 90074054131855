import * as React from 'react'
import { BrowserRouter, HashRouter, Switch, Route } from 'react-router-dom'
import { get as idbGet, set as idbSet } from 'idb-keyval'
import { QueryParamProvider } from 'use-query-params'

import AppUrlListener from '../../UrlListener.tsx'
import RhythmbotPage from '../../pages/RhythmBot'
import { MobileAppHomePage } from '../../pages/MobileAppHome'

import useGlobalRefs from '../../hooks/useGlobalRefs'
import { APP_STORES_PATH } from './constants'

const Router = window.cordova ? HashRouter : BrowserRouter

const ReactTheme = React.lazy(() => import('../../styles/loaders/react'))
const ThemeSelector = ({ children }) => {
	return (
		<>
			<React.Suspense fallback={<></>}>
				<ReactTheme />
				{children}
			</React.Suspense>
		</>
	)
}

const RBRoutes = () => {
	const refs = useGlobalRefs()

	/**
	 * Keep track of page views
	 */
	React.useEffect(() => {
		idbGet('pageViews').then((value) => {
			if (typeof value === 'number') {
				idbSet('pageViews', value + 1)
				return
			}
			idbSet('pageViews', 1)
			idbSet('pageViewMultiplier', 1.0)
		})
	}, [])

	return (
		<>
			<ThemeSelector>
				<Router>
					<AppUrlListener></AppUrlListener>
					<QueryParamProvider ReactRouterRoute={Route}>
						<Switch>
							<Route exact path="/">
								<RhythmbotPage refs={refs} />
							</Route>
							<Route exact path="/language/:languageCode">
								<RhythmbotPage refs={refs} />
							</Route>
							<Route exact path={APP_STORES_PATH}>
								<MobileAppHomePage />
							</Route>
						</Switch>
					</QueryParamProvider>
				</Router>
			</ThemeSelector>
		</>
	)
}

export default RBRoutes
