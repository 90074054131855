import get from 'lodash/get'

import store from '../../redux/store'
import modes from './enum/mode'
import time from './enum/time'
import { Sounds, Cymbal, Ghosts } from './enum/sounds'
import RenderingModes from './enum/renderingMode'

const Pro = [
	{ name: 'Free', value: false },
	{ name: 'Paid', value: true },
]

const Modes = () => [
	{ name: get(store.getState(), 'rendering.text.filters.mode.once', 'Once'), value: modes.ONCE },
	{ name: get(store.getState(), 'rendering.text.filters.mode.loop', 'Loop'), value: modes.LOOP },
	{ name: get(store.getState(), 'rendering.text.filters.mode.trade.title', 'Trade'), value: modes.TRADE },
	{ name: get(store.getState(), 'rendering.text.filters.mode.click.title', 'Click'), value: modes.CLICK },
]

const ModesLoop = () => [
	{ name: get(store.getState(), 'rendering.text.filters.mode.standard', 'Standard'), value: modes.LOOP },
	{ name: get(store.getState(), 'rendering.text.filters.mode.reading', 'Reading'), value: modes.LOOPREADING },
]

const ModesTrade = () => [
	{ name: get(store.getState(), 'rendering.text.filters.mode.standard', 'Standard'), value: modes.TRADE },
	{ name: get(store.getState(), 'rendering.text.filters.mode.reading', 'Reading'), value: modes.TRADEREADING },
]

const ModesClick = () => [
	{ name: get(store.getState(), 'rendering.text.filters.mode.standard', 'Standard'), value: modes.CLICK },
	{ name: get(store.getState(), 'rendering.text.filters.mode.reading', 'Reading'), value: modes.CLICKREADING },
]

const Time = () => [
	{ name: get(store.getState(), 'rendering.text.filters.subdivision.straight', 'Straight'), value: time.STRAIGHT },
	{ name: get(store.getState(), 'rendering.text.filters.subdivision.triplets', 'Triplets'), value: time.TRIPLETS },
	{ name: get(store.getState(), 'rendering.text.filters.subdivision.mixed', 'Mixed'), value: time.MIXED },
]

const Time1 = () => [
	{ name: get(store.getState(), 'rendering.text.filters.subdivision.sixteenth', '16th'), value: time.STRAIGHT },
	{ name: get(store.getState(), 'rendering.text.filters.subdivision.eighth', '8th'), value: time.EIGHTH },
]

const TimeLockSixteenth = () => [{ name: get(store.getState(), 'rendering.text.filters.subdivision.sixteenth', '16th'), value: time.STRAIGHT }]

const TimeLockEighth = () => [{ name: get(store.getState(), 'rendering.text.filters.subdivision.eighth', '8th'), value: time.EIGHTH }]

const TimeLockTriplets = () => [{ name: get(store.getState(), 'rendering.text.filters.subdivision.triplets', 'Triplets'), value: time.TRIPLETS }]

const PlaybackAs = () => [
	{ name: get(store.getState(), 'rendering.text.filters.playback.snare', 'Snare'), value: Sounds.SNARE },
	{ name: get(store.getState(), 'rendering.text.filters.playback.kick', 'Kick'), value: Sounds.KICK },
	{ name: get(store.getState(), 'rendering.text.filters.playback.groove.title', 'Groove'), value: Sounds.GROOVE },
]

const GrooveLockPlaybackAs = [{ name: get(store.getState(), 'rendering.text.filters.playback.groove.title', 'Groove'), value: Sounds.GROOVE }]

const GrooveCymbal = [
	{ name: 'Hi-Hat', value: Cymbal.HH },
	{ name: 'Ride', value: Cymbal.RIDE },
]

const GrooveGhosts = [
	{ name: 'Fill Space', value: Ghosts.FILL },
	{ name: 'Match Kick', value: Ghosts.MATCH },
]

const Levels = [
	{ name: '1', value: 1 },
	{ name: '2', value: 2 },
	{ name: '3', value: 3 },
	{ name: '4', value: 4 },
	{ name: '5', value: 5 },
]

const FirstGrooveLevels = [
	{ name: '1', value: RenderingModes.FIRSTGROOVES.ONE },
	{ name: '2', value: RenderingModes.FIRSTGROOVES.TWO },
	{ name: '3', value: RenderingModes.FIRSTGROOVES.THREE },
	{ name: 'All', value: RenderingModes.FIRSTGROOVES.ALL },
]

const TripletSDLevels = [
	{ name: '1', value: RenderingModes.SD.TRIPLETS.ONE },
	{ name: '2', value: RenderingModes.SD.TRIPLETS.TWO },
	{ name: '3', value: RenderingModes.SD.TRIPLETS.THREE },
	{ name: '4', value: RenderingModes.SD.TRIPLETS.FOUR },
	{ name: '5', value: RenderingModes.SD.TRIPLETS.FIVE },
]

export {
	Time,
	Time1,
	TimeLockSixteenth,
	TimeLockEighth,
	TimeLockTriplets,
	Modes,
	ModesLoop,
	ModesTrade,
	ModesClick,
	Levels,
	FirstGrooveLevels,
	TripletSDLevels,
	PlaybackAs,
	GrooveLockPlaybackAs,
	GrooveGhosts,
	GrooveCymbal,
	Pro,
}
