import React from 'react'
import { connect } from 'react-redux'

import { IonList } from '@ionic/react'

import Level from './level'
import Space from './space'
import Custom from './Custom'
import CustomMenuItem from './Custom/item'

import { getIsComplexityCustomOpen } from '../../../../../../redux/selectors/ionic'

const Complexity = ({ generateRhythm, updateLevel, customOpen }) => {
	if (customOpen) return <Custom />
	return (
		<IonList>
			<Level generateRhythm={generateRhythm} updateLevel={updateLevel} />
			<Space />
			<CustomMenuItem />
		</IonList>
	)
}

const mapStateToProps = (state) => {
	const customOpen = getIsComplexityCustomOpen(state)
	return {
		customOpen,
	}
}

export default connect(mapStateToProps, {})(Complexity)
