import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { IonItem, IonRange, IonIcon } from '@ionic/react'
import { volumeHighOutline, volumeLowOutline, volumeMuteOutline } from 'ionicons/icons'

import { getClickVolume } from '../../../../redux/selectors/playback'
import { setClickVolume } from '../../../../redux/actions'

const Volume = ({ setClickVolume, volume }) => {
	const getFirstIcon = () => {
		if (volume > 0) {
			return volumeLowOutline
		}
		return volumeMuteOutline
	}
	return (
		<IonItem className="volume">
			<IonIcon onClick={() => setClickVolume({ volume: 0 })} className="start" icon={getFirstIcon()} />
			<IonRange
				value={volume * 100}
				onIonChange={(e) => {
					setClickVolume({ volume: get(e, [`detail`, `value`]) / 100 })
				}}
			/>
			<IonIcon className="end" icon={volumeHighOutline} />
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const volume = getClickVolume(state)
	return {
		volume,
	}
}

export default connect(mapStateToProps, { setClickVolume })(Volume)
