import React from 'react'
import { Provider } from 'react-redux'
import store from './redux/store'
import ReactDOM from 'react-dom'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import WebRoutes from './routes/Web'
import IonicRoutes from './routes/Ionic'
import EffectsProvider from './providers/effects'

import { setFeaturesList, setUpdatePrompt } from './redux/actions'

import { FeaturesListEnum } from './utils/constants/enum/featuresList'
import { isIonic } from './utils/functions'
import './utils/deployment'

const Routes = isIonic() ? IonicRoutes : WebRoutes

const startRhythmBot = () => {
	ReactDOM.render(
		<Provider store={store}>
			<EffectsProvider />
			<Routes />
		</Provider>,
		document.getElementById('root')
	)
	serviceWorkerRegistration.register({
		onUpdate: (registration) => {
			if (registration && registration.waiting) {
				registration.waiting.postMessage({ type: 'SKIP_WAITING' })
			}
			store.dispatch(setUpdatePrompt(true))
			store.dispatch(setFeaturesList(FeaturesListEnum.UPDATE))
			localStorage.setItem('featuresList', FeaturesListEnum.UPDATE)
		},
	})
}

if (!window.cordova) {
	startRhythmBot()
} else {
	console.warn('Cordova detected')
	document.addEventListener('deviceready', startRhythmBot, false)
}
