import get from 'lodash/get'
import isNil from 'lodash/isNil'
import forEach from 'lodash/forEach'

import {
	SET_BARS,
	SET_TIME,
	SET_PLAYBACK_TIME,
	SET_RANDOM,
	SET_PLAY_THIS,
	SET_BPM,
	SET_MODE,
	SET_PLAYING,
	SET_USE_METRONOME,
	SET_USE_COUNT_IN,
	SET_USE_GHOSTS,
	SET_PLAYBACK_AS,
	SET_GROOVE_CYMBAL_PATTERN,
	SET_GROOVE_CYMBAL_SOUND,
	SET_GROOVE_GHOSTS,
	SET_GROOVE_BACKBEAT,
	SET_SWING,
	SET_CUSTOM,
	SET_CUSTOM_ARRAY,
	SET_SELECTED_BARS,
	SET_ISOLATED,
	SET_PLAY_ENABLED,
	SET_ISOLATED_ARRAY,
	SET_LEVELS_ARRAY,
	SET_LEVEL,
	TOGGLE_TRADE_DIRECTION,
	SET_TRADE_DIRECTION,
	SET_GROOVE_MIX,
	SET_RHYTHM_LOCK,
	TOGGLE_RHYTHM_LOCK,
	SET_GROOVE_LOCK,
	TOGGLE_GROOVE_LOCK,
	SET_SPACE,
	SET_TIME_SIGNATURE,
	SET_TIME_SIGNATURE_BOTTOM,
	SET_TIME_SIGNATURE_TOP,
	SET_PRACTICE_MODE,
	SET_RHYTHM_TIMINGS,
	SET_RHYTHM_START_TIME,
	SET_USER_HITS,
	ADD_USER_HIT,
	ADD_USER_MISS,
	SET_ACCURACY,
	SET_CLICK_RATE,
	SET_CLICK_OFFSET_AMOUNT,
	SET_CLICK_OFFSET_RATE,
	SWITCH_CLICK_OFFSET_RATE,
	SET_GAP_CLICK_MATCH_RHYTHM,
	SET_CLICK_MARK_ONE,
	SET_GAP_CLICK,
	SET_CLICK_RATE_AMOUNT,
	SET_CLICK_RATE_CUSTOM,
	SET_CLICK_RATE_NOTE,
	RESET_CLICK,
	SWITCH_GAP_CLICK_OFF_FIRST,
	SET_CLICK_VOLUME,
	RESET_PRESET,
	SET_PRESET,
	SET_PRESET_SHUFFLE_ALL,
	SET_PRESET_CONSTANTS,
	SET_EXACT_CLICK_RATE,
	SET_EXACT_CLICK_OFFSET,
	SET_EXACT_CLICK_GAP,
	ADD_RHYTHM_HISTORY,
	CLEAR_RHYTHM_HISTORY,
	RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_GROUPING,
	RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_START_POINTS,
} from '../actionTypes'

import { initPlayback } from './initStates'
import { timeSignatureLimits } from '../../utils/functions'
import ClickRateEnum from '../../utils/constants/enum/click'

const practiceAccuracy = ({ hits, misses, perRhythm }) => {
	// TODO logic is not quite right
	// let adjustment = 0
	let accuracy = Math.min(Math.max(0, (hits / perRhythm) * 100), 100)
	// 7 hits 3 misses
	// 7 hits = 90%
	// Accuracy = 90 * (Hits/(hits + misses))
	// = 90 * (7/10)
	// = 63
	if (misses > 0) {
		accuracy = Math.round(accuracy * (hits / (hits + misses)))
		// adjustment = ((100 / perRhythm) * misses) / 2
	}
	return accuracy
}

// const timeSignatureTimeAdjustments = (timeSignature, time) => {
// 	return
// 	if (get(timeSignature, 'bottom') !== 8) {
// 		return { time }
// 	}
// 	const top = get(timeSignature, 'top')
// 	if (top === 6 || top === 12) {
// 		return {
// 			time: {
// 				...time,
// 				options: 64,
// 				previousOptions: time.options,
// 			},
// 		}
// 	}
// 	console.log('previous Options', time.previousOptions)
// 	return {
// 		time: {
// 			...time,
// 			options: time.previousOptions,
// 			previousOptions: null,
// 		},
// 	}
// }

export default function playback(state = initPlayback, action) {
	switch (action.type) {
		case SET_BARS: {
			const { bars } = action.payload
			return {
				...state,
				bars: bars,
			}
		}
		case SET_TIME: {
			const { time } = action.payload
			// const { timeSignature } = state
			// if (timeSignature.bottom === 8 && (timeSignature.top === 6 || timeSignature.top === 12)) {
			// 	time.options = 64
			// }
			return {
				...state,
				time: time,
			}
		}
		case SET_PLAYBACK_TIME: {
			const { playbackTime } = action.payload
			return {
				...state,
				playbackTime: playbackTime,
			}
		}
		case SET_RANDOM: {
			const { random } = action.payload
			return {
				...state,
				random: random,
			}
		}
		case SET_PLAY_THIS: {
			const { playThis } = action.payload
			return {
				...state,
				playThis: playThis,
			}
		}
		case SET_BPM: {
			const { bpm } = action.payload
			return {
				...state,
				bpm: bpm,
			}
		}
		case SET_MODE: {
			const { mode } = action.payload
			return {
				...state,
				mode: mode,
			}
		}
		case SET_PLAYING: {
			const { playing } = action.payload
			return {
				...state,
				playing: playing,
			}
		}
		case SET_USE_METRONOME: {
			const { useMetronome } = action.payload
			return {
				...state,
				click: {
					...state.click,
					on: useMetronome,
				},
			}
		}
		case SET_USE_COUNT_IN: {
			const { useCountIn } = action.payload
			return {
				...state,
				click: {
					...state.click,
					countIn: useCountIn,
				},
			}
		}
		case SET_USE_GHOSTS: {
			const { useGhosts } = action.payload
			return {
				...state,
				useGhosts: useGhosts,
			}
		}
		case SET_PLAYBACK_AS: {
			const { playbackAs } = action.payload
			return {
				...state,
				playbackAs: playbackAs,
			}
		}
		case SET_GROOVE_CYMBAL_PATTERN: {
			const { pattern } = action.payload
			return {
				...state,
				groove: {
					...state.groove,
					cymbal: {
						...state.groove.cymbal,
						pattern,
					},
				},
			}
		}
		case SET_GROOVE_CYMBAL_SOUND: {
			const { sound } = action.payload
			return {
				...state,
				groove: {
					...state.groove,
					cymbal: {
						...state.groove.cymbal,
						sound,
					},
				},
			}
		}
		case SET_GROOVE_GHOSTS: {
			const { ghosts } = action.payload
			return {
				...state,
				groove: {
					...state.groove,
					ghosts,
				},
			}
		}
		case SET_GROOVE_BACKBEAT: {
			const { backbeat } = action.payload
			return {
				...state,
				groove: {
					...state.groove,
					backbeat,
				},
			}
		}
		case SET_GROOVE_MIX: {
			const { mix } = action.payload
			return {
				...state,
				groove: {
					...state.groove,
					mix,
				},
			}
		}
		case SET_GROOVE_LOCK: {
			const { lock } = action.payload
			return {
				...state,
				groove: {
					...state.groove,
					lock,
				},
			}
		}
		case TOGGLE_GROOVE_LOCK: {
			return {
				...state,
				groove: {
					...state.groove,
					lock: !state.groove.lock,
				},
			}
		}
		case SET_SWING: {
			const { swing } = action.payload
			return {
				...state,
				swing: swing,
			}
		}
		case SET_CUSTOM: {
			const { custom } = action.payload
			return {
				...state,
				custom: custom,
			}
		}
		case SET_CUSTOM_ARRAY: {
			const { customArray } = action.payload
			return {
				...state,
				customArray: customArray,
			}
		}
		case SET_SPACE: {
			const { space } = action.payload
			return {
				...state,
				space: space,
			}
		}
		case SET_SELECTED_BARS: {
			const { selectedBars } = action.payload
			return {
				...state,
				selectedBars: selectedBars,
			}
		}
		case SET_PLAY_ENABLED: {
			const { playEnabled } = action.payload
			return {
				...state,
				playEnabled: playEnabled,
			}
		}
		case SET_ISOLATED: {
			const { isolated } = action.payload
			return {
				...state,
				isolated: isolated,
			}
		}
		case SET_ISOLATED_ARRAY: {
			const { isolatedArray } = action.payload
			return {
				...state,
				isolatedArray: isolatedArray,
			}
		}
		case SET_LEVELS_ARRAY: {
			const { levelsArray } = action.payload
			return {
				...state,
				levelsArray: levelsArray,
			}
		}
		case SET_LEVEL: {
			const { level } = action.payload
			return {
				...state,
				level: level,
			}
		}
		case TOGGLE_TRADE_DIRECTION: {
			return {
				...state,
				tradeDirection: !state.tradeDirection,
			}
		}
		case SET_TRADE_DIRECTION: {
			const { tradeDirection } = action.payload
			return {
				...state,
				tradeDirection: tradeDirection,
			}
		}
		case SET_RHYTHM_LOCK: {
			const { rhythmLock } = action.payload
			return {
				...state,
				rhythmLock: rhythmLock,
			}
		}
		case TOGGLE_RHYTHM_LOCK: {
			return {
				...state,
				rhythmLock: !state.rhythmLock,
			}
		}
		case SET_TIME_SIGNATURE: {
			const { timeSignature } = action.payload
			const { limits } = timeSignatureLimits(get(timeSignature, 'bottom'))
			const newTimeSignature = {
				...state.timeSignature,
				...timeSignature,
				limits,
			}
			// const { time } = timeSignatureTimeAdjustments(newTimeSignature, state.time)

			return {
				...state,
				timeSignature: newTimeSignature,
				// time,
			}
		}
		case SET_TIME_SIGNATURE_TOP: {
			const { top } = action.payload
			const newTimeSignature = {
				...state.timeSignature,
				top: top,
			}
			// const { time } = timeSignatureTimeAdjustments(newTimeSignature, state.time)
			return {
				...state,
				timeSignature: newTimeSignature,
				// time,
			}
		}
		case SET_TIME_SIGNATURE_BOTTOM: {
			const { bottom } = action.payload
			const { limits } = timeSignatureLimits(bottom)
			const newTimeSignature = {
				...state.timeSignature,
				bottom,
				limits,
			}
			// const { time } = timeSignatureTimeAdjustments(newTimeSignature, state.time)
			return {
				...state,
				timeSignature: newTimeSignature,
				// time,
			}
		}
		case SET_PRACTICE_MODE: {
			const { practice } = action.payload
			let countIn = get(state, `click.countIn`, true)
			if (practice) {
				countIn = true
			}
			return {
				...state,
				practice: {
					...state.practice,
					on: practice,
				},
				click: {
					...state.click,
					countIn,
				},
			}
		}
		case SET_RHYTHM_TIMINGS: {
			const { straight, triplets } = action.payload
			if (!straight && !triplets) {
				return {
					...state,
					practice: {
						...state.practice,
						rhythm: {
							...state.practice.rhythm,
							timings: get(initPlayback, `practice.rhythm.timings`),
							hitsPerRhythm: get(initPlayback, `practice.rhythm.hitsPerRhythm`),
						},
					},
				}
			}
			const { straightTimings, straightClickTimings, straightTapTimings } = straight
			const { tripletTimings, tripletClickTimings, tripletTapTimings } = triplets

			let hitsPerRhythm = 0
			const SeenTimingsMap = new Map([])
			forEach(straightTimings, (s) => {
				if (!get(s, 'hit')) {
					return
				}
				if (SeenTimingsMap.get(get(s, `timeStamp`))) {
					return
				}
				SeenTimingsMap.set(get(s, `timeStamp`), true)
				hitsPerRhythm++
			})
			forEach(tripletTimings, (t) => {
				if (!get(t, 'hit')) {
					return
				}
				if (SeenTimingsMap.get(get(t, `timeStamp`))) {
					return
				}
				SeenTimingsMap.set(get(t, `timeStamp`), true)
				hitsPerRhythm++
			})

			return {
				...state,
				practice: {
					...state.practice,
					rhythm: {
						...state.practice.rhythm,
						timings: {
							...state.practice.rhythm.timings,
							straight: straightTimings || get(state, ['practice', 'rhythm', 'timings', 'straight']),
							triplets: tripletTimings || get(state, ['practice', 'rhythm', 'timings', 'triplets']),
							tap: {
								...state.practice.rhythm.timings.tap,
								straight: straightTapTimings || get(state, ['practice', 'rhythm', 'timings', 'tap', 'straight']),
								triplets: tripletTapTimings || get(state, ['practice', 'rhythm', 'timings', 'tap', 'triplets']),
							},
							click: {
								...state.practice.rhythm.timings.click,
								straight: straightClickTimings || get(state, ['practice', 'rhythm', 'timings', 'click', 'straight']),
								triplets: tripletClickTimings || get(state, ['practice', 'rhythm', 'timings', 'click', 'triplets']),
							},
						},
						hitsPerRhythm: hitsPerRhythm,
					},
				},
			}
		}
		case SET_RHYTHM_START_TIME: {
			const { startTime } = action.payload
			return {
				...state,
				practice: {
					...state.practice,
					rhythm: {
						...state.practice.rhythm,
						start: startTime,
						loopStart: startTime,
					},
				},
			}
		}
		case SET_ACCURACY: {
			const { accuracy } = action.payload
			return {
				...state,
				practice: {
					...state.practice,
					user: {
						...state.practice.user,
						accuracy,
					},
				},
			}
		}
		case ADD_USER_HIT: {
			const { time, position, timingIndex, stats } = action.payload
			const hits = get(state, `practice.user.hits`, [])
			const noOfMisses = get(state, `practice.user.misses.length`, 0)
			const accuracy = practiceAccuracy({ hits: get(hits, 'length') + 1, misses: noOfMisses, perRhythm: get(state, 'practice.rhythm.hitsPerRhythm') })

			const timings = get(state, `practice.user.played.timings`, [])
			const positions = get(state, `practice.user.played.positions`, [])
			let p = position
			if (positions[positions.length - 1] === position) {
				p += 1
			}
			positions.push(p)
			timings.push(time)

			if (timingIndex !== false) {
				hits.push({
					timing: time,
					timingIndex,
				})
			} else if (stats !== false) {
				hits.push({
					timing: time,
					stats,
				})
			} else {
				hits.push({
					timing: time,
					position: p,
				})
			}

			return {
				...state,
				practice: {
					...state.practice,
					user: {
						...state.practice.user,
						hits,
						played: {
							timings,
							positions,
						},
						accuracy,
					},
				},
			}
		}
		case ADD_USER_MISS: {
			const { time, position, timingIndex, stats } = action.payload
			const misses = get(state, `practice.user.misses`, [])
			const noOfHits = get(state, `practice.user.hits.length`, 0)
			const accuracy = practiceAccuracy({ hits: noOfHits, misses: get(misses, 'length') + 1, perRhythm: get(state, 'practice.rhythm.hitsPerRhythm') })

			let timings = get(state, `practice.user.played.timings`, [])
			let positions = get(state, `practice.user.played.positions`, [])
			let p = position
			if (positions[positions.length - 1] === position) {
				p += 1
			}
			timings.push(time)
			positions.push(p)

			if (timingIndex !== false) {
				misses.push({
					timing: time,
					timingIndex,
				})
			} else if (stats !== false) {
				misses.push({
					timing: time,
					stats,
				})
			} else {
				misses.push({
					timing: time,
					position: p,
				})
			}

			return {
				...state,
				practice: {
					...state.practice,
					user: {
						...state.practice.user,
						misses,
						played: {
							timings,
							positions,
						},
						accuracy,
					},
				},
			}
		}
		case SET_USER_HITS: {
			const { hits, misses } = action.payload
			return {
				...state,
				practice: {
					...state.practice,
					user: {
						...state.practice.user,
						hits,
						misses,
					},
				},
			}
		}
		case ADD_RHYTHM_HISTORY: {
			const { practice, appState } = action.payload
			let history = get(state, `practice.history`, [])
			history.push({ practice, appState })
			return {
				...state,
				practice: {
					...state.practice,
					user: {
						...state.practice.user,
						played: {
							positions: [],
							timings: [],
						},
					},
					history,
				},
			}
		}
		case CLEAR_RHYTHM_HISTORY: {
			return {
				...state,
				practice: {
					...state.practice,
					history: [],
				},
			}
		}
		case RESET_CLICK: {
			return {
				...state,
				click: {
					...state.click,
					volume: get(initPlayback, `click.volume`),
					offset: {
						...get(initPlayback, `click.offset`),
					},
					gap: {
						...get(initPlayback, `click.gap`),
					},
					rate: {
						...get(initPlayback, `click.rate`),
					},
				},
			}
		}
		case SET_CLICK_VOLUME: {
			const { volume } = action.payload
			return {
				...state,
				click: {
					...state.click,
					volume,
				},
			}
		}
		case SET_EXACT_CLICK_RATE: {
			const { clickRateObj } = action.payload
			const convertedObject = {
				custom: Boolean(get(clickRateObj, `custom`, 0)),
				selectedImage: get(clickRateObj, `selectedImage`, 0),
				amount: get(clickRateObj, `amount`, 0),
				time: get(clickRateObj, `time`, 0),
				markTheOne: Boolean(get(clickRateObj, `markTheOne`, 0)),
			}
			return {
				...state,
				click: {
					...state.click,
					offset: {
						...state.click.offset,
					},
					gap: {
						...state.click.gap,
					},
					rate: {
						...convertedObject,
					},
				},
			}
		}
		case SET_CLICK_RATE: {
			const { selectedImage, newRate, custom } = action.payload
			return {
				...state,
				click: {
					...state.click,
					offset: {
						...state.click.offset,
					},
					gap: {
						...state.click.gap,
					},
					rate: {
						...state.click.rate,
						custom,
						selectedImage,
						amount: get(newRate, `amount`, false) || state.click.rate.amount,
						time: get(newRate, `time`, false) === false ? state.click.rate.time : get(newRate, `time`, false),
					},
				},
			}
		}
		case SET_CLICK_RATE_CUSTOM: {
			const { bool } = action.payload
			return {
				...state,
				click: {
					...state.click,
					offset: {
						...state.click.offset,
					},
					gap: {
						...state.click.gap,
					},
					rate: {
						...state.click.rate,
						custom: bool,
						selectedImage: !bool ? 1 : 10,
					},
				},
			}
		}
		case SET_CLICK_RATE_AMOUNT: {
			const { value } = action.payload
			return {
				...state,
				click: {
					...state.click,
					offset: {
						...state.click.offset,
					},
					gap: {
						...state.click.gap,
					},
					rate: {
						...state.click.rate,
						amount: value,
					},
				},
			}
		}
		case SET_CLICK_RATE_NOTE: {
			const { value } = action.payload
			return {
				...state,
				click: {
					...state.click,
					offset: {
						...state.click.offset,
					},
					gap: {
						...state.click.gap,
					},
					rate: {
						...state.click.rate,
						time: value,
					},
				},
			}
		}
		case SET_EXACT_CLICK_OFFSET: {
			const { clickOffsetObj } = action.payload
			const convertedObject = {
				amount: get(clickOffsetObj, `amount`, 0),
				rate: get(clickOffsetObj, `rate`, 0),
				modified: Boolean(get(clickOffsetObj, `modified`, 0)),
			}
			return {
				...state,
				click: {
					...state.click,
					rate: {
						...state.click.rate,
					},
					gap: {
						...state.click.gap,
					},
					offset: {
						...convertedObject,
					},
				},
			}
		}
		case SET_CLICK_OFFSET_AMOUNT: {
			const { amount, manual } = action.payload
			let a = amount
			const rate = get(state, `click.offset.rate`)
			switch (rate) {
				// Deal with maximum offsets, if 2 is selected at any point move
				// it back to the latest sensible offset
				case ClickRateEnum.SIXTEENTH:
					if (a > 3) {
						a = 3
					}
					break
				default:
					if (a > 2) {
						a = 2
					}
					break
			}
			return {
				...state,
				click: {
					...state.click,
					rate: {
						...state.click.rate,
					},
					gap: {
						...state.click.gap,
					},
					offset: {
						...state.click.offset,
						amount: a,
						modified: manual || state.click.offset.modified,
					},
				},
			}
		}
		case SET_CLICK_OFFSET_RATE: {
			const { rate } = action.payload
			const stateAmount = get(state, `click.offset.amount`)
			let amount = false
			switch (rate) {
				case ClickRateEnum.SIXTEENTH:
					if (stateAmount > 4) {
						amount = 4
					}
					break
				case ClickRateEnum.TRIPLETS:
					if (stateAmount > 3) {
						amount = 3
					}
					break
				default:
					break
			}
			return {
				...state,
				click: {
					...state.click,
					rate: {
						...state.click.rate,
					},
					gap: {
						...state.click.gap,
					},
					offset: {
						...state.click.offset,
						rate,
						amount: amount || stateAmount,
					},
				},
			}
		}
		case SWITCH_CLICK_OFFSET_RATE: {
			const stateRate = get(state, `click.offset.rate`)
			const stateAmount = get(state, `click.offset.amount`)
			let amount = false
			let to
			switch (stateRate) {
				case ClickRateEnum.SIXTEENTH:
					to = ClickRateEnum.TRIPLETS
					if (stateAmount > 3) {
						amount = 3
					}
					break
				default:
					to = ClickRateEnum.SIXTEENTH
					if (stateAmount > 4) {
						amount = 4
					}
					break
			}
			return {
				...state,
				click: {
					...state.click,
					rate: {
						...state.click.rate,
					},
					gap: {
						...state.click.gap,
					},
					offset: {
						...state.click.offset,
						rate: to,
						modified: true, // Manual modification done through this action
						amount: amount || stateAmount,
					},
				},
			}
		}
		case SET_GAP_CLICK_MATCH_RHYTHM: {
			const { bool } = action.payload
			return {
				...state,
				click: {
					...state.click,
					rate: {
						...state.click.rate,
					},
					offset: {
						...state.click.offset,
					},
					gap: {
						...state.click.gap,
						matchRhythmLength: bool,
					},
				},
			}
		}
		case SET_CLICK_MARK_ONE: {
			const { bool } = action.payload
			return {
				...state,
				click: {
					...state.click,
					offset: {
						...state.click.offset,
					},
					gap: {
						...state.click.gap,
					},
					rate: {
						...state.click.rate,
						markTheOne: bool,
					},
				},
			}
		}
		case SET_EXACT_CLICK_GAP: {
			const { clickGapObj } = action.payload
			const convertedObject = {
				on: get(clickGapObj, `on`, false),
				off: get(clickGapObj, `off`, false),
				matchRhythmLength: Boolean(get(clickGapObj, `matchRhythmLength`, 0)),
				startOff: Boolean(get(clickGapObj, `startOff`, 0)),
				controlsTouched: Boolean(get(clickGapObj, `controlsTouched`, 0)),
			}
			return {
				...state,
				click: {
					...state.click,
					rate: {
						...state.click.rate,
					},
					offset: {
						...state.click.offset,
					},
					gap: {
						...convertedObject,
					},
				},
			}
		}
		case SET_GAP_CLICK: {
			const { on, off, touched } = action.payload
			return {
				...state,
				click: {
					...state.click,
					offset: {
						...state.click.offset,
					},
					rate: {
						...state.click.rate,
					},
					gap: {
						...state.click.gap,
						on: isNil(on) ? state.click.gap.on : on,
						off: isNil(off) ? state.click.gap.off : off,
						controlsTouched: isNil(touched) ? state.click.gap.controlsTouched : touched,
					},
				},
			}
		}
		case SWITCH_GAP_CLICK_OFF_FIRST: {
			return {
				...state,
				click: {
					...state.click,
					offset: {
						...state.click.offset,
					},
					rate: {
						...state.click.rate,
					},
					gap: {
						...state.click.gap,
						startOff: !state.click.gap.startOff,
					},
				},
			}
		}
		case RESET_PRESET: {
			return {
				...state,
				preset: {
					...get(initPlayback, 'preset'),
					constants: state.preset.constants,
				},
			}
		}
		case SET_PRESET: {
			const { on } = action.payload
			return {
				...state,
				preset: {
					...state.preset,
					on,
				},
			}
		}
		case SET_PRESET_SHUFFLE_ALL: {
			const { on, params } = action.payload
			return {
				...state,
				preset: {
					...state.preset,
					shuffleAll: {
						on,
						params,
					},
				},
			}
		}
		case SET_PRESET_CONSTANTS: {
			const { constants } = action.payload
			return {
				...state,
				preset: {
					...state.preset,
					constants,
				},
			}
		}

		// Rhythmic Vocabulary Permutations
		case RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_GROUPING: {
			const { groupingIndex } = action.payload
			return {
				...state,
				rhythmicVocabularyPermutations: {
					...state.rhythmicVocabularyPermutations,
					groupingIndex,
				},
			}
		}

		case RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_START_POINTS: {
			const { startingPoints } = action.payload
			return {
				...state,
				rhythmicVocabularyPermutations: {
					...state.rhythmicVocabularyPermutations,
					startingPoints,
				},
			}
		}

		default:
			return state
	}
}
