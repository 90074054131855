import React from 'react'
import { connect } from 'react-redux'

import { IonItem, IonLabel } from '@ionic/react'

import { getIsCustom, getIsPlaying, getLevel, getTime } from '../../../../../../../redux/selectors/playback'
import { ionSetComplexityOpen } from '../../../../../../../redux/ionicActions'
import { getRenderingMode } from '../../../../../../../redux/selectors/rendering'
import RenderingMode from '../../../../../../../utils/constants/enum/renderingMode'
const Complexity = ({ playing, ionSetComplexityOpen, level, custom, renderingMode }) => {
	const getLabel = () => {
		if (custom) {
			return `Complexity - Custom`
		}
		switch (renderingMode) {
			case RenderingMode.FIRSTGROOVES.ALL:
				return `Complexity - All Grooves`
			case RenderingMode.FIRSTGROOVES.ONE:
			case RenderingMode.FIRSTGROOVES.TWO:
			case RenderingMode.FIRSTGROOVES.THREE:
				return `Complexity - Level ${renderingMode - 7}`
			case RenderingMode.SD.TRIPLETS.ONE:
			case RenderingMode.SD.TRIPLETS.TWO:
			case RenderingMode.SD.TRIPLETS.THREE:
			case RenderingMode.SD.TRIPLETS.FOUR:
			case RenderingMode.SD.TRIPLETS.FIVE:
				return `Complexity - Level ${renderingMode - 2}`
			default:
				break
		}
		return `Complexity - Level ${level}`
	}

	const disabled = () => {
		if (playing) return true
		switch (renderingMode) {
			case RenderingMode.DOWNUP.STRAIGHT:
			case RenderingMode.DOWNUP.TRIPLETS:
				return true
			default:
				break
		}
		return false
	}

	return (
		<IonItem
			detail
			button
			disabled={disabled()}
			onClick={() => {
				ionSetComplexityOpen(true)
			}}
		>
			<IonLabel>{getLabel()}</IonLabel>
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const renderingMode = getRenderingMode(state)
	const playing = getIsPlaying(state)
	const time = getTime(state)
	const level = getLevel(state)
	const custom = getIsCustom(state)
	return {
		playing,
		time,
		level,
		custom,
		renderingMode,
	}
}

export default connect(mapStateToProps, { ionSetComplexityOpen })(Complexity)
