import React from 'react'
import get from 'lodash/get'
import map from 'lodash/map'
import split from 'lodash/split'
import { connect } from 'react-redux'

import Button from 'react-bootstrap/Button'

import { setFeaturesList } from '../../redux/actions'
import { getRenderingState, getThemeName } from '../../redux/selectors/rendering'

import { FeaturesListEnum } from '../../utils/constants/enum/featuresList'
import { mobileTimeSignatureAnimation } from '../../utils/functions'

const UpdateList = ({ rendering, themeName, setFeaturesList }) => {
	const render = get(rendering, 'featuresList') === FeaturesListEnum.ON && !get(rendering, 'analyticsPrompt')
	if (!render) {
		return null
	}
	return (
		<div className={'banner features flex ' + themeName}>
			<div className={'message ' + themeName}>
				<p className="text bold size-18">{`What's New`}</p>
				<ul>
					{map(split(process.env.REACT_APP_FEATURES_LIST, ':'), (entry, idx) => {
						return (
							<li key={idx} className="text medium size-15">
								{entry.length > 0 && entry}
							</li>
						)
					})}
				</ul>
			</div>
			<div className="actions">
				<Button
					onClick={() => {
						setFeaturesList(FeaturesListEnum.OFF)
						localStorage.setItem('featuresList', FeaturesListEnum.OFF)
						mobileTimeSignatureAnimation()
					}}
					className="dismiss action"
				>
					{`Ok`}
				</Button>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const themeName = getThemeName(state)

	return {
		rendering,
		themeName,
	}
}

export default connect(mapStateToProps, { setFeaturesList })(UpdateList)
