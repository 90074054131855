import React from 'react'
import { connect } from 'react-redux'

import { IonList } from '@ionic/react'

import Bottom from './Pickers/bottom'
import Top from './Pickers/top'

const TimeSignature = ({ changeTimeSignatureTop, changeTimeSignatureBottom }) => {
	return (
		<IonList>
			<Top changeTimeSignatureTop={changeTimeSignatureTop} />
			<Bottom changeTimeSignatureBottom={changeTimeSignatureBottom} />
		</IonList>
	)
}

const mapStateToProps = (state) => {
	return {}
}

export default connect(mapStateToProps, {})(TimeSignature)
