import React from 'react'
import { connect } from 'react-redux'

import { IonItem, IonLabel } from '@ionic/react'

import useBarPicker from '../../Hooks/useBarPicker'

import { getBars, getIsPlaying } from '../../../../../../../redux/selectors/playback'

const Bars = ({ bars, playing }) => {
	const openPicker = useBarPicker()

	const getLabel = () => `${bars} ${bars > 1 ? `Bars` : `Bar`}`

	return (
		<IonItem disabled={playing} detail button onClick={openPicker}>
			<IonLabel>{getLabel()}</IonLabel>
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const bars = getBars(state)
	const playing = getIsPlaying(state)

	return {
		bars,
		playing,
	}
}

export default connect(mapStateToProps, {})(Bars)
