import React from 'react'
import { connect } from 'react-redux'

import { IonToggle, IonLabel, IonItem, IonIcon } from '@ionic/react'

import { setUseCountIn } from '../../../../../../../redux/actions'
import { getIsPracticeModeOn, getUsingCountIn } from '../../../../../../../redux/selectors/playback'

import { lockClosedSharp } from 'ionicons/icons'

const CountIn = ({ setUseCountIn, usingCountIn, isPracticeModeOn }) => {
	const toggle = () => {
		setUseCountIn(!usingCountIn)
	}

	const getLabel = () => `Count In`

	const getToggleContent = () => {
		if (isPracticeModeOn)
			return (
				<>
					<IonIcon icon={lockClosedSharp} />
					<IonToggle disabled={true} checked={usingCountIn} />
				</>
			)
		return <IonToggle disabled={isPracticeModeOn} checked={usingCountIn} onClick={toggle} />
	}

	return (
		<IonItem>
			<IonLabel>{getLabel()}</IonLabel>
			{getToggleContent()}
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const isPracticeModeOn = getIsPracticeModeOn(state)
	const usingCountIn = getUsingCountIn(state)

	return {
		usingCountIn,
		isPracticeModeOn,
	}
}

export default connect(mapStateToProps, { setUseCountIn })(CountIn)
