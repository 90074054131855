import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { IonIcon, IonButton } from '@ionic/react'
import { refreshOutline } from 'ionicons/icons'
import { getCustomArray, getIsCustom, getIsPlaying, getLevel, getPlaybackTimeOptions, getSpace, getTime } from '../../../redux/selectors/playback'
import { getAreFiltersOpen } from '../../../redux/selectors/ionic'
import { arrayEquals } from '../../../utils/functions'

// TODO, I hate this what the hell happened here???
let l = null
let s = null
let c = null
let ca = null

const Refresh = ({ playing, generateRhythm, areFiltersOpen, time, playbackTimeOptions, level, space, custom, customArray }) => {
	const newRhythm = () => generateRhythm({})
	const [openLevel, setOpenLevel] = useState(l)
	const [openSpace, setOpenSpace] = useState(s)
	const [openCustom, setOpenCustom] = useState(c)
	const [openCustomArray, setOpenCustomArray] = useState(ca)

	const stateSetter = () => {
		l = level
		s = space
		c = custom
		ca = customArray
		setOpenLevel(l)
		setOpenSpace(s)
		setOpenCustom(c)
		setOpenCustomArray(ca)
	}

	useEffect(() => {
		stateSetter()
	}, [])

	useEffect(() => {
		if (areFiltersOpen) {
			return
		}
		const newSubdivision = get(time, `options`) !== playbackTimeOptions
		const newComplexity = openLevel !== level
		const differentSpace = openSpace !== space
		const customChange = openCustom !== custom || !arrayEquals(openCustomArray, customArray)
		if (newComplexity || newSubdivision || differentSpace || customChange) {
			newRhythm()
		}
		stateSetter()
	}, [areFiltersOpen])

	return (
		<span className="ion-refresh-button">
			<IonButton disabled={playing} onClick={newRhythm} color={`dark`} shape="circle">
				<IonIcon color="canvas" icon={refreshOutline} />
			</IonButton>
		</span>
	)
}

const mapStateToProps = (state) => {
	return {
		space: getSpace(state),
		areFiltersOpen: getAreFiltersOpen(state),
		time: getTime(state),
		playbackTimeOptions: getPlaybackTimeOptions(state),
		playing: getIsPlaying(state),
		level: getLevel(state),
		custom: getIsCustom(state),
		customArray: getCustomArray(state),
	}
}

export default connect(mapStateToProps, {})(Refresh)
