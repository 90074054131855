import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { PlatformEnum } from '../utils/constants/enum/platform'
import { APP_STORES_PATH } from '../routes/Web/constants'
import useWindowResize from '../hooks/useWindowResize'
import { beta } from '../utils/functions'
import { getRenderingState, getIsApp, getThemeName } from '../redux/selectors/rendering'

import Button from 'react-bootstrap/Button'
import Feedback from './Buttons/feedback'
import Tutorial from './Buttons/tutorial'
import Share from './Buttons/share'
import Reset from './Buttons/reset'
import Practice from './Switches/practice'
import Theme from './Switches/theme'
import ScreenLock from './Switches/screenLock'
import appLogo from '../images/rb-logo.png'

const MobileSettings = (props) => {
	const [device] = useWindowResize()
	const themeName = get(props, `themeName`)
	const isApp = get(props, `isApp`, false)

	return (
		<div
			className={`settings-menu ios-${get(props, [`rendering`, `platform`]) === PlatformEnum.IOS ? 'true' : 'false'} ${device} ${themeName} ${get(props, [
				`rendering`,
				`showSettings`,
			])}`}
		>
			<div className="top">
				<div className="app-logo">
					<img alt="app-logo" src={appLogo}></img>
				</div>
				<div className="title text bold">{`RhythmBot`}</div>
				<div className="subtitle text">{`Rhythmic Vocabulary Trainer`}</div>
			</div>
			<div className="bottom">
				<div className="buttons tutorial">
					<Tutorial />
				</div>
				<div className="divider narrow"></div>
				<div className="buttons share">
					<Share />
				</div>
				{!isApp && (
					<>
						<div className="divider narrow"></div>
						<div className="buttons feedback">
							<Feedback />
						</div>
					</>
				)}
				<div className="divider narrow"></div>
				<div className="buttons">
					{isApp ? (
						<Feedback />
					) : (
						<Button className={'get-app action'} href={APP_STORES_PATH} target="_blank">
							{`Get the App!`}
						</Button>
					)}
					<Reset />
				</div>
				<div className="divider narrow"></div>
				<div className="slider">
					{beta() && (
						<div className="slider-inner">
							<Practice inMenu={true} title={`Practice`} labelSize={'size-12'} />
						</div>
					)}
					<div className="slider-inner">
						<ScreenLock labelSize={'size-12'} />
					</div>
					<div className="slider-inner">
						<Theme labelSize={'size-12'} />
					</div>
				</div>
				<div className="divider wide"></div>
				<div className="menu-credits">
					<p className={'text light size-13 ' + device}>{get(props, [`rendering`, `text`, `credits`])}</p>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const isApp = getIsApp(state)
	const themeName = getThemeName(state)

	return {
		themeName,
		rendering,
		isApp,
	}
}

export default connect(mapStateToProps, {})(MobileSettings)
