import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { IonRouterOutlet } from '@ionic/react'
import { IonMenuButton, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge, IonTabs } from '@ionic/react'
import { timerOutline, musicalNotes } from 'ionicons/icons'

import Router from './router'

import { getIsCustomClick } from '../../redux/selectors/playback'
import { tabs } from '../../utils/ionic/tabs'
import { getMenuID } from '../../redux/selectors/ionic'

const Tabs = ({ customClick, menuID }) => {
	const getMetronomeBadge = () => {
		if (!customClick) return
		return <IonBadge color={`danger`} />
	}

	return (
		<IonTabs>
			<IonRouterOutlet id={menuID}>
				<Router />
			</IonRouterOutlet>
			<IonTabBar color="dark" slot="bottom">
				<IonTabButton tab="practice" href={get(tabs, [`practice`, `href`])}>
					<IonIcon icon={musicalNotes} />
					<IonLabel>{get(tabs, [`practice`, `title`])}</IonLabel>
				</IonTabButton>
				<IonTabButton tab="metronome" href={get(tabs, [`metronome`, `href`])}>
					<IonIcon icon={timerOutline} />
					<IonLabel>{get(tabs, [`metronome`, `title`])}</IonLabel>
					{getMetronomeBadge()}
				</IonTabButton>
				<IonTabButton tab="menu">
					<IonMenuButton className="ion-menu-button" />
				</IonTabButton>
			</IonTabBar>
		</IonTabs>
	)
}

const mapStateToProps = (state) => {
	const menuID = getMenuID(state)
	const customClick = getIsCustomClick(state)
	return {
		customClick,
		menuID,
	}
}

export default connect(mapStateToProps, {})(Tabs)
