import get from 'lodash/get'
import { useDispatch, useSelector } from 'react-redux'
import { KeepAwake } from '@capacitor-community/keep-awake'

import { requestWakeLock } from '../utils/functions'
import { getIsApp, getScreenLock } from '../redux/selectors/rendering'
import { setScreenLock, setScreenLockObject } from '../redux/actions'

/**
 * For interfacing with screen lock
 * @returns
 */
const useScreenLock = () => {
	const dispatch = useDispatch()
	const isApp = useSelector(getIsApp)
	const screenLock = useSelector(getScreenLock)

	/**
	 * @returns {Boolean} is screen lock forced on
	 */
	const forcedCheck = () => {
		const isOn = get(screenLock, [`state`])
		const isForced = get(screenLock, `forced`)
		if (isOn && isForced) {
			return true
		}
		return false
	}

	const turnOn = async () => {
		if (forcedCheck()) {
			return
		}
		switch (isApp) {
			case false:
				dispatch(setScreenLockObject(await requestWakeLock()))
				break
			default:
				await KeepAwake.keepAwake()
		}
		dispatch(setScreenLock(true))
	}

	const turnOff = async () => {
		if (forcedCheck()) {
			return
		}
		switch (isApp) {
			case false:
				const wakeLockObject = get(screenLock, [`lockObject`])
				wakeLockObject &&
					wakeLockObject.release().then(() => {
						setScreenLockObject(null)
					})
				break
			default:
				await KeepAwake.allowSleep()
		}
		dispatch(setScreenLock(false))
	}

	const toggle = () => {
		const isOn = get(screenLock, [`state`])
		if (isOn) {
			return turnOff()
		}
		return turnOn()
	}

	return { toggle, turnOn, turnOff }
}

export default useScreenLock
