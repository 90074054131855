import React from 'react'
import get from 'lodash/get'

import Button from 'react-bootstrap/Button'

import { openDonations } from '../../utils/functions'
import { donationsTitle } from '../../utils/text/donations'
import { donationsResponse } from '../../utils/donations'

const Donate = (props) => {
	const className = get(props, `className`, `action`)
	return (
		<Button
			onClick={() => {
				donationsResponse(true)
				openDonations()
			}}
			variant="primary"
			className={`donate ${className}`}
		>
			{donationsTitle}
		</Button>
	)
}

export default Donate
