import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { toggleShowSettings, setShowFilters, setShowSettings, toggleShowFilters } from '../../redux/actions'
import { getRenderingState, getIsApp, getThemeName } from '../../redux/selectors/rendering'

import { beta, wakeLockAvailable } from '../../utils/functions'

import useWindowResize from '../../hooks/useWindowResize'

import Feedback from '../Buttons/feedback'
import Tutorial from '../Buttons/tutorial'
import Share from '../Buttons/share'
import Reset from '../Buttons/reset'
import Donate from '../Buttons/donate'

import Practice from '../Switches/practice'
import Theme from '../Switches/theme'
import ScreenLock from '../Switches/screenLock'

const NavbarSettings = (props) => {
	const [device] = useWindowResize()
	const themeName = get(props, `themeName`)
	const isWakeLockAvailable = wakeLockAvailable()

	const render = () => {
		return (
			<>
				<div className="setting button">
					<Tutorial />
				</div>
				<div className="setting button">
					<Share />
				</div>
				<div className="setting button">
					<Feedback />
				</div>
				{!get(props, `isApp`) && (
					<div className="setting button">
						<Donate className={`action-3`} />
					</div>
				)}
				<div className="setting button">
					<Reset />
				</div>
				{beta() && (
					<div className="setting switch">
						<Practice />
					</div>
				)}
				{isWakeLockAvailable && (
					<div className="setting switch">
						<ScreenLock />
					</div>
				)}
				<div className="setting switch">
					<Theme />
				</div>
			</>
		)
	}
	const show = get(props, [`rendering`, `showSettings`]) ? `show` : ``
	return <div className={`settings-navbar ${themeName} ${device} ${show}`}>{render()}</div>
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const isApp = getIsApp(state)
	const themeName = getThemeName(state)

	return {
		themeName,
		rendering,
		isApp,
	}
}

export default connect(mapStateToProps, { toggleShowFilters, toggleShowSettings, setShowFilters, setShowSettings })(NavbarSettings)
