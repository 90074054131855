import React from 'react'
import { connect } from 'react-redux'

import { IonMenu, IonContent } from '@ionic/react'

import { getMenuID } from '../../../redux/selectors/ionic'
import { reset } from '../../../redux/actions'

const Menu = ({ menuID }) => {
	return (
		<>
			<IonMenu contentId={menuID} side="end" type="overlay">
				<IonContent />
			</IonMenu>
		</>
	)
}

const mapStateToProps = (state) => {
	const menuID = getMenuID(state)

	return {
		menuID,
	}
}

export default connect(mapStateToProps, { reset })(Menu)
