import store from '../../redux/store'
import { bookOutline, alertCircleOutline } from 'ionicons/icons'
import { setShowError } from '../../redux/actions'

export const practiceSummary = {
	icon: bookOutline,
	message: 'Practice Summary',
	position: `bottom`,
	color: `button`,
	buttons: [
		{
			text: 'Dismiss',
			role: 'cancel',
		},
		{
			text: 'Open',
			role: 'info',
			handler: () => {
				console.log('practice summary')
			},
		},
	],
}

export const errorMessage = ({ navigateToFeedback }) => ({
	icon: alertCircleOutline,
	message: `Error!`,
	position: `bottom`,
	color: `danger`,
	cssClass: `ion-error-message`,
	buttons: [
		{
			text: 'Dismiss',
			role: 'cancel',
			handler: () => store.dispatch(setShowError(false)),
		},
		{
			text: 'Send Feedback',
			role: 'info',
			handler: () => {
				navigateToFeedback()
				store.dispatch(setShowError(false))
			},
		},
	],
})
