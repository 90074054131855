import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { IonIcon, IonLabel, IonItem } from '@ionic/react'
import { shareOutline, checkmarkCircleOutline } from 'ionicons/icons'

import { getIsApp, getRenderedArray } from '../../../../../redux/selectors/rendering'
import { getBars, getPlaybackTimeOptions, getTimeSignature } from '../../../../../redux/selectors/playback'
import { copyShareableURL } from '../../../../../utils/functions'

const Share = ({ shareContent, setShareContent, renderedArray, bars, timeSignature, playbackTimeOptions, isApp }) => {
	const shareRhythm = () => {
		if (get(shareContent, 'done', false)) return

		const renderedLength = get(renderedArray, [`length`])
		let barsStr = `bars=${bars}&`
		let timeStr = `time=${playbackTimeOptions}&`
		let timeSignatureString = `timeSignature=${get(timeSignature, [`top`])}:${get(timeSignature, [`bottom`])}&`
		let beatStr = ``
		for (let i = 0; i < renderedLength; i++) {
			beatStr += `beat=${get(renderedArray, [i])}`
			if (i < renderedLength - 1) {
				beatStr += '&'
			}
		}

		const queryParams = `?${barsStr}${timeStr}${timeSignatureString}${beatStr}`
		copyShareableURL({ isApp, queryParams })
		setShareContent({ text: `Link Copied`, logo: checkmarkCircleOutline, color: `primary`, done: true })
	}

	if (isEmpty(renderedArray)) {
		return null
	}

	return (
		<>
			<IonItem onClick={shareRhythm} button>
				<IonIcon slot="end" icon={get(shareContent, `logo`, shareOutline)} />
				<IonLabel color={get(shareContent, `color`, ``)}>{get(shareContent, `text`, `Share Rhythm`)}</IonLabel>
			</IonItem>
		</>
	)
}

const mapStateToProps = (state) => {
	const renderedArray = getRenderedArray(state)
	const bars = getBars(state)
	const playbackTimeOptions = getPlaybackTimeOptions(state)
	const timeSignature = getTimeSignature(state)
	const isApp = getIsApp(state)

	return {
		renderedArray,
		bars,
		playbackTimeOptions,
		timeSignature,
		isApp,
	}
}

export default connect(mapStateToProps, {})(Share)
