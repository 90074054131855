const StraightLevels = {
  levels: [
    {
      id: 1,
      arr: [1, 2, 3],
    },
    {
      id: 2,
      arr: [4],
    },
    {
      id: 3,
      arr: [5, 6, 7],
    },
    {
      id: 4,
      arr: [11, 10, 9, 8],
    },
  ],
};

// 4-09
// const StraightLevels = {
//   levels: [
//     {
//       id: 1,
//       arr: [1, 2, 3],
//     },
//     {
//       id: 2,
//       arr: [1, 2, 3, 4],
//     },
//     {
//       id: 3,
//       arr: [1, 2, 3, 4, 5, 6, 7],
//     },
//     {
//       id: 4,
//       arr: [1, 2, 3, 4, 5, 6, 7, 11, 10, 9, 8],
//     },
//   ],
// };

//Backup
// const StraightLevels = {
// 	levels: [
// 		{
// 			id: 1,
// 			arr: [1, 9, 16], [1, 2, 3]
// 		},
// 		{
// 			id: 2,
// 			arr: [1, 9, 16, 3], [1, 2, 3, 4]
// 		},
// 		{
// 			id: 3,
// 			arr: [1, 9, 16, 3, 15, 13, 11], [1, 2, 3, 4, 5, 6, 7]
// 		},
// 		{
// 			id: 4,
// 			arr: [1, 9, 16, 3, 15, 13, 11, 5, 7, 8, 14], [1, 2, 3, 4, 5, 6, 7, 11, 10, 9, 8]
// 		},
// 	],
// };

export default StraightLevels;
