import React, { useRef } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { getRenderingState } from '../../redux/selectors/rendering'
import { setShowError } from '../../redux/actions'

import { copyToClipboard } from '../../utils/functions'

import Button from 'react-bootstrap/Button'

const Error = ({ rendering, setShowError }) => {
	const errorMessageRef = useRef(null)
	const copyError = () => copyToClipboard({ text: get(errorMessageRef, [`current`, `innerHTML`], ``) })

	const render = get(rendering, 'showError')
	if (!render) {
		return null
	}
	return (
		<div className="banner error flex">
			<div className="message">
				<p className="text bold size-18">{`Something went wrong`}</p>
				<p ref={errorMessageRef} value={get(rendering, `errorText`)} className="text medium size-16">
					{get(rendering, 'errorText', 'No error details available')}
				</p>
			</div>
			<div className="actions">
				<Button
					onClick={() => {
						setShowError(false)
					}}
					className="reject action-2"
				>
					{`Close`}
				</Button>
				<Button onClick={copyError} className="reject action-2">
					{`Copy Error Details`}
				</Button>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		rendering: getRenderingState(state),
	}
}

export default connect(mapStateToProps, { setShowError })(Error)
