import React, { useState } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { useHistory } from 'react-router-dom'

import { IonFooter, IonIcon, IonLabel, IonMenu, IonContent, IonList, IonItem } from '@ionic/react'
import { shareOutline, refreshCircleOutline, chatboxEllipsesOutline, linkOutline } from 'ionicons/icons'
import { menuController } from '@ionic/core/components'
import Share from './Buttons/share'
import ScreenLock from './Switches/screenLock'
import Practice from './Switches/practice'

import { getMenuID } from '../../../../redux/selectors/ionic'
import { reset } from '../../../../redux/actions'
import { tutorial } from '../../../../utils/links'
import { tabs } from '../../../../utils/ionic/tabs'

const Menu = ({ reset, menuID }) => {
	const history = useHistory()
	const [shareContent, setShareContent] = useState({ text: `Share Rhythm`, logo: shareOutline })

	const closeMenu = async () => {
		await menuController.enable(true, menuID)
		await menuController.close()
	}

	const resetTap = () => {
		reset()
		history.push(get(tabs, [`practice`, `href`]))
		closeMenu()
	}

	const feedbackTap = () => {
		history.push(get(tabs, [`feedback`, `href`]))
		closeMenu()
	}

	const resetMenu = () => {
		setShareContent({ text: `Share Rhythm`, logo: shareOutline })
	}

	return (
		<>
			<IonMenu onIonDidClose={resetMenu} contentId={menuID} side="end" type="overlay">
				<IonContent />
				<IonFooter>
					<IonList>
						<Practice />
						<ScreenLock />
						<IonItem onClick={tutorial} button>
							<IonIcon slot="end" icon={linkOutline} />
							<IonLabel>{'Tutorial'}</IonLabel>
						</IonItem>
						<IonItem onClick={feedbackTap}>
							<IonIcon slot="end" icon={chatboxEllipsesOutline} />
							<IonLabel>{get(tabs, ['feedback', 'title'])}</IonLabel>
						</IonItem>
						<Share shareContent={shareContent} setShareContent={setShareContent} />
						<IonItem onClick={resetTap} button>
							<IonIcon slot="end" icon={refreshCircleOutline} />
							<IonLabel>{`Reset`}</IonLabel>
						</IonItem>
					</IonList>
				</IonFooter>
			</IonMenu>
		</>
	)
}

const mapStateToProps = (state) => {
	const menuID = getMenuID(state)

	return {
		menuID,
	}
}

export default connect(mapStateToProps, { reset })(Menu)
