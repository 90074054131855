import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Redirect, useHistory } from 'react-router-dom'
import get from 'lodash/get'

import { useIonToast } from '@ionic/react'
import { StatusBar, Style } from '@capacitor/status-bar'

import RhythmbotPage from '../../pages/RhythmBot'
import MetronomePage from '../../components/IonicUI/Pages/Metronome'
import GroovePage from '../../components/IonicUI/Pages/Groove'
import FeedbackPage from '../../components/IonicUI/Pages/FeedbackPage'
import PlaceholderPage from '../../components/IonicUI/PlaceHolder/page'

import { tabs } from '../../utils/ionic/tabs'
import { errorOccurred } from '../../utils/functions'
import { errorMessage } from '../../utils/ionic/toast'
import useGlobalRefs from '../../hooks/useGlobalRefs'

import { getUsingPlaceholders } from '../../redux/selectors/ionic'
import { getPlatform, getShowError } from '../../redux/selectors/rendering'
import { ionSetFiltersOpen } from '../../redux/ionicActions'
import { PlatformEnum } from '../../utils/constants/enum/platform'

const Router = ({ platform, usingPlaceholders, showError, ionSetFiltersOpen }) => {
	const refs = useGlobalRefs()

	const [ionicToast] = useIonToast()
	const history = useHistory()

	const navigateToFeedback = () => {
		ionSetFiltersOpen(false)
		history.push(get(tabs, [`feedback`, `href`]))
	}

	useEffect(() => {
		const setStatusBar = async (platform) => {
			const isIos = platform === PlatformEnum.IOS
			await StatusBar.setStyle({ style: isIos ? Style.Dark : Style.Light })
			if (isIos) {
				return
			}
			await StatusBar.setBackgroundColor({ color: `#008a8f` })
		}

		switch (platform) {
			case PlatformEnum.WEB:
				return
			case PlatformEnum.ANDROID:
			case PlatformEnum.IOS:
				setStatusBar(platform)
				break
			default:
		}
	}, [platform])

	useEffect(() => {
		if (!showError) return
		ionicToast(errorMessage({ navigateToFeedback }))
	}, [showError])

	/**
	 * @param {String} title
	 * @param {JSX} component
	 * @returns
	 */
	const getPlaceholderPage = ({ title, Component = null }) => {
		if (!Component) {
			return errorOccurred(`routes/Ionic/router.getPlaceholderPage - No Component to render`)
		}
		if (usingPlaceholders) {
			return <PlaceholderPage title={title} />
		}
		return <Component />
	}

	return (
		<>
			<Route exact path={`/`}>
				<Redirect
					push
					to={{
						pathname: get(tabs, [`practice`, `href`]),
						search: get(window, [`location`, `search`]),
					}}
				/>
			</Route>
			<Route exact path={`/language/:languageCode`}>
				<Redirect
					push
					to={{
						pathname: `${get(tabs, [`practice`, `href`])}/language/:languageCode`,
						search: get(window, [`location`, `search`]),
					}}
				/>
			</Route>
			<Route exact path={get(tabs, [`practice`, `href`])}>
				<RhythmbotPage refs={refs} />
			</Route>
			<Route path={`${get(tabs, [`practice`, `href`])}/language/:languageCode`} exact>
				<RhythmbotPage refs={refs} />
			</Route>
			<Route exact path={get(tabs, [`metronome`, `href`])}>
				{getPlaceholderPage({ title: get(tabs, [`metronome`, `title`]), Component: MetronomePage })}
			</Route>
			<Route exact path={get(tabs, [`groove`, `href`])}>
				{getPlaceholderPage({ title: get(tabs, [`groove`, `title`]), Component: GroovePage })}
			</Route>
			<Route exact path={get(tabs, [`feedback`, `href`])}>
				<FeedbackPage />
			</Route>
		</>
	)
}

const mapStateToProps = (state) => {
	const usingPlaceholders = getUsingPlaceholders(state)
	const showError = getShowError(state)
	const platform = getPlatform(state)
	return {
		usingPlaceholders,
		showError,
		platform,
	}
}

export default connect(mapStateToProps, { ionSetFiltersOpen })(Router)
