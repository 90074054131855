import React, { useEffect } from 'react'
import set from 'lodash/set'
import { useHistory } from 'react-router-dom'
import { App, URLOpenListenerEvent } from '@capacitor/app'

const AppUrlListener: React.FC<any> = () => {
	let history = useHistory()
	useEffect(() => {
		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			const slug = event.url.split('.app').pop()
			if (slug) {
				set(window, `appQueryString`, slug.substring(1))
				history.replace(slug, { update: true })
			}
			// If no match, do nothing - let regular routing
			// logic take over
		})
	}, [])

	return null
}

export default AppUrlListener
