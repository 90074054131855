import React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'

import Button from 'react-bootstrap/Button'

import { reset } from '../../redux/actions'
import { getPlaybackState } from '../../redux/selectors/playback'

const Reset = ({ reset, playback }) => (
	<Button onClick={reset} disabled={get(playback, [`playing`])} variant="primary" className="reset action-2">
		{`Reset`}
	</Button>
)

const mapStateToProps = (state) => {
	return {
		playback: getPlaybackState(state),
	}
}

export default connect(mapStateToProps, { reset })(Reset)
