import React from 'react'

import Button from 'react-bootstrap/Button'

import { tutorial } from '../../utils/links'

const Tutorial = () => (
	<Button onClick={tutorial} className={'tutorial action-3'}>
		{`Tutorial`}
	</Button>
)

export default Tutorial
