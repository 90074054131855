import React from 'react'
import { connect } from 'react-redux'

import { IonToggle, IonLabel, IonItem } from '@ionic/react'

import { setMode } from '../../../../../../../redux/actions'
import { getIsPlaying, getIsReadingMode, getMode } from '../../../../../../../redux/selectors/playback'

import ModeEnum from '../../../../../../../utils/constants/enum/mode'
import { errorOccurred } from '../../../../../../../utils/functions'

const Reading = ({ playing, mode, setMode, isReadingMode }) => {
	const getLabel = () => `Reading Mode`

	const toggle = () => {
		switch (mode) {
			case ModeEnum.LOOP:
				return setMode(ModeEnum.LOOPREADING)
			case ModeEnum.LOOPREADING:
				return setMode(ModeEnum.LOOP)
			case ModeEnum.TRADE:
				return setMode(ModeEnum.TRADEREADING)
			case ModeEnum.TRADEREADING:
				return setMode(ModeEnum.TRADE)
			case ModeEnum.CLICK:
				return setMode(ModeEnum.CLICKREADING)
			case ModeEnum.CLICKREADING:
				return setMode(ModeEnum.CLICK)
			default:
				errorOccurred(`ionicUI.Pages.Practice.Filters.Controls.Switches.Reading - Mode not compatible ${mode}`)
				break
		}
	}

	const getToggleContent = () => (
		<IonToggle
			checked={isReadingMode}
			onClick={(e) => {
				e.stopPropagation()
				toggle()
			}}
		/>
	)

	return (
		<IonItem disabled={playing}>
			<IonLabel>{getLabel()}</IonLabel>
			{getToggleContent()}
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const isReadingMode = getIsReadingMode(state)
	const mode = getMode(state)
	const playing = getIsPlaying(state)
	return {
		isReadingMode,
		mode,
		playing,
	}
}

export default connect(mapStateToProps, { setMode })(Reading)
