import get from 'lodash/get'
import store from './store'

import {
	SET_ACTX,
	SET_THEME,
	SET_SNARE,
	SET_KICK,
	SET_HIHAT,
	SET_RIDE,
	SET_METRONOME,
	SET_METRONOMEONE,
	SET_VOLUME,
	SET_SHOW_FILTERS,
	SET_SHOW_SETTINGS,
	SET_ANALYTICS_PROMPT,
	SET_DONATION_PROMPT,
	SET_LOGIN_OVERLAY,
	TOGGLE_SHOW_SETTINGS,
	TOGGLE_SHOW_FILTERS,
	TOGGLE_ANALYTICS_PROMPT,
	TOGGLE_DONATION_PROMPT,
	SET_RENDERED_ARRAY,
	SET_ISOLATED_ARRAY,
	SET_SHOW_GROOVE_MODAL,
	SET_SHOW_SWING,
	SET_MODAL_HH_ARRAY,
	SET_SELECTED,
	SET_ISOLATED,
	SET_HIGHLIGHTED,
	SET_BAR_STYLE,
	SET_PLAY_ENABLED,
	SET_SAVEABLE,
	SET_LOADABLE,
	SET_SHOW_LOAD_MODAL,
	SET_SHOW_FEEDBACK_MODAL,
	SET_SHOW_HELPER_MODAL,
	SET_SHOW_PRACTICE_MODAL,
	SET_SHOW_ERROR,
	SET_ERROR_TEXT,
	SET_BARS,
	SET_TIME,
	SET_TIME_NAME,
	SET_PLAYBACK_TIME,
	SET_RHYTHM_NAME,
	SET_RANDOM,
	SET_PLAY_THIS,
	SET_BPM,
	SET_MODE,
	SET_PLAYING,
	SET_USE_METRONOME,
	SET_USE_COUNT_IN,
	SET_USE_GHOSTS,
	SET_PLAYBACK_AS,
	SET_GROOVE_CYMBAL_PATTERN,
	SET_GROOVE_CYMBAL_SOUND,
	SET_GROOVE_GHOSTS,
	SET_GROOVE_BACKBEAT,
	SET_SWING,
	SET_CUSTOM,
	SET_CUSTOM_ARRAY,
	SET_SPACE,
	SET_SELECTED_BARS,
	SET_LEVEL,
	SET_LEVELS_ARRAY,
	SET_SHOW_SOUNDBANK_MODAL,
	SET_UPDATE_PROMPT,
	SET_FEATURES_LIST,
	TOGGLE_TRADE_DIRECTION,
	SET_TRADE_DIRECTION,
	SET_GROOVE_MIX,
	TOGGLE_RHYTHM_LOCK,
	TOGGLE_GROOVE_LOCK,
	SET_GROOVE_LOCK,
	SET_RHYTHM_LOCK,
	RESET,
	SET_TIME_SIGNATURE,
	SET_TIME_SIGNATURE_TOP,
	SET_TIME_SIGNATURE_BOTTOM,
	SET_MOBILE_TIME_SIGNATURE_CONTROLS,
	MANUAL_TOGGLE_SCREEN_LOCK,
	SET_SCREEN_LOCK,
	SET_PRO,
	LOGIN,
	SET_PRACTICE_MODE,
	SET_RHYTHM_TIMINGS,
	SET_RHYTHM_START_TIME,
	SET_USER_HITS,
	ADD_USER_HIT,
	ADD_USER_MISS,
	SET_ACCURACY,
	SET_LANGUAGE,
	SET_RENDERING_MODE,
	SET_TRANSLATION_SETTINGS,
	SET_TRANSLATION_MODE,
	SET_APPLICATION_TEXT,
	SET_SHOW_CLICK_MODAL,
	SET_CLICK_MODAL_HAS_OPENED,
	SET_MARK_ONE,
	SET_CLICK_RATE,
	SET_CLICK_OFFSET_AMOUNT,
	SET_CLICK_OFFSET_RATE,
	SWITCH_CLICK_OFFSET_RATE,
	SET_GAP_CLICK_MATCH_RHYTHM,
	SET_CLICK_MARK_ONE,
	SET_GAP_CLICK,
	SET_CLICK_RATE_AMOUNT,
	SET_CLICK_RATE_NOTE,
	SET_CLICK_RATE_CUSTOM,
	SET_EXACT_CLICK_RATE,
	SET_EXACT_CLICK_OFFSET,
	SET_EXACT_CLICK_GAP,
	RESET_CLICK,
	SWITCH_GAP_CLICK_OFF_FIRST,
	SET_MOBILE_CONTROLS_DOWN,
	SET_CLICK_VOLUME,
	RESET_PRESET,
	SET_PRESET,
	SET_PRESET_SHUFFLE_ALL,
	SET_PRESET_CONSTANTS,
	SET_PLATFORM,
	ADD_RHYTHM_HISTORY,
	CLEAR_RHYTHM_HISTORY,
	TOGGLE_PRACTICE_MODAL,
	SET_SCREEN_LOCK_OBJECT,
	SET_MOBILE_TIME_SIGNATURE_SEEN,
	RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_GROUPING,
	RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_START_POINTS,
	SET_SHOW_RV_PERMUTATIONS_MODAL,
} from './actionTypes'

import GrooveOptions from '../utils/constants/groove-elements'
import ClickRateEnum, { ClickRateImages } from '../utils/constants/enum/click'
import TimeEnum from '../utils/constants/enum/time'

const highestNoteRate = (time) => {
	switch (time) {
		case TimeEnum.STRAIGHT:
		case TimeEnum.EIGHTH:
			return 16
		case TimeEnum.TRIPLETS:
			return 12
		default:
			return null
	}
}

const playbackTimeAdjustments = ({ selected }) => {
	const s = store.getState()
	switch (selected) {
		//Each time change - decide what to render, set the time name and update the level
		case TimeEnum.STRAIGHT:
			store.dispatch(setShowSwing(true))
			store.dispatch(setModalHHArray(GrooveOptions.options[0].hh))
			if (!get(s, `playback.click.offset.modified`)) {
				store.dispatch(setClickOffsetRate({ rate: ClickRateEnum.SIXTEENTH }))
				if (get(s, `playback.click.offset.amount`) > 4) {
					store.dispatch(setClickOffsetAmount({ amount: 4 }))
				}
			}
			break
		case TimeEnum.EIGHTH:
			store.dispatch(setShowSwing(true))
			store.dispatch(setModalHHArray(GrooveOptions.options[1].hh))
			if (!get(s, `playback.click.offset.modified`)) {
				store.dispatch(setClickOffsetRate({ rate: ClickRateEnum.SIXTEENTH }))
				if (get(s, `playback.click.offset.amount`) > 4) {
					store.dispatch(setClickOffsetAmount({ amount: 4 }))
				}
			}
			break
		case TimeEnum.TRIPLETS:
			store.dispatch(setShowSwing(false))
			store.dispatch(setModalHHArray(GrooveOptions.options[2].hh))
			if (!get(s, `playback.click.offset.modified`)) {
				store.dispatch(setClickOffsetRate({ rate: ClickRateEnum.TRIPLETS }))
				if (get(s, `playback.click.offset.amount`) > 3) {
					store.dispatch(setClickOffsetAmount({ amount: 3 }))
				}
			}
			break
		default:
			store.dispatch(setShowSwing(false))
			store.dispatch(setModalHHArray(GrooveOptions.options[3].hh))
			if (!get(s, `playback.click.offset.modified`)) {
				store.dispatch(setClickOffsetRate({ rate: ClickRateEnum.SIXTEENTH }))
				if (get(s, `playback.click.offset.amount`) > 4) {
					store.dispatch(setClickOffsetAmount({ amount: 4 }))
				}
			}
			break
	}
}

//Audio
export const setAudioContext = (audioContext) => ({
	type: SET_ACTX,
	payload: {
		audioContext: audioContext,
	},
})
export const setSnare = (payload) => ({
	type: SET_SNARE,
	payload: {
		snare: payload,
	},
})
export const setKick = (payload) => ({
	type: SET_KICK,
	payload: {
		kick: payload,
	},
})
export const setHihat = (payload) => ({
	type: SET_HIHAT,
	payload: {
		hihat: payload,
	},
})
export const setRide = (payload) => ({
	type: SET_RIDE,
	payload: {
		ride: payload,
	},
})
export const setMetronome = (payload) => ({
	type: SET_METRONOME,
	payload: {
		metronome: payload,
	},
})
export const setMetronomeOne = (payload) => ({
	type: SET_METRONOMEONE,
	payload: {
		metronomeOne: payload,
	},
})
export const setVolume = ({ payload, direct = false }) => {
	// TODO this was used for overall volume, and not used anymore 06-03-22
	let volumeBoost = (payload / 50).toFixed(2)
	if (direct) {
		volumeBoost = payload
	}
	return {
		type: SET_VOLUME,
		payload: {
			volumeBoost: volumeBoost,
		},
	}
}
export const setMarkOne = (payload) => ({
	type: SET_MARK_ONE,
	payload: {
		markOne: payload,
	},
})
//Rendering
export const setPlatform = (payload) => ({
	type: SET_PLATFORM,
	payload: {
		platform: payload,
	},
})
export const setTheme = (payload) => ({
	type: SET_THEME,
	payload: {
		theme: payload,
	},
})
export const setShowSettings = (payload) => ({
	type: SET_SHOW_SETTINGS,
	payload: {
		showSettings: payload,
	},
})
export const setShowFilters = (payload) => ({
	type: SET_SHOW_FILTERS,
	payload: {
		showFilters: payload,
	},
})
export const setAnalyticsPrompt = (payload) => ({
	type: SET_ANALYTICS_PROMPT,
	payload: {
		analyticsPrompt: payload,
	},
})
export const setDonationPrompt = (payload) => ({
	type: SET_DONATION_PROMPT,
	payload: {
		donationPrompt: payload,
	},
})
export const toggleShowSettings = () => ({
	type: TOGGLE_SHOW_SETTINGS,
})
export const toggleShowFilters = () => ({
	type: TOGGLE_SHOW_FILTERS,
})
export const toggleAnalyticsPrompt = () => ({
	type: TOGGLE_ANALYTICS_PROMPT,
})
export const toggleDonationsPrompt = () => ({
	type: TOGGLE_DONATION_PROMPT,
})
export const setRenderedArray = (payload) => ({
	type: SET_RENDERED_ARRAY,
	payload: {
		renderedArray: payload,
	},
})

export const setShowGrooveModal = (payload) => ({
	type: SET_SHOW_GROOVE_MODAL,
	payload: {
		showGrooveModal: payload,
	},
})
export const setShowSwing = (payload) => ({
	type: SET_SHOW_SWING,
	payload: {
		showSwing: payload,
	},
})
export const setModalHHArray = (payload) => ({
	type: SET_MODAL_HH_ARRAY,
	payload: {
		modalHHArray: payload,
	},
})
export const setSelected = (payload) => ({
	type: SET_SELECTED,
	payload: {
		selected: payload,
	},
})

export const setHighlighted = (payload) => ({
	type: SET_HIGHLIGHTED,
	payload: {
		highlighted: payload,
	},
})
export const setBarSettings = (payload) => ({
	type: SET_BAR_STYLE,
	payload: {
		barSettings: payload,
	},
})

export const setSaveable = (payload) => ({
	type: SET_SAVEABLE,
	payload: {
		saveable: payload,
	},
})
export const setLoadable = (payload) => ({
	type: SET_LOADABLE,
	payload: {
		loadable: payload,
	},
})
export const setShowLoadModal = (payload) => ({
	type: SET_SHOW_LOAD_MODAL,
	payload: {
		showLoadModal: payload,
	},
})
export const setShowFeedbackModal = (payload) => ({
	type: SET_SHOW_FEEDBACK_MODAL,
	payload: {
		showFeedbackModal: payload,
	},
})
export const setShowPracticeModal = (payload) => ({
	type: SET_SHOW_PRACTICE_MODAL,
	payload: {
		showPracticeModal: payload,
	},
})
export const togglePracticeModal = () => ({
	type: TOGGLE_PRACTICE_MODAL,
})
export const setShowHelperModal = ({ show, title, text, footer }) => ({
	type: SET_SHOW_HELPER_MODAL,
	payload: {
		show,
		title,
		text,
		footer,
	},
})
export const setShowError = (payload) => ({
	type: SET_SHOW_ERROR,
	payload: {
		showError: payload,
	},
})
export const setErrorText = (payload) => ({
	type: SET_ERROR_TEXT,
	payload: {
		errorText: payload,
	},
})
export const setShowSoundbankModal = (payload) => ({
	type: SET_SHOW_SOUNDBANK_MODAL,
	payload: {
		showSoundbankModal: payload,
	},
})
export const setUpdatePrompt = (payload) => ({
	type: SET_UPDATE_PROMPT,
	payload: {
		updatePrompt: payload,
	},
})
export const setFeaturesList = (payload) => ({
	type: SET_FEATURES_LIST,
	payload: {
		featuresList: payload,
	},
})
export const setMobileTimeSignatureControls = (payload) => ({
	type: SET_MOBILE_TIME_SIGNATURE_CONTROLS,
	payload: {
		mobileTimeSignatureControls: payload,
	},
})
export const setMobileTimeSignatureSeen = (payload) => ({
	type: SET_MOBILE_TIME_SIGNATURE_SEEN,
	payload: {
		seen: payload,
	},
})
export const setMobileControlsDown = ({ down, manual = false, manualReset = false }) => ({
	type: SET_MOBILE_CONTROLS_DOWN,
	payload: {
		down,
		manual,
		manualReset,
	},
})
export const toggleScreenLock = () => ({
	type: MANUAL_TOGGLE_SCREEN_LOCK,
})

export const setScreenLock = (payload) => ({
	type: SET_SCREEN_LOCK,
	payload: {
		screenLock: payload,
	},
})

export const setScreenLockObject = (payload) => ({
	type: SET_SCREEN_LOCK_OBJECT,
	payload: {
		lockObject: payload,
	},
})

export const setLoginOverlay = (payload) => ({
	type: SET_LOGIN_OVERLAY,
	payload: {
		loginOverlay: payload,
	},
})

export const setLanguage = (language) => ({
	type: SET_LANGUAGE,
	payload: {
		language,
	},
})

export const setShowTranslationSettings = (showSettings) => ({
	type: SET_TRANSLATION_SETTINGS,
	payload: {
		showSettings,
	},
})

export const setTranslationMode = (mode) => ({
	type: SET_TRANSLATION_MODE,
	payload: {
		mode,
	},
})

export const setApplicationText = ({ filters, credits, canvas }) => ({
	type: SET_APPLICATION_TEXT,
	payload: {
		filters,
		canvas,
		credits,
	},
})

export const setRenderingMode = (renderingMode) => ({
	type: SET_RENDERING_MODE,
	payload: {
		renderingMode,
	},
})

export const setShowClickModal = (payload) => ({
	type: SET_SHOW_CLICK_MODAL,
	payload: {
		showClickModal: payload,
	},
})

export const setShowRVPermutationsModal = (show) => ({
	type: SET_SHOW_RV_PERMUTATIONS_MODAL,
	payload: {
		show,
	},
})

/**
 * Manually set the click modal `hasOpened` to true. Used when responding to
 * click query params
 */
export const setClickModalHasOpened = () => ({
	type: SET_CLICK_MODAL_HAS_OPENED,
})

//Playback
export const setBars = (payload) => ({
	type: SET_BARS,
	payload: {
		bars: payload,
	},
})
export const setTime = (payload) => ({
	type: SET_TIME,
	payload: {
		time: {
			options: payload,
			highestNoteRate: highestNoteRate(payload),
		},
	},
})
export const setTimeName = (payload) => ({
	type: SET_TIME_NAME,
	payload: {
		timeName: payload,
	},
})
export const setPlaybackTime = (payload) => {
	playbackTimeAdjustments({ selected: payload })
	return {
		type: SET_PLAYBACK_TIME,
		payload: {
			playbackTime: {
				options: payload,
				highestNoteRate: highestNoteRate(payload),
			},
		},
	}
}
export const setRhythmName = ({ name, preset = false }) => ({
	type: SET_RHYTHM_NAME,
	payload: {
		name,
		preset,
	},
})
export const setRandom = (payload) => ({
	type: SET_RANDOM,
	payload: {
		random: payload,
	},
})
export const setPlayThis = (payload) => ({
	type: SET_PLAY_THIS,
	payload: {
		playThis: payload,
	},
})
export const setBpm = (payload) => ({
	type: SET_BPM,
	payload: {
		bpm: payload,
	},
})
export const setMode = (payload) => ({
	type: SET_MODE,
	payload: {
		mode: payload,
	},
})
export const setPlaying = (payload) => ({
	type: SET_PLAYING,
	payload: {
		playing: payload,
	},
})
export const setUseMetronome = (payload) => ({
	type: SET_USE_METRONOME,
	payload: {
		useMetronome: payload,
	},
})
export const setUseCountIn = (payload) => ({
	type: SET_USE_COUNT_IN,
	payload: {
		useCountIn: payload,
	},
})

export const setUseGhosts = (payload) => ({
	type: SET_USE_GHOSTS,
	payload: {
		useGhosts: payload,
	},
})
export const setPlaybackAs = (payload) => ({
	type: SET_PLAYBACK_AS,
	payload: {
		playbackAs: payload,
	},
})
export const setGrooveCymbalPattern = (payload) => ({
	type: SET_GROOVE_CYMBAL_PATTERN,
	payload: {
		pattern: payload,
	},
})
export const setGrooveCymbalSound = (payload) => ({
	type: SET_GROOVE_CYMBAL_SOUND,
	payload: {
		sound: payload,
	},
})
export const setGrooveGhosts = (payload) => ({
	type: SET_GROOVE_GHOSTS,
	payload: {
		ghosts: payload,
	},
})
export const setGrooveBackbeat = (payload) => ({
	type: SET_GROOVE_BACKBEAT,
	payload: {
		backbeat: payload,
	},
})
export const setGrooveMix = (payload) => ({
	type: SET_GROOVE_MIX,
	payload: {
		mix: payload,
	},
})
export const setSwing = (payload) => ({
	type: SET_SWING,
	payload: {
		swing: payload,
	},
})
export const setCustom = (payload) => ({
	type: SET_CUSTOM,
	payload: {
		custom: payload,
	},
})
export const setCustomArray = (payload) => ({
	type: SET_CUSTOM_ARRAY,
	payload: {
		customArray: payload,
	},
})
export const setSpace = (payload) => ({
	type: SET_SPACE,
	payload: {
		space: payload,
	},
})
export const setSelectedBars = (payload) => ({
	type: SET_SELECTED_BARS,
	payload: {
		selectedBars: payload,
	},
})
export const setIsolatedArray = (payload) => ({
	type: SET_ISOLATED_ARRAY,
	payload: {
		isolatedArray: payload,
	},
})
export const setIsolated = (payload) => ({
	type: SET_ISOLATED,
	payload: {
		isolated: payload,
	},
})
export const setPlayEnabled = (payload) => ({
	type: SET_PLAY_ENABLED,
	payload: {
		playEnabled: payload,
	},
})
export const setLevel = (payload) => ({
	type: SET_LEVEL,
	payload: {
		level: payload,
	},
})
export const setLevelsArray = (payload) => ({
	type: SET_LEVELS_ARRAY,
	payload: {
		levelsArray: payload,
	},
})
export const toggleTradeDirection = () => ({
	type: TOGGLE_TRADE_DIRECTION,
})
export const setTradeDirection = (payload) => ({
	type: SET_TRADE_DIRECTION,
	payload: {
		tradeDirection: payload,
	},
})
export const setRhythmLock = (payload) => ({
	type: SET_RHYTHM_LOCK,
	payload: {
		rhythmLock: payload,
	},
})
export const toggleRhythmLock = () => ({
	type: TOGGLE_RHYTHM_LOCK,
})
export const setGrooveLock = (payload) => ({
	type: SET_GROOVE_LOCK,
	payload: {
		grooveLock: payload,
	},
})
export const toggleGrooveLock = () => ({
	type: TOGGLE_GROOVE_LOCK,
})
export const setTimeSignature = (payload) => {
	return {
		type: SET_TIME_SIGNATURE,
		payload: {
			timeSignature: {
				top: parseInt(payload.split(':')[0]),
				bottom: parseInt(payload.split(':')[1]),
			},
		},
	}
}
export const setTimeSignatureTop = (payload) => ({
	type: SET_TIME_SIGNATURE_TOP,
	payload: {
		top: payload,
	},
})
export const setTimeSignatureBottom = (payload) => ({
	type: SET_TIME_SIGNATURE_BOTTOM,
	payload: {
		bottom: payload,
	},
})
export const setPractice = (payload) => ({
	type: SET_PRACTICE_MODE,
	payload: {
		practice: payload,
	},
})
export const setRhythmTimings = ({ straight = false, triplets = false }) => ({
	type: SET_RHYTHM_TIMINGS,
	payload: {
		straight,
		triplets,
	},
})
export const setRhythmStartTime = ({ startTime }) => ({
	type: SET_RHYTHM_START_TIME,
	payload: {
		startTime,
	},
})
export const setAccuracy = (payload) => ({
	type: SET_ACCURACY,
	payload: {
		accuracy: payload,
	},
})
/**
 *
 * @param {Number} time the timestamp of the hit
 * @param {Number} position it's position in the grid if known
 * @param {Number} timingIndex which hit is it (e.g. timingIndex = 0 means this
 * is the first hit in the rhythm)
 * @param {Object} stats provided if the note played does not adhere to the
 * current grid.
 * @returns
 */
export const userHit = ({ time, position = false, timingIndex = false, stats = false }) => ({
	type: ADD_USER_HIT,
	payload: {
		time,
		position,
		timingIndex,
		stats,
	},
})
/**
 *
 * @param {Number} time the timestamp of the miss
 * @param {Number} position it's position in the grid if known
 * @param {Number} timingIndex which miss is it (e.g. timingIndex = 0 means this
 * is the first miss in the rhythm)
 * @param {Object} stats provided if the note played does not adhere to the
 * current grid.
 * @returns
 */
export const userMiss = ({ time, position = false, timingIndex = false, stats = false }) => ({
	type: ADD_USER_MISS,
	payload: {
		time,
		position,
		timingIndex,
		stats,
	},
})
export const setUserHits = ({ hits, misses }) => ({
	type: SET_USER_HITS,
	payload: {
		hits,
		misses,
	},
})
export const addRhythmHistory = ({ practice, appState }) => ({
	type: ADD_RHYTHM_HISTORY,
	payload: {
		practice,
		appState,
	},
})
export const clearRhythmHistory = () => ({
	type: CLEAR_RHYTHM_HISTORY,
})
export const resetClick = () => ({
	type: RESET_CLICK,
})
export const setClickVolume = ({ volume }) => ({
	type: SET_CLICK_VOLUME,
	payload: {
		volume,
	},
})

/**
 * Set the entire click rate object - used in query params
 * @param {Object} clickRateObj the click rate object
 */
export const setExactClickRate = ({ clickRateObj }) => ({
	type: SET_EXACT_CLICK_RATE,
	payload: {
		clickRateObj,
	},
})

export const setClickrate = ({ imageKey }) => ({
	type: SET_CLICK_RATE,
	payload: {
		selectedImage: Number(imageKey),
		newRate: get(ClickRateImages, `${imageKey}`),
		custom: !get(ClickRateImages, `${imageKey}.auto`, false),
	},
})
export const setClickRateCustom = ({ bool }) => ({
	type: SET_CLICK_RATE_CUSTOM,
	payload: {
		bool,
	},
})
export const setClickRateAmount = ({ value }) => ({
	type: SET_CLICK_RATE_AMOUNT,
	payload: {
		value,
	},
})
export const setClickRateNote = ({ value }) => ({
	type: SET_CLICK_RATE_NOTE,
	payload: {
		value,
	},
})

/**
 * Set the entire click offset object - used in query params
 * @param {Object} clickOffsetObj the click rate object
 */
export const setExactClickOffset = ({ clickOffsetObj }) => ({
	type: SET_EXACT_CLICK_OFFSET,
	payload: {
		clickOffsetObj,
	},
})

export const setClickOffsetAmount = ({ amount, manual = false }) => ({
	type: SET_CLICK_OFFSET_AMOUNT,
	payload: {
		amount,
		manual,
	},
})
export const setClickOffsetRate = ({ rate }) => ({
	type: SET_CLICK_OFFSET_RATE,
	payload: {
		rate,
	},
})
export const switchClickOffsetRate = () => ({
	type: SWITCH_CLICK_OFFSET_RATE,
})
export const setClickMatchRhythm = (bool) => ({
	type: SET_GAP_CLICK_MATCH_RHYTHM,
	payload: {
		bool,
	},
})
export const setClickMarkOne = (bool) => ({
	type: SET_CLICK_MARK_ONE,
	payload: {
		bool,
	},
})

/**
 * Set the entire click gap object - used in query params
 * @param {Object} clickGapObj the click rate object
 */
export const setExactClickGap = ({ clickGapObj }) => ({
	type: SET_EXACT_CLICK_GAP,
	payload: {
		clickGapObj,
	},
})

export const setGapClickOn = ({ amount, firstChange = false }) => {
	const turnOff = amount === 0 && (get(store.getState(), 'playback.click.gap.off') === 0 || get(store.getState(), 'playback.click.gap.on') === false)
	if (turnOff) {
		return {
			type: SET_GAP_CLICK,
			payload: {
				on: false,
				off: false,
				touched: false,
			},
		}
	}
	if (!firstChange) {
		return {
			type: SET_GAP_CLICK,
			payload: {
				on: amount,
			},
		}
	}
	return {
		type: SET_GAP_CLICK,
		payload: {
			on: amount,
			off: amount,
			touched: true,
		},
	}
}

export const setGapClickOff = ({ amount, firstChange = false }) => {
	const turnOff = amount === 0 && (get(store.getState(), 'playback.click.gap.on') === 0 || get(store.getState(), 'playback.click.gap.on') === false)
	if (turnOff) {
		return {
			type: SET_GAP_CLICK,
			payload: {
				on: false,
				off: false,
				touched: false,
			},
		}
	}
	if (!firstChange) {
		return {
			type: SET_GAP_CLICK,
			payload: {
				off: amount,
			},
		}
	}
	return {
		type: SET_GAP_CLICK,
		payload: {
			on: amount,
			off: amount,
			touched: true,
		},
	}
}
export const switchGapClickOffFirst = () => {
	return {
		type: SWITCH_GAP_CLICK_OFF_FIRST,
	}
}

export const resetPreset = () => {
	return {
		type: RESET_PRESET,
	}
}

export const setPreset = ({ on }) => {
	return {
		type: SET_PRESET,
		payload: {
			on,
		},
	}
}

export const setPresetShuffleAll = ({ on, params }) => {
	return {
		type: SET_PRESET_SHUFFLE_ALL,
		payload: {
			on,
			params,
		},
	}
}

export const setPresetConstants = ({ constants }) => {
	return {
		type: SET_PRESET_CONSTANTS,
		payload: {
			constants,
		},
	}
}

// Rhythmic vocabulary permutations
export const rhythmicVocabPermutationsSetGrouping = (groupingIndex) => {
	return {
		type: RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_GROUPING,
		payload: {
			groupingIndex,
		},
	}
}

export const rhythmicVocabPermutationsSetStartPoints = (startingPoints) => {
	return {
		type: RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_START_POINTS,
		payload: {
			startingPoints,
		},
	}
}

//User
export const setPro = (payload) => ({
	type: SET_PRO,
	payload: {
		pro: payload,
	},
})
export const login = ({ email, password }) => {
	return {
		type: LOGIN,
		payload: {
			email,
			password,
		},
	}
}

//Reset
export const reset = () => ({
	type: RESET,
})
