import get from 'lodash/get'
import toLower from 'lodash/toLower'

import { Sounds, Cymbal } from '../../utils/constants/enum/sounds'
import Modes from '../../utils/constants/enum/mode'
import Time from '../../utils/constants/enum/time'
import ClickRate from '../../utils/constants/enum/click'
import RenderingModes from '../../utils/constants/enum/renderingMode'
import GrooveOptions from '../../utils/constants/groove-elements'
import { FeaturesListEnum } from '../../utils/constants/enum/featuresList'
import { PlatformEnum } from '../../utils/constants/enum/platform'
import { ThemeEnum } from '../../utils/constants/enum/theme'
import { Capacitor } from '@capacitor/core'

const audioConstructor = window.AudioContext || window.webkitAudioContext

const initialisePlatform = () => {
	const cordovaDevice = get(window, 'device', false)
	const capacitorDevice = Capacitor.isNativePlatform()
	if (!cordovaDevice && !capacitorDevice) {
		return PlatformEnum.WEB
	}
	if (capacitorDevice) {
		switch (Capacitor.getPlatform()) {
			case 'ios':
				return PlatformEnum.IOS
			case 'android':
				return PlatformEnum.ANDROID
			default:
				return
		}
	}
	switch (toLower(get(cordovaDevice, 'platform'))) {
		case 'ios':
			return PlatformEnum.IOS
		case 'android':
			return PlatformEnum.ANDROID
		default:
			return
	}
}

export const initialText = {
	filters: {
		mode: {
			title: `Mode`,
			once: `Once`,
			standard: `Standard`,
			reading: `Reading`,
			loop: `Loop`,
			trade: {
				title: `Trade`,
				standard: {
					desktop: {
						you: `You first, click to switch order`,
						rb: `RhythmBot first, click to switch order`,
					},
					mobile: {
						you: `You first, tap to switch order`,
						rb: `RhythmBot first, tap to switch order`,
					},
				},
				reading: {
					you: `You first, infinite new rhythms`,
					rb: `RhythmBot first, infinite new rhythms`,
				},
			},
			click: {
				title: `Click`,
				subtitle: `Looping Metronome`,
			},
			infinite: `Infinite new rhythms`,
		},
		subdivision: {
			title: `Subdivision`,
			straight: `Straight`,
			triplets: `Triplets`,
			mixed: `Mixed`,
			sixteenth: `16th`,
			eighth: `8th`,
		},
		playback: {
			title: `Playback`,
			snare: `Snare`,
			kick: `Kick`,
			groove: {
				title: `Groove`,
				subtitle: {
					desktop: {
						full: `Click to edit cymbal, backbeat & swing`,
						short: `Click to edit cymbal & backbeat`,
					},
					mobile: {
						full: `Tap to edit cymbal, backbeat & swing`,
						short: `Tap to edit cymbal & backbeat`,
					},
				},
			},
		},
		bars: `Bars`,
		clear: `Clear`,
		random: `Random Rhythm`,
		presets: {
			title: `Presets`,
			subtitle: {
				sixteenth: `From JPBouvetMethod.com's Big 10`,
				triplets: `From JPBouvetMethod.com's Big 8`,
			},
			threes: `Groups of 3`,
			fives: `Groups of 5`,
		},
		complexity: {
			title: `Complexity`,
			custom: `Custom`,
			normal: `Normal`,
			moreRests: `More Rests`,
		},
		controls: {
			bpm: `Bpm`,
			count: `Count In`,
			ghost: `Ghost Notes`,
			metronome: `Metronome`,
		},
	},
	canvas: {
		shuffle: `Shuffle`,
		rhythmLock: `Rhythm Lock`,
		isolate: `Isolate`,
		unisolate: `Un-isolate`,
		grooveLock: `Groove Lock`,
		clear: `Clear Selection`,
	},
	credits: 'Created by Jamie Howard and JP Bouvet',
}

export const initRendering = {
	platform: initialisePlatform(),
	text: {
		filters: get(initialText, `filters`),
		canvas: get(initialText, `canvas`),
		credits: get(initialText, `credits`),
	},
	mode: RenderingModes.NORMAL,
	theme: ThemeEnum.LIGHT,
	showSettings: false,
	showFilters: false,
	analyticsPrompt: false,
	donationPrompt: false,
	renderedArray: false,
	loginOverlay: false,
	signUpOverlay: false,
	showSwing: true,
	modalHHArray: GrooveOptions.options[0].hh,
	rhythmName: {
		name: false,
		preset: false,
	},
	timeName: '16th Note',
	selected: false,
	highlighted: 0,
	barSettings: {
		path: 'straight',
		style: 'straight',
	},
	saveable: [true, 'Save'],
	loadable: false,
	showModals: {
		practice: false,
		groove: false,
		load: false,
		feedback: false,
		click: {
			show: false,
			hasOpened: false,
		},
		helper: {
			show: false,
			title: ``,
			text: ``,
			footer: null,
		},
		rhythmicVocabularyPermutations: false,
	},
	showError: false,
	errorText: '',
	showSoundbankModal: false,
	updatePrompt: false,
	featuresList: FeaturesListEnum.OFF,
	mobileControls: {
		timeSignature: {
			show: false,
			seen: false,
		},
		down: {
			value: false,
			manual: false,
		},
	},
	screenLock: {
		lockObject: null,
		state: false,
		forced: false,
	},
}

export const initAudio = {
	audioConstructor: window.AudioContext || window.webkitAudioContext,
	audioContext: new audioConstructor(),
	snare: null,
	kick: null,
	hihat: null,
	ride: null,
	metronome: null,
	metronomeOne: null,
	markOne: null,
	volumeBoost: 1.0,
}

export const initPlayback = {
	bars: 2,
	time: {
		options: Time.STRAIGHT,
		highestNoteRate: Time.STRAIGHT,
	},
	playbackTime: {
		options: null,
		highestNoteRate: null,
	},
	timeSignature: {
		top: 4,
		bottom: 4,
		limits: {
			upper: 7,
			lower: 2,
		},
	},
	preset: {
		on: false,
		constants: {},
		shuffleAll: {
			on: false,
			params: {},
		},
	},
	random: false,
	playThis: [],
	bpm: 85,
	mode: Modes.ONCE,
	playing: false,
	click: {
		volume: 0.8,
		on: true,
		countIn: true,
		rate: {
			custom: false,
			selectedImage: 1,
			amount: 3,
			time: ClickRate.SIXTEENTH,
			markTheOne: true,
		},
		offset: {
			amount: 0,
			rate: ClickRate.SIXTEENTH,
			modified: false,
		},
		gap: {
			on: false,
			off: false,
			matchRhythmLength: false,
			startOff: false,
			controlsTouched: false,
		},
	},
	useGhosts: true,
	playbackAs: Sounds.SNARE,
	groove: {
		cymbal: {
			pattern: 2,
			sound: Cymbal.HH,
			overTheBarLine: false,
		},
		backbeat: 0,
		mix: 0,
		lock: true,
	},
	swing: 0,
	custom: false,
	customArray: [],
	space: 0,
	selectedBars: [],
	isolated: false,
	playEnabled: true,
	isolatedArray: false,
	level: 5,
	levelsArray: false,
	tradeDirection: false,
	rhythmLock: false,
	practice: {
		// TODO add enabled here if restricting feature to app version only
		// enabled: false,
		on: false,
		rhythm: {
			start: 0,
			loopStart: 0,
			// ms timings of all the notes in the rhythm
			timings: {
				straight: [],
				triplets: [],
				tap: {
					straight: [],
					triplets: [],
				},
				click: {
					straight: [],
					triplets: [],
				},
			},
			hitsPerRhythm: 0,
		},
		user: {
			hits: [],
			misses: [],
			played: {
				// positions in the rhythm timings hit by the user (e.g.
				// timingPositionHit = 4, they hit the fourth hit in the rhythm)
				timingPositionHits: [],
				// positions played by the user (e.g. 0 = first note)
				positions: [],
				// ms timings of all the notes played by the use
				timings: [],
			},
			accuracy: 0,
		},
		// When in loop or reading mode, history contains the stats for
		// previous rhythms (Rhythm timings, stats, images, ones and zeros,
		// crotchet time) - Anything needed to populate the PracticeModal
		// Spec of history entry:
		// practice: {
		// 	timings: get(practiceState, 'timings'),
		// 	stats: get(practiceState, 'stats'),
		// },
		// appState: {
		// 	imageKeys: renderedArrayState,
		// 	beatsPerBar: get(renderedArrayState, 'length') / get(playback, 'bars'),
		// 	onesAndZeros: playThisState,
		// 	subdivisionBarDuration,
		// 	time: get(playback, 'playbackTime.options'),
		// 	timeSignature: get(playback, 'timeSignature'),
		// 	crotchetTime,
		// 	barSettings: get(rendering, `barSettings`),
		// },
		history: [],
	},
	rhythmicVocabularyPermutations: {
		groupingIndex: 0,
		startingPoints: [[0]], // Indexes within each bar that the grouping should start
	},
}

export const initIonic = {
	menuID: 'rhythmbotMenu',
	pages: {
		usePlaceholders: false,
		practice: {
			filters: {
				open: false,
				presets: {
					open: false,
				},
				complexity: {
					open: false,
					custom: {
						open: false,
					},
				},
				timeSignature: {
					open: false,
				},
			},
		},
	},
}

export const initUser = {
	name: {
		first: 'Rhythm',
		last: 'Bot',
	},
	email: '',
	loggedIn: false,
	pro: false,
}
