import React from 'react'
import { connect } from 'react-redux'

import { IonList } from '@ionic/react'

import CountIn from './Controls/Switches/CountIn'
import GhostNotes from './Controls/Switches/GhostNotes'
import Click from './Controls/Switches/Click'
import Bars from './Controls/Pickers/Bars'
import Playback from './Controls/Pickers/Playback'
import Bpm from './Controls/Pickers/Bpm'
import Swing from './Controls/Pickers/Swing'
import Mode from './Controls/Pickers/Mode'
import Reading from './Controls/Switches/Reading'
import Subdivision from './Controls/Pickers/Subdivision'
import Presets from './Controls/Items/presets'
import Complexity from './Controls/Items/complexity'
import TimeSignature from './Controls/Items/timeSignature'

import ModeEnum from '../../../../../utils/constants/enum/mode'
import { getMode } from '../../../../../redux/selectors/playback'

const FiltersList = ({ dismiss, updateLevel, turnOnGrooveMode, render, mode }) => {
	const readingToggle = () => {
		if (mode === ModeEnum.ONCE) {
			return
		}
		return <Reading />
	}
	return (
		<IonList>
			<Mode />
			{readingToggle()}
			<Playback dismiss={dismiss} turnOnGrooveMode={turnOnGrooveMode} />
			<Subdivision updateLevel={updateLevel} turnOnGrooveMode={turnOnGrooveMode} />
			<Bars />
			<TimeSignature />
			<Bpm />
			<Swing />
			<Click dismiss={dismiss} />
			<CountIn />
			<GhostNotes />
			<Presets />
			<Complexity />
		</IonList>
	)
}

const mapStateToProps = (state) => {
	const mode = getMode(state)
	return {
		mode,
	}
}

export default connect(mapStateToProps, {})(FiltersList)
