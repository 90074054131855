/**
 * Set document style variables for use in sass to style the accuracy button
 */
export const playalongHitsStyler = ({ reset = false, accuracy }) => {
	if (reset) {
		document.documentElement.style.setProperty('--accuracy-teal', `0%`)
		return
	}
	const teal = `${Math.floor(accuracy)}%`
	document.documentElement.style.setProperty('--accuracy-teal', `${teal}`)
}
