import { createSelector } from 'reselect'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import toLower from 'lodash/toLower'

import RenderingMode from '../../utils/constants/enum/renderingMode'
import { getPlatformName, PlatformEnum } from '../../utils/constants/enum/platform'
import { ThemeEnum, themeName } from '../../utils/constants/enum/theme'

export const getRenderingState = (store) => store.rendering

export const getRenderingMode = createSelector(getRenderingState, (rendering) => get(rendering, `mode`))
export const getBarSettings = createSelector(getRenderingState, (rendering) => get(rendering, `barSettings`))
export const getHighlighted = createSelector(getRenderingState, (rendering) => get(rendering, `highlighted`))
export const getIsSelected = createSelector(getRenderingState, (rendering) => get(rendering, `selected`))

export const getRenderedArray = createSelector(getRenderingState, (rendering) => get(rendering, `renderedArray`))
export const getIsRenderedArrayEmpty = createSelector(getRenderedArray, (renderedArray) => isEmpty(renderedArray))

export const getIsApp = createSelector(getRenderingState, (rendering) => get(rendering, 'platform') !== PlatformEnum.WEB)

export const getTheme = createSelector(getRenderingState, (rendering) => get(rendering, 'theme'))
export const getThemeName = createSelector(getTheme, (theme) => themeName(theme))
export const getIsDarkTheme = createSelector(getRenderingState, (rendering) => get(rendering, 'theme') === ThemeEnum.DARK || includes(get(rendering, 'theme'), 'd'))

export const getHelperModalContent = createSelector(getRenderingState, (rendering) => {
	return {
		title: get(rendering, 'showModals.helper.title'),
		text: get(rendering, 'showModals.helper.text'),
		footer: get(rendering, 'showModals.helper.footer'),
	}
})

export const getPlatform = createSelector(getRenderingState, (rendering) => get(rendering, 'platform'))
export const getPlatformClass = createSelector(getPlatform, (platform) => toLower(getPlatformName(platform)))

export const getShowError = createSelector(getRenderingState, (rendering) => get(rendering, 'showError', ``))
export const getError = createSelector(getRenderingState, (rendering) => get(rendering, 'errorText', ``))

export const getScreenLock = createSelector(getRenderingState, (rendering) => get(rendering, 'screenLock'))
export const getShowSwing = createSelector(getRenderingState, (rendering) => get(rendering, 'showSwing'))
export const getTimeName = createSelector(getRenderingState, (rendering) => get(rendering, 'timeName'))
export const getModalHHArray = createSelector(getRenderingState, (rendering) => get(rendering, 'modalHHArray'))

export const getShowingFilters = createSelector(getRenderingState, (rendering) => get(rendering, 'showFilters'))
export const getShowingSettings = createSelector(getRenderingState, (rendering) => get(rendering, 'showSettings'))

export const getMobileControls = createSelector(getRenderingState, (rendering) => get(rendering, 'mobileControls'))
export const getSeenMobileTimeSignature = createSelector(getMobileControls, (mobileControls) => get(mobileControls, [`timeSignature`, `seen`]))

export const getIsRhythmicVocabularyPermutations = createSelector(getRenderingMode, (mode) => mode === RenderingMode.RHYTHMIC_VOCABULARY_PERMUTATIONS.STRAIGHT)
