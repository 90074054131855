import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { QueryParamProvider } from 'use-query-params'

import { IonApp, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import AppUrlListener from '../../UrlListener.tsx'
import Menu from './menu'
import Tabs from './tabs'
import { getPlatform } from '../../redux/selectors/rendering'
import unmute from '../../utils/unmute'
import { PlatformEnum } from '../../utils/constants/enum/platform'
import { getAudioContext } from '../../redux/selectors/audio'
setupIonicReact()

const IonicTheme = React.lazy(() => import('../../styles/loaders/ionic'))
const ThemeSelector = ({ children }) => (
	<>
		{/* <React.Suspense fallback={<IonLoading cssClass="my-custom-class" message={'Loading...'} duration={5000} />}> */}
		<React.Suspense fallback={<></>}>
			<IonicTheme />
			{children}
		</React.Suspense>
	</>
)

const Routes = ({ platform, audioContext }) => {
	/**
	 * Enable audio playback when ios device is on silent
	 */
	useEffect(() => {
		if (platform !== PlatformEnum.IOS) {
			return
		}
		unmute({ force: true, context: audioContext })
	}, [])

	return (
		<ThemeSelector>
			<IonApp>
				<IonReactRouter>
					<Menu />
					<AppUrlListener></AppUrlListener>
					<QueryParamProvider ReactRouterRoute={Route}>
						<Tabs />
					</QueryParamProvider>
				</IonReactRouter>
			</IonApp>
		</ThemeSelector>
	)
}

const mapStateToProps = (state) => {
	const platform = getPlatform(state)
	const audioContext = getAudioContext(state)
	return {
		platform,
		audioContext,
	}
}

export default connect(mapStateToProps, {})(Routes)
