import React from 'react'
import { map, get } from 'lodash'
import { connect } from 'react-redux'

import { IonList } from '@ionic/react'

import { getPresetConstants } from '../../../../../../redux/selectors/playback'
import Item from './item'

const Presets = ({ dismiss, presetConstants, changePreset }) => {
	return (
		<IonList>
			{map(presetConstants, (preset, idx) => (
				<Item dismiss={dismiss} key={idx} preset={preset} changePreset={changePreset} value={get(preset, `preset`)} />
			))}
		</IonList>
	)
}

const mapStateToProps = (state) => {
	const presetConstants = getPresetConstants(state)
	return {
		presetConstants,
	}
}

export default connect(mapStateToProps, {})(Presets)
