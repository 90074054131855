import React from 'react'
import { isEmpty, get } from 'lodash'
import { connect } from 'react-redux'

import { useSpring, animated } from '@react-spring/web'

import { IonIcon, IonButton, IonGrid, IonRow, IonCol } from '@ionic/react'
import { closeCircleOutline } from 'ionicons/icons'

import { getBars, getIsIsolated, getIsPlaying } from '../../../../../redux/selectors/playback'
import { getSelectedBars } from '../../../../../redux/selectors/playback'
import { getIsSelected } from '../../../../../redux/selectors/rendering'
import { setSelected, setSelectedBars } from '../../../../../redux/actions'
import useIsolate from '../../../../../hooks/useIsolate'

const Isolate = ({ isolated, playing, selectedBars, selected, setSelected, setSelectedBars, bars }) => {
	const { isolate } = useIsolate()
	const [springs, api] = useSpring(
		() => ({
			from: { opacity: 0, transform: 'translate(0px, 4px)' },
			config: { tension: 180, friction: 25 },
			reset: true,
		}),
		[playing, selected, selectedBars]
	)

	const isolateTap = () => isolate({ on: true, selectedBars })

	const clearBarSelection = () => {
		setSelected(false)
		setSelectedBars([])
		isolate({ on: false, selectedBars: false })
	}

	const getButton = (action, content, color = 'darkbutton') => {
		return (
			<IonButton color={color} onClick={action}>
				{content}
			</IonButton>
		)
	}

	const clearButton = () => getButton(clearBarSelection, isolated ? 'Reset' : <IonIcon icon={closeCircleOutline} />)

	const isolateButton = () => {
		if (isolated) {
			return null
		}
		return getButton(isolateTap, 'Isolate')
	}

	if (playing) {
		return null
	}
	if (!selected) {
		return null
	}
	if (isEmpty(selectedBars)) {
		return null
	}
	if (get(selectedBars, `length`) === bars) {
		return null
	}

	api.start({
		opacity: 1,
		transform: 'translate(0px, 0px)',
	})

	return (
		<IonGrid className="ion-isolate">
			<IonRow>
				<IonCol className="text-right ion-align-items-center ion-justify-content-center">
					<animated.div style={springs}>
						{clearButton()}
						{isolateButton()}
					</animated.div>
				</IonCol>
			</IonRow>
		</IonGrid>
	)
}

const mapStateToProps = (state) => {
	const isolated = getIsIsolated(state)
	const selected = getIsSelected(state)
	const playing = getIsPlaying(state)
	const selectedBars = getSelectedBars(state)
	const bars = getBars(state)

	return {
		playing,
		selectedBars,
		selected,
		isolated,
		bars,
	}
}

export default connect(mapStateToProps, { setSelected, setSelectedBars })(Isolate)
