import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { KeepAwake } from '@capacitor-community/keep-awake'

import Switch from '@mui/material/Switch'

import { setScreenLockObject, toggleScreenLock } from '../../redux/actions'
import { getIsApp, getRenderingState } from '../../redux/selectors/rendering'

import { requestWakeLock } from '../../utils/functions'

const ScreenLock = (props) => {
	const labelSize = get(props, 'labelSize', 'size-14')

	const toggle = async () => {
		const isLockOn = get(props, [`rendering`, `screenLock`, `state`])
		switch (get(props, `isApp`)) {
			case false:
				const wakeLockObject = get(props, [`rendering`, `screenLock`, `lockObject`])
				if (isLockOn) {
					wakeLockObject &&
						wakeLockObject.release().then(() => {
							get(props, `setScreenLockObject`)(null)
						})
					break
				}
				get(props, `setScreenLockObject`)(await requestWakeLock())
				break
			default:
				if (isLockOn) {
					await KeepAwake.allowSleep()
					break
				}
				await KeepAwake.keepAwake()
				break
		}
		get(props, `toggleScreenLock`)()
	}

	return (
		<>
			<span className={`text medium ${labelSize}`}>{`Screen Lock`}</span>
			<Switch size="small" checked={get(props, [`rendering`, `screenLock`, `state`])} onChange={toggle} name="theme" color="primary" />
		</>
	)
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const isApp = getIsApp(state)

	return {
		rendering,
		isApp,
	}
}

export default connect(mapStateToProps, { toggleScreenLock, setScreenLockObject })(ScreenLock)
