const MixedLevels = {
  levels: [
    {
      id: 1,
      arr: [1, 2, 3, 22],
    },
    {
      id: 2,
      arr: [4, 21],
    },
    {
      id: 3,
      arr: [5, 6, 7, 18],
    },
    {
      id: 4,
      arr: [11, 10, 9, 8, 20, 19],
    },
  ],
};

//Backup
// const MixedLevels = {
// 	levels: [
// 		{
// 			id: 1,
// 			arr: [1, 9, 16, 22],
// 		},
// 		{
// 			id: 2,
// 			arr: [1, 3, 9, 16, 21, 22],
// 		},
// 		{
// 			id: 3,
// 			arr: [1, 3, 9, 11, 13, 15, 16, 18, 21, 22],
// 		},
// 		{
// 			id: 4,
// 			arr: [1, 3, 5, 7, 8, 9, 11, 13, 14, 15, 16, 19, 20, 21, 22],
// 		},
// 	],
// };

export default MixedLevels;
