const Sounds = {
	SNARE: 0,
	KICK: 1,
	GROOVE: 2,
}

const getSoundName = (s) => {
	switch (s) {
		case Sounds.SNARE:
			return `Snare`
		case Sounds.KICK:
			return `Kick`
		case Sounds.GROOVE:
			return `Groove`
		default:
			return ``
	}
}
const Cymbal = {
	HH: 0,
	RIDE: 1,
}
const Ghosts = {
	FILL: 0,
	MATCH: 1,
}

export { getSoundName, Sounds, Cymbal, Ghosts }
