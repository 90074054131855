import React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'

import Button from 'react-bootstrap/Button'

import { getIsApp, getRenderingState, getThemeName } from '../../redux/selectors/rendering'
import { analyticsTitle, analyticsText, analyticsAccept, analyticsReject } from '../../utils/analytics/text.js'
import { analyticsResponse } from '../../utils/analytics'
import { prod } from '../../utils/functions'

const Analytics = ({ rendering, themeName, isApp }) => {
	const render = get(rendering, 'analyticsPrompt') && prod({ isApp })
	if (!render) {
		return null
	}
	return (
		<div className={'banner analytics flex ' + themeName}>
			<div className={'message ' + themeName}>
				<p className="text bold size-18">{analyticsTitle}</p>
				<p className="text medium size-15">{analyticsText}</p>
			</div>
			<div className="actions">
				<Button
					onClick={() => {
						analyticsResponse({ ack: false, isApp })
					}}
					className="reject action-2"
				>
					{analyticsReject}
				</Button>
				<Button
					onClick={() => {
						analyticsResponse({ ack: true, isApp })
					}}
					className="consent action"
				>
					{analyticsAccept}
				</Button>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const themeName = getThemeName(state)
	const isApp = getIsApp(state)

	return {
		rendering,
		themeName,
		isApp,
	}
}

export default connect(mapStateToProps, {})(Analytics)
