import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import get from 'lodash/get'

import { deviceDetect } from 'react-device-detect'

import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { Network } from '@capacitor/network'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import { getIsApp, getThemeName, getPlatform, getError } from '../../redux/selectors/rendering'

import { emailValidator } from '../../utils/validation/email'
import { getPlatformName } from '../../utils/constants/enum/platform'
import { localhost, prod } from '../../utils/functions'
import { authToken } from '../../utils/api/auth'

const FeedbackModal = (props) => {
	const [name, setName] = useState(``)
	const [email, setEmail] = useState(``)
	const [message, setMessage] = useState(``)
	const [nameError, setNameError] = useState(false)
	const [emailError, setEmailError] = useState(false)
	const [messageError, setMessageError] = useState(false)
	const [sendButton, setSendButton] = useState({})

	useEffect(() => {
		if (prod({ isApp: get(props, 'isApp', false) }) || !localhost()) {
			return
		}
		setName(`Rhythm Bot`)
		setEmail(`test@rhythmbot.app`)
		setMessage(`${getPlatformName(get(props, `platform`))} test`)
	}, [])

	/**
	 * Check the current network status and set modal status accordingly
	 */
	const networkChecks = async () => {
		if (!(await online())) {
			return setSendButton({
				status: 'No Internet',
				disabled: true,
			})
		}
		if (!(await enabled())) {
			return setSendButton({
				status: 'Disabled',
				disabled: true,
			})
		}
		return setSendButton({
			status: 'Send',
		})
	}
	Network.addListener('networkStatusChange', networkChecks)
	useEffect(() => networkChecks(), [get(window, `navigator.onLine`)])

	const online = async () => get(window, `navigator.onLine`, false) || get(await Network.getStatus(), `connected`, false)
	const enabled = async () => (await online()) && process.env.REACT_APP_FEEDBACK_API.includes('https')

	const className = `${get(props, 'device')} ${get(props, `themeName`, `light`)} feedback`

	/**
	 * Submit the feedback form
	 */
	const submit = async (e) => {
		e.preventDefault()
		if (!(await online())) {
			return setSendButton({
				status: 'No Internet',
				disabled: true,
			})
		}
		if (!(await enabled())) {
			return setSendButton({
				status: 'Disabled',
				disabled: true,
			})
		}
		const emailError = !emailValidator.test(email)
		const nameError = !get(name, 'length') > 0
		const messageError = !get(message, 'length') > 0
		const noError = !emailError && !nameError && !messageError
		if (noError) {
			setSendButton({
				status: '...',
				disabled: true,
			})

			let device = JSON.stringify(deviceDetect())
			if (Capacitor.isNativePlatform()) {
				device = JSON.stringify(await Device.getInfo())
			}

			const body = JSON.stringify({
				name,
				email,
				message,
				//Add device, OS and browser info
				device: `\n\tPlatform: ${getPlatformName(get(props, `platform`))}\n\t${device}\n`,
				error: props.errorText.length > 0 ? props.errorText : 'No error thrown',
				isProduction: prod({}),
			})
			let headers = new Headers()
			headers.append('Content-Type', 'application/json')
			headers.append('Authorization', `${authToken}`)

			let requestOptions = {
				method: 'POST',
				headers,
				body,
				redirect: 'follow',
			}

			fetch(process.env.REACT_APP_FEEDBACK_API, requestOptions)
				.then((response) => response.text())
				.then((result) => {
					console.log(result)
					setSendButton({
						status: 'Thank you!',
						disabled: true,
					})
				})
				.catch((error) => {
					console.error('Error Sending Feedback', error)
				})
		}
		setNameError(nameError)
		setEmailError(emailError)
		setMessageError(messageError)
	}

	return (
		<>
			<Modal animation={false} show={props.show} onHide={props.toggle} keyboard={false} dialogClassName={className}>
				<Modal.Header closeButton>
					<div className="header">
						<Modal.Title>{`Send Feedback`}</Modal.Title>
					</div>
				</Modal.Header>
				<form id="feedback" className="feedback-form" method="post">
					<Modal.Body className={className}>
						<div className="form-fields">
							<div className="field">
								<div className="text bold size-17">
									{`Name`}
									{nameError && <span className="text feedback-error light orange size-15"> enter your name</span>}
								</div>

								<input defaultValue={name} id="form-name" type="text" name="name" onChange={(e) => setName(e.target.value)} />
							</div>
							<div className="field">
								<div className="text bold size-17">
									{`Email`}
									{emailError && <span className="text feedback-error light orange size-15"> enter a valid email address</span>}
								</div>
								<input defaultValue={email} id="form-email" type="email" name="email" onChange={(e) => setEmail(e.target.value)} />
							</div>
							<div className="field">
								<div className="text bold size-17">
									{`Message`}
									{messageError && <span className="text feedback-error light orange size-15"> enter a message</span>}
								</div>
								<textarea defaultValue={message} id="form-message" name="message" cols="30" onChange={(e) => setMessage(e.target.value)}></textarea>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer className={className}>
						<div className="left"></div>
						<div className="right">
							<Button
								disabled={get(sendButton, 'disabled', false)}
								className={'send action-3'}
								onClick={(e) => {
									submit(e)
								}}
							>
								{get(sendButton, 'status', '')}
							</Button>
						</div>
					</Modal.Footer>
				</form>
			</Modal>
		</>
	)
}

const mapStateToProps = (state) => {
	const isApp = getIsApp(state)
	const errorText = getError(state)
	const platform = getPlatform(state)
	const themeName = getThemeName(state)

	return { errorText, platform, isApp, themeName }
}

export default connect(mapStateToProps)(FeedbackModal)
