//Constants for sounds made by each triplet note grouping
const TripSounds = {
	sounds: [
		{
			id: 1,
			mixedID: 1,
			arr: [0, 0, 0],
			arrMix: [2, 2, 2],
		},
		{
			id: 2,
			mixedID: 2,
			arr: [1, 0, 0],
			arrMix: [3, 2, 2],
		},
		{
			id: 3,
			mixedID: 17,
			arr: [1, 1, 1],
			arrMix: [3, 3, 3],
		},
		{
			id: 4,
			mixedID: 18,
			arr: [1, 0, 1],
			arrMix: [3, 2, 3],
		},
		{
			id: 5,
			mixedID: 19,
			arr: [1, 1, 0],
			arrMix: [3, 3, 2],
		},
		{
			id: 6,
			mixedID: 20,
			arr: [0, 1, 1],
			arrMix: [2, 3, 3],
		},
		{
			id: 7,
			mixedID: 21,
			arr: [0, 0, 1],
			arrMix: [2, 2, 3],
		},
		{
			id: 8,
			mixedID: 22,
			arr: [0, 1, 0],
			arrMix: [2, 3, 2],
		},
	],
}

//Backup
// const TripSounds = {
//     sounds: [
//         {
//             id: 1,
//             arr: [1, 0, 0],
//             arrMix: [3, 2, 2]
//         },
//         {
//             id: 2,
//             arr: [0, 1, 0],
//             arrMix: [2, 3, 2]
//         },
//         {
//             id: 3,
//             arr: [0, 0, 1],
//             arrMix: [2, 2, 3]
//         },
//         {
//             id: 4,
//             arr: [1, 1, 0],
//             arrMix: [3, 3, 2]
//         },
//         {
//             id: 5,
//             arr: [0, 1, 1],
//             arrMix: [2, 3, 3]
//         },
//         {
//             id: 6,
//             arr: [1, 0, 1],
//             arrMix: [3, 2, 3]
//         },
//         {
//             id: 7,
//             arr: [1, 1, 1],
//             arrMix: [3, 3, 3]
//         },
//         {
//             id: 8,
//             arr: [0, 0, 0],
//             arrMix: [2, 2, 2]
//         }
//     ]
// };

export default TripSounds
