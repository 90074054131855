import { createStore } from 'redux'
import rootReducer from './reducers'
import { composeWithDevTools } from 'redux-devtools-extension'

import { localhost } from '../utils/functions'

let store = createStore(rootReducer)
if (localhost()) {
	store = createStore(rootReducer, composeWithDevTools())
}

export default store
