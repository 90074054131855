import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

export const keys = [37, 39, 17, 18, 91, 93, 18, 16, 192, 90, 88, 67, 86, 66, 78, 77, 191]

// Tapping to play along on mobile triggers a click event
export const mobileKeys = [8]
let mobileKeyboardEvent = new Event('keydown')
mobileKeyboardEvent.keyCode = mobileKeys[0]
export { mobileKeyboardEvent }

// Tapping to play along on mobile triggers a click event
export const driftKeys = [9]
let driftEvent = new Event('keydown')
driftEvent.keyCode = driftKeys[0]
export { driftEvent }

// Tapping to play along on mobile triggers a click event
export const driftClickKeys = [81]
let driftClickEvent = new Event('keydown')
driftClickEvent.keyCode = driftClickKeys[0]
export { driftClickEvent }

const tapTimeAccuracies = 4
export const setTimeStamp = (n) => Number(n.toFixed(tapTimeAccuracies))

/**
 * closestHit is used to find the closest hit to the current time from a collection
 * @param {Array} hits array of hit objects
 * @param {Number} currentTime the current audio context time
 * @returns
 */
export const closestHit = (hits, currentTime) => {
	if (isEmpty(hits)) {
		return null
	}
	return hits.reduce((a, b) => {
		const aTimeStamp = get(a, `timeStamp`)
		const bTimeStamp = get(b, `timeStamp`)
		const aDiff = Math.abs(aTimeStamp - currentTime)
		const bDiff = Math.abs(bTimeStamp - currentTime)
		if (aDiff === bDiff) {
			// Choose largest vs smallest (> vs <)
			return aTimeStamp > bTimeStamp ? a : b
		}
		return bDiff < aDiff ? b : a
	})
}

/**
 * closestHit is used to find the closest hit to the current time from a collection
 * @param {Array} hits array of hit objects
 * @param {Object} pollingProgress
 * @returns
 */
export const closestHitPolling = (hits, currentBar, currentPercentage) => {
	if (isEmpty(hits)) {
		return null
	}
	// TODO handle odd times
	return hits
		.filter((hit) => hit.bar + 1 === currentBar)
		.reduce((prev, curr) => (Math.abs(curr.barPercentage - currentPercentage) < Math.abs(prev.barPercentage - currentPercentage) ? curr : prev))
}

export const practiceTapAddition = 0.0634
// export const practiceClickAddition = isIonic() ? 0.126 : 0.0385
// export const practiceClickAddition = isIonic() ? 0.126 : 0.0285
// export const practiceClickAddition = 0.056
// export const practiceClickAddition = isIonic() ? 0.0431 : 0.0629
export const practiceClickAddition = 0
