import React from 'react'
import { connect } from 'react-redux'

import { IonCard, IonCardContent, IonProgressBar } from '@ionic/react'

import { getHasInteractedWithPractice, getIsIsolated, getIsPlaying, getIsPracticeModeOn, getUserAccuracy } from '../../../../../redux/selectors/playback'
import { getIsSelected } from '../../../../../redux/selectors/rendering'

const ProgressCard = ({ accuracy, playing, isPracticeModeOn }) => {
	if (!playing || !isPracticeModeOn) return null

	return (
		<IonCard className={`ion-practice-progress-card`}>
			<IonCardContent>
				<IonProgressBar value={accuracy / 100} />
			</IonCardContent>
		</IonCard>
	)
}

const mapStateToProps = (state) => {
	return {
		accuracy: getUserAccuracy(state),
		isolated: getIsIsolated(state),
		selected: getIsSelected(state),
		isPracticeModeOn: getIsPracticeModeOn(state),
		playing: getIsPlaying(state),
		hasInteractedWithPractice: getHasInteractedWithPractice(state),
	}
}

export default connect(mapStateToProps, {})(ProgressCard)
