import React from 'react'
import { connect } from 'react-redux'

import MainMenu from '../../components/IonicUI/Pages/Menu/menu'
import PlaceholderMenu from '../../components/IonicUI/PlaceHolder/menu'

import { getUsingPlaceholders } from '../../redux/selectors/ionic'

const Menu = ({ usingPlaceholders }) => {
	if (usingPlaceholders) {
		return <PlaceholderMenu />
	}
	return <MainMenu />
}

const mapStateToProps = (state) => {
	const usingPlaceholders = getUsingPlaceholders(state)
	return {
		usingPlaceholders,
	}
}

export default connect(mapStateToProps, {})(Menu)
