import get from 'lodash/get'
import { combineReducers } from 'redux'
import audio from './audio'
import playback from './playback'
import rendering from './rendering'
import ionic from './ionic'

import { initRendering } from './initStates'

const appReducer = combineReducers({
	audio: audio,
	playback: playback,
	rendering: rendering,
	ionic: ionic,
})

const rootReducer = (state, action) => {
	if (action.type === 'RESET') {
		const { audio, rendering } = state
		state = {
			audio: { ...audio, volumeBoost: 1.0 },
			rendering: {
				...initRendering,
				platform: get(rendering, 'platform'),
				theme: get(rendering, 'theme'),
				showSettings: get(rendering, 'showSettings'),
				showFilters: get(rendering, 'showFilters'),
				text: { ...get(rendering, 'text') },
			},
		}
	}
	return appReducer(state, action)
}

export default rootReducer
