import React from 'react'
import { connect } from 'react-redux'

import { IonItem, IonLabel, IonIcon } from '@ionic/react'
import { cogOutline, checkmarkCircle } from 'ionicons/icons'
import { getIsCustom, getIsPlaying } from '../../../../../../../redux/selectors/playback'
import { ionSetComplexityCustomOpen } from '../../../../../../../redux/ionicActions'
import { getRenderingMode } from '../../../../../../../redux/selectors/rendering'
import RenderingMode from '../../../../../../../utils/constants/enum/renderingMode'
const Custom = ({ playing, custom, ionSetComplexityCustomOpen, renderingMode }) => {
	const getLabel = () => `Custom Groupings`

	const getTickContent = () => {
		if (!custom) return
		return <IonIcon color="primary" icon={checkmarkCircle} />
	}

	const navigateToCustom = () => {
		ionSetComplexityCustomOpen(true)
	}

	const disabled = () => {
		if (playing) return true
		switch (renderingMode) {
			case RenderingMode.SD.TRIPLETS.ONE:
			case RenderingMode.SD.TRIPLETS.TWO:
			case RenderingMode.SD.TRIPLETS.THREE:
			case RenderingMode.SD.TRIPLETS.FOUR:
			case RenderingMode.SD.TRIPLETS.FIVE:
				return true
			default:
				break
		}
		return false
	}

	return (
		<IonItem onClick={navigateToCustom} button disabled={disabled()}>
			<IonLabel>{getLabel()}</IonLabel>
			<IonIcon icon={cogOutline} />
			{getTickContent()}
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const playing = getIsPlaying(state)
	const custom = getIsCustom(state)
	const renderingMode = getRenderingMode(state)
	return {
		playing,
		custom,
		renderingMode,
	}
}

export default connect(mapStateToProps, { ionSetComplexityCustomOpen })(Custom)
