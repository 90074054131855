import React, { useState, useEffect } from 'react'
import { get, isEmpty, includes, forEach, map } from 'lodash'
import { connect } from 'react-redux'

import { IonButton, IonGrid, IonItem, IonLabel, IonList, IonToggle, IonCol, IonRow, IonImg } from '@ionic/react'

import { getCustomArray, getIsCustom, getIsSixTwelveEight, getTime, getTimeSignature } from '../../../../../../../redux/selectors/playback'
import { setCustom, setCustomArray } from '../../../../../../../redux/actions'
import { getRenderingMode } from '../../../../../../../redux/selectors/rendering'

import RenderingModes from '../../../../../../../utils/constants/enum/renderingMode'
import TimeEnum from '../../../../../../../utils/constants/enum/time'
import useWindowResize from '../../../../../../../hooks/useWindowResize'
import { desktop, makeID } from '../../../../../../../utils/functions'

const Custom = ({ customArray, time, custom, setCustom, renderingMode, isSixTwelveEight, setCustomArray, timeSignature }) => {
	const [device] = useWindowResize()

	const [imagePath, setImagePath] = useState('straight/')
	const [imageArray, setImageArray] = useState(Array(get(time, `options`)).fill(0))

	// Setting the image path and array
	useEffect(() => {
		setImageArray(Array(get(time, `options`)).fill(0))
		switch (renderingMode) {
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
				setImagePath('first-grooves/')
				setImageArray(Array(28).fill(0))
				return
			default:
				break
		}
		switch (get(time, `options`)) {
			case TimeEnum.STRAIGHT:
				if (isSixTwelveEight) {
					setImagePath('six-eight/')
					setImageArray(Array(64).fill(0))
				} else {
					setImagePath('straight/')
				}
				break
			case TimeEnum.EIGHTH:
				setImagePath('straight-8/')
				break
			case TimeEnum.TRIPLETS:
				setImagePath('swung/')
				break
			default:
				setImagePath('mixed/')
				break
		}
	}, [get(time, `options`), timeSignature, renderingMode])

	/**
	 * Loads an image
	 * @param {number} index of the image to load
	 * @returns
	 */
	const getImage = (index) => require(`../../../../../../../images/rhythms/${imagePath}${index}.png`)

	/**
	 * Determine the className for an image
	 * @param {number} index of the image
	 * @returns
	 */
	const imgClassName = (index) => {
		if (isEmpty(customArray)) {
			return ``
		}
		if (includes(customArray, index)) {
			return `selected`
		}
		return `faded`
	}

	/**
	 * Select an image, adding it to the custom array
	 * @param {number} idx index
	 */
	const select = (idx) => {
		const customArrayTmp = [...customArray]
		const startLength = get(customArrayTmp, 'length')
		if (!includes(customArrayTmp, idx)) {
			customArrayTmp.push(idx)
		} else {
			customArrayTmp.splice(customArrayTmp.indexOf(idx), 1)
		}

		if (isEmpty(customArrayTmp)) {
			setCustom(false)
		}
		if (startLength === 0 && get(customArrayTmp, `length`) === 1) {
			setCustom(true)
		}
		setCustomArray(customArrayTmp)
	}

	const getAmount = () => {
		let amount = get(time, `options`)
		if (isSixTwelveEight) {
			amount = 64
		}
		switch (renderingMode) {
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
				amount = 28
				break
			default:
				break
		}
		return amount
	}

	const selectAll = () => {
		const amount = getAmount()
		let temp = []
		for (let i = 0; i < amount; i++) {
			temp.push(i + 1)
		}
		setCustomArray(temp)
		customOn()
	}

	const selectRandom = () => {
		const amount = getAmount()
		let temp = []
		for (let i = 0; i < amount; i++) {
			if (Math.floor(Math.random() * 10) > 5) {
				temp.push(i + 1)
			}
		}
		setCustomArray(temp)
		customOn()
	}

	const clearSelection = () => setCustomArray([])

	const customOn = () => setCustom(true)
	const customOff = () => setCustom(false)
	const toggleCustom = () => {
		setCustom(!custom)
	}

	const getNoOfGridColumns = () => {
		switch (renderingMode) {
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
				return 1
			default:
				break
		}
		if (desktop(device)) {
			return 5
		}
		return 3
	}

	const buildGrid = () => {
		const noOfColumns = getNoOfGridColumns()
		const rows = []
		let row = []
		map(imageArray, (__, index) => {
			// const image = <img key={index} alt="notation" className={imgClassName(index + 1)} src={getImage(index + 1)} onClick={() => select(index + 1)} />
			const image = <IonImg button key={index} alt="notation" className={imgClassName(index + 1)} src={getImage(index + 1)} onClick={() => select(index + 1)} />
			row.push(<IonCol key={index}>{image}</IonCol>)
			if (get(row, `length`) === noOfColumns) {
				rows.push(row)
				row = []
			}
			if (index + 1 === get(imageArray, `length`)) {
				while (get(row, `length`) < noOfColumns) {
					row.push(<IonCol key={makeID(2)}></IonCol>)
				}
				rows.push(row)
				row = []
			}
		})
		return map(rows, (row, i) => {
			return (
				<IonRow key={i}>
					{forEach(row, (r) => {
						return r
					})}
				</IonRow>
			)
		})
	}

	return (
		<IonList className="ion-custom-list">
			<IonItem disabled={!custom && isEmpty(customArray)} button onClick={toggleCustom} slot="end">
				<IonLabel className="toggle-label" color={custom ? `primary` : ``}>
					{custom ? `On` : `Off`}
				</IonLabel>
				<IonToggle checked={custom} onClick={toggleCustom}></IonToggle>
			</IonItem>
			<IonItem>
				<IonGrid className={`image-grid`}>{buildGrid()}</IonGrid>
			</IonItem>
			<IonItem className="action-buttons">
				<IonGrid>
					<IonRow>
						<IonCol />
						<IonCol>
							<IonButton
								onClick={() => {
									clearSelection()
									customOff()
								}}
								disabled={isEmpty(customArray)}
								color={`darkbutton`}
							>{`Clear`}</IonButton>
						</IonCol>
						<IonCol>
							<IonButton
								onClick={() => {
									clearSelection()
									selectRandom()
								}}
								color={`darkbutton`}
							>{`Random`}</IonButton>
						</IonCol>
						<IonCol>
							<IonButton
								onClick={() => {
									clearSelection()
									selectAll()
								}}
								color={`darkbutton`}
								disabled={get(customArray, `length`) >= get(time, `options`)}
							>{`Select All`}</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonItem>
		</IonList>
	)
}

const mapStateToProps = (state) => {
	const custom = getIsCustom(state)
	const customArray = getCustomArray(state)
	const time = getTime(state)
	const renderingMode = getRenderingMode(state)
	const isSixTwelveEight = getIsSixTwelveEight(state)
	const timeSignature = getTimeSignature(state)

	return {
		customArray,
		time,
		custom,
		renderingMode,
		isSixTwelveEight,
		timeSignature,
	}
}

export default connect(mapStateToProps, { setCustom, setCustomArray })(Custom)
