//Constants for sounds made by each sixteenth note grouping
const SixteenthSounds = {
	sounds: [
		{
			id: 1,
			arr: [0, 0, 0, 0],
		},
		{
			id: 2,
			arr: [1, 0, 0, 0],
		},
		{
			id: 3,
			arr: [1, 0, 1, 0],
		},
		{
			id: 4,
			arr: [0, 0, 1, 0],
		},
		{
			id: 5,
			arr: [1, 1, 1, 1],
		},
		{
			id: 6,
			arr: [1, 0, 1, 1],
		},
		{
			id: 7,
			arr: [1, 1, 1, 0],
		},
		{
			id: 8,
			arr: [1, 1, 0, 1],
		},
		{
			id: 9,
			arr: [1, 0, 0, 1],
		},
		{
			id: 10,
			arr: [0, 0, 1, 1],
		},
		{
			id: 11,
			arr: [1, 1, 0, 0],
		},
		{
			id: 12,
			arr: [0, 1, 1, 0],
		},
		{
			id: 13,
			arr: [0, 1, 1, 1],
		},
		{
			id: 14,
			arr: [0, 1, 0, 1],
		},
		{
			id: 15,
			arr: [0, 1, 0, 0],
		},
		{
			id: 16,
			arr: [0, 0, 0, 1],
		},
		{
			id: 17,
			arr: [1],
		},
		{
			id: 18,
			arr: [0, 1],
		},
		{
			id: 19,
			arr: [0, 0, 1],
		},
		{
			id: 20,
			arr: [1, 0],
		},
		{
			id: 21,
			arr: [0],
		},
		{
			id: 22,
			arr: [0, 0],
		},
		{
			id: 23,
			arr: [0, 0, 0],
		},
		{
			id: 24,
			arr: [1, 1],
		},
		{
			id: 25,
			arr: [1, 1, 1],
		},
		{
			id: 26,
			arr: [1, 0, 1],
		},
		{
			id: 27,
			arr: [0, 1, 0],
		},
		{
			id: 28,
			arr: [0, 1, 1],
		},
		{
			id: 29,
			arr: [1, 1, 0],
		},
		{
			id: 30,
			arr: [1, 0, 0],
		},
	],
}

//Backup
// const SixteenthSounds = {
//     sounds: [
//         {
//             id: 1,
//             arr: [1, 0, 0, 0]
//         },
//         {
//             id: 2,
//             arr: [0, 1, 0, 0]
//         },
//         {
//             id: 3,
//             arr: [0, 0, 1, 0]
//         },
//         {
//             id: 4,
//             arr: [0, 0, 0, 1]
//         },
//         {
//             id: 5,
//             arr: [1, 1, 0, 0]
//         },
//         {
//             id: 6,
//             arr: [0, 1, 1, 0]
//         },
//         {
//             id: 7,
//             arr: [0, 0, 1, 1]
//         },
//         {
//             id: 8,
//             arr: [1, 0, 0, 1]
//         },
//         {
//             id: 9,
//             arr: [1, 0, 1, 0]
//         },
//         {
//             id: 10,
//             arr: [0, 1, 0, 1]
//         },
//         {
//             id: 11,
//             arr: [1, 1, 1, 0]
//         },
//         {
//             id: 12,
//             arr: [0, 1, 1, 1]
//         },
//         {
//             id: 13,
//             arr: [1, 0, 1, 1]
//         },
//         {
//             id: 14,
//             arr: [1, 1, 0, 1]
//         },
//         {
//             id: 15,
//             arr: [1, 1, 1, 1]
//         },
//         {
//             id: 16,
//             arr: [0, 0, 0, 0]
//         }
//     ]
// };

export default SixteenthSounds
