import React from 'react'
import { connect } from 'react-redux'

import get from 'lodash/get'

import Button from 'react-bootstrap/Button'

import { toggleShowSettings, setShowFilters, setShowSettings, toggleShowFilters } from '../../redux/actions'
import { getRenderingState, getIsApp, getThemeName } from '../../redux/selectors/rendering'

import useWindowResize from '../../hooks/useWindowResize'

import { APP_STORES_PATH } from '../../routes/Web/constants'
import { beta, desktop } from '../../utils/functions'

import cog from '../../images/cog.png'
import leftArrow from '../../images/left-arrow.png'
import burgerMenu from '../../images/burger-menu.png'

const NavbarButtons = (props) => {
	const [device] = useWindowResize()
	const themeName = get(props, `themeName`)

	/**
	 * Respond to a click of the burger menu icon
	 */
	const burgerClick = () => {
		if (get(props, `rendering`, `showFilters`)) {
			get(props, `setShowFilters`)(false)
		}
		get(props, `toggleShowSettings`)()
	}

	/**
	 * @returns JSX
	 */
	const render = () => {
		if (!desktop(device)) {
			return (
				<>
					<img onClick={burgerClick} alt="settings" className={`burger fixed settings ${themeName}`} src={burgerMenu} />
					<img
						onClick={() => {
							if (!get(props, [`rendering`, `showFilters`])) {
								get(props, `setShowSettings`)(false)
							}
							get(props, `toggleShowFilters`)()
						}}
						alt="filters-toggle"
						className={`fixed filters-toggle  ${themeName}`}
						src={get(props, [`rendering`, `showFilters`]) ? leftArrow : cog}
					/>
				</>
			)
		}

		let appStoreLinks = null
		if (beta() && !get(props, `isApp`)) {
			appStoreLinks = (
				<Button className={'get-app action-3'} href={APP_STORES_PATH} target="_blank">
					{`Get the App!`}
				</Button>
			)
		}
		return (
			<div className={`fixed settings navbar-buttons ${themeName}`}>
				{appStoreLinks}
				<img onClick={burgerClick} alt="settings" className={`burger ${themeName}`} src={burgerMenu} />
			</div>
		)
	}

	return render()
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const isApp = getIsApp(state)
	const themeName = getThemeName(state)

	return {
		themeName,
		rendering,
		isApp,
	}
}

export default connect(mapStateToProps, { toggleShowFilters, toggleShowSettings, setShowFilters, setShowSettings })(NavbarButtons)
