import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import findIndex from 'lodash/findIndex'

import { useIonPicker } from '@ionic/react'

import { getBpm, getIsPlaying, getUsingGhosts } from '../../../../../../redux/selectors/playback'
import { setBpm, setUseGhosts } from '../../../../../../redux/actions'

const useBpmPicker = () => {
	const upperLimit = 280
	const lowerLimit = 10

	const dispatch = useDispatch()
	const [present] = useIonPicker()

	const playing = useSelector(getIsPlaying)
	const usingGhosts = useSelector(getUsingGhosts)
	const bpm = useSelector(getBpm)

	const updateBpm = (value) => {
		if (playing) {
			return
		}
		if (value > 140 && usingGhosts) {
			//Auto disable ghosts above this tempo
			dispatch(setUseGhosts(false))
		}
		return dispatch(setBpm(value))
	}

	const openPicker = async () => {
		if (playing) {
			return
		}

		const options = []
		for (let i = 0; i <= upperLimit; i++) {
			if (i < lowerLimit) {
				continue
			}
			options.push({
				text: `${i}`,
				value: i,
			})
		}
		const selectedIndex = findIndex(options, (o) => get(o, `value`) === bpm)

		present({
			columns: [
				{
					name: 'bpm',
					selectedIndex,
					options,
				},
			],
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'Confirm',
					handler: (value) => {
						updateBpm(get(value, [`bpm`, `value`]))
					},
				},
			],
		})
	}

	return openPicker
}

export default useBpmPicker
