import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import map from 'lodash/map'
import get from 'lodash/get'
import includes from 'lodash/includes'

import { getRenderingState, getIsRhythmicVocabularyPermutations } from '../redux/selectors/rendering'
import {
	getIsReadingMode,
	getPlaybackState,
	getRVPermutationsGroupingIndex,
	getRVPermutationsStartingPoints,
	getSubdivisionBarDuration,
} from '../redux/selectors/playback'
import { rhythmicVocabPermutationsSetStartPoints } from '../redux/actions'

import RenderingModes from '../utils/constants/enum/renderingMode'
import { namePosition } from '../utils/rhythmic-vocabulary-permutations'

const Bar = (props) => {
	const [selectedClass, setSelectedClass] = useState('')
	const [rvPermutationsCoveredIndexes, setRvPermutationsCoveredIndexes] = useState(props.getCoveredIndexes())

	//Determine whether this bar is currently selected
	useEffect(() => {
		const selected = !(get(props, 'playback.playing') || get(props, 'playback.isolated') || !includes(get(props, 'playback.selectedBars'), get(props, 'barNo')))
		setSelectedClass(selected ? `selected` : ``)
	}, [props.playback.selectedBars, props.barNo, props.playback.playing, props.playback.isolated])

	useEffect(() => {
		if (props.playback.playing) {
			return
		}

		setRvPermutationsCoveredIndexes(props.getCoveredIndexes())
	}, [props.playback.playing, props.rvGroupingIndex, props.rvStartingPoints])

	useEffect(() => {
		if (!props.isReadingMode || props.highlighted === 'highlighted') {
			return
		}

		setRvPermutationsCoveredIndexes(props.getCoveredIndexes())
	}, [props.highlighted, props.isReadingMode])

	/**
	 * Select this bar through the parent barSelected function
	 */
	const select = () => {
		if (get(props, 'faded') === 'faded' || get(props, 'clickable') !== 'clickable') {
			return
		}
		const selected = !includes(get(props, 'playback.selectedBars'), get(props, 'barNo'))
		setSelectedClass(selected ? `selected` : ``)
		props.barSelected(get(props, 'barNo'))
	}

	/**
	 * Render the beats (images) onto the bar
	 * @param {number} idx the index of the image to be rendered
	 * @returns
	 */
	const renderBeats = (__, idx) => {
		const className = get(props, 'className')
		return (
			<div key={idx} className={`beat ${className}`}>
				<img key={idx} alt={`beat${idx + 1}`} className={`img ${className}`} src={props.beats[idx]}></img>
			</div>
		)
	}

	/**
	 * A bar with only 1 image should set it as the background-image (e.g. first
	 * grooves mode)
	 * This function provides the path to that image
	 */
	const fullBackground = () => get(props, 'beats')[0]

	const renderRVPermutationsGrid = () => {
		const getClassName = (index) => {
			if (!props.rvStartingPoints?.[props.barNo]) {
				return ''
			}

			const baseClass = 'rv-bar-grid-item'
			if (rvPermutationsCoveredIndexes.includes(index + props.barNo * props.subdivisionBarDuration)) {
				return `${baseClass} covered`
			}

			if (props.rvStartingPoints[props.barNo].includes(index)) {
				return `${baseClass} selected`
			}

			return `${baseClass}`
		}

		return (
			<div className="rv-bar-grid">
				{Array.from({ length: props.subdivisionBarDuration }, (_, index) => (
					<div
						key={index}
						onClick={() => {
							const newStartingPoints = [...props.rvStartingPoints]

							let barStartingPoints = [...newStartingPoints[props.barNo]]
							if (barStartingPoints.includes(index)) {
								barStartingPoints = barStartingPoints.filter((item) => item !== index)
							} else {
								barStartingPoints.push(index)
							}

							newStartingPoints[props.barNo] = barStartingPoints.sort((a, b) => a - b)
							props.rhythmicVocabPermutationsSetStartPoints(newStartingPoints)
						}}
						className={`${getClassName(index)}`}
					>
						{namePosition(index)}
					</div>
				))}
			</div>
		)
	}

	const bar = () => {
		if (props.isRVPermutations) {
			return <div className={`bar rv ${props.className} ${props.highlighted} ${props.playback.playing ? 'playing' : ''}`}>{renderRVPermutationsGrid()}</div>
		}

		if (!get(props, 'beats')) {
			return <div className={`bar ${props.className}`}></div>
		}
		let inner = (
			<div className="bar-background barline">
				<div className={`notes ${props.className}`}>{map(get(props, 'beats'), renderBeats)}</div>
			</div>
		)
		if (get(props, 'beats.length') === 1) {
			let zoom = ``
			switch (get(props, `rendering.mode`)) {
				case RenderingModes.FIRSTGROOVES.ALL:
				case RenderingModes.FIRSTGROOVES.ONE:
				case RenderingModes.FIRSTGROOVES.TWO:
				case RenderingModes.FIRSTGROOVES.THREE:
					zoom = `zoom`
					break
				default:
					break
			}
			inner = <div className={`bar-background notation ${zoom}`} style={{ backgroundImage: `url(${fullBackground()})` }}></div>
		}
		const className = `bar ${props.className} ${get(props, `highlighted`, ``)} ${get(props, `clickable`, ``)} ${selectedClass}  ${get(props, `faded`, ``)}`
		return (
			<div className={className} onClick={select}>
				{inner}
			</div>
		)
	}

	return bar()
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const playback = getPlaybackState(state)
	const subdivisionBarDuration = getSubdivisionBarDuration(state)
	const isReadingMode = getIsReadingMode(state)
	const isRVPermutations = getIsRhythmicVocabularyPermutations(state)
	const rvStartingPoints = getRVPermutationsStartingPoints(state)
	const rvGroupingIndex = getRVPermutationsGroupingIndex(state)

	return { rendering, playback, isRVPermutations, rvStartingPoints, rvGroupingIndex, subdivisionBarDuration, isReadingMode }
}

export default connect(mapStateToProps, {
	rhythmicVocabPermutationsSetStartPoints,
})(Bar)
