const SixEightLevels = {
	levels: [
		{
			id: 1,
			arr: [1, 2, 3, 4, 5, 6, 7, 8],
		},
		{
			id: 2,
			arr: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
		},
		{
			id: 3,
			arr: [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34],
		},
		{
			id: 4,
			arr: [35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47],
		},
	],
}

export default SixEightLevels
