//Constants for sounds made by each eighth note grouping
const EighthSounds = {
	sounds: [
		{
			id: 1,
			arr: [0, 0, 0, 0],
		},
		{
			id: 2,
			arr: [1, 0, 0, 0],
		},
		{
			id: 3,
			arr: [0, 0, 1, 0],
		},
		{
			id: 4,
			arr: [1, 0, 1, 0],
		},
	],
};

export default EighthSounds;
