import React, { useState, useRef, useEffect } from 'react'
import { toUpper } from 'lodash'
import { connect } from 'react-redux'
import { IonCard, IonCardContent, IonProgressBar } from '@ionic/react'

import { getHasInteractedWithPractice, getIsIsolated, getIsPlaying, getIsPracticeModeOn, getUserAccuracy } from '../../../../../redux/selectors/playback'
import { getIsSelected } from '../../../../../redux/selectors/rendering'
import Isolate from '../Isolate'
import { setBpm } from '../../../../../redux/actions'
import { arrayAverage } from '../../../../../utils/functions'
import { getAudioContext } from '../../../../../redux/selectors/audio'

const TapCard = ({ practiceRefs, audioContext, accuracy, setBpm, isolate, isolated, selected, playing, isPracticeModeOn, practiceTap, hasInteractedWithPractice }) => {
	//Tap Tempo settings
	const [times, setTimes] = useState([])
	const [tapTime, setTapTime] = useState(false)
	const tapTempoTimeout = useRef(null)

	const isCardButton = () => {
		if (playing) return false
		if (isolated || selected) {
			return false
		}
		return true
	}

	const getTextContent = () => {
		if (isolated || selected) {
			return ``
		}
		if (!playing) {
			return toUpper(`tap tempo`)
		}
		if (isPracticeModeOn) {
			if (hasInteractedWithPractice) {
				return toUpper(`${Math.floor(accuracy)}%`)
			}
			return toUpper(`tap here`)
		}
		return ``
	}

	const tapTempo = () => {
		if (isolated || selected) {
			return
		}

		clearTimeout(tapTempoTimeout.current)
		tapTempoTimeout.current = setTimeout(() => {
			setTimes([])
			setTapTime(false)
		}, 1700)
		if (!tapTime) {
			return setTapTime(new Date())
		}

		const endTime = new Date()
		const tempo = endTime - tapTime
		times.push(tempo)
		const average = arrayAverage(times)
		const bpm = 60000 / average
		if (bpm < 281 && bpm > 29) {
			setBpm(Math.floor(bpm))
		} else {
			setTimes([])
			setTapTime(false)
		}
		setTapTime(endTime)
	}

	const tap = (animationTimestamp) => {
		if (!isPracticeModeOn) {
			return
		}
		// if (progressStatsRef) {
		// if (false) {
		// return practiceTap({ progressStatsRef, latency })
		// }
		return practiceTap({ click: true, timeOverride: audioContext.currentTime - (performance.now() - animationTimestamp) / 1000 })
	}

	const getExtraTextClass = () => {
		if (!playing) return ``
		if (!isPracticeModeOn) return ``
		return `active`
	}

	const cardClass = () => {
		return ``
		if (!playing) return ``
		if (!isPracticeModeOn) return ``
		return `practicing`
	}

	if (playing) {
		if (!isPracticeModeOn) {
			return null
		}

		return (
			<IonCard onPointerDown={() => window.requestAnimationFrame(tap)} button={isCardButton()} className={`ion-tap-card ${cardClass()}`}>
				<IonCardContent>
					<span className={`text bold size-30`}>{getTextContent()}</span>
				</IonCardContent>
			</IonCard>
		)
		// return (
		// 	<Tappable
		// 		onTap={(e) => {
		// 			console.log('tap')
		// 			const latency = (performance.now() - e.timeStamp) / 1000 + (audioContext.baseLatency + audioContext.outputLatency)
		// 			tap({ progressStatsRef: { ...practiceRefs.progressStatsRef.current }, latency })
		// 		}}
		// 	>
		// 		<IonCard onClick={() => console.log('click')} button={isCardButton()} className={`ion-tap-card ${cardClass()}`}>
		// 			<IonCardContent>
		// 				<span className={`text bold size-30`}>{getTextContent()}</span>
		// 			</IonCardContent>
		// 		</IonCard>
		// 	</Tappable>
		// )
	}

	return (
		<IonCard onClick={() => tapTempo()} button={isCardButton()} className={`ion-tap-card ${cardClass()}`}>
			<IonCardContent>
				<Isolate isolate={isolate} />
				<span className={`text bold size-30`}>{getTextContent()}</span>
			</IonCardContent>
		</IonCard>
	)
}

const mapStateToProps = (state) => {
	return {
		audioContext: getAudioContext(state),
		accuracy: getUserAccuracy(state),
		isolated: getIsIsolated(state),
		selected: getIsSelected(state),
		isPracticeModeOn: getIsPracticeModeOn(state),
		playing: getIsPlaying(state),
		hasInteractedWithPractice: getHasInteractedWithPractice(state),
	}
}

export default connect(mapStateToProps, { setBpm })(TapCard)
