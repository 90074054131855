import React, { useRef } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { IonIcon, IonButton } from '@ionic/react'
import { playSharp, stopSharp } from 'ionicons/icons'

import { getAudioContext, getAudioConstructor } from '../../../redux/selectors/audio'
import { getIsPlaying, getIsPracticeModeOn, getMsBarDuration, getNoOfBars } from '../../../redux/selectors/playback'
import { getIsRenderedArrayEmpty } from '../../../redux/selectors/rendering'
import { errorOccurred } from '../../../utils/functions'
import { setAudioContext } from '../../../redux/actions'

const PlayButton = ({
	playing,
	play: parentPlay,
	stop: parentStop,
	audioContext,
	audioConstructor,
	isPracticeModeOn,
	practiceRefs: { progressStatsRef, pollingRef },
	noOfBars,
	isRenderedArrayEmpty,
	msBarDuration,
	setAudioContext,
}) => {
	const accurateInterval = require('accurate-interval')
	const practiceDelayRef = useRef(null)

	const playHandler = async () => {
		if (!audioContext) {
			return errorOccurred(`ActionButtons.play.play - null AudioContext`)
		}
		const state = get(audioContext, `state`, ``)
		if (state === 'suspended') {
			await audioContext.resume()
			return play()
		}
		await audioContext.suspend()
		await audioContext.resume()
		return play()
	}

	const practiceModeSetup = async () => {
		progressStatsRef.current = { bar: 0, percentage: 0, loop: 0 }

		const resolution = 150 // Sample 150 times per bar
		const msDelay = 110
		let i = 0

		const speed = 5
		practiceDelayRef.current = accurateInterval(() => {
			pollingRef.current = accurateInterval(
				() => {
					const current = progressStatsRef.current
					const currentPercentage = current.percentage
					const currentBar = current.bar
					const currentLoop = current.loop

					if (currentPercentage >= 100) {
						const finalBar = currentBar + 1 > noOfBars

						progressStatsRef.current.bar = finalBar ? 1 : currentBar + 1
						progressStatsRef.current.percentage = finalBar ? currentLoop + 1 : currentLoop
						progressStatsRef.current.percentage = 0
						return
					}

					progressStatsRef.current.percentage = currentPercentage + 100 / (msBarDuration / speed)
				},
				speed,
				{ immediate: true }
			)
			practiceDelayRef.current.clear()
		}, 0)
	}

	const play = async () => {
		if (isPracticeModeOn) {
			await practiceModeSetup()
		}
		parentPlay()
	}

	const stop = () => parentStop({ timeout: false })

	const getIcon = () => {
		let icon = playSharp
		let className = ``
		if (playing) {
			icon = stopSharp
			className = `stop`
		}
		return <IonIcon className={className} color="canvas" icon={icon} />
	}

	return (
		<span className="ion-play-button">
			<IonButton onClick={playing ? stop : playHandler} disabled={isRenderedArrayEmpty} shape="circle">
				{getIcon()}
			</IonButton>
		</span>
	)
}

const mapStateToProps = (state) => {
	const audioContext = getAudioContext(state)
	const audioConstructor = getAudioConstructor(state)
	const isRenderedArrayEmpty = getIsRenderedArrayEmpty(state)
	const playing = getIsPlaying(state)
	const isPracticeModeOn = getIsPracticeModeOn(state)
	const msBarDuration = getMsBarDuration(state)
	const noOfBars = getNoOfBars(state)

	return {
		audioContext,
		audioConstructor,
		isRenderedArrayEmpty,
		playing,
		msBarDuration,
		isPracticeModeOn,
		noOfBars,
	}
}

export default connect(mapStateToProps, { setAudioContext })(PlayButton)
