import React from 'react'
import { get, findIndex } from 'lodash'
import { connect } from 'react-redux'

import { useIonPicker, IonItem, IonLabel } from '@ionic/react'

import { getIsPlaying, getTime, getTimeSignature, isTimeSignatureUpdateAllowed } from '../../../../../../../redux/selectors/playback'
import TimeEnum from '../../../../../../../utils/constants/enum/time'

const TSBottom = ({ changeTimeSignatureBottom, playing, timeSignatureUpdateAllowed, timeSignature, time }) => {
	const [present] = useIonPicker()

	const getLabel = () => `Bottom - ${get(timeSignature, `bottom`)}`

	const getBottomOptions = () => {
		switch (get(time, `options`)) {
			case TimeEnum.STRAIGHT:
				return [
					{ text: `4`, value: 4 },
					{ text: `8`, value: 8 },
					{ text: `16`, value: 16 },
				]
			case TimeEnum.EIGHTH:
				return [
					{ text: `4`, value: 4 },
					{ text: `8`, value: 8 },
				]
			case TimeEnum.TRIPLETS:
				return [{ text: `4`, value: 4 }]
			case TimeEnum.MIXED:
				return [{ text: `4`, value: 4 }]
			default:
				return
		}
	}

	const openPicker = async () => {
		if (playing) {
			return
		}
		const options = getBottomOptions()
		const selectedIndex = findIndex(options, (o) => get(o, `value`) === get(timeSignature, `bottom`))

		present({
			columns: [
				{
					name: 'timeSignature',
					selectedIndex,
					options,
				},
			],
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'Confirm',
					handler: (value) => {
						changeTimeSignatureBottom({ value: get(value, [`timeSignature`, `value`]) })
					},
				},
			],
		})
	}

	const disabled = () => {
		if (playing || !timeSignatureUpdateAllowed) return true
		switch (get(time, `options`)) {
			case TimeEnum.TRIPLETS:
			case TimeEnum.MIXED:
				return true
			default:
				break
		}
		return false
	}

	return (
		<IonItem detail button disabled={disabled()} onClick={openPicker}>
			<IonLabel>{getLabel()}</IonLabel>
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const time = getTime(state)
	const playing = getIsPlaying(state)
	const timeSignatureUpdateAllowed = isTimeSignatureUpdateAllowed(state)
	const timeSignature = getTimeSignature(state)

	return {
		playing,
		timeSignatureUpdateAllowed,
		timeSignature,
		time,
	}
}

export default connect(mapStateToProps, {})(TSBottom)
