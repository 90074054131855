// All the groups possible
export const SDGroups = {
	simple: [`A`, `B`],
	medium: [`A`, `B`, `C`, `D`],
	all: [`A`, `B`, `C`, `D`, `E`, `F`, `G`, `H`, `I`],
}

// What each of the images corresponds to as sounds
export const sdSounds = {
	A: {
		1: [1, 0, 1, 1, 0, 1, 1, 0, 1, 0, 1, 0],
		2: [1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0],
		3: [1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0],
		4: [1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0],
		5: [1, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 0],
		6: [1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 0],
	},
	B: {
		1: [1, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0],
		2: [1, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0],
		3: [1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0],
	},
	C: {
		1: [1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1],
		2: [1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1],
		3: [1, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1],
		4: [1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1],
	},
	D: {
		1: [1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1],
	},
	E: {
		1: [0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1],
		2: [0, 1, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1],
		3: [0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1],
		4: [0, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1],
	},
	F: {
		// TODO ask JP, these all end on an S but with no space the other side
		1: [0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 0, 1],

		2: [0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1],

		3: [0, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1],

		4: [0, 1, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1],

		5: [0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1],

		6: [0, 1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1],
		7: [0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 1],
	},
	G: {
		1: [0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 1, 0],

		2: [0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0],

		3: [0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0],

		4: [0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0],

		5: [0, 1, 1, 0, 1, 1, 0, 1, 0, 1, 1, 0],

		6: [0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0],
		7: [0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0],
		8: [0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0],
	},
	H: {
		// TODO same as F but with D
		1: [1, 0, 1, 1, 0, 1, 1, 0, 1, 1, 0, 1],
		2: [1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 1, 1],
	},
	I: {
		// TODO same as F but with D
		1: [1, 1, 0, 1, 1, 0, 1, 1, 0, 1, 0, 1],
		2: [1, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 1],
	},
}

// Images in each group, normal and advanced mode
export const sdImageMap = {
	A: {
		normal: [1, 2, 3],
		advanced: [4, 5, 6],
	},
	B: {
		normal: [1, 2],
		advanced: [3],
	},
	C: {
		normal: [1, 2, 3],
		advanced: [4],
	},
	D: {
		normal: [1],
		advanced: [],
	},
	E: {
		normal: [1],
		advanced: [2, 3, 4],
	},
	F: {
		normal: [1, 2, 3],
		advanced: [4, 5, 6, 7],
	},
	G: {
		normal: [1, 2, 3, 4],
		advanced: [5, 6, 7, 8],
	},
	H: {
		normal: [1],
		advanced: [2],
	},
	I: {
		normal: [1, 2],
		advanced: [],
	},
	// TODO ask JP if these empty arrays are correct
}

// Indicates which group each group can be folowed by
export const sdGroupMap = {
	A: [`A`, `B`, `C`, `D`, `H`, `I`],
	B: [`A`, `B`, `C`, `D`, `H`, `I`],
	C: [`A`, `C`],
	D: [`A`, `C`],
	E: [`A`, `C`],
	F: [`E`, `F`, `G`],
	G: [`A`, `B`, `C`, `D`, `H`, `I`],
	H: [`E`, `F`, `G`],
	I: [`E`, `F`, `G`],
}
