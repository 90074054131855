import React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'

import Switch from '@mui/material/Switch'

import { setPractice, setShowHelperModal } from '../../redux/actions'
import { getIsPracticeModeOn } from '../../redux/selectors/playback'
import { getIsDarkTheme } from '../../redux/selectors/rendering'
import { title as PracticeTitle, description as PracticeDescription, action as PracticeAction } from '../../utils/practice/description'

import help from '../../images/help-circle.svg'

const Practice = (props) => {
	const title = get(props, 'title', 'Practice Mode')
	const inMenu = get(props, 'inMenu', false)
	const labelSize = get(props, 'labelSize', 'size-14')

	const openPracticeHelperModal = () =>
		get(
			props,
			`setShowHelperModal`
		)({
			show: true,
			title: PracticeTitle,
			text: PracticeDescription,
			footer: {
				title: PracticeAction,
				action: () => get(props, `setPractice`)(true),
			},
		})

	const invertHelper = () => (!inMenu || get(props, `isDarkTheme`, `false`) ? `invert` : ``)

	return (
		<>
			<span className={`text medium clickable ${labelSize}`} onClick={openPracticeHelperModal}>
				{title}
				<img className={`helper ${invertHelper()}`} alt="help" src={help} />
			</span>

			<Switch
				size="small"
				checked={get(props, `isPracticeModeOn`, false)}
				onChange={() => {
					get(props, `setPractice`)(!get(props, `isPracticeModeOn`, false))
				}}
				name="theme"
				color="primary"
				data-tip="practice"
				data-for="practice"
			/>
		</>
	)
}

const mapStateToProps = (state) => {
	const isPracticeModeOn = getIsPracticeModeOn(state)
	const isDarkTheme = getIsDarkTheme(state)

	return {
		isPracticeModeOn,
		isDarkTheme,
	}
}

export default connect(mapStateToProps, { setShowHelperModal, setPractice })(Practice)
