/*
	A mode where you can select a grouping and where it
	should appear in the bar then play that back
	Everything other than the grouping is space

	In reading mode the position that the grouping appears is
	randomised each bar
 */

import ModeEnum from '../constants/enum/mode'

export const title = 'Rhythmic Vocabulary Permutations'

export const straightOptions = [
	{
		images: [2],
		onesAndZeros: [1, 0, 0, 0],
	},
	{
		images: [11],
		onesAndZeros: [1, 1, 0, 0],
	},
	{
		images: [3],
		onesAndZeros: [1, 0, 1, 0],
	},
	{
		images: [9],
		onesAndZeros: [1, 0, 0, 1],
	},
	{
		images: [6],
		onesAndZeros: [1, 0, 1, 1],
	},
	{
		images: [8],
		onesAndZeros: [1, 1, 0, 1],
	},
	{
		images: [6, 15],
		onesAndZeros: [1, 0, 1, 1, 0, 1, 0, 0],
	},
]

/**
 *
 * @param {Number} index position in the bar
 * @param {Number} subdivisionsPerBeat how many subdivisions per beat
 * @returns {String}
 */
export const namePosition = (index, subdivisionsPerBeat = 4) => {
	const wholeBeat = index % subdivisionsPerBeat === 0
	if (wholeBeat) {
		return `${index / subdivisionsPerBeat + 1}`
	}

	// TODO make compatible with other subdivisions
	return ['e', '&', 'a'][(index % subdivisionsPerBeat) - 1]
}

/**
 * Calculate the absolute indexes covered by the given starting points and grouping
 *
 * @param {Array} startingPoints
 * @param {Number} groupingIndex
 * @param {Number} bars
 * @returns
 */
export const getRvPermutationsCoveredIndexes = (startingPoints, groupingIndex, bars, mode) => {
	const onesAndZeros = straightOptions[groupingIndex].onesAndZeros
	const isReadingMode = [ModeEnum.LOOPREADING, ModeEnum.TRADEREADING, ModeEnum.CLICKREADING].includes(mode)

	const absoluteIndexes = startingPoints.map((barStartingPoints, bar) => barStartingPoints.map((point) => point + bar * 16)).flat()
	const coveredIndexes = []

	const hitPositions = []
	for (let i = 0; i < onesAndZeros.length; i++) {
		if (onesAndZeros[i] === 1) {
			hitPositions.push(i)
		}
	}

	const limit = bars * 16
	const allowLooping = !isReadingMode

	absoluteIndexes.forEach((index) => {
		hitPositions.forEach((hitPosition) => {
			const overflow = index + hitPosition >= limit
			if (overflow && !allowLooping) {
				return
			}

			const absoluteIndex = (index + hitPosition) % limit
			if (absoluteIndexes.includes(absoluteIndex)) {
				return
			}

			coveredIndexes.push(absoluteIndex)
		})
	})

	return coveredIndexes.sort((a, b) => a - b)
}
