import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ReactTooltip from 'react-tooltip'
import Slider from '@mui/material/Slider'
import Switch from '@mui/material/Switch'
import { Stack, Divider } from '@mui/material'

import ClickRateEnum, { ClickRateImages } from '../../utils/constants/enum/click'
import { renderClickOffsetTooltip } from '../../utils/tooltips'
import { clickOffsetValueText } from '../../utils/functions'
import TimeEnum from '../../utils/constants/enum/time'

import {
	setUseMetronome,
	setClickVolume,
	setClickrate,
	setClickOffsetAmount,
	setClickOffsetRate,
	setClickMatchRhythm,
	setClickMarkOne,
	setGapClickOn,
	setGapClickOff,
	setClickRateAmount,
	setClickRateCustom,
	setClickRateNote,
	switchClickOffsetRate,
	resetClick,
	switchGapClickOffFirst,
} from '../../redux/actions'
import { getRenderingState, getThemeName } from '../../redux/selectors/rendering'
import { getPlaybackState } from '../../redux/selectors/playback'

import backArrow from '../../images/left-arrow.png'
import close from '../../images/close.png'
import cog from '../../images/cog.png'
import change from '../../images/switch-arrows.svg'
import volumeDown from '../../images/volume-down.svg'
import volumeUp from '../../images/volume-up.svg'
import volumeMute from '../../images/volume-x.svg'

const ClickModal = (props) => {
	const className = `${get(props, `device`)} ${get(props, `themeName`, `light`)}`
	const customImageKey = 10

	const [showGapClickControls, setShowGapClickControls] = useState(false)
	const [showCustomClickRateControls, setShowCustomClickRateControls] = useState(false)

	useEffect(() => {
		if (get(props, `playback.click.rate.selectedImage`) !== customImageKey) {
			return
		}
		setShowCustomClickRateControls(true)
	}, [])

	const straightClick = () => get(props, `playback.click.rate.time`) !== ClickRateEnum.TRIPLETS
	const getClickRateOptions = () => Object.keys(ClickRateImages)
	const clickRateSelection = (imageKey) => props.setClickrate({ imageKey })

	const getOffsetTime = () => {
		if (straightClick()) {
			return TimeEnum.STRAIGHT
		}
		return TimeEnum.TRIPLETS
	}

	/**
	 * Handle change of the offset slider
	 */
	const offsetSliderChange = (__, amount) => {
		props.setClickOffsetAmount({ amount, manual: true })
	}

	/**
	 * Handle change of the gap click on change
	 */
	const gapClickOnChange = (__, amount) => {
		props.setGapClickOn({ amount, firstChange: !get(props, 'playback.click.gap.controlsTouched') })
	}

	/**
	 * Handle change of the gap click off change
	 */
	const gapClickOffChange = (__, amount) => {
		props.setGapClickOff({ amount, firstChange: !get(props, 'playback.click.gap.controlsTouched') })
	}

	/**
	 * Change the click rate amount
	 */
	const clickRateAmountChange = (__, amount) => {
		props.setClickRateAmount({ value: amount })
	}

	/**
	 * Change the click rate note
	 */
	const clickRateNoteChange = (__, value) => {
		props.setClickRateNote({ value })
		const max = get(clickRateNoteInfo({ override: value }), `max`)
		if (get(props, `playback.click.rate.amount`) > max) {
			props.setClickRateAmount({ value: max })
		}
	}

	/**
	 * Get info about the current click rate: name, time and max amount for slider
	 */
	const clickRateNoteInfo = ({ override = false }) => {
		const t = override === false ? get(props, 'playback.click.rate.time') : override
		const multiplier = 1.5 // Max number of bars
		let name, max

		switch (t) {
			case ClickRateEnum.QUARTER:
				name = `Quarter notes`
				max = 4 * multiplier
				break
			case ClickRateEnum.EIGHTH:
				name = `Eighth notes`
				max = 8 * multiplier
				break
			case ClickRateEnum.SIXTEENTH:
				name = `Sixteenth notes`
				max = 16
				break
			case ClickRateEnum.TRIPLETS:
				name = `Eighth note triplets`
				max = 12
				break
			default:
				break
		}
		return {
			value: t,
			name,
			max,
		}
	}

	// JSX generators
	const renderFooterControls = () => {
		const desktop = get(props, `device`) === `desktop`
		let bpm

		const getOffsetTitle = () => {
			const offsetRate = get(props, `playback.click.offset.rate`)
			let noteStr
			switch (offsetRate) {
				case ClickRateEnum.SIXTEENTH:
					noteStr = `16th`
					break
				default:
					noteStr = `Triplet`
					break
			}
			return `${noteStr} offset`
		}

		const getSliderValue = () => {
			const offsetAmount = get(props, `playback.click.offset.amount`)
			const offsetRate = get(props, `playback.click.offset.rate`)
			let ret = offsetAmount
			switch (offsetRate) {
				case ClickRateEnum.SIXTEENTH:
					if (ret > 3) {
						ret = 3
					}
					break
				default:
					if (ret > 2) {
						ret = 2
					}
					break
			}
			return ret
		}

		if (desktop) {
			bpm = (
				<div className="control">
					<div className="text medium size-14">Bpm</div>
					<div className="bpm">{props.renderBpmControls({ modal: true })}</div>
				</div>
			)
		}
		return (
			<Stack className="right" direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem variant="inset" />}>
				{bpm}
				<div className="control">
					<div
						onClick={() => {
							props.switchClickOffsetRate()
						}}
						className="offset text medium size-14 clickable"
						data-tip="offset"
						data-for="offset"
						data-tip-disable={true}
					>
						{getOffsetTitle()}
						<img key={`change`} alt="change" className={`change`} src={change}></img>
					</div>
					<ReactTooltip
						id="offset"
						effect="solid"
						place="top"
						delayShow={200}
						delayHide={200}
						multipline
						type={get(props, 'isDarkTheme', false) ? 'light' : 'dark'}
					>
						{renderClickOffsetTooltip({ time: getOffsetTime() })}
					</ReactTooltip>
					<div className="slider">
						<Slider
							size="small"
							className="click-offset"
							aria-label="translation"
							key={`slider-${get(props, `playback.click.offset.amount`)}`}
							// TODO want to disable the top value of this slider
							// in a better way
							defaultValue={getSliderValue()}
							value={getSliderValue()}
							onChangeCommitted={offsetSliderChange}
							getAriaValueText={(value) => clickOffsetValueText({ straight: get(props, `playback.click.offset.rate`) === ClickRateEnum.SIXTEENTH, value })}
							valueLabelFormat={(value) => clickOffsetValueText({ straight: get(props, `playback.click.offset.rate`) === ClickRateEnum.SIXTEENTH, value })}
							aria-labelledby="discrete-slider-custom"
							valueLabelDisplay="auto"
							step={1}
							min={0}
							max={get(props, `playback.click.offset.rate`) === ClickRateEnum.SIXTEENTH ? 4 : 3}
							marks
						/>
					</div>
				</div>
				<div className="control markTheOne">
					<div className="text medium size-14">{`Mark one`}</div>
					<Switch
						className="switch"
						size={`medium`}
						checked={get(props, `playback.click.rate.markTheOne`)}
						onChange={() => {
							props.setClickMarkOne(!get(props, `playback.click.rate.markTheOne`))
						}}
						name="countin"
					/>
				</div>
			</Stack>
		)
	}

	const renderGapClickTitle = () => {
		let image, matchRhythmLength, contentLeft, contentRight, className
		switch (showGapClickControls) {
			case true:
				className = 'left text medium size-16'
				contentRight = `Match rhythm length`
				matchRhythmLength = (
					<Switch
						size={get(props, `device`) === `desktop` ? 'small' : 'medium'}
						checked={get(props, `playback.click.gap.matchRhythmLength`)}
						onChange={() => {
							props.setClickMatchRhythm(!get(props, `playback.click.gap.matchRhythmLength`))
						}}
						name="countin"
					/>
				)
				image = (
					<img
						key={`back-arrow`}
						alt="back-arrow"
						className={`back-arrow`}
						onClick={() => {
							setShowGapClickControls(!showGapClickControls)
						}}
						src={backArrow}
					></img>
				)
				break
			default:
				className = 'left text bold size-21'
				contentLeft = `Gap Click`
				image = (
					<img
						key={`cog`}
						alt="cog"
						className={`cog`}
						onClick={() => {
							setShowGapClickControls(!showGapClickControls)
						}}
						src={cog}
					></img>
				)
				break
		}

		return (
			<div className="split-title">
				<div className={className}>
					{contentLeft}
					{image}
					{contentRight}
					{matchRhythmLength}
				</div>
			</div>
		)
	}

	const renderGapClickContents = () => {
		const onControl = (
			<div className="gap-click-control">
				<div className="left text bold size-18">{`
		On ${get(props, `playback.click.gap.on`) === false ? `` : ` - ${get(props, `playback.click.gap.on`)}`}`}</div>
				<Slider
					size="medium"
					className="click-offset"
					aria-label="translation"
					key={`slider-${get(props, `playback.click.gap.on`)}`}
					defaultValue={get(props, `playback.click.gap.on`) === false ? 0 : get(props, `playback.click.gap.on`)}
					onChangeCommitted={gapClickOnChange}
					aria-labelledby="discrete-slider-custom"
					valueLabelDisplay="auto"
					step={1}
					min={0}
					max={10}
					marks
				/>
			</div>
		)
		const offControl = (
			<div className="gap-click-control">
				<div className="left text bold size-18">{`
		Off ${get(props, `playback.click.gap.on`) === false ? `` : ` - ${get(props, `playback.click.gap.off`)}`}`}</div>
				<Slider
					size="medium"
					className="click-offset"
					aria-label="translation"
					key={`slider-${get(props, `playback.click.gap.off`)}`}
					defaultValue={get(props, `playback.click.gap.on`) === false ? 0 : get(props, `playback.click.gap.off`)}
					onChangeCommitted={gapClickOffChange}
					aria-labelledby="discrete-slider-custom"
					valueLabelDisplay="auto"
					step={1}
					min={0}
					max={10}
					marks
				/>
			</div>
		)
		let firstControl, secondControl
		switch (get(props, `playback.click.gap.startOff`)) {
			case true:
				firstControl = offControl
				secondControl = onControl
				break
			default:
				firstControl = onControl
				secondControl = offControl
				break
		}

		return (
			<div className={`grid-container modal-gap-click-controls ${className}`}>
				<div className="spacer" />
				{firstControl}
				<div className="spacer" />
				<img key={`change`} alt="change" className={`change`} src={change} onClick={props.switchGapClickOffFirst}></img>
				<div className="spacer" />
				{secondControl}
				<div className="spacer" />
			</div>
		)
	}

	const renderClickRateControls = () => {
		let image
		let controls = (
			<div className="grid-container modal-click-rate">
				{getClickRateOptions().map((item, index) => {
					return (
						<img
							key={index}
							alt="click-rate"
							className={`click-rate ${get(props, `playback.click.rate.selectedImage`) === index + 1 ? `selected` : ``}`}
							onClick={() => {
								if (Number(item) === customImageKey) {
									props.setClickRateCustom({ bool: true })
									setShowCustomClickRateControls(!showCustomClickRateControls)
									return
								}
								clickRateSelection(item)
							}}
							src={require(`../../images/click/${item}.png`)}
						/>
					)
				})}
			</div>
		)
		if (showCustomClickRateControls) {
			controls = (
				<div className={`grid-container modal-click-rate custom ${get(props, 'device')}`}>
					<div className="spacer" />
					<div className="click-rate-control">
						<div className="left text bold size-18">{`
				Every ${get(props, `playback.click.rate.custom`) === false ? `` : ` ${get(props, `playback.click.rate.amount`)}`}`}</div>
						<Slider
							size="medium"
							className="click-rate"
							aria-label="translation"
							key={`slider-${get(props, `playback.click.rate.custom`)}`}
							defaultValue={get(props, `playback.click.rate.custom`) === false ? 0 : get(props, `playback.click.rate.amount`)}
							aria-labelledby="discrete-slider-custom"
							valueLabelDisplay="auto"
							step={1}
							min={1}
							max={get(clickRateNoteInfo({}), `max`)}
							marks
							onChangeCommitted={clickRateAmountChange}
						/>
					</div>
					<div className="spacer" />
					<div className="click-rate-control">
						<div className="left text bold size-18">{`${get(clickRateNoteInfo({}), 'name')}`}</div>
						<Slider
							size="medium"
							className="click-offset"
							aria-label="translation"
							key={`slider-${get(props, `playback.click.rate.time`)}`}
							defaultValue={get(clickRateNoteInfo({}), `value`)}
							valueLabelDisplay="off"
							step={1}
							min={ClickRateEnum.QUARTER}
							max={ClickRateEnum.TRIPLETS}
							marks
							onChangeCommitted={clickRateNoteChange}
						/>
					</div>
					<div className="spacer" />
				</div>
			)
			image = (
				<img
					key={`close`}
					alt="close"
					className={`close`}
					onClick={() => {
						props.setClickRateCustom({ bool: false })
						setShowCustomClickRateControls(!showCustomClickRateControls)
					}}
					src={close}
				></img>
			)
		}
		return (
			<>
				<div className="split-title">
					<div className="left text bold size-21">{`Click Rate`}</div>
					{image}
				</div>
				{controls}
			</>
		)
	}

	return (
		<>
			<Modal animation={false} show={props.show} onHide={props.toggle} keyboard={false} dialogClassName={className}>
				<Modal.Header closeButton>
					<div className="header">
						<Modal.Title>Metronome</Modal.Title>
						<Stack className={`volume`} spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
							<img
								className={`down`}
								alt={get(props, `playback.click.volume`) === 0 ? `volume-muted` : `volume-down`}
								src={get(props, `playback.click.volume`) === 0 ? volumeMute : volumeDown}
								onClick={() => {
									if (get(props, `playback.click.volume`) === 0) {
										return
									}
									props.setClickVolume({ volume: 0 })
								}}
							/>
							<Slider
								aria-label="Volume"
								className="slider-volume"
								key={`slider-${get(props, `playback.click.volume`) * 100}`}
								defaultValue={get(props, `playback.click.volume`) * 100}
								onChangeCommitted={(__, value) => {
									props.setClickVolume({ volume: value / 100 })
								}}
							/>
							<img alt={`volume-up`} src={volumeUp} />
						</Stack>
					</div>
				</Modal.Header>
				<Modal.Body className={className}>
					<div className="click">
						<div className="control-group-modal">{renderClickRateControls()}</div>
						<div className="control-group-modal">
							{renderGapClickTitle()}
							{renderGapClickContents()}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className={className}>
					<div className="left">{renderFooterControls()}</div>
					<div className="right">
						<Button
							className="action-2"
							onClick={() => {
								setShowGapClickControls(false)
								setShowCustomClickRateControls(false)
								props.resetClick()
							}}
						>
							Reset
						</Button>
						<Button
							className="action-3"
							onClick={() => {
								get(props, `setUseMetronome`)(true)
								get(props, `toggle`)()
							}}
						>
							Confirm
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const playback = getPlaybackState(state)
	const themeName = getThemeName(state)
	return { rendering, playback, themeName }
}

export default connect(mapStateToProps, {
	setUseMetronome,
	setClickVolume,
	setClickrate,
	setClickOffsetRate,
	setClickOffsetAmount,
	setClickMatchRhythm,
	setClickMarkOne,
	setGapClickOn,
	setGapClickOff,
	setClickRateAmount,
	setClickRateCustom,
	setClickRateNote,
	switchClickOffsetRate,
	resetClick,
	switchGapClickOffFirst,
})(ClickModal)
