import React from 'react'
import { get, findIndex } from 'lodash'
import { connect } from 'react-redux'

import { useIonPicker, IonItem, IonLabel } from '@ionic/react'

import { getIsPlaying, getTime, getTimeSignature, isTimeSignatureUpdateAllowed } from '../../../../../../../redux/selectors/playback'
import { timeSignatureLimits } from '../../../../../../../utils/functions'

const TSTop = ({ changeTimeSignatureTop, playing, timeSignatureUpdateAllowed, ts }) => {
	const [present] = useIonPicker()

	const getLabel = () => `Top - ${get(ts, `top`)}`

	const openPicker = async () => {
		if (playing) {
			return
		}
		const limits = timeSignatureLimits(get(ts, `bottom`))
		let options = []
		for (let i = 0; i <= get(limits, [`limits`, `upper`]); i++) {
			if (i < get(limits, [`limits`, `lower`])) {
				continue
			}
			options.push({
				text: `${i}`,
				value: i,
			})
		}
		const selectedIndex = findIndex(options, (o) => get(o, `value`) === get(ts, `top`))

		present({
			columns: [
				{
					name: 'timeSignature',
					selectedIndex,
					options,
				},
			],
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'Confirm',
					handler: (value) => {
						changeTimeSignatureTop({ value: get(value, [`timeSignature`, `value`]) })
					},
				},
			],
		})
	}

	return (
		<IonItem detail button disabled={playing || !timeSignatureUpdateAllowed} onClick={openPicker}>
			<IonLabel>{getLabel()}</IonLabel>
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const time = getTime(state)
	const playing = getIsPlaying(state)
	const timeSignatureUpdateAllowed = isTimeSignatureUpdateAllowed(state)
	const ts = getTimeSignature(state)

	return {
		time,
		playing,
		timeSignatureUpdateAllowed,
		ts,
	}
}

export default connect(mapStateToProps, {})(TSTop)
