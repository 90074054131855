import React, { useRef, useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import concat from 'lodash/concat'
import chunk from 'lodash/chunk'
import forEach from 'lodash/forEach'
import take from 'lodash/take'
import filter from 'lodash/filter'
import sample from 'lodash/sample'
import slice from 'lodash/slice'
import takeRight from 'lodash/takeRight'
import map from 'lodash/map'
import includes from 'lodash/includes'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import set from 'lodash/set'
import split from 'lodash/split'
import lowerCase from 'lodash/lowerCase'
import inRange from 'lodash/inRange'
import findIndex from 'lodash/findIndex'
import flattenDeep from 'lodash/flattenDeep'
import isUndefined from 'lodash/isUndefined'

import {
	getPlaybackState,
	getMsRhythmDuration,
	getMsBarDuration,
	getClickRate,
	getNoOfBeatsToRender,
	getBeatsPerBar,
	isTripletTimeSignature,
	getTimeToUse,
	toSwingClick,
	clickVolumeBoost,
	isTradeMode,
	getIsTimeSignatureOdd,
	getIsStraightTime,
	getNoOfBars,
	getClickAccentPattern,
	isEightNoteTime,
	getTimeSignatureHasBackbeat,
	swingable,
	getIsSixTwelveEight,
	getIsTimeSignature44,
	getSubdivisionBarDuration,
	getIsPracticeModeOn,
	getIsReadingMode,
	getShortBeatLength,
	getIsTripletTime,
	getGrooveSettings,
	getRVPermutationsStartingPoints,
	getRVPermutationsGroupingIndex,
} from '../redux/selectors/playback'
import { getAudioState } from '../redux/selectors/audio'
import { getRenderingState, getIsApp, getThemeName, getPlatformClass, getIsRhythmicVocabularyPermutations } from '../redux/selectors/rendering'
import {
	setBars,
	setTime,
	setTimeName,
	setPlaybackTime,
	setRandom,
	setRenderedArray,
	setPlayThis,
	setAnalyticsPrompt,
	setBpm,
	setMode,
	setPlaying,
	setUseMetronome,
	setUseCountIn,
	setUseGhosts,
	setPlaybackAs,
	setShowGrooveModal,
	setGrooveCymbalPattern,
	setGrooveCymbalSound,
	setGrooveGhosts,
	setGrooveBackbeat,
	setSwing,
	setIsolated,
	setHighlighted,
	setBarSettings,
	setShowLoadModal,
	setShowFeedbackModal,
	setDonationPrompt,
	setAudioContext,
	setShowFilters,
	setShowSettings,
	setSaveable,
	setLoadable,
	setFeaturesList,
	setGrooveMix,
	setGrooveLock,
	setRhythmLock,
	setTimeSignature,
	setTimeSignatureBottom,
	setTimeSignatureTop,
	setMobileControlsDown,
	setRhythmStartTime,
	setAccuracy,
	setRhythmTimings,
	setUserHits,
	userHit,
	userMiss,
	setCustom,
	setCustomArray,
	setRenderingMode,
	setApplicationText,
	setSpace,
	resetPreset,
	setPreset,
	setPresetConstants,
	setPresetShuffleAll,
	setExactClickRate,
	setExactClickOffset,
	setExactClickGap,
	setClickModalHasOpened,
	setShowHelperModal,
	addRhythmHistory,
	clearRhythmHistory,
	togglePracticeModal,
	rhythmicVocabPermutationsSetStartPoints,
} from '../redux/actions'

import { useQueryParams, NumberParam, ArrayParam, withDefault, StringParam, BooleanParam, NumericObjectParam } from 'use-query-params'
import { get as idbGet, set as idbSet } from 'idb-keyval'
import ReactGA from 'react-ga'
import { useParams, useHistory } from 'react-router-dom'
import { useIonToast } from '@ionic/react'

import PracticePage from '../components/IonicUI/Pages/Practice'
import Navbar from '../components/Navbar/index.js'
import NavbarSettings from '../components/Navbar/settings'
import Filters from '../components/Filters'
import Canvas from '../components/Canvas.js'
import GrooveModal from '../components/Modals/GrooveModal.js'
import LoadModal from '../components/Modals/LoadModal.js'
import FeedbackModal from '../components/Modals/FeedbackModal.js'
import HelperModal from '../components/Modals/HelperModal.js'
import PracticeModal from '../components/Modals/PracticeModal.js'
import MobileSettings from '../components/MobileSettings'
import PracticeSurface from '../components/PracticeSurface'
import Error from '../components/Banners/error'
import Analytics from '../components/Banners/analytics'
import UpdatePrompt from '../components/Banners/updatePrompt'
import UpdateList from '../components/Banners/updateList'
import Donations from '../components/Banners/donations'

import { StraightPresets, TripletPresets } from '../utils/constants/presets'
import SixteenthSounds from '../utils/constants/sounds/16th-sounds'
import EighthSounds from '../utils/constants/sounds/8th-sounds'
import TripSounds from '../utils/constants/sounds/trip-sounds'
import SixEightSounds from '../utils/constants/sounds/6-8-sounds'
import {
	keys as PracticeKeys,
	mobileKeys as MobilePracticeKeys,
	setTimeStamp as setPracticeTimeStamp,
	closestHit,
	practiceClickAddition,
	practiceTapAddition,
	closestHitPolling,
} from '../utils/practice'
import { hasInteractedWithLatestLoop, hasInteractedWithPracticeState } from '../utils/practice/state'
import Modes from '../utils/constants/enum/mode'
import { LanguageEnum } from '../utils/constants/enum/language'
import ClickRateEnum from '../utils/constants/enum/click'
import { FeaturesListEnum } from '../utils/constants/enum/featuresList'
import RenderingModes from '../utils/constants/enum/renderingMode'
import Times from '../utils/constants/enum/time'
import { Cymbal, Sounds } from '../utils/constants/enum/sounds'
import { straightDownUpsKey, straightDownUpsOptions } from '../utils/downups/straight'
import { tripletDownUpsOptions, tripletDownUpsKey, tripletDownUpsOnesAndZerosToSoundKey, TripletDownUpImages } from '../utils/downups/triplets'
import { sdImageMap, sdGroupMap, SDGroups } from '../utils/s-d/triplets'
import { firstGrooveLevels } from '../utils/first-grooves'
import GrooveOptions from '../utils/constants/groove-elements'
import {
	timeSignatureLimits,
	arrayEquals,
	errorOccurred,
	prod,
	dev,
	countOccurrences,
	wakeLockAvailable,
	desktop,
	getMobileHeightParams,
	lowestCommonMultiple,
	mobileTimeSignatureAnimation,
	isIonic,
	generateUniqueRandomArray,
} from '../utils/functions'

import { timeSignatureStyler } from '../utils/stylers/timeSignature'
import { playalongHitsStyler } from '../utils/stylers/playalongHits'

import { triggerTranslation } from '../utils/text'
import { containsEncodedComponents, queryIsNumber, querySettingsValidation } from '../utils/query-params'
import {
	metronomeOneLevel,
	metronomeLevel,
	metronomeGhostLevel,
	kickLevel,
	snareGhostLevel,
	hhAccentLevel,
	hhGhostLevel,
	rideAccentLevel,
	rideGhostLevel,
} from '../utils/constants/sounds/volume'
import { practiceSummary } from '../utils/ionic/toast'
import { BackbeatPatterns, CymbalPatterns } from '../utils/constants/enum/groove'
import { downUpNoOfBeats } from '../utils/downups/utils'
import { straightOptions as rvOptions } from '../utils/rhythmic-vocabulary-permutations'

import useScreenLock from '../hooks/useScreenLock'
import usePopulatePlay from '../hooks/usePopulatePlay'
import useRenderer from '../hooks/useRenderer'
import useClearRhythm from '../hooks/useClearRhythm'
import useRhythmName from '../hooks/useRhythmName'
import useIsolate from '../hooks/useIsolate'
import useComplexityLevel from '../hooks/useComplexityLevel'
import useSwipeable from '../hooks/Swipeable/useSwipeable'
import useWindowResize from '../hooks/useWindowResize'
import useKeyboard from '../hooks/useKeyboard'

const RhythmBot = ({
	audio,
	rendering,
	playback,
	msBarDuration,
	msRhythmDuration,
	clickRate,
	clickRateCountIn,
	clickAccentPattern,
	clickAccentPatternCountIn,
	noOfBeatsPerBar,
	noOfBeatsToRender,
	tripletTimeSignature,
	timeToUse,
	swingClick,
	clickBoost,
	isTrade,
	isOdd,
	isStraightTime,
	isTripletTime,
	noOfBars,
	eighthNoteTimeSignature,
	hasDefaultBackbeat,
	isSwingable,
	isSixTwelveEight,
	is44,
	subdivisionBarDuration,
	isPracticeModeOn,
	themeName,
	isReadingMode,
	shortBeatLength,
	platformClass,
	groove = {},
	isRVPermutations,
	RVPermutationsStartingPoints,
	RVGroupingIndex,
	//Actions
	//Playback
	setBars,
	setTime,
	setPlaybackTime,
	setBpm,
	setMode,
	setPlaying,
	setUseMetronome,
	setUseCountIn,
	setUseGhosts,
	setPlaybackAs,
	setGrooveCymbalPattern,
	setGrooveCymbalSound,
	setGrooveGhosts,
	setGrooveBackbeat,
	setGrooveMix,
	setSwing,
	setRandom,
	setRenderedArray,
	setPlayThis,
	setIsolated,
	setRhythmLock,
	setGrooveLock,
	setTimeSignature,
	setTimeSignatureBottom,
	setTimeSignatureTop,
	setRhythmStartTime,
	setAccuracy,
	setRhythmTimings,
	userHit,
	userMiss,
	setUserHits,
	setCustom,
	setCustomArray,
	setSpace,
	resetPreset,
	setPreset,
	setPresetConstants,
	setPresetShuffleAll,
	setExactClickRate,
	setExactClickOffset,
	setExactClickGap,
	addRhythmHistory,
	clearRhythmHistory,
	//Rendering
	setAnalyticsPrompt,
	setShowGrooveModal,
	setHighlighted,
	setBarSettings,
	setSaveable,
	setLoadable,
	setShowLoadModal,
	setShowFeedbackModal,
	setShowHelperModal,
	setDonationPrompt,
	setTimeName,
	setShowFilters,
	setShowSettings,
	setFeaturesList,
	setMobileControlsDown,
	setRenderingMode,
	setApplicationText,
	isApp,
	setClickModalHasOpened,
	togglePracticeModal,
	rhythmicVocabPermutationsSetStartPoints,
	//Audio
	setAudioContext,
	//Global refs
	refs = {},
}) => {
	const history = useHistory()
	const [device] = useWindowResize()
	const { languageCode } = useParams() // e.g. RB/language/languageCode
	const [ionicToast] = useIonToast()

	const loading = get(rendering, `showModals.load`) || get(rendering, `showModals.feedback`) || get(rendering, `showModals.soundbank`)
	const pageViewBaseCount = 3 // Controls how often to ask for donations

	const { turnOn: turnScreenLockOn, turnOff: turnScreenLockOff } = useScreenLock()
	const { populatePlay, direct: populatePlayDirect } = usePopulatePlay()
	const { render } = useRenderer()
	const { clearRhythm } = useClearRhythm()
	const { updateLevel } = useComplexityLevel()
	const { isolate } = useIsolate()
	const { getRhythmName, set: setRhythmName } = useRhythmName()
	const { MainSwipes } = useSwipeable()

	// TODO move this to redux state?
	const [accuracySummary, setAccuracySummary] = useState(false)

	// Presets
	const [presetName, setPresetName] = useState(null)
	const [longMelodyArray, setLongMelodyArray] = useState([])

	//Audio/Playback
	const crotchetTime = 60 / get(playback, `bpm`)
	const eighthNoteTime = 60 / get(playback, `bpm`) / 2
	const sixteenthNoteTime = 60 / get(playback, `bpm`) / 4
	const tripletTime = 60 / get(playback, `bpm`) / 3

	//Timers and intervals
	const accurateInterval = require('accurate-interval')

	//Browser wake lock API
	const isWakeLockAvailable = wakeLockAvailable()

	// Valid query params for controlling most of the apps settings
	const [query] = useQueryParams({
		mode: NumberParam,
		playbackMode: NumberParam,
		playback: NumberParam,
		bars: NumberParam,
		time: NumberParam,
		swing: NumberParam,
		bpm: NumberParam,
		level: NumberParam,
		space: NumberParam,
		cymbal: NumberParam,
		cymbalSound: NumberParam,
		backbeat: NumberParam,
		grooveMix: NumberParam,
		click: BooleanParam,
		countIn: BooleanParam,
		ghosts: BooleanParam,
		timeSignature: StringParam,
		beat: withDefault(ArrayParam, []),
		grouping: withDefault(ArrayParam, []),
		clickRate: NumericObjectParam,
		clickOffset: NumericObjectParam,
		clickGap: NumericObjectParam,
		enforceDownbeat: BooleanParam,
	})

	const [grooveOrder, setGrooveOrder] = useState(false)
	const grooveOrderRef = useRef(grooveOrder)

	/**
	 * References to for rv permutations mode
	 */
	const rvHasLoopedRef = useRef(false)
	const rvPermutationsStartingPointsRef = useRef(RVPermutationsStartingPoints)

	useEffect(() => {
		if (!isRVPermutations) return
		if (playback.playing) return

		rvPermutationsStartingPointsRef.current = RVPermutationsStartingPoints
	}, [playback.playing, RVPermutationsStartingPoints, isRVPermutations])

	const audioContextCheck = useCallback(() => {
		if (!audio.audioContext || audio.audioContext === null) {
			return false
		}
		return true
	}, [audio.audioContext])

	/**
	 * Respond to change of `languageCode`, triggering text translations where possible
	 */
	useEffect(() => {
		switch (languageCode) {
			case 'de':
				setApplicationText(triggerTranslation({ language: LanguageEnum.GERMAN }))
				break
			default:
				setApplicationText(triggerTranslation({ language: LanguageEnum.ENGLISH }))
				break
		}
	}, [languageCode])

	useEffect(() => {
		if (!isIonic()) return
		if (!isEmpty(get(rendering, `renderedArray`, []))) return
		generateRhythm({})
	}, [rendering.renderedArray])

	/**
	 * Time signature styling based on how many beats are rendered
	 */
	useEffect(() => {
		const timeSignature = get(playback, `timeSignature`)
		const beatsPerBar = get(timeSignature, `top`) / (get(timeSignature, `bottom`) / 4)
		timeSignatureStyler(beatsPerBar)
		updateLevel({ level: get(playback, 'level') }) // Call updateLevel incase we have moved into 6/12:8
	}, [playback.timeSignature])

	useEffect(() => {
		if (!get(playback, `rhythmLock`, false)) {
			return
		}
		setGrooveOrder(false)
	}, [playback.rhythmLock])
	useEffect(() => {
		grooveOrderRef.current = grooveOrder
	}, [grooveOrder])

	/**
	 * Respond to query params
	 * @param {Object} query object adhering to specs of use-query-params
	 */
	const processQueryParams = ({ query }) => {
		let queryParams = get(window, [`location`, `search`], ``)
		if (isApp) {
			queryParams = get(window, `appQueryString`)
		}
		if (isEmpty(queryParams)) {
			return
		}
		queryParams = queryParams.substring(1)
		const details = containsEncodedComponents({ queryParams })
		if (get(details, 'contains', false)) {
			// If the query params are encoded push the decoded version to
			// history and reload the page
			const toReplace = `?${get(details, 'decoded')}`
			if (isApp) {
				set(window, `appQueryString`, toReplace)
			}
			history.replace(toReplace, { update: true })
			return history.go(0)
		}

		if (isApp) {
			// TODO what needs doing here to reset the app?
			setRenderingMode(RenderingModes.NORMAL)
		}

		try {
			// Validate the core params
			const validation = querySettingsValidation({
				renderingMode: get(query, 'mode', RenderingModes.NORMAL),
				time: get(query, 'time', false),
				playbackMode: get(query, 'playbackMode', false),
				playbackAs: get(query, 'playback', false),
				timeSignature: get(query, 'timeSignature', false),
			})

			// Validated constants for the core settings - disallowing silly
			// things in query params (tut, tut)
			const vRenderingMode = get(validation, `renderingMode`)
			const vTime = get(validation, `time`)
			const vPlaybackMode = get(validation, `playbackMode`)
			const vPlaybackAs = get(validation, `playbackAs`)
			const vTimeSignature = get(validation, `timeSignature`)

			const beat = map(get(query, 'beat'), (entry) => Number(entry))
			const groupings = map(get(query, 'grouping'), (entry) => Number(entry))
			const customMode = !isEmpty(beat) && !isEmpty(groupings)

			// TODO please make this less ugly - have to make sure everything is
			// a valid number otherwise things will break
			let bars, swing, bpm, space, level, backbeat, grooveMix, cymbal, cymbalSound
			let customGrooveParams = false
			if (queryIsNumber(get(query, 'bars', false))) {
				bars = get(query, 'bars', false) > 6 ? 6 : get(query, 'bars', false) < 1 ? 1 : get(query, 'bars', false)
			} else {
				bars = false
			}
			if (queryIsNumber(get(query, 'swing', false))) {
				swing = get(query, 'swing', false) >= 0 && get(query, 'swing', false) <= 100 ? get(query, 'swing', false) : false
			} else {
				swing = false
			}
			if (queryIsNumber(get(query, 'bpm', false))) {
				bpm = get(query, 'bpm', false) >= 10 && get(query, 'bpm', false) <= 280 ? get(query, 'bpm', false) : false
			} else {
				bpm = false
			}
			if (queryIsNumber(get(query, 'space', false))) {
				space = get(query, 'space', false) >= 0 && get(query, 'space', false) <= 100 ? get(query, 'space', false) : false
			} else {
				space = false
			}
			if (queryIsNumber(get(query, 'level', false))) {
				level = get(query, 'level', false) > 0 && get(query, 'level', false) < 5 ? get(query, 'level', false) : false
			} else {
				level = false
			}
			// Groove params
			if (queryIsNumber(get(query, 'backbeat', false))) {
				backbeat = get(query, 'backbeat', false) >= 0 && get(query, 'backbeat', false) <= 5 ? get(query, 'backbeat', false) : false
			} else {
				backbeat = false
			}
			if (queryIsNumber(get(query, 'grooveMix', false))) {
				grooveMix = get(query, 'grooveMix', false) >= 0 && get(query, 'grooveMix', false) <= 100 ? get(query, 'grooveMix', false) : false
			} else {
				grooveMix = false
			}
			if (queryIsNumber(get(query, 'cymbal', false))) {
				cymbal = get(query, 'cymbal', false) >= 0 && get(query, 'cymbal', false) <= 16 ? get(query, 'cymbal', false) : false
			} else {
				cymbal = false
			}
			if (queryIsNumber(get(query, 'cymbalSound', false))) {
				cymbalSound = get(query, 'cymbalSound', false) >= 0 && get(query, 'cymbalSound', false) <= 1 ? get(query, 'cymbalSound', false) : false
			} else {
				cymbalSound = false
			}

			const handleGrooveParams = ({ timeOverride = false, cymbalSound = false, cymbal = false, backbeat = false, grooveMix = false }) => {
				const t = timeOverride || vTime || Times.STRAIGHT
				let ret = false
				if (cymbal !== false) {
					let allowed = false
					find(get(GrooveOptions, `options`), (grooveOpts) => {
						if (get(grooveOpts, `id`) !== t) {
							return
						}
						if (includes(get(grooveOpts, `hh`), cymbal)) {
							allowed = true
							return false
						}
					})
					if (allowed) {
						ret = true
						setGrooveCymbalPattern(cymbal)
					}
				}
				if (cymbalSound !== false) {
					setGrooveCymbalSound(cymbalSound)
				}
				if (backbeat !== false) {
					ret = true
					setGrooveBackbeat(backbeat)
				}
				if (grooveMix !== false) {
					ret = true
					setGrooveMix(grooveMix)
				}
				return ret
			}

			if (vPlaybackMode !== false) {
				setMode(vPlaybackMode)
			}
			if (vPlaybackAs !== false) {
				setPlaybackAs(vPlaybackAs)
			}
			if (vPlaybackAs === Sounds.GROOVE) {
				customGrooveParams = handleGrooveParams({ cymbalSound, cymbal, backbeat, grooveMix })
			}
			if (swing !== false) {
				setSwing(swing)
			}
			if (bpm !== false) {
				setBpm(bpm)
			}
			if (space !== false) {
				setSpace(space)
			}
			if (!isNil(get(query, 'ghosts'))) {
				setUseGhosts(get(query, 'ghosts'))
			}

			// Click
			if (!isNil(get(query, 'click'))) {
				setUseMetronome(get(query, 'click'))
			}
			if (!isNil(get(query, 'countIn'))) {
				setUseCountIn(get(query, 'countIn'))
			}
			if (!isNil(get(query, 'clickRate'))) {
				setExactClickRate({ clickRateObj: get(query, 'clickRate') })
			}
			if (!isNil(get(query, 'clickOffset'))) {
				setExactClickOffset({ clickOffsetObj: get(query, 'clickOffset') })
			}
			if (!isNil(get(query, 'clickGap'))) {
				setExactClickGap({ clickGapObj: get(query, 'clickGap') })
			}
			if (!isNil(get(query, 'clickRate')) || !isNil(get(query, 'clickOffset')) || !isNil(get(query, 'clickGap'))) {
				// If any of these query params have been provided then we need
				// to pretend the click modal has opened so the click
				// description will show
				setClickModalHasOpened()
			}

			switch (vRenderingMode) {
				case RenderingModes.DOWNUP.STRAIGHT:
				case RenderingModes.DOWNUP.TRIPLETS:
					const straight = vRenderingMode === RenderingModes.DOWNUP.STRAIGHT
					const t = straight ? Times.STRAIGHT : Times.TRIPLETS
					const b = bars || playback.bars
					setRenderingMode(vRenderingMode)
					setTimeSignature(vTimeSignature || '4:4')
					setTime(t)
					setPlaybackTime(t)
					if (straight) {
						const downUpGroupings = straightDownUpsOptions
						setCustom(true)
						setCustomArray(downUpGroupings)
					}
					setBars(b)
					let beatsPerBar = 4
					if (vTimeSignature && split(vTimeSignature, ':')[0] === `2`) {
						beatsPerBar = 2
					}
					if (!straight) {
						beatsPerBar *= 2
					}
					if (vPlaybackAs === Sounds.GROOVE) {
						handleGrooveParams({ timeOverride: t, cymbalSound, cymbal, backbeat, grooveMix })
					}
					generateRhythm({ renderingMode: vRenderingMode, playbackTime: t, beatsOverride: b * beatsPerBar })
					return
				case RenderingModes.SD.TRIPLETS.ONE:
				case RenderingModes.SD.TRIPLETS.TWO:
				case RenderingModes.SD.TRIPLETS.THREE:
				case RenderingModes.SD.TRIPLETS.FOUR:
				case RenderingModes.SD.TRIPLETS.FIVE:
					setRenderingMode(vRenderingMode)
					setTimeSignature('4:4')
					setTime(Times.TRIPLETS)
					setPlaybackTime(Times.TRIPLETS)
					setBars(bars || playback.bars)
					if (vPlaybackAs === Sounds.GROOVE) {
						handleGrooveParams({ timeOverride: Times.TRIPLETS, cymbalSound, cymbal, backbeat, grooveMix })
					}
					generateRhythm({ renderingMode: vRenderingMode, playbackTime: Times.TRIPLETS, beatsOverride: bars || 1 })
					return
				case RenderingModes.FIRSTGROOVES.ALL:
				case RenderingModes.FIRSTGROOVES.ONE:
				case RenderingModes.FIRSTGROOVES.TWO:
				case RenderingModes.FIRSTGROOVES.THREE:
					setRenderingMode(vRenderingMode)
					setTimeSignature('4:4')
					setPlaybackAs(Sounds.GROOVE)
					if (!customGrooveParams) {
						turnOnGrooveMode({ random: false, time: Times.EIGHTH, fromClick: false })
					}
					setTime(Times.EIGHTH)
					setPlaybackTime(Times.EIGHTH)
					if (!isEmpty(groupings)) {
						setCustom(true)
						setCustomArray(groupings)
						setMode(Modes.LOOPREADING)
					}
					setBars(bars || playback.bars)
					if (!isEmpty(beat)) {
						render({ images: beat, renderingMode: vRenderingMode })
						setRhythmName({ name: getRhythmName({ renderingMode: vRenderingMode, customOverride: true }) })
						populatePlay({ arr: beat, time: Times.EIGHTH, renderingMode: vRenderingMode })
						return
					}
					generateRhythm({ renderingMode: vRenderingMode, playbackTime: Times.EIGHTH, beatsOverride: bars || 1 })
					return
				case RenderingModes.RHYTHMIC_VOCABULARY_PERMUTATIONS.STRAIGHT:
					setRenderingMode(vRenderingMode)
					setTimeSignature('4:4')
					setTime(Times.STRAIGHT)
					setPlaybackTime(Times.STRAIGHT)
					setRhythmName({ name: getRhythmName({ renderingMode: vRenderingMode }) })
					let rvBars = bars || playback.bars
					if ([Modes.LOOPREADING, Modes.TRADEREADING, Modes.CLICKREADING].includes(vPlaybackMode)) {
						rvBars = Math.max(2, rvBars)
					}
					setBars(rvBars)
					rhythmicVocabPermutationsSetStartPoints(Array.from({ length: rvBars }, () => [0]))
					return
				default:
			}

			if (level !== false && vTime !== false && !customMode) {
				updateLevel({ vTime, level })
			}

			let sixTwelveEight = false

			if (bars !== false) {
				if ([Modes.LOOPREADING, Modes.TRADEREADING, Modes.CLICKREADING].includes(vPlaybackMode)) {
					setBars(Math.max(2, bars))
				} else {
					setBars(bars)
				}
			}

			if (vTimeSignature !== false) {
				setTimeSignature(vTimeSignature)
				sixTwelveEight =
					get(vTimeSignature.split(':'), '1') === '8' && (get(vTimeSignature.split(':'), '0') === '6' || get(vTimeSignature.split(':'), '0') === '12')
			}
			if (vTime !== false) {
				setTime(vTime)
				setPlaybackTime(vTime)
				if (!isEmpty(beat)) {
					/**
					 * Find out if this shared rhythm matches a preset
					 * Describe the rhythm if it does
					 * @param {Object} presetConstants the constants to search
					 * @returns
					 */
					const presetSearch = (presetConstants) => {
						let ret = false
						const maxTries = 10
						find(get(presetConstants, `presets`), (entry) => {
							if (lowerCase(get(entry, `preset`)) === `longer melodies`) {
								return
							}
							forEach(get(entry, `val.presets`), (pre) => {
								if (get(beat, 'length') % get(pre, `arr.length`) !== 0) {
									return
								}
								let a = get(pre, `arr`)
								let i = 0
								while (get(a, 'length') !== get(beat, 'length')) {
									if (i > maxTries) {
										break
									}
									a = [...a, ...get(pre, `arr`)]
									i++
								}
								if (arrayEquals(a, beat)) {
									ret = `${get(entry, `preset`)} - ${get(pre, `preset`)}`
									return false
								}
							})
						})
						return ret
					}
					const customText = !isEmpty(groupings) ? ` - Custom Groupings` : ``
					let presetName = false
					switch (vTime) {
						case Times.STRAIGHT:
							presetName = presetSearch(StraightPresets())
							setRhythmName({ name: presetName || `Shared 16th Note Rhythm ${customText}` })
							setTimeName('16th Note')
							if (sixTwelveEight) {
								setBarSettings({
									path: 'six-eight',
									style: 'straight',
								})
								break
							}
							setBarSettings({
								path: 'straight',
								style: 'straight',
							})
							break
						case Times.EIGHTH:
							setRhythmName({ name: `Shared 8th Note Rhythm ${customText}` })
							setTimeName('8th Note')
							if (sixTwelveEight) {
								setBarSettings({
									path: 'six-eight',
									style: 'straight',
								})
								break
							}
							setBarSettings({
								path: 'straight-8',
								style: 'straight',
							})
							break
						case Times.TRIPLETS:
							presetName = presetSearch(TripletPresets)
							setRhythmName({ name: presetName || `Shared Triplet Rhythm ${customText}` })
							setTimeName('Triplet')
							setBarSettings({
								path: 'swung',
								style: 'swung',
							})
							break
						default:
							setRhythmName({ name: `Shared Mixed Rhythm ${customText}` })
							setTimeName('Mixed')
							setBarSettings({
								path: 'mixed',
								style: 'mixed',
							})
							break
					}
					setRenderedArray(beat)
					populatePlay({ arr: beat, time: vTime, sixEightOverride: sixTwelveEight })
				}
			}
			if (!isEmpty(groupings)) {
				setCustom(true)
				setCustomArray(groupings)
			}
		} catch (e) {
			errorOccurred(`RhythmBot.processQueryParams: ${e}`)
		}
	}

	/**
	 * If a custom rendering mode is loaded up in Groove mode and there are no
	 * custom groove query params, reset the groove params to the default for this time.
	 */
	const grooveAdjustments = () => {
		const adjust = get(playback, `playbackAs`) === Sounds.GROOVE
		if (!adjust) {
			return
		}
		const queryParams = get(query, `cymbal`, false) || get(query, `backbeat`, false) || get(query, `grooveMix`, false)
		if (queryParams) {
			// If there are query params for these settings don't adjust
			return
		}
		const renderingMode = get(rendering, `mode`)
		switch (renderingMode) {
			case RenderingModes.DOWNUP.STRAIGHT:
				return turnOnGrooveMode({ random: false, time: Times.STRAIGHT })
			case RenderingModes.DOWNUP.TRIPLETS:
			case RenderingModes.SD.TRIPLETS.ONE:
			case RenderingModes.SD.TRIPLETS.TWO:
			case RenderingModes.SD.TRIPLETS.THREE:
			case RenderingModes.SD.TRIPLETS.FOUR:
			case RenderingModes.SD.TRIPLETS.FIVE:
				return turnOnGrooveMode({ random: false, time: Times.TRIPLETS })
			default:
				return
		}
	}

	/**
	 * Respond to query params
	 */
	useEffect(() => processQueryParams({ query }), [])

	/**
	 * Query handler for Capacitor app deep links
	 */
	useEffect(() => {
		if (!isApp) {
			return
		}
		processQueryParams({ query })
	}, [query])

	/**
	 * Make relevant groove adjustments
	 */
	useEffect(() => grooveAdjustments(), [playback.playbackAs])

	/**
	 * For evaluating which banners should display on page load
	 */
	useEffect(() => {
		// TODO - make sure this change works - probably split into 2 useEffects
		const evaluateBanners = async () => {
			let showMobileTimeSignature = true

			const heightParams = getMobileHeightParams()
			document.documentElement.style.setProperty('--mobile-controls-height-divider', get(heightParams, `rest`))

			const analyticsAck = await idbGet('analyticsAck')
			if (prod({ isApp })) {
				if (typeof analyticsAck === 'boolean') {
					if (analyticsAck) {
						console.log('Initialising analytics')
						ReactGA.initialize(process.env.REACT_APP_GA_KEY)
						ReactGA.pageview(window.location.pathname + window.location.search)
					}
				} else {
					showMobileTimeSignature = false
					setAnalyticsPrompt(true)
				}
			}
			const savedFeaturesList = Number(localStorage.getItem('featuresList'))
			const pageViews = await idbGet('pageViews')
			if (typeof pageViews === 'number') {
				switch (true) {
					case !isUndefined(languageCode):
						// Bypass all of this when in a different language
						break
					case dev() && process.env.REACT_APP_FEATURES_LIST !== 'false':
						// Show the features list on all dev sites
						showMobileTimeSignature = false
						setFeaturesList(FeaturesListEnum.ON)
						localStorage.setItem('featuresList', FeaturesListEnum.ON)
						break
					case pageViews < 1 || process.env.REACT_APP_FEATURES_LIST === 'false':
						// Don't show features in this case
						setFeaturesList(FeaturesListEnum.OFF)
						localStorage.setItem('featuresList', FeaturesListEnum.OFF)
						break
					default:
						if (savedFeaturesList === FeaturesListEnum.UPDATE) {
							showMobileTimeSignature = false
							setFeaturesList(FeaturesListEnum.ON)
							localStorage.setItem('featuresList', FeaturesListEnum.ON)
							setDonationPrompt(true)
							break
						}
						setFeaturesList(savedFeaturesList)
						localStorage.setItem('featuresList', savedFeaturesList)
						break
				}

				const pageViewMultiplier = await idbGet('pageViewMultiplier')
				if (pageViews >= Math.floor(pageViewBaseCount * pageViewMultiplier)) {
					//Page view count has been exceeded
					showMobileTimeSignature = false
					setDonationPrompt(true)
				}
			}

			// Short animation for mobile devices showing how to access the time signature
			if (showMobileTimeSignature) {
				mobileTimeSignatureAnimation()
			}
		}
		evaluateBanners()
	}, [setAnalyticsPrompt, setDonationPrompt, setFeaturesList])

	useEffect(() => {
		if (!get(playback, 'playing') || !isPracticeModeOn) {
			playalongHitsStyler({ reset: true })
			return
		}
		playalongHitsStyler({ accuracy: get(playback, 'practice.user.accuracy') })
	}, [playback.practice.user.accuracy])

	/**
	 * Register `Practice Mode` mobile screen tap - Simulated as a keyboard press
	 */
	useKeyboard({
		keyCodes: MobilePracticeKeys,
		props: {
			loading: get(rendering, 'showModals.feedback'),
		},
		callback: () => {
			if (!get(playback, 'playing') || !isPracticeModeOn) {
				return
			}
			return practiceTap({ click: true })
		},
	})

	/**
	 * Register `Practice Mode` keyboard tap
	 */
	useKeyboard({
		// TODO these keyboard codes could be mac specific, e.g. bottom row of
		// the keyboard
		// This is most of the bottom two rows of the keyboard
		keyCodes: PracticeKeys,
		props: {
			loading: get(rendering, 'showModals.feedback'),
		},
		callback: () => {
			practiceTap({})
		},
	})

	/**
	 * Generate query params for copying over app settings
	 * Can be triggered from Filters or CustomModal
	 * @param {Boolean} modal if coming from the custom modal
	 */
	const settingsQueryParams = ({ modal = false }) => {
		/**
		 * If there is a rendered rhythm, copy it too
		 */
		const handleRenderedRhythm = () => {
			const renderingMode = get(rendering, 'mode')
			switch (renderingMode) {
				case RenderingModes.NORMAL:
				case RenderingModes.FIRSTGROOVES.ONE:
				case RenderingModes.FIRSTGROOVES.TWO:
				case RenderingModes.FIRSTGROOVES.THREE:
				case RenderingModes.FIRSTGROOVES.ALL:
					break
				default:
					return
			}
			const renderedLength = get(rendering, [`renderedArray`, `length`])
			// Copy the current rhythm
			for (let i = 0; i < renderedLength; i++) {
				let grouping = get(rendering, ['renderedArray', i])
				beatStr += `beat=${grouping}&`
			}
		}
		/**
		 * The app is in custom mode, this will copy across the groupings selected
		 */
		const handleCustomGroupings = () => {
			const renderedLength = get(rendering, 'renderedArray.length')
			const customArray = get(playback, 'customArray')
			const sixTwelveEight = isSixTwelveEight
			const renderingMode = get(rendering, 'mode')
			const bars = get(playback, 'bars')
			const canHandleTS = get(playback, `timeSignature.bottom`) === 4 // whenever copying into custom mode, can only handle certain TS

			// Build groupings string
			forEach(customArray, (entry, __) => {
				groupingsStr += `grouping=${entry}&`
			})

			// Beats adjustment
			let beats = 8
			switch (true) {
				case renderingMode === RenderingModes.FIRSTGROOVES.ALL:
				case renderingMode === RenderingModes.FIRSTGROOVES.ONE:
				case renderingMode === RenderingModes.FIRSTGROOVES.TWO:
				case renderingMode === RenderingModes.FIRSTGROOVES.THREE:
					beats = 2
					break
				case sixTwelveEight:
					beats = 4
					if (get(playback, 'timeSignature.top') === 12) {
						beats *= 2
					}
					break
				case canHandleTS:
					beats = get(playback, `timeSignature.top`) * 2
					break
				default:
					break
			}

			switch (true) {
				case renderedLength > 0:
					barsStr = `bars=${bars}&`
					// Make sure every current grouping is compatible
					for (let i = 0; i < renderedLength; i++) {
						let grouping = get(rendering, ['renderedArray', i])
						if (!includes(customArray, grouping)) {
							grouping = sample(customArray)
						}
						beatStr += `beat=${grouping}&`
					}
					break
				default:
					barsStr = `bars=2&`
					for (let j = 0; j < beats; j++) {
						beatStr += `beat=${sample(customArray)}&`
					}
					break
			}

			// Set time and time signature string
			switch (true) {
				case renderingMode === RenderingModes.FIRSTGROOVES.ALL:
				case renderingMode === RenderingModes.FIRSTGROOVES.ONE:
				case renderingMode === RenderingModes.FIRSTGROOVES.TWO:
				case renderingMode === RenderingModes.FIRSTGROOVES.THREE:
					break
				case !canHandleTS && renderedLength > 0:
					// If we can't handle this TS but there is a rhythm
					// rendered, just copy it
					barsStr = `bars=${bars}&`
					beatStr = ``
					handleRenderedRhythm()
					break
				case canHandleTS:
				case sixTwelveEight:
					timeSignatureStr = `timeSignature=${get(playback, 'timeSignature.top')}:${get(playback, 'timeSignature.bottom')}&`
					break
				default:
					timeStr = `time=${get(playback, 'time.options')}&`
					timeSignatureStr = `timeSignature=4:4&`
					break
			}
		}

		const renderedArray = get(rendering, 'renderedArray')
		const renderingMode = get(rendering, 'mode')
		const playbackMode = get(playback, 'mode')
		const playbackAs = get(playback, 'playbackAs')
		const tsTop = get(playback, 'timeSignature.top')
		const tsBottom = get(playback, 'timeSignature.bottom')
		const bars = get(playback, 'bars')
		const bpm = get(playback, 'bpm')
		const swing = get(playback, 'swing')
		const space = get(playback, 'space')
		const customClickRate = get(playback, 'click.rate.custom')
		const customClickOffset = get(playback, 'click.offset.modified')
		const customClickGap = get(playback, 'click.gap.controlsTouched')
		let modeStr,
			playbackModeStr,
			barsStr,
			playbackStr,
			beatStr,
			groupingsStr,
			timeSignatureStr,
			swingStr,
			bpmStr,
			timeStr,
			levelStr,
			spaceStr,
			clickStr,
			countInStr,
			ghostsStr,
			cymbalStr,
			cymbalSoundStr,
			backbeatStr,
			grooveMixStr,
			clickRateStr,
			clickOffsetStr,
			clickGapStr
		modeStr =
			playbackModeStr =
			barsStr =
			playbackStr =
			beatStr =
			groupingsStr =
			timeSignatureStr =
			swingStr =
			bpmStr =
			timeStr =
			levelStr =
			spaceStr =
			clickStr =
			countInStr =
			ghostsStr =
			cymbalStr =
			cymbalSoundStr =
			backbeatStr =
			grooveMixStr =
			clickRateStr =
			clickOffsetStr =
			clickGapStr =
				``

		playbackModeStr = `playbackMode=${playbackMode}&`
		barsStr = `bars=${bars}&`
		bpmStr = `bpm=${bpm}&`
		clickStr = `click=${get(playback, 'click.on') ? 1 : 0}&`
		countInStr = `countIn=${get(playback, 'click.countIn') ? 1 : 0}&`
		ghostsStr = `ghosts=${get(playback, 'useGhosts') ? 1 : 0}&`
		if (playbackAs === Sounds.GROOVE) {
			backbeatStr = `backbeat=${get(groove, 'backbeat')}&`
			grooveMixStr = `grooveMix=${get(groove, 'mix')}&`
			cymbalStr = `cymbal=${get(groove, [`cymbal`, `pattern`])}&`
			cymbalSoundStr = `cymbalSound=${get(groove, [`cymbal`, `sound`])}&`
		}
		playbackStr = `playback=${playbackAs}` // Always the last added

		if (customClickRate) {
			let customStr = `custom-${+customClickRate}`
			let selectedImageStr = `selectedImage-${get(playback, 'click.rate.selectedImage')}`
			let amountStr = `amount-${get(playback, 'click.rate.amount')}`
			let timeStr = `time-${get(playback, 'click.rate.time')}`
			let markTheOneStr = `markTheOne-${+get(playback, 'click.rate.markTheOne')}`
			clickRateStr = `clickRate=${customStr}_${selectedImageStr}_${amountStr}_${timeStr}_${markTheOneStr}&`
		}

		if (customClickOffset) {
			let amountStr = `amount-${get(playback, 'click.offset.amount')}`
			let rateStr = `rate-${get(playback, 'click.offset.rate')}`
			let modifiedStr = `modified-${+customClickOffset}`
			clickOffsetStr = `clickOffset=${amountStr}_${rateStr}_${modifiedStr}&`
		}

		if (customClickGap) {
			let onStr = `on-${+get(playback, 'click.gap.on')}`
			let offStr = `off-${+get(playback, 'click.gap.off')}`
			let matchRhythmLengthStr = `matchRhythmLength-${+get(playback, 'click.gap.matchRhythmLength')}`
			let startOffStr = `startOff-${+get(playback, 'click.gap.startOff')}`
			let controlsTouchedStr = `controlsTouched-${+customClickGap}`
			clickGapStr = `clickGap=${onStr}_${offStr}_${matchRhythmLengthStr}_${startOffStr}_${controlsTouchedStr}&`
		}

		let disableSwing = false
		switch (renderingMode) {
			case RenderingModes.NORMAL:
				let t
				switch (true) {
					case get(playback, 'playbackTime.options') !== get(playback, 'time.options'):
						// These links would be broken unless the rhythm is regenerated
						t = get(playback, 'time.options')
						if (!isEmpty(get(rendering, 'renderedArray'))) {
							// e.g. have a rhythm rendered in triplets but the time set
							// to sixteenths
							generateRhythm({})
						}
						break
					default:
						t = get(playback, 'playbackTime.options') || get(playback, 'time.options')
						break
				}
				if (t === Times.TRIPLETS || t === Times.MIXED) {
					disableSwing = true
				}
				if (!modal && t !== Times.EIGHTH && get(playback, 'level') < 5) {
					levelStr = `level=${get(playback, 'level')}&`
				}
				timeStr = `time=${t}&`
				break
			default:
				modeStr = `mode=${get(rendering, 'mode')}&`
				break
		}
		if (space > 0) {
			spaceStr = `space=${space}&`
		}
		if (swing > 0 && !disableSwing) {
			swingStr = `swing=${swing}&`
		}
		switch (true) {
			case tsTop !== 4 || tsBottom !== 4:
			case get(rendering, 'mode') === RenderingModes.DOWNUP.STRAIGHT:
			case get(rendering, 'mode') === RenderingModes.DOWNUP.TRIPLETS:
				timeSignatureStr = `timeSignature=${get(playback, 'timeSignature.top')}:${get(playback, 'timeSignature.bottom')}&`
				break
			default:
				break
		}

		switch (true) {
			case get(playback, 'playbackTime.options') !== get(playback, 'time.options'):
				break
			case modal || get(playback, `custom`):
				handleCustomGroupings()
				break
			case !isEmpty(renderedArray):
				handleRenderedRhythm()
				break
			default:
				break
		}

		return `?${modeStr}${playbackModeStr}${barsStr}${timeSignatureStr}${beatStr}${groupingsStr}${swingStr}${bpmStr}${timeStr}${levelStr}${spaceStr}${clickStr}${countInStr}${clickRateStr}${clickOffsetStr}${clickGapStr}${ghostsStr}${cymbalStr}${cymbalSoundStr}${backbeatStr}${grooveMixStr}${playbackStr}`
	}

	// TODO - BROKEN!! save and load wont work with TS and 6/8 updates
	/**
	 * Save the current rhythm
	 */
	const saveRhythm = () => {
		let rhythmName = ''
		let timeName = ''
		switch (playback.playbackTime.options) {
			case Times.STRAIGHT:
				timeName = '16th Note'
				break
			case Times.EIGHTH:
				timeName = '8th Note'
				break
			case Times.TRIPLETS:
				timeName = 'Triplet'
				break
			default:
				timeName = 'Mixed'
		}
		rhythmName += playback.bars + ' Bar ' + timeName + ' Rhythm'
		let dateOptions = { month: 'short', day: 'numeric', year: 'numeric' }
		let today = new Date()
		let date = today.toLocaleDateString('en-US', dateOptions)
		let dateTime = today.getHours() + ':' + String(today.getMinutes()).padStart(2, '0')
		let rhythmDetails = {
			bars: playback.bars,
			playbackTime: playback.playbackTime.options,
			timeName: timeName,
			renderedArray: rendering.renderedArray,
			rhythmName: rhythmName,
			date: date,
			time: dateTime,
		}
		idbGet('savedRhythms').then((value) => {
			if (value) {
				let tempArr = [...value]
				tempArr.push(rhythmDetails)
				idbSet('savedRhythms', tempArr)
			} else {
				idbSet('savedRhythms', [rhythmDetails])
			}
		})
		setLoadable(true)
	}

	/**
	 * Load a saved rhythm
	 * @param {Object} rhythmDetails
	 */
	const loadRhythm = (rhythmDetails) => {
		setTime(rhythmDetails.playbackTime)
		setPlaybackTime(rhythmDetails.playbackTime)
		switch (rhythmDetails.playbackTime) {
			case Times.STRAIGHT:
				setBarSettings({
					path: 'straight',
					style: 'straight',
				})
				break
			case Times.EIGHTH:
				setBarSettings({
					path: 'straight-8',
					style: 'straight',
				})
				break
			case Times.TRIPLETS:
				setBarSettings({
					path: 'swung',
					style: 'swung',
				})
				break
			default:
				setBarSettings({
					path: 'mixed',
					style: 'mixed',
				})
		}
		setRhythmName({ name: rhythmDetails.rhythmName })
		setRenderedArray(rhythmDetails.renderedArray)
		populatePlay({ arr: rhythmDetails.renderedArray, time: rhythmDetails.playbackTime })
		setBars(rhythmDetails.bars)
		setSaveable([false, 'Saved'])
		setShowSettings(false)
	}

	/**
	 * Hide/Show the load modal
	 * @param {Array} rhythmsArray
	 * @param {Boolean} load
	 */
	const toggleLoadModal = (rhythmsArray, load) => {
		if (load) {
			loadRhythm(load)
		}
		if (isEmpty(rhythmsArray)) {
			setLoadable(false)
			setSaveable([true, 'Save'])
		}
		idbSet('savedRhythms', rhythmsArray.reverse())
		setShowLoadModal(!get(rendering, `showModals.load`))
	}

	/**
	 * Hide/Show the groove modal
	 */
	const toggleGrooveModal = () => setShowGrooveModal(!get(rendering, `showModals.groove`))

	/**
	 * Hide/Show the helper modal
	 */
	const toggleHelperModal = () => setShowHelperModal({ show: !get(rendering, `showModals.helper.show`) })

	/**
	 * Hide/Show the feedback modal
	 */
	const toggleFeedbackModal = () => setShowFeedbackModal(!get(rendering, 'showModals.feedback'))

	/**
	 * Turn on groove mode
	 * @param {*} random
	 * @param {*} time
	 * @param {*} fromClick
	 */
	const turnOnGrooveMode = ({ random = true, time, fromClick = false }) => {
		setPlaybackAs(Sounds.GROOVE)
		// Clear rhythm if rendered rhythm won't be compatible with groove playback in new time
		if (fromClick && playback.playbackTime.options !== time) {
			if (!(playback.playbackTime.options / time === 4) && !(time / playback.playbackTime.options === 4)) {
				clearRhythm()
				setGrooveMix(0)
				playback.playing && stop({ timeout: false })
			}
		}
		//Either assign random groove playback settings or use defaults for the subdivision
		if (random) {
			switch (parseInt(time)) {
				case Times.STRAIGHT:
					setGrooveCymbalPattern(GrooveOptions.options[0].hh[Math.floor(Math.random() * GrooveOptions.options[0].hh.length)])
					break
				case Times.EIGHTH:
					setGrooveCymbalPattern(GrooveOptions.options[1].hh[Math.floor(Math.random() * GrooveOptions.options[1].hh.length)])
					break
				case Times.TRIPLETS:
					setGrooveCymbalPattern(GrooveOptions.options[2].hh[Math.floor(Math.random() * GrooveOptions.options[2].hh.length)])
					break
				case Times.MIXED:
					setGrooveCymbalPattern(GrooveOptions.options[3].hh[Math.floor(Math.random() * GrooveOptions.options[3].hh.length)])
					break
				default:
					break
			}
			setGrooveBackbeat(Math.floor(Math.random() * (3 + 1)))
			let swingValue = Math.floor(Math.random() * (100 + 1))
			if (!isSwingable) {
				swingValue = 0
			}
			setSwing(swingValue)
		} else {
			//Setting groove default values
			switch (parseInt(time)) {
				case Times.STRAIGHT:
				case Times.EIGHTH:
					setGrooveCymbalPattern(2)
					break
				default:
					setGrooveCymbalPattern(0)
			}
			if (hasDefaultBackbeat) {
				setGrooveBackbeat(BackbeatPatterns.TIMESIG_DEFAULT)
			} else {
				setGrooveBackbeat(BackbeatPatterns.TWO_AND_FOUR)
			}
			if (!isSwingable) {
				setSwing(0)
			}
		}
		setGrooveGhosts(0)
	}

	/**
	 * Trim a 4:4 image ID to an appropriate duration
	 * @param {Number} beatChosenId original 4:4 image key
	 * @param {Number} targetDuration target subdivisions
	 * @returns
	 */
	const trimNotation = (beatChosenId, targetDuration) => {
		const sixteenthSounds = get(SixteenthSounds, 'sounds')
		const shortBeatArr = take(get(sample(filter(sixteenthSounds, (entry) => get(entry, 'id') === beatChosenId)), 'arr'), targetDuration)
		return get(sample(filter(sixteenthSounds, (entry) => arrayEquals(get(entry, 'arr'), shortBeatArr))), 'id')
	}

	const randomNotationArray = ({ noOfBeats, playbackTime = false, timeSignature = playback.timeSignature, optionsOverride = false }) => {
		const time = playbackTime || get(playback, 'time')
		const highestNoteRate = get(time, 'highestNoteRate')
		const options = optionsOverride || get(time, 'options')
		const sdBarDuration = get(timeSignature, 'top') * (highestNoteRate / get(timeSignature, 'bottom'))

		let durationLeft = sdBarDuration
		let groupingDuration = highestNoteRate / 4

		if (get(playback, `custom`)) {
			//Picking only from custom groupings selected
			return Array.from(Array(noOfBeats)).map(() => {
				const beatChosen =
					Math.round(Math.random() * 100) <= 75 * (playback.space / 100) ? 1 : playback.customArray[Math.floor(Math.random() * playback.customArray.length)]
				if (durationLeft < groupingDuration) {
					//Trim the chosen beat to the correct length
					const shortBeatId = trimNotation(beatChosen, durationLeft)
					durationLeft = sdBarDuration
					return shortBeatId
				}
				durationLeft = durationLeft - groupingDuration === 0 ? sdBarDuration : durationLeft - groupingDuration
				return beatChosen
			})
		}
		if (!isEmpty(get(playback, `levelsArray`))) {
			//Picking only from groupings at this complexity level
			return Array.from(Array(noOfBeats)).map(() => {
				const beatChosen =
					Math.round(Math.random() * 100) <= 75 * (playback.space / 100) ? 1 : playback.levelsArray[Math.floor(Math.random() * playback.levelsArray.length)]
				if (durationLeft < groupingDuration) {
					//Trim the chosen beat to the correct length
					const shortBeatId = trimNotation(beatChosen, durationLeft)
					durationLeft = sdBarDuration
					return shortBeatId
				}
				durationLeft = durationLeft - groupingDuration === 0 ? sdBarDuration : durationLeft - groupingDuration
				return beatChosen
			})
		}
		//Picking from all possible groupings
		return Array.from(Array(noOfBeats)).map(() => {
			if (durationLeft < groupingDuration) {
				//Pick random notation of the correct length - this only
				//applies to sixteenths as odd times are only available here
				const shortBeatId = get(
					sample(
						filter(SixteenthSounds.sounds, (entry) => {
							const entryArray = get(entry, 'arr')
							const lengthCheck = get(entryArray, 'length') === durationLeft
							if (options === Times.STRAIGHT) {
								return lengthCheck
							} else {
								// const x = lengthCheck && (get(countBy(entryArray), '1') < 2 && (!entryArray.includes(1) || head(entryArray) === 1))
								return lengthCheck && (arrayEquals(entryArray, [0, 0]) || arrayEquals(entryArray, [1, 0]))
							}
						})
					),
					'id'
				)
				durationLeft = sdBarDuration
				return shortBeatId
			}
			durationLeft = durationLeft - groupingDuration === 0 ? sdBarDuration : durationLeft - groupingDuration
			return Math.round(Math.random() * 100) <= 75 * (playback.space / 100) ? 1 : Math.floor(Math.random() * options) + 1
		})
	}

	/**
	 * Get images keys for first groove notation
	 * @param {Number} renderingMode
	 * @param {Number} noOfBars
	 * @returns {Array}
	 */
	const firstGrooveNotationImages = ({ renderingMode = RenderingModes.FIRSTGROOVES.ALL, noOfBars = false }) => {
		const numberOfBars = noOfBars || get(playback, 'bars')
		const images = []

		let options = get(firstGrooveLevels, 1)
		switch (renderingMode) {
			case RenderingModes.FIRSTGROOVES.ALL:
				options.push(...get(firstGrooveLevels, 2))
				options.push(...get(firstGrooveLevels, 3))
				break
			case RenderingModes.FIRSTGROOVES.TWO:
				options = get(firstGrooveLevels, 2)
				break
			case RenderingModes.FIRSTGROOVES.THREE:
				options = get(firstGrooveLevels, 3)
				break
			default:
				break
		}
		if (get(playback, 'custom')) {
			options = get(playback, 'customArray')
		}
		let count = 0
		while (count < numberOfBars) {
			images.push(sample(options))
			count++
		}
		return images
	}

	/**
	 * Get images keys for triplet SD notation
	 * @param {Number} renderingMode
	 * @param {Number} noOfBars
	 * @returns {Array}
	 */
	const tripletSdNotationImages = ({ renderingMode, noOfBars = false }) => {
		let advanced = false
		let possibleGroups
		switch (renderingMode) {
			case RenderingModes.SD.TRIPLETS.ONE:
				possibleGroups = get(SDGroups, 'simple')
				break
			case RenderingModes.SD.TRIPLETS.TWO:
				possibleGroups = get(SDGroups, 'medium')
				break
			case RenderingModes.SD.TRIPLETS.THREE:
				possibleGroups = get(SDGroups, 'simple')
				advanced = true
				break
			case RenderingModes.SD.TRIPLETS.FOUR:
				advanced = true
				possibleGroups = get(SDGroups, 'medium')
				break
			case RenderingModes.SD.TRIPLETS.FIVE:
				advanced = true
				possibleGroups = get(SDGroups, 'all')
				break
			default:
				advanced = true
				possibleGroups = get(SDGroups, 'all')
				break
		}

		const numberOfBars = noOfBars || get(playback, 'bars')
		const startingGroup = sample(possibleGroups)
		let currentGroup = startingGroup
		const images = []
		let count = 0
		while (count < numberOfBars) {
			let options = get(sdImageMap, `${currentGroup}.normal`)
			if (advanced) {
				options.push(...get(sdImageMap, `${currentGroup}.advanced`))
			}

			let nextGroups
			switch (true) {
				case numberOfBars === 1:
					while (!includes(get(sdGroupMap, `${currentGroup}`), currentGroup)) {
						currentGroup = sample(possibleGroups)
					}
					options = get(sdImageMap, `${currentGroup}.normal`)
					if (advanced) {
						options.push(...get(sdImageMap, `${currentGroup}.advanced`))
					}
					images.push(`${currentGroup}/${sample(options)}`)
					break
				case count + 2 === numberOfBars:
					// Tries to make sure the loop is valid, can't always work
					images.push(`${currentGroup}/${sample(options)}`)
					nextGroups = get(sdGroupMap, `${currentGroup}`)
					let validNextGroups = []
					forEach(nextGroups, (group) => {
						if (includes(get(sdGroupMap, `${group}`), startingGroup)) {
							validNextGroups.push(group)
						}
					})
					if (isEmpty(validNextGroups)) {
						// It's not guaranteed that a valid loop will be possible
						currentGroup = sample(nextGroups)
						break
					}
					currentGroup = sample(validNextGroups)
					break
				default:
					images.push(`${currentGroup}/${sample(options)}`)
					nextGroups = get(sdGroupMap, `${currentGroup}`)
					currentGroup = sample(nextGroups)
					break
			}
			count++
		}

		return images
	}

	/**
	 * Get images keys for SD notation
	 * @param {Number} renderingMode
	 * @param {Number} noOfBars
	 * @returns {Array}
	 */
	const sdNotationImages = ({ renderingMode = RenderingModes.SD.TRIPLETS.FIVE, noOfBars = false }) => {
		switch (renderingMode) {
			case RenderingModes.SD.TRIPLETS.ONE:
			case RenderingModes.SD.TRIPLETS.TWO:
			case RenderingModes.SD.TRIPLETS.THREE:
			case RenderingModes.SD.TRIPLETS.FOUR:
			case RenderingModes.SD.TRIPLETS.FIVE:
				return tripletSdNotationImages({ renderingMode, noOfBars })
			default:
				return
		}
	}

	/**
	 * Get original image notation keys for straight time down ups
	 * Adhering to the down up rules layed out in notation-key.jpeg
	 * @param {Number} noOfBeats
	 * @returns
	 */
	const straightDownUpNotationImages = ({ noOfBeats, nextGrouping = false }) => {
		// Only allow enforceDownbeat if the number of bars provided in the query
		// makes sense
		const beatsPerBar = noOfBeats / get(query, 'bars')
		const useEnforceDownbeat = get(query, 'enforceDownbeat') && (beatsPerBar === 2 || beatsPerBar === 4)

		let latestBeat
		let firstBeat = false
		let position = -1
		const normalNotation = map(Array.from(Array(noOfBeats)), () => {
			position++
			const isFirstBeat = position === 0
			const firstBeatOfBar = position % beatsPerBar === 0

			if (useEnforceDownbeat && firstBeatOfBar) {
				latestBeat = sample(straightDownUpsOptions.slice(0, 3))
				if (isFirstBeat) {
					firstBeat = latestBeat
				}
				return latestBeat
			}
			if (isFirstBeat) {
				latestBeat = sample(straightDownUpsOptions)
				firstBeat = latestBeat
				return latestBeat
			}
			const options = get(straightDownUpsKey, `${latestBeat}`)
			const lastTime = position + 1 === noOfBeats
			if (lastTime) {
				// TODO the last one going back into the top can that work???
				// Look at the first entry and make sure it is compatible with
				// the last (i.e. no triple hits or spaces)
				// TODO should this apply everywhere or just for looping?
				// const standardLoop = get(playback, 'mode') === Modes.LOOP || get(playback, 'mode') === Modes.TRADE || get(playback, 'mode') === Modes.CLICK
				latestBeat = false
				const next = nextGrouping || firstBeat
				let count = 0
				while (!includes(get(straightDownUpsKey, `${latestBeat}`, []), next)) {
					count++
					latestBeat = sample(options)
					if (count > 10) {
						break
					}
				}
				return latestBeat
			}
			latestBeat = sample(options)
			return latestBeat
		})
		return normalNotation
	}

	/**
	 * Get down up image  keys for triplet time down ups
	 * @param {Number} noOfBeats
	 * @returns
	 */
	const tripletDownUpNotationImages = ({ noOfBeats }) => {
		// Only allow enforceDownbeat if the number of bars provided in the query
		// makes sense
		const beatsPerBar = noOfBeats / get(query, 'bars')
		const useEnforceDownbeat = get(query, 'enforceDownbeat') && (get(query, 'timeSignature') === '2:4' || get(query, 'timeSignature') === '4:4')

		let latestBeat
		let firstBeat = false
		let down = true
		let consecutiveSpaces = 0

		let position = -1
		const notation = map(Array.from(Array(noOfBeats)), () => {
			position++
			const firstBeatOfBar = position % beatsPerBar === 0
			const isFirstBeat = position === 0

			if (isFirstBeat) {
				if (useEnforceDownbeat) {
					latestBeat = tripletDownUpsOptions.start[0]
					firstBeat = latestBeat
					down = true
					return latestBeat
				}
				// Picking the first beat, this can either be a down or a space
				latestBeat = sample(get(tripletDownUpsOptions, 'start'))
				if (latestBeat === TripletDownUpImages.SPACE) {
					// If it's a space we are now in up mode
					down = false
				}
				firstBeat = latestBeat
				return latestBeat
			}
			if (latestBeat === TripletDownUpImages.SPACE) {
				// If the latest beat is a space, it can't be followed by
				// another space. Continue in either up or down
				latestBeat = down ? TripletDownUpImages.DOWN : TripletDownUpImages.UP
				return latestBeat
			}
			if (consecutiveSpaces === 0) {
				// There has to be at least one space once switched to down or
				// up. To avoid ending up in the shuffle pattern
				latestBeat = TripletDownUpImages.SPACE
				consecutiveSpaces++
				return latestBeat
			}

			const options = get(tripletDownUpsKey, `${latestBeat}`)
			if (useEnforceDownbeat && firstBeatOfBar && options.includes(tripletDownUpsOptions.start[0])) {
				latestBeat = tripletDownUpsOptions.start[0]
			} else {
				latestBeat = sample(options)
			}

			if (latestBeat === TripletDownUpImages.DOWN) {
				consecutiveSpaces = 0
				down = true
			}
			if (latestBeat === TripletDownUpImages.UP) {
				consecutiveSpaces = 0
				down = false
			}
			return latestBeat
		})
		return notation
	}

	/**
	 * Get original image notation keys for straight time and down up image keys
	 * for triplets
	 * @param {Number} noOfBeats
	 * @returns
	 */
	const downUpNotationImages = ({ renderingMode, noOfBeats, nextGrouping = false }) => {
		if (renderingMode === RenderingModes.DOWNUP.STRAIGHT) {
			return straightDownUpNotationImages({ noOfBeats, nextGrouping })
		}
		return tripletDownUpNotationImages({ noOfBeats })
	}

	/**
	 * Convert ones and zeros into image keys for straight time down up notation
	 * @param {Array} onesAndZeros
	 *
	 */
	const straightDownUpConversion = ({ onesAndZeros }) => {
		let newImages = []
		let consecutiveSpaces = 0
		let began = false
		let latestDown = true
		forEach(onesAndZeros, (entry) => {
			if (entry === 0) {
				consecutiveSpaces++
				newImages = concat(newImages, 3)
				return
			}
			if (!began) {
				// Work out what the first image in the Rhythm should be
				if (consecutiveSpaces % 2 === 0) {
					// Down
					newImages = concat(newImages, 1)
					latestDown = true
				} else {
					// Up
					newImages = concat(newImages, 2)
					latestDown = false
				}
				began = true
				consecutiveSpaces = 0
				return
			}
			// Continue
			let imageKey = latestDown ? 1 : 2
			if (consecutiveSpaces === 0) {
				// Flip
				imageKey = latestDown ? 2 : 1
				latestDown = !latestDown
			}
			newImages = concat(newImages, imageKey)
			consecutiveSpaces = 0
		})
		return newImages
	}

	/**
	 * Convert ones and zeros into image keys for triplet down up notation
	 * @param {Array} onesAndZeros
	 *
	 */
	const tripletDownUpConversion = ({ onesAndZeros }) => {
		const quarterNotes = chunk(onesAndZeros, 3)
		let newImages = []
		let key = ``
		forEach(quarterNotes, (quarter) => {
			forEach(quarter, (q) => {
				switch (q) {
					case 0:
						key += `z`
						break
					default:
						key += `o`
						break
				}
			})
			newImages.push(...get(tripletDownUpsOnesAndZerosToSoundKey, `${key}`))
			key = ``
		})
		return newImages
	}

	/**
	 * Convert ones and zeros into image keys for down up notation
	 * @param {Number} renderingMode
	 * @param {Array} onesAndZeros
	 * @returns
	 */
	const downUpConversion = ({ renderingMode, onesAndZeros }) => {
		if (renderingMode === RenderingModes.DOWNUP.STRAIGHT) {
			return straightDownUpConversion({ onesAndZeros })
		}
		return tripletDownUpConversion({ onesAndZeros })
	}

	/**
	 * Go from straight time down up images to ones and zeros then to Straight
	 * time image keys
	 * Pretty niche, used for reading mode in down up mode to avoid triple
	 * hits/spaces
	 * @param {Array} images array of straight time down up image keys
	 * @returns
	 */
	const straightDownUpImagesToOriginalImages = ({ images = [] }) => {
		const onesAndZeros = []
		forEach(images, (entry) => {
			switch (entry) {
				case 3:
					onesAndZeros.push(0)
					break
				case 2:
				case 1:
					onesAndZeros.push(1)
					break
				default:
					break
			}
		})
		return get(playThisToImages({ bars: [onesAndZeros] }), 0)
	}

	/**
	 * Go from triplet time down up images to ones and zeros
	 * @param {Array} images array of straight time down up image keys
	 * @returns
	 */
	const tripletDownUpImagesToOriginalImages = ({ images = [] }) => {
		const onesAndZeros = []
		forEach(images, (entry) => {
			switch (entry) {
				case 3:
					onesAndZeros.push(0)
					break
				case 2:
				case 1:
					onesAndZeros.push(1)
					break
				default:
					break
			}
		})
		return get(playThisToImages({ bars: [onesAndZeros] }), 0)
	}

	/**
	 * Go from down up images to ones and zeros then to Straight
	 * time image keys
	 * Pretty niche, used for reading mode in down up mode to avoid triple
	 * hits/spaces
	 * @param {Array} images array of straight time down up image keys
	 * @returns
	 */
	const downUpImagesToOriginalImages = ({ renderingMode, images = [] }) => {
		if (isEmpty(images)) {
			return
		}
		if (renderingMode === RenderingModes.DOWNUP.STRAIGHT) {
			return straightDownUpImagesToOriginalImages({ images })
		}
		return tripletDownUpImagesToOriginalImages({ images })
	}

	/**
	 * When updating the time signature in groove mode, adjust the groove parameters
	 * Checking if the current backbeat pattern is compatible with the new time signature
	 * if eighth note groove in 12/8 or 6/8 reset swing
	 * @param {Object} newTime the time signature changing into
	 * @returns
	 */
	const adjustGrooveMode = (newTime) => {
		if (get(playback, 'playbackAs') !== 2) {
			return
		}
		const grooveBackbeat = get(groove, 'backbeat')
		const newBottom = get(newTime, 'bottom')
		const newTop = get(newTime, 'top')

		// backbeat option 5 'time signature default' is availble in these
		// time signatures (/8)
		let options = [12, 6, 5, 7]
		if (grooveBackbeat === BackbeatPatterns.TIMESIG_DEFAULT) {
			if (newBottom !== 8) {
				setGrooveBackbeat(BackbeatPatterns.TWO_AND_FOUR)
				return
			}
			if (!includes(options, newTop)) {
				setGrooveBackbeat(BackbeatPatterns.TWO_AND_FOUR)
				return
			}
		} else {
			if (newBottom === 8 && includes(options, newTop)) {
				setGrooveBackbeat(BackbeatPatterns.TIMESIG_DEFAULT)
				return
			}
		}
		// Looking for 6/8 or 12/8 in 8th not groove - reset swing in these cases
		if (newBottom === 8 && get(timeToUse, 'options') === 4) {
			options = [6, 12]
			if (includes(options, newTop)) {
				setSwing(0)
			}
		}
		return
	}

	/**
	 * Adjust the time signature
	 * @returns
	 */
	const timeSignatureAdjustment = ({ notesMissing, intoSixTwelveEight }) => {
		if (notesMissing === 0) {
			return
		}

		let bars = [get(playback, 'playThis')]
		if (playback.isolated) {
			bars = [isolate({ on: false })]
		}
		if (playback.bars > 1) {
			bars = chunk(get(bars, '0'), get(bars, '0.length') / get(playback, 'bars'))
		}
		let newBars
		if (notesMissing < 0) {
			//Add notes
			newBars = map(bars, (bar) => {
				let thisBar = bar
				while (get(thisBar, 'length') < -notesMissing) {
					thisBar = thisBar.concat(...bar)
				}
				return bar.concat(...takeRight(thisBar, -notesMissing))
			})
		} else {
			//Take notes away
			newBars = map(bars, (bar) => take(bar, bar.length - notesMissing))
		}
		const newPlayThis = flattenDeep(newBars)
		setPlayThis(newPlayThis)
		let newImages = playThisToImages({ bars: newBars })
		if (intoSixTwelveEight) {
			newImages = sixEightConversion(newPlayThis)
		}

		const renderingMode = get(rendering, 'mode')
		switch (renderingMode) {
			case RenderingModes.DOWNUP.STRAIGHT:
			case RenderingModes.DOWNUP.TRIPLETS:
				newImages = downUpConversion({ renderingMode, onesAndZeros: newPlayThis })
				break
			default:
				break
		}
		render({ images: newImages, playbackTime: get(playback, 'playbackTime.options'), sixEightOverride: intoSixTwelveEight, renderingMode })
	}

	/**
	 * Determine the difference (in terms of missing notes) between two time signatures
	 * Call the parent timeSignatureAdjustment function if adjustment is made
	 * @param {*} oldTime the time signature we are changing from
	 * @param {*} newTime the time signature we are changing to
	 * @returns {boolean} whether the time signature change is permitted
	 */
	const timeSignatureDifference = (oldTime, newTime) => {
		const highestNoteRate = get(playback, 'playbackTime.highestNoteRate', false)
		const rendered = get(rendering, 'renderedArray.length', 0) > 0
		if (highestNoteRate && rendered) {
			const oldNotes = (highestNoteRate / 4) * oldTime.top * (4 / oldTime.bottom)
			const newNotes = (highestNoteRate / 4) * newTime.top * (4 / newTime.bottom)
			const intoSixTwelveEight = newTime.bottom === 8 && (newTime.top === 6 || newTime.top === 12)
			timeSignatureAdjustment({ notesMissing: oldNotes - newNotes, intoSixTwelveEight })
			return true
		}
		return false
	}

	/**
	 * Change the top number of the time signature
	 * @param {boolean} up whether the number is increasing or decreasing
	 * @returns if time signature cannot be updated
	 */
	const changeTimeSignatureTop = ({ up, value = false }) => {
		const currentTimeSignature = get(playback, 'timeSignature')
		const top = get(currentTimeSignature, 'top')
		const renderingMode = get(rendering, 'mode')
		let upperLimit, lowerLimit, interval
		switch (renderingMode) {
			case RenderingModes.DOWNUP.STRAIGHT:
			case RenderingModes.DOWNUP.TRIPLETS:
				upperLimit = 4
				lowerLimit = 2
				interval = 2
				break
			default:
				upperLimit = get(currentTimeSignature, 'limits.upper')
				lowerLimit = get(currentTimeSignature, 'limits.lower')
				interval = 1
				break
		}

		let newTop = up ? top + interval : top - interval
		if (value !== false) {
			newTop = value
		}

		if (newTop > upperLimit) {
			newTop = lowerLimit
		} else if (newTop < lowerLimit) {
			newTop = upperLimit
		}
		setTimeSignatureTop(newTop)
		timeSignatureDifference(currentTimeSignature, { ...playback.timeSignature, top: newTop })
		adjustGrooveMode({ ...playback.timeSignature, top: newTop })
	}

	/**
	 * Change the bottom number of the time signature
	 * @param {boolean} up whether the number is increasing or decreasing
	 * @returns if time signature cannot be updated
	 */
	const changeTimeSignatureBottom = ({ up, value = false }) => {
		const currentTimeSignature = get(playback, 'timeSignature')
		const top = get(playback, 'timeSignature.top')
		const bottom = get(playback, 'timeSignature.bottom')
		const noteRateLimit = get(timeToUse, 'options') === Times.STRAIGHT ? 16 : 8
		let newBottom, newTop
		if (value !== false) {
			newBottom = value
		} else if (bottom === 4 && !up) {
			newBottom = noteRateLimit
		} else if (bottom === noteRateLimit) {
			newBottom = up ? 4 : bottom / 2
		} else {
			newBottom = up ? bottom * 2 : bottom / 2
		}
		// Check if the new time signature exceeds the limits and adjust accordingly
		const { limits } = timeSignatureLimits(newBottom)
		const upperLimit = get(limits, 'upper')
		const lowerLimit = get(limits, 'lower')
		newTop = top
		if (top > upperLimit) {
			// This will always be when coming down
			newTop = upperLimit
		} else if (top < lowerLimit) {
			// This will always be when going up
			newTop = lowerLimit
		}
		setTimeSignatureBottom(newBottom)
		setTimeSignatureTop(newTop)
		timeSignatureDifference(currentTimeSignature, {
			...playback.timeSignature,
			top: newTop,
			bottom: newBottom,
		})
		adjustGrooveMode({
			...playback.timeSignature,
			top: newTop,
			bottom: newBottom,
		})
	}

	/**
	 * Reset time signature to 4:4
	 */
	const resetTimeSignature = () => {
		const currentTimeSignature = get(playback, 'timeSignature')
		setTimeSignature('4:4')
		timeSignatureDifference(currentTimeSignature, { ...playback.timeSignature, top: 4, bottom: 4 })
		adjustGrooveMode({ ...playback.timeSignature, top: 4, bottom: 4 })
	}

	/**
	 * playThisToImages converts arrays of 1's & 0's (the bars) to the
	 * corresponding images keys
	 * @param {array} bars the bars to be converted
	 * @returns
	 */
	const playThisToImages = ({ bars, allowSixEight = false }) => {
		if (get(timeToUse, 'options') === Times.MIXED || includes(flattenDeep(bars), 4)) {
			// Handle mixed time separately
			const chunks = []
			forEach(bars, (bar) => {
				const barChunks = []
				let currentChunk = []
				forEach(bar, (b, i) => {
					if (i === 0) {
						return
					}
					if (i === get(bar, 'length') - 1) {
						currentChunk.push(b)
						barChunks.push(currentChunk)
						return
					}
					if (b === 4) {
						barChunks.push(currentChunk)
						currentChunk = []
						return
					}
					currentChunk.push(b)
				})
				chunks.push(barChunks)
			})
			const imageIds = map(chunks, (chunks) =>
				map(chunks, (chunk) =>
					get(
						sample(filter(get(TripSounds, 'sounds'), (entry) => arrayEquals(get(entry, 'arrMix'), chunk))),
						'mixedID',
						//Fallback to Sixteenth Sounds if not found
						get(
							sample(filter(get(SixteenthSounds, 'sounds'), (entry) => arrayEquals(get(entry, 'arr'), chunk))),
							'id',
							// Further fallback for six eight support
							get(sample(filter(get(SixEightSounds, 'sounds'), (entry) => arrayEquals(get(entry, 'arr'), chunk))), 'id')
						)
					)
				)
			)
			return flattenDeep(imageIds)
		}

		let chunkSize = get(timeToUse, 'highestNoteRate') / 4
		let SoundsConstants
		switch (parseInt(get(timeToUse, 'options'))) {
			case Times.TRIPLETS:
				SoundsConstants = TripSounds
				break
			case Times.EIGHTH:
				SoundsConstants = EighthSounds
				break
			default:
				if (isSixTwelveEight && allowSixEight) {
					chunkSize = 6
					SoundsConstants = SixEightSounds
				} else {
					SoundsConstants = SixteenthSounds
				}
				break
		}
		const chunks = map(bars, (bar) => chunk(bar, chunkSize))
		const sounds = get(SoundsConstants, 'sounds')
		const imageIds = map(chunks, (chunks) =>
			map(chunks, (chunk) =>
				get(
					sample(filter(sounds, (entry) => arrayEquals(get(entry, 'arr'), chunk))),
					'id',
					//Fallback to Sixteenth Sounds if not found
					get(sample(filter(get(SixteenthSounds, 'sounds'), (entry) => arrayEquals(get(entry, 'arr'), chunk))), 'id')
				)
			)
		)
		return flattenDeep(imageIds)
	}

	/**
	 * Generate rhythm sets rhythm parameters, generates a new rhythm and renders it
	 */
	const generateRhythm = ({ renderingMode = null, playbackTime = false, beatsOverride = false }) => {
		const rMode = renderingMode || get(rendering, 'mode', null)
		const options = playbackTime || get(playback, 'time.options')
		setPlaybackTime(options)
		const bars = get(playback, 'bars')
		let timeSignature = get(playback, 'timeSignature')
		let beats = noOfBeatsToRender

		// TODO Find a better way to do this 6/8 stuff
		let sixTwelveEight = isSixTwelveEight
		let sixEightOverride = sixTwelveEight && (get(playback, 'custom') || !isEmpty(get(playback, 'levelsArray')))

		setIsolated(false)
		setRandom(bars > 1 ? true : false)
		resetPreset()

		switch (options) {
			case Times.MIXED:
				//TODO - Make mixed time compatible with other time signatures
				setTimeSignature('4:4')
				timeSignature = { ...timeSignature, top: 4, bottom: 4 }
				beats = bars * 4
				sixEightOverride = false
				sixTwelveEight = false
				break
			case Times.TRIPLETS:
				if (!tripletTimeSignature) {
					setTimeSignature('4:4')
					timeSignature = { ...timeSignature, top: 4, bottom: 4 }
					beats = bars * 4
				}
				sixEightOverride = false
				sixTwelveEight = false
				break
			case Times.EIGHTH:
				if (get(timeSignature, 'bottom') > 8) {
					const top = Math.ceil(get(timeSignature, 'top') / 2)
					setTimeSignature(`${top}:8`)
					timeSignature = { ...timeSignature, top, bottom: 8 }
					beats = bars * Math.ceil(top / 2)
				}
				break
			default:
				break
		}

		if (sixEightOverride) {
			if (get(playback, 'timeSignature.top') === 12) {
				beats = 4 * bars
			} else {
				beats = 2 * bars
			}
		}

		let notes
		switch (rMode) {
			case RenderingModes.NORMAL:
				notes = randomNotationArray({ noOfBeats: beats, timeSignature })
				break
			case RenderingModes.DOWNUP.STRAIGHT:
			case RenderingModes.DOWNUP.TRIPLETS:
				if (rMode === RenderingModes.DOWNUP.TRIPLETS) {
					beats *= 2
				}
				notes = downUpNotationImages({ renderingMode: rMode, noOfBeats: beatsOverride || beats })
				break
			case RenderingModes.SD.TRIPLETS.ONE:
			case RenderingModes.SD.TRIPLETS.TWO:
			case RenderingModes.SD.TRIPLETS.THREE:
			case RenderingModes.SD.TRIPLETS.FOUR:
			case RenderingModes.SD.TRIPLETS.FIVE:
				notes = sdNotationImages({ renderingMode: rMode, noOfBars: beatsOverride || bars })
				break
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
				notes = firstGrooveNotationImages({ renderingMode: rMode, noOfBars: beatsOverride || bars })
				break
			default:
				console.log(`TODO implement notation images for this mode?`)
				break
		}

		let onesAndZeros
		switch (rMode) {
			case RenderingModes.SD.TRIPLETS.ONE:
			case RenderingModes.SD.TRIPLETS.TWO:
			case RenderingModes.SD.TRIPLETS.THREE:
			case RenderingModes.SD.TRIPLETS.FOUR:
			case RenderingModes.SD.TRIPLETS.FIVE:
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
			case RenderingModes.DOWNUP.TRIPLETS:
				onesAndZeros = populatePlay({ arr: notes, time: options, sixEightOverride, renderingMode: rMode })
				break
			default:
				onesAndZeros = populatePlay({ arr: notes, time: options, sixEightOverride })
				break
		}

		// rhythmTimings({ onesAndZeros, options })
		if (sixTwelveEight && !sixEightOverride) {
			notes = sixEightConversion(onesAndZeros)
		}

		switch (rMode) {
			case RenderingModes.DOWNUP.STRAIGHT:
				notes = downUpConversion({ renderingMode: rMode, onesAndZeros })
				break
			default:
				break
		}

		render({ images: notes, sixEightOverride: sixTwelveEight, renderingMode: rMode })
		setSaveable([true, 'Save'])
		setRhythmName({ name: getRhythmName({ renderingMode: rMode }) })
	}

	/**
	 * rhythmTimings sets the timings for all valid straight and triplet notes
	 * within the current rhythm
	 * @param {Array} straight of ms timings for each hit in the rhythm
	 * @param {Array} triplets of ms timings for each hit in the rhythm
	 */
	const rhythmTimings = ({ straight = false, triplets = false }) => {
		if (!isReadingMode && get(refs, [`currentLoopRef`, `current`]) > 1) {
			return
		}
		return setRhythmTimings({ straight, triplets })
	}

	/**
	 * practiceTap registers a user playing along with a rhythm
	 * @param {number} timeOverride provide a value for the time the tap occured
	 * @param {Boolean} click true if this was a screen tap rather than a
	 * keyboard press
	 */
	const practiceTap = ({ timeOverride = false, click = false, progressStatsRef, latency = 0 }) => {
		if (!get(playback, 'playing') || !isPracticeModeOn) {
			return
		}

		// if (progressStatsRef) {
		if (false) {
			// TODO trade mode, isolation and swing

			if (progressStatsRef.bar === 0) {
				// Playing outside of the rhythm's time bounds... do nothing
				return
			}

			const practice = get(refs, [`practiceRef`, `current`])
			const straightHits = get(practice, [`rhythm`, `timings`, click ? `click` : `tap`, `straight`], [])
			const tripletHits = get(practice, [`rhythm`, `timings`, click ? `click` : `tap`, `triplets`], [])
			const allHits = [...straightHits, ...tripletHits]

			const currentPercentage = Math.max(progressStatsRef.percentage - 2, 0)

			const closestOverall = closestHitPolling(allHits, progressStatsRef.bar, currentPercentage)
			const closestStraight = closestHitPolling(straightHits, progressStatsRef.bar, currentPercentage)
			const closestTriplet = closestHitPolling(tripletHits, progressStatsRef.bar, currentPercentage)

			const straightLock = isSixTwelveEight || get(refs, [`straightLockRef`, `current`], false)
			const tripletLock = get(refs, [`tripletLockRef`, `current`], false)

			let hit = closestOverall
			let isStraight = get(hit, `straight`)
			if (!isSixTwelveEight && ((!straightLock && !tripletLock) || get(hit, 'beat') !== refs.latestHitBeatRef.current)) {
				// No grid lock to adhere to in this case
				// Slightly favour the correct grid for the current time???
				if (isStraightTime && !isStraight) {
					if (Math.abs(currentPercentage - get(closestStraight, `barPercentage`)) / Math.abs(currentPercentage - get(hit, `barPercentage`)) < 2.415) {
						hit = closestStraight
					}
				} else if (isTripletTime && isStraight) {
					if (Math.abs(currentPercentage - get(closestTriplet, `barPercentage`)) / Math.abs(currentPercentage - get(hit, `barPercentage`)) < 2.415) {
						hit = closestTriplet
					}
				}
			} else if (straightLock) {
				hit = closestStraight
			} else if (tripletLock) {
				hit = closestTriplet
			}

			isStraight = get(hit, `straight`)
			const midStraight = isStraight && inRange(get(hit, `beatPosition`), 1, 3)
			const midTriplet = !isStraight && get(hit, `beatPosition`) === 1

			if (isSixTwelveEight) {
				refs.straightLockRef.current = true
				refs.tripletLockRef.current = false
			} else if (midStraight) {
				refs.straightLockRef.current = true
				refs.tripletLockRef.current = false
			} else if (midTriplet) {
				refs.tripletLockRef.current = true
				refs.straightLockRef.current = false
			} else {
				refs.straightLockRef.current = false
				refs.tripletLockRef.current = false
			}
			refs.latestHitBeatRef.current = get(hit, 'beat')

			if (get(hit, [`hit`])) {
				return userHit({ stats: hit })
			}
			return userMiss({ stats: hit })
		}

		let currentTime = timeOverride || get(audio, 'audioContext.currentTime')
		if (get(refs, [`currentLoopRef`, `current`]) > 1) {
			const tradeMultiplier = isTrade ? 2 : 1
			currentTime = setPracticeTimeStamp(currentTime - (get(refs, [`currentLoopRef`, `current`]) - 1) * ((msRhythmDuration / 1000) * tradeMultiplier))
		} else {
			currentTime = setPracticeTimeStamp(currentTime)
		}

		const practice = get(refs, [`practiceRef`, `current`])
		const straightHits = get(practice, [`rhythm`, `timings`, click ? `click` : `tap`, `straight`], [])
		const tripletHits = get(practice, [`rhythm`, `timings`, click ? `click` : `tap`, `triplets`], [])
		const allHits = [...straightHits, ...tripletHits]

		const startListening = setPracticeTimeStamp(get(straightHits, [0, 'timeStamp']))
		const stopListening = setPracticeTimeStamp(startListening + msRhythmDuration / 1000)

		console.log(timeOverride)
		console.log(startListening)
		// const currentPercentage = Math.max(progressStatsRef.percentage - 2.5, 0)
		// const currentPercentage = progressStatsRef.percentage

		// const currentTime = setPracticeTimeStamp(
		// 	startListening + (msBarDuration / 1000) * (progressStatsRef.bar - 1) + (currentPercentage / 100) * (msBarDuration / 1000) - latency / 1000
		// )
		// console.log(currentTime)
		// console.log(latency / 1000)
		// if (get(refs, [`currentLoopRef`, `current`]) > 1) {
		// 	const tradeMultiplier = isTrade ? 2 : 1
		// 	currentTime = setPracticeTimeStamp(currentTime - (get(refs, [`currentLoopRef`, `current`]) - 1) * ((msRhythmDuration / 1000) * tradeMultiplier))
		// } else {
		// 	currentTime = setPracticeTimeStamp(currentTime)
		// }

		if (currentTime < startListening - crotchetTime / 5.5 || currentTime > stopListening) {
			// Playing outside of the rhythm's time bounds... do nothing
			return
		}

		const closestOverall = closestHit(allHits, currentTime)
		const closestStraight = closestHit(straightHits, currentTime)
		const closestTriplet = closestHit(tripletHits, currentTime)

		const straightLock = isSixTwelveEight || get(refs, [`straightLockRef`, `current`], false)
		const tripletLock = get(refs, [`tripletLockRef`, `current`], false)

		let hit = closestOverall

		// TODO for testing timings
		// if (click) {
		// 	console.log(`Click`, currentTime)
		// 	console.log(`Start Listening`, startListening)
		// 	console.log(`Diff`, currentTime - startListening)
		// 	console.log(`Overall`, hit.timeStamp)
		// 	console.log(`Obj`, hit)
		// } else {
		// 	console.log(`Tap`, currentTime)
		// 	console.log(`Start Listening`, startListening)
		// 	console.log(`Diff123`, currentTime - startListening)
		// 	console.log(`Overall`, hit.timeStamp)
		// 	console.log(`Obj`, hit)
		// }

		let isStraight = get(hit, `straight`)
		if (!isSixTwelveEight && ((!straightLock && !tripletLock) || get(hit, 'beat') !== refs.latestHitBeatRef.current)) {
			// No grid lock to adhere to in this case
			// Slightly favour the correct grid for the current time???
			if (isStraightTime && !isStraight) {
				if (Math.abs(currentTime - get(closestStraight, `timeStamp`)) / Math.abs(currentTime - get(hit, `timeStamp`)) < 2.415) {
					hit = closestStraight
				}
			} else if (isTripletTime && isStraight) {
				if (Math.abs(currentTime - get(closestTriplet, `timeStamp`)) / Math.abs(currentTime - get(hit, `timeStamp`)) < 2.415) {
					hit = closestTriplet
				}
			}
		} else if (straightLock) {
			hit = closestStraight
		} else if (tripletLock) {
			hit = closestTriplet
		}

		isStraight = get(hit, `straight`)
		const midStraight = isStraight && inRange(get(hit, `beatPosition`), 1, 3)
		const midTriplet = !isStraight && get(hit, `beatPosition`) === 1
		if (isSixTwelveEight) {
			refs.straightLockRef.current = true
			refs.tripletLockRef.current = false
		} else if (midStraight) {
			refs.straightLockRef.current = true
			refs.tripletLockRef.current = false
		} else if (midTriplet) {
			refs.tripletLockRef.current = true
			refs.straightLockRef.current = false
		} else {
			refs.straightLockRef.current = false
			refs.tripletLockRef.current = false
		}
		refs.latestHitBeatRef.current = get(hit, 'beat')

		if (get(hit, [`hit`])) {
			return userHit({ stats: hit })
		}
		return userMiss({ stats: hit })
	}

	/**
	 * Handle the converion of Ones and Zeros into image keys for 6/12 eight.
	 * Splitting the array into groups of 6 and finding the corresponsing image keys
	 * @param {*} onesAndZeros
	 * @returns
	 */
	const sixEightConversion = (onesAndZeros) => {
		const beats = get(onesAndZeros, 'length') / 6
		let newImages = []
		for (let i = 0; i < beats; i++) {
			const start = i * 6
			const playbackSplice = slice(onesAndZeros, start, start + 6)
			const imageId = get(
				find(SixEightSounds.sounds, (entry) => arrayEquals(get(entry, 'arr'), playbackSplice)),
				'id'
			)
			newImages = concat(newImages, imageId)
		}
		return newImages
	}

	/**
	 * Regenerate a bar of the rhythm
	 * @param {number} barNo Which bar is being regenerated
	 * @param {array} currentRhythm the current rhythm
	 * @param {boolean} toRender whether to actually change the rendered rhythm
	 * this time
	 * @returns
	 */
	const regenerateBar = ({ barNo, currentRhythm, toRender = true }) => {
		const sixEightOverride = isSixTwelveEight
		const renderingMode = get(rendering, 'mode')
		let beatsPerBar = noOfBeatsPerBar
		let optionsOverride = false
		if (sixEightOverride) {
			// TODO redo this during the 6/8 refactor
			beatsPerBar = 2
			if (get(playback, 'timeSignature.top') === 12) {
				beatsPerBar = 4
			}
			if (!(get(playback, 'custom') || get(playback, 'levelsArray'))) {
				optionsOverride = 64
				if (get(playback, 'playbackTime.options') === 4) {
					// TODO 6/8 eighth note reading mode - yikes
					// Give a set of numbers to pick from - these are all the
					// 6/8 image codes which are 8th notes
					optionsOverride = 8
				}
			}
		}

		switch (renderingMode) {
			case RenderingModes.DOWNUP.STRAIGHT:
			case RenderingModes.DOWNUP.TRIPLETS:
				beatsPerBar = downUpNoOfBeats({ renderingMode, timeSignatureTop: get(playback, 'timeSignature.top') })
				break
			case RenderingModes.SD.TRIPLETS.ONE:
			case RenderingModes.SD.TRIPLETS.TWO:
			case RenderingModes.SD.TRIPLETS.THREE:
			case RenderingModes.SD.TRIPLETS.FOUR:
			case RenderingModes.SD.TRIPLETS.FIVE:
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
				beatsPerBar = 1
				break
			default:
				break
		}

		if (!refs.rhythmLockRef.current || !toRender) {
			let newRhythm = [...currentRhythm]
			let arr
			switch (renderingMode) {
				case RenderingModes.DOWNUP.STRAIGHT:
					// TODO
					// Need a better way to detect what is coming next in
					// reading mode with down ups
					// Determine what the next grouping after this bar is, then
					// when selecting for the images can avoid incompatible
					// sequences
					const nextGroupingStart = beatsPerBar * barNo
					let nextGrouping = slice(currentRhythm, nextGroupingStart, nextGroupingStart + 4)
					if (isEmpty(nextGrouping)) {
						nextGrouping = slice(currentRhythm, 0, 4)
					}
					nextGrouping = downUpImagesToOriginalImages({ renderingMode, images: nextGrouping })

					// Get the original notation image keys - Using noOfBeatsPerBar
					// rather than 16 as getting four original image keys here
					const notationImages = downUpNotationImages({ renderingMode, noOfBeats: noOfBeatsPerBar, nextGrouping })
					// Convert them to ones and zeros but don't populate playThis
					const onesAndZeros = populatePlay({
						arr: notationImages,
						time: playback.playbackTime.options,
						setPlay: false, // don't populate playThis
						sixEightOverride: false,
					})
					// Convert the ones and zeros to Down up image keys
					arr = downUpConversion({ renderingMode, onesAndZeros })
					break
				case RenderingModes.DOWNUP.TRIPLETS:
					// TODO make it so there aren't big gaps etc (double spaces)
					arr = downUpNotationImages({ renderingMode, noOfBeats: 8 })
					break
				case RenderingModes.SD.TRIPLETS.ONE:
				case RenderingModes.SD.TRIPLETS.TWO:
				case RenderingModes.SD.TRIPLETS.THREE:
				case RenderingModes.SD.TRIPLETS.FOUR:
				case RenderingModes.SD.TRIPLETS.FIVE:
					// TODO find a way to make the newly selected images valid
					arr = sdNotationImages({ renderingMode, noOfBars: 1 })
					break

				case RenderingModes.FIRSTGROOVES.ALL:
				case RenderingModes.FIRSTGROOVES.ONE:
				case RenderingModes.FIRSTGROOVES.TWO:
				case RenderingModes.FIRSTGROOVES.THREE:
					arr = firstGrooveNotationImages({ renderingMode, noOfBars: 1 })
					break
				default:
					if (get(playback, `preset.shuffleAll.on`)) {
						let onesAndZeros
						const existingRhythm = slice(newRhythm, beatsPerBar * (barNo - 1), beatsPerBar)
						arr = existingRhythm
						while (arrayEquals(existingRhythm, arr)) {
							// Get one bar adhering to the current shuffle all params
							arr = shuffleAllPresetArray({ barsOverride: 1 })
							// Convert it into ones and zeros
							onesAndZeros = populatePlay({ arr, setPlay: false, time: get(timeToUse, `options`) })
							// That bar will be in 4:4 so wont be the right
							// length and might not be long enough
							while (get(onesAndZeros, 'length') < subdivisionBarDuration) {
								onesAndZeros = [...onesAndZeros, ...onesAndZeros]
							}
							// Take an appropraite slice for this time signature
							onesAndZeros = take(onesAndZeros, subdivisionBarDuration)
							// Convert that into image keys to use... phew
							arr = playThisToImages({ bars: [onesAndZeros], allowSixEight: true })
						}
						break
					}
					arr = randomNotationArray({ noOfBeats: beatsPerBar, playbackTime: playback.playbackTime, optionsOverride })
					break
			}
			newRhythm.splice(beatsPerBar * (barNo - 1), beatsPerBar, ...arr)
			if (toRender) {
				render({ images: newRhythm, preset: false, playbackTime: playback.playbackTime.options, sixEightOverride, renderingMode })
			}
			return newRhythm
		}
		return refs.renderedArrayRef.current
	}

	/**
	 * Update the number of bars and handle changes to rendering and playback required
	 * @param value boolean or number - If boolean `true` means increment
	 * or decrement, if a number bars are set directly to this value
	 */
	const updateBars = (value) => {
		const setter = (bars, preset) => {
			setBars(bars)
			if (!preset && (!get(playback, `random`) || bars === 1)) {
				return false
			}
			return true
		}
		const sixEightOverride = isSixTwelveEight
		const renderingMode = get(rendering, 'mode')
		const playing = get(playback, 'playing')
		const bars = get(playback, 'bars')
		const time = get(timeToUse, 'options')
		const renderedArray = get(rendering, 'renderedArray')
		const beatsPerBar = get(renderedArray, 'length') / get(playback, 'bars')

		if (playing) {
			return
		}
		let newArr
		const lastBarIndex = get(renderedArray, 'length') - beatsPerBar
		if (value === true && bars < 4) {
			setRandom(setter(bars + 1, false))

			if (isRVPermutations) {
				// Add a position to the end of the grouping position array
				rhythmicVocabPermutationsSetStartPoints([...RVPermutationsStartingPoints, RVPermutationsStartingPoints.at(-1)])
			} else if (!isEmpty(renderedArray)) {
				const addBar = slice(renderedArray, lastBarIndex)
				newArr = concat(renderedArray, addBar)
				populatePlay({ arr: newArr, time, sixEightOverride, renderingMode })
				render({ images: newArr, sixEightOverride, renderingMode })
			}
		} else if (value === false && bars > 1) {
			setRandom(setter(bars - 1, false))

			if (isRVPermutations) {
				// Take a position away from the end of the grouping position
				// array
				rhythmicVocabPermutationsSetStartPoints(RVPermutationsStartingPoints.slice(0, -1))
			} else if (get(renderedArray, 'length') > beatsPerBar) {
				newArr = slice(renderedArray, 0, lastBarIndex)
				populatePlay({ arr: newArr, time, sixEightOverride, renderingMode })
				render({ images: newArr, sixEightOverride, renderingMode })
			}
		} else if (parseInt(value)) {
			// For presets - `value` is an actual number
			setRandom(setter(parseInt(value), true))
		}
		return
	}

	const mapPresetToOddTime = ({ forceBars = false, playThis, changeBars = true, groupings = 4 }) => {
		let arr = []
		const bars = get(playback, 'bars')
		const playThisLength = get(playThis, 'length')
		const lengthToResolve = lowestCommonMultiple(subdivisionBarDuration, groupings)

		let maxBars = changeBars ? 16 : bars > 4 ? 4 : bars
		let noOfBarsToResolve = lengthToResolve / subdivisionBarDuration
		if (forceBars) {
			maxBars = forceBars
			noOfBarsToResolve = forceBars
		}

		let lengthLeft
		if (noOfBarsToResolve > maxBars) {
			//If the rhythm is going to take more than maxBars bars to resolve, cap at maxBars
			noOfBarsToResolve = maxBars
			lengthLeft = subdivisionBarDuration * maxBars
		} else {
			lengthLeft = subdivisionBarDuration * noOfBarsToResolve
		}
		let playThisOddTime
		if (lengthLeft <= playThisLength) {
			playThisOddTime = take(playThis, lengthLeft)
		} else {
			while (lengthLeft > playThisLength) {
				arr = concat(arr, ...playThis)
				lengthLeft -= playThisLength
			}
			playThisOddTime = concat(arr, ...take(playThis, lengthLeft))
		}
		return {
			noOfBarsToResolve,
			playThisOddTime,
		}
	}

	const oddTimePresets = ({ forceBars = false, changeBars = false, playThis, groupings }) => {
		const { playThisOddTime, noOfBarsToResolve } = mapPresetToOddTime({ playThis, changeBars, groupings, forceBars })
		populatePlayDirect({ onesAndZeros: playThisOddTime })
		const bars = chunk(playThisOddTime, subdivisionBarDuration)
		let arrayToRender = playThisToImages({ bars, allowSixEight: true })
		updateBars(noOfBarsToResolve)
		render({ images: arrayToRender, preset: false, sixEightOverride: isSixTwelveEight })
	}

	/**
	 * Change the preset
	 */
	const changePreset = ({ preset, newSet = false }) => {
		const playing = get(playback, 'playing')
		if (get(preset, `first`)) {
			setLongMelodyArray(preset.arr)
		}
		if (get(preset, `val`)) {
			// Linking to the next preset group
			setPresetConstants({ constants: get(preset, `val`) })
			return setPresetName(get(preset, `preset`))
		}
		// Actually rendering the preset
		const time = get(playback, 'time.options')
		setPlaybackTime(time)
		let presetArray, playThis
		switch (true) {
			case newSet && get(playback, `preset.shuffleAll.on`):
				// Requesting a new set of `Shuffle all`
				presetArray = shuffleAllPresetArray({
					fromPresetSelector: false,
					params: get(playback, `preset.shuffleAll.params`),
				})
				break
			case get(preset, `shuffleAll.on`):
				const params = {
					options: get(preset, 'shuffleAll.options', []),
					bars: get(preset, 'bars', 4),
					grouping: get(preset, 'grouping', 4),
				}
				setPresetShuffleAll({
					on: true,
					params,
				})
				presetArray = shuffleAllPresetArray({
					fromPresetSelector: true,
					params,
				})
				break
			case !isEmpty(longMelodyArray):
				setPresetShuffleAll({
					on: false,
					params: {},
				})
				presetArray = longMelodyArray.concat(get(preset, 'arr'))
				break
			default:
				setPresetShuffleAll({
					on: false,
					params: {},
				})
				presetArray = get(preset, 'arr')
				break
		}
		if (!playing) {
			let forceBars = false
			if (get(preset, `shuffleAll.on`, false) || get(playback, `preset.shuffleAll.on`)) {
				// If this is a shuffle all preset, force the number of bars
				// regardless of the time signature
				forceBars = get(preset, `bars`, false) || get(playback, `preset.shuffleAll.params.bars`, 4)
			}
			switch (true) {
				case !newSet && !get(preset, 'bars', false):
					var arr = []
					for (var i = 0; i < get(playback, `bars`); i++) {
						arr = arr.concat(presetArray)
					}
					playThis = populatePlay({ arr, time })
					if (is44) {
						render({ images: arr, preset: false })
						break
					}
					// TODO why was this props.isOdd before - a bug?
					// oddTimePresets({ playThis, changeBars: props.isOdd,
					// groupings: get(preset, 'grouping', 4) })
					oddTimePresets({
						forceBars,
						playThis,
						groupings: get(preset, 'grouping', 4),
					})
					break
				default:
					playThis = populatePlay({ arr: presetArray, time })
					if (is44) {
						updateBars(get(preset, 'bars', get(playback, 'bars')))
						render({ images: presetArray, preset: false })
						break
					}
					oddTimePresets({
						forceBars,
						playThis,
						groupings: get(playback, `preset.shuffleAll.params.grouping`) || get(preset, 'grouping', 4),
					})
					break
			}
		}

		if (get(playback, 'time.options') === Times.STRAIGHT) {
			setPresetConstants({ constants: StraightPresets() })
		} else {
			setPresetConstants({ constants: TripletPresets })
		}
		if (!playing && !newSet) {
			setShowFilters(false)
			setPreset({ on: true })
			setRhythmName({ name: `${presetName} - ${preset.preset}`, preset: true })
		}
		setLongMelodyArray([])
	}

	/**
	 * Shuffle the current rhythm
	 */
	const shuffle = () => {
		const sixEightOverride = isSixTwelveEight
		const renderingMode = get(rendering, 'mode')

		let beatsPerBar = noOfBeatsPerBar
		if (sixEightOverride) {
			beatsPerBar = 2
			if (get(playback, 'timeSignature.top') === 12) {
				beatsPerBar = 4
			}
		}
		switch (renderingMode) {
			case RenderingModes.DOWNUP.STRAIGHT:
			case RenderingModes.DOWNUP.TRIPLETS:
				beatsPerBar = downUpNoOfBeats({ renderingMode, timeSignatureTop: get(playback, 'timeSignature.top') })
				break
			case RenderingModes.SD.TRIPLETS.ONE:
			case RenderingModes.SD.TRIPLETS.TWO:
			case RenderingModes.SD.TRIPLETS.THREE:
			case RenderingModes.SD.TRIPLETS.FOUR:
			case RenderingModes.SD.TRIPLETS.FIVE:
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
				beatsPerBar = 1
				break
			default:
				break
		}

		let tempArr, max
		//Which array to shuffle
		if (!playback.isolated) {
			tempArr = [...rendering.renderedArray]
			max = playback.bars
		} else {
			tempArr = [...playback.isolatedArray[1]]
			max = playback.isolatedArray[0].length
		}
		let newArr = []
		//Shuffle the array
		for (let i = 0; i < noOfBars; i++) {
			let rand = Math.floor(Math.random() * Math.floor(max))
			newArr = newArr.concat(tempArr.splice(rand * beatsPerBar, beatsPerBar).flat())
			max--
		}
		//If shuffling isolated array, need to rebuild entire renderedArray
		if (playback.isolated) {
			let isoArray = [...rendering.renderedArray]
			for (let j = 0; j < playback.isolatedArray[0].length; j++) {
				let insert = newArr.slice(j * beatsPerBar, j * beatsPerBar + beatsPerBar).flat()
				isoArray.splice.apply(isoArray, [playback.isolatedArray[0][j] * beatsPerBar, beatsPerBar].concat(insert))
			}
			newArr = isoArray
		}
		if (!arrayEquals(newArr, rendering.renderedArray)) {
			setRenderedArray(newArr)
			populatePlay({ arr: newArr, time: playback.playbackTime.options, sixEightOverride, renderingMode })
			if (playback.isolated) {
				isolate({ on: true, selectedBars: playback.isolatedArray[0], newArray: newArr })
			}
		} else {
			shuffle()
		}
	}

	const shuffleAllPresetArray = ({ barsOverride = false, fromPresetSelector = false, params = false }) => {
		const p = params || get(playback, `preset.shuffleAll.params`)
		if (!p) {
			return
		}
		let noOfBars = get(p, `bars`, 4)
		if (!fromPresetSelector) {
			noOfBars = get(playback, `bars`)
		}
		noOfBars = barsOverride || noOfBars

		const pOptions = get(p, `options`, [])
		if (isEmpty(pOptions)) {
			return
		}

		let barOptions = {}
		forEach(pOptions, (po) => {
			let chunks = chunk(po, get(po, 'length') / (get(po, 'length') / 4)) // Valid chunks for each bar
			forEach(chunks, (chunk, i) => {
				if (get(barOptions, i)) {
					set(barOptions, i, [...get(barOptions, i), chunk])
					return
				}
				set(barOptions, i, [chunk])
				return
			})
		})

		let k = 0
		let ret = []
		let presetSample
		while (k < noOfBars) {
			switch (noOfBars) {
				case 1:
					presetSample = sample(sample(barOptions))
					break
				default:
					presetSample = sample(get(barOptions, k)) || sample(get(barOptions, 0))
					break
			}
			ret = [...ret, ...presetSample]
			k++
		}
		return ret
	}

	/**
	 * Play a count in
	 * @returns {number} ms duration of the count in
	 */
	const playCountIn = () => {
		const play = get(playback, `click.countIn`)
		if (!play) {
			return 0
		}

		let divider = 1
		let swingMultiplier = 1
		let swingOffset = 0
		let accentPattern = clickAccentPatternCountIn
		if (swingClick) {
			swingMultiplier = 1 + (playback.swing / 100) * 0.33
			if (swingMultiplier !== 1 && accentPattern === 3 && eighthNoteTimeSignature) {
				// Swung eighth not groove in 6/8 or 12/8 should not
				// accent every 3
				accentPattern = 2
			}
		}
		if (get(playback.timeSignature, 'bottom') === 16 && get(playback.timeSignature, 'top') % 2 === 0) {
			divider = 2
		}
		let volumeBoost = refs.clickVolumeRef.current === 0 ? 0.8 : refs.clickVolumeRef.current
		for (var i = 0; i < playback.timeSignature.top / divider; i++) {
			let time = get(audio, 'audioContext.currentTime') + swingOffset
			if (i % playback.timeSignature.top === 0) {
				playSound({ buffer: audio.metronomeOne, time, volume: Math.min(metronomeOneLevel * volumeBoost, 1.5) })
			} else {
				let volume = metronomeLevel
				if (clickRateCountIn !== crotchetTime) {
					if (i % accentPattern === 0) {
						volume = metronomeLevel
					} else {
						volume = metronomeGhostLevel
					}
				}
				playSound({ buffer: audio.metronome, time, volume: Math.min(volume * clickBoost * volumeBoost, 1.5) })
			}
			swingOffset += clickRateCountIn * swingMultiplier
			swingMultiplier = 2 - swingMultiplier
		}
		return msBarDuration
	}

	/**
	 * Play the metronome
	 * @param {Boolean} muted
	 * @returns
	 */
	const playClick = ({ muted = false, startTime, swingMultiplier, tradeMultiplier }) => {
		const mode = get(playback, `mode`)
		const play = refs.metronomeRef.current || [Modes.CLICK, Modes.CLICKREADING].includes(mode)
		if (!play) {
			return
		}

		let clickStartTime = startTime
		let clickSwingMultiplier = 1
		let clickSwingOffset = 0
		let accentPattern = clickAccentPattern
		if (swingClick) {
			clickSwingMultiplier = swingMultiplier || 1
			if (clickSwingMultiplier !== 1 && accentPattern === 3 && eighthNoteTimeSignature) {
				// Swung eighth note groove in 6/8 or 12/8 should not
				// accent every 3
				accentPattern = 2
			}
		}

		const custom = get(playback, `click.rate.custom`)
		let numberOfClicks, divider
		let nextClickOffset = 0
		switch (custom) {
			case false:
				divider = 1
				if (get(playback, 'timeSignature.bottom') === 16 && get(playback, 'timeSignature.top') % 2 === 0) {
					divider = 2
				}
				numberOfClicks = ((get(playback, 'timeSignature.top') * noOfBars) / divider) * tradeMultiplier
				break
			default:
				let clickDuration
				switch (get(playback, `click.rate.time`)) {
					case ClickRateEnum.QUARTER:
						clickDuration = crotchetTime * get(playback, `click.rate.amount`)
						break
					case ClickRateEnum.EIGHTH:
						clickDuration = (crotchetTime / 2) * get(playback, `click.rate.amount`)
						break
					case ClickRateEnum.SIXTEENTH:
						clickDuration = (crotchetTime / 4) * get(playback, `click.rate.amount`)
						break
					case ClickRateEnum.TRIPLETS:
						clickDuration = (crotchetTime / 3) * get(playback, `click.rate.amount`)
						break
					default:
						break
				}

				let unRoundedAmount = (msRhythmDuration * tradeMultiplier - get(refs, [`nextClickOffsetRef`, 'current']) * 1000) / (clickDuration * 1000)
				let difference = unRoundedAmount - Math.floor(unRoundedAmount)
				switch (difference) {
					case 0:
						nextClickOffset = difference
						break
					default:
						nextClickOffset = clickDuration - clickDuration * difference
						break
				}
				numberOfClicks = Math.ceil(unRoundedAmount)
				break
		}

		const mutes = []
		const bD = msBarDuration / 1000
		const nB = noOfBars * tradeMultiplier
		const on = get(playback, `click.gap.on`)
		const off = get(playback, `click.gap.off`)
		const matchRhythmLength = get(playback, `click.gap.matchRhythmLength`)

		const totalBars = get(playback, `click.gap.on`) + get(playback, `click.gap.off`)
		const lowerModulo = nB % totalBars
		// const upperModulo = totalBars % nB
		const oddLoop = (totalBars < nB && lowerModulo !== 0) || totalBars > nB
		const setRefs = !matchRhythmLength && oddLoop

		switch (on) {
			case false:
				break
			default:
				let barsEnabled = []
				const onArr = []
				const offArr = []

				switch (get(refs, [`gapClickRef`, `current`], false)) {
					case false:
						for (var o = 0; o < on; o++) {
							onArr.push(true)
						}
						for (var x = 0; x < off; x++) {
							offArr.push(false)
						}

						switch (get(playback, `click.gap.startOff`)) {
							case true:
								barsEnabled = [...offArr, ...onArr]
								break
							default:
								barsEnabled = [...onArr, ...offArr]
								break
						}
						refs.originalBarsEnabledRef.current = barsEnabled
						break
					default:
						barsEnabled = get(refs, [`gapClickRef`, `current`])
						break
				}

				switch (true) {
					case get(barsEnabled, 'length') < nB:
						let idx = 0
						while (get(barsEnabled, 'length') !== nB) {
							barsEnabled.push(barsEnabled[idx])
							idx++
						}
						break
					case get(barsEnabled, 'length') > nB:
						barsEnabled = slice(barsEnabled, 0, nB)
						break
					default:
						break
				}

				if (matchRhythmLength && !get(refs, [`gapClickRef`, `current`], false)) {
					refs.gapClickRef.current = barsEnabled
				}

				if (setRefs) {
					let currentSlice
					switch (totalBars > nB) {
						case false:
							currentSlice = slice(barsEnabled, lowerModulo)
							refs.gapClickRef.current = [...slice, ...take(slice, lowerModulo)]
							break
						default:
							currentSlice = slice(get(refs, [`originalBarsEnabledRef`, `current`]), nB)
							refs.originalBarsEnabledRef.current = [
								...currentSlice,
								...take(
									get(refs, [`originalBarsEnabledRef`, `current`]),
									get(refs, [`originalBarsEnabledRef`, `current`, `length`]) - get(currentSlice, 'length')
								),
							]
							refs.gapClickRef.current = take(get(refs, [`originalBarsEnabledRef`, `current`]), nB)
							break
					}
				}

				barsEnabled = map(barsEnabled, (bool, count) => {
					const startTime = clickStartTime + count * bD
					const endTime = startTime + bD
					return {
						on: bool,
						startTime,
						endTime,
					}
				})

				forEach(barsEnabled, (be) => {
					if (get(be, `on`)) {
						return
					}
					mutes.push({
						start: Number(get(be, `startTime`).toFixed(2)),
						end: Number(get(be, `endTime`).toFixed(2)),
					})
				})
				break
		}

		let clickOffset = get(playback, `click.offset.amount`)
		switch (get(playback, `click.offset.rate`)) {
			case ClickRateEnum.TRIPLETS:
				clickOffset *= tripletTime
				break
			default:
				clickOffset *= sixteenthNoteTime
				break
		}

		let withinBarIndex = 0
		for (var j = 0; j < numberOfClicks; j++) {
			let skip = false
			let time = clickStartTime + clickOffset + get(refs, [`nextClickOffsetRef`, `current`]) + clickSwingOffset
			const fixedTime = time.toFixed(2)

			// Should this click be muted, is it in any of the muted ranges given?
			forEach(mutes, (m) => {
				const gt = fixedTime >= get(m, `start`)
				const lt = fixedTime - get(m, `end`) < 0
				if (gt && lt) {
					skip = true
				}
			})

			switch (!custom && j % (get(playback, 'timeSignature.top') / divider) === 0) {
				case true:
					if (swingClick) {
						// If swinging the click - reset swing
						// parameters at the start of each new bar
						const multiplier = j / (playback.timeSignature.top / divider)
						clickSwingMultiplier = swingMultiplier
						clickSwingOffset = (msBarDuration / 1000) * multiplier
					}
					withinBarIndex = 0
					if (!skip && refs.clickVolumeRef.current > 0) {
						playSound({ muted, buffer: audio.metronomeOne, time, volume: Math.min(metronomeOneLevel * refs.clickVolumeRef.current, 1.5) })
					}
					break
				default:
					let volume = metronomeLevel
					if (!custom && clickRate !== crotchetTime && withinBarIndex % accentPattern !== 0) {
						volume = metronomeGhostLevel
					}
					if (!skip && refs.clickVolumeRef.current > 0) {
						playSound({ muted, buffer: audio.metronome, time, volume: Math.min(volume * clickBoost * refs.clickVolumeRef.current, 1.5) })
					}
					break
			}
			clickSwingOffset += clickRate * clickSwingMultiplier
			clickSwingMultiplier = 2 - clickSwingMultiplier
			withinBarIndex++
		}
		set(refs, [`nextClickOffsetRef`, `current`], nextClickOffset)

		const mkTheOne = (custom || get(playback, `click.offset.amount`)) && get(playback, `click.rate.markTheOne`)
		if (!mkTheOne) {
			return
		}
		for (var k = 0; k < noOfBars * tradeMultiplier; k++) {
			let time = clickStartTime + k * (msBarDuration / 1000)
			if (refs.clickVolumeRef.current > 0) {
				playSound({ muted, buffer: get(audio, 'markOne'), time, volume: Math.min(metronomeOneLevel * refs.clickVolumeRef.current, 1.5) })
			}
		}
	}

	/**
	 * Play the backbeats
	 * @param {number} noOfBars
	 * @param {boolean} play whether to actually play these backbeats, sometimes
	 * played silently to help detect backbeat clashes
	 * @param {number} startTime
	 * @returns {array} of backbeats to play
	 */
	const playBackbeats = ({ muted = false, noOfBars, play, startTime = 0 }) => {
		let backbeats = []
		let time
		let barResetTime = startTime + msBarDuration / 1000

		// Used to handle odd backbeat patterns
		const oddBackbeats = ({ spaceOrder }) => {
			const options = get(timeToUse, 'options')
			let index = 0
			for (let n = 0; n < Math.round(noOfBeatsPerBar / 2) * noOfBars; n++) {
				let swingMultiplier = options === Times.STRAIGHT ? 1 + (get(playback, 'swing') / 100) * 0.33 : 1
				if (n === 0) {
					time = startTime + crotchetTime
				} else {
					let count = spaceOrder[index]
					let add = 0
					for (let i = 0; i < count; i++) {
						add += sixteenthNoteTime * swingMultiplier
						swingMultiplier = 2 - swingMultiplier
					}
					time = time + add
					if (index + 1 === get(spaceOrder, 'length')) {
						index = 0
					} else {
						index++
					}
					if (isNewBar({ time, barResetTime })) {
						time = barResetTime + crotchetTime
						index = 0
						barResetTime += msBarDuration / 1000
					}
				}
				play && playSound({ muted, buffer: audio.snare, time })
				backbeats.push(time.toFixed(5))
			}
		}
		switch (groove.backbeat) {
			case 0: // 2/4
				for (let j = 0; j < Math.floor(noOfBeatsPerBar / 2) * noOfBars; j++) {
					if (j === 0) {
						time = startTime + crotchetTime
					} else {
						time = time + 2 * crotchetTime
						if (isNewBar({ time, barResetTime })) {
							time = barResetTime + crotchetTime
							barResetTime += msBarDuration / 1000
						}
					}

					play && playSound({ muted, buffer: audio.snare, time })
					backbeats.push(time.toFixed(5))
				}
				break
			case 1: // 3
				for (let k = 0; k < Math.floor(noOfBeatsPerBar / 3) * noOfBars; k++) {
					if (k === 0) {
						time = startTime + 2 * crotchetTime
					} else {
						time = time + 4 * crotchetTime
						if (time >= barResetTime) {
							time = barResetTime + 2 * crotchetTime
							barResetTime += msBarDuration / 1000
						}
					}
					play && playSound({ muted, buffer: audio.snare, time })
					backbeats.push(time.toFixed(5))
				}
				break
			case 2: // 4
				for (let l = 0; l < Math.floor(noOfBeatsPerBar / 4) * noOfBars; l++) {
					if (l === 0) {
						time = startTime + 3 * crotchetTime
					} else {
						time = time + 4 * crotchetTime
						if (time >= barResetTime) {
							time = barResetTime + 3 * crotchetTime
							barResetTime += msBarDuration / 1000
						}
					}
					play && playSound({ muted, buffer: audio.snare, time })
					backbeats.push(time.toFixed(5))
				}
				break
			case 3: //  1/2/3/4
				for (let m = 0; m < noOfBeatsPerBar * noOfBars; m++) {
					if (m === 0) {
						time = startTime
					} else {
						time = time + crotchetTime
						if (time >= barResetTime) {
							time = barResetTime
							barResetTime += msBarDuration / 1000
						}
					}
					play && playSound({ muted, buffer: audio.snare, time })
					backbeats.push(time.toFixed(5))
				}
				break
			case 5: // Time signature default - different backbeats depending on the time signature
				const top = get(playback, 'timeSignature.top')
				let spaceOrder
				switch (top) {
					case 6:
					case 12:
						for (let n = 0; n < Math.round(noOfBeatsPerBar / 3) * noOfBars; n++) {
							if (n === 0) {
								time = startTime + eighthNoteTime * 3
							} else {
								time = time + eighthNoteTime * 6
								if (isNewBar({ time, barResetTime })) {
									time = barResetTime + eighthNoteTime * 3
									barResetTime += msBarDuration / 1000
								}
							}
							play && playSound({ muted, buffer: audio.snare, time })
							backbeats.push(time.toFixed(5))
						}
						break
					case 5:
						spaceOrder = [3, 13]
						oddBackbeats({ spaceOrder })
						break
					case 7:
						spaceOrder = [7, 9]
						oddBackbeats({ spaceOrder })
						break
					case 9:
						spaceOrder = [8, 3, 6]
						oddBackbeats({ spaceOrder })
						break
					default:
						break
				}
				break
			default:
		}

		return backbeats
	}

	/**
	 * Determine whether a backbeat should be played
	 * It is not played if it clashes with another accented snare in the main rhythm
	 * @param backbeats array of ms times of scheduled backbeats
	 * @param {number} time ms time of the backbeat being checked
	 * @param {number} sound identify the clashing sound by its volume level
	 * @returns {boolean} whether or not this backbeat should play
	 */
	const backbeatClash = ({ backbeats = false, time, sound }) => {
		let ret = false
		if (backbeats) {
			if (backbeats.includes(time.toFixed(5))) {
				switch (groove.mix) {
					case 0:
						break
					case 100:
						ret = true
						break
					default:
						if (sound !== kickLevel) {
							ret = true
							break
						}
						break
				}
			}
		}
		return ret
	}

	/**
	 * Generate ones and zeros for the whole rhythm from the starting positions
	 * and groupings defined in the rv permutations state
	 *
	 * @returns {Array}
	 */
	const buildRVPermutations = () => {
		const bars = get(playback, 'bars')
		if (rvPermutationsStartingPointsRef.current.length < bars) {
			errorOccurred('RhythmBot.buildRVPermutations - RVPermutationsStartingPoints.length < bars')
			return
		}

		const grouping = rvOptions[RVGroupingIndex].onesAndZeros
		if (!grouping) {
			errorOccurred('RhythmBot.buildRVPermutations - !grouping')
			return
		}

		const result = new Array(subdivisionBarDuration * bars).fill(0)
		rvPermutationsStartingPointsRef.current.forEach((startingPoints, index) => {
			for (let i of startingPoints) {
				const adjustedIndex = i + index * subdivisionBarDuration

				for (let j = 0; j < grouping.length; j++) {
					if (!grouping[j]) {
						continue
					}

					if (rvHasLoopedRef.current) {
						result[(adjustedIndex + j) % result.length] = grouping[j]
						continue
					}

					if (adjustedIndex + j >= result.length) {
						continue
					}

					result[adjustedIndex + j] = grouping[j]
				}
			}
		})

		rvHasLoopedRef.current = true
		return result
	}

	/**
	 * Play the rhythm
	 * @param {Boolean} muted if set no sounds will be played, used to
	 * load in the rhythm timings in reading mode
	 * @param {*} playThisOveride if set will override the current value of playThis
	 * @param {float} rhythmStartTime override the startTime, if not provided
	 * the time the function was called will be used
	 * @returns {number} the ms duration of this rhythm
	 */
	const playRhythm = ({ muted = false, rhythmStartTime = false, playThisOveride = false }) => {
		const mode = get(playback, 'mode')
		const cancel = isEmpty(get(playback, 'playThis')) && mode !== Modes.CLICK && mode !== Modes.CLICKREADING && !isRVPermutations
		if (cancel) {
			return null
		}

		const currentTime = get(audio, 'audioContext.currentTime')

		if (!isReadingMode) {
			refs.currentLoopRef.current++
		}
		const timings = establishRhythmTimings({
			rhythmStartTime: rhythmStartTime || currentTime,
		})

		const playbackAs = get(playback, 'playbackAs')
		const playbackTime = get(playback, 'playbackTime.options')

		// Value to be returned, determining when next loop starts or when
		// to stop rhythm
		let elapsedTime

		let toPlay
		if (playThisOveride) {
			toPlay = playThisOveride
		} else if (isRVPermutations) {
			toPlay = buildRVPermutations()
		} else {
			toPlay = get(playback, 'playThis')
		}

		let startTime = rhythmStartTime || currentTime
		// For timing playback in trade mode when in mixed subdivisions
		let mixedTradeMultiplier = 1
		// Main sound buffer
		let mainSound
		let volume = 1
		let useTime
		let mixed = false
		//Subdivision
		let swingMultiplier = 1
		let swingOffset = 0
		let timeMultiplier = 1
		switch (playbackTime) {
			case Times.STRAIGHT:
				useTime = sixteenthNoteTime
				swingMultiplier = 1 + (get(playback, 'swing') / 100) * 0.33
				break
			case Times.EIGHTH:
				useTime = sixteenthNoteTime
				timeMultiplier = 2
				swingMultiplier = 1 + (get(playback, 'swing') / 100) * 0.33
				break
			case Times.TRIPLETS:
				useTime = tripletTime
				break
			case Times.MIXED:
				mixed = true
				break
			default:
				break
		}
		switch (mode) {
			case Modes.TRADE:
			case Modes.TRADEREADING:
				if (!mixed) {
					if (playback.tradeDirection && playbackAs !== Sounds.GROOVE) {
						// 'You First' - does not apply in groove mode
						// because of the adjusted startTime... oops
						toPlay = new Array(toPlay.length).fill(0).concat(toPlay)
					} else {
						toPlay = toPlay.concat(new Array(toPlay.length).fill(0))
					}
				} else {
					//Mixed time trade mode - pad the array with empty space
					//to play back
					if (get(playback, 'tradeDirection')) {
						// 'You First'
						toPlay = new Array(4 * noOfBars).fill([4, 0, 0, 0, 0]).flat().concat(toPlay)
					} else {
						toPlay = toPlay.concat(new Array(4 * noOfBars).fill([4, 0, 0, 0, 0]).flat())
					}
					mixedTradeMultiplier = 2
				}
				elapsedTime = 2 * (msBarDuration * noOfBars)
				break
			default:
				//Play Once & Loop
				elapsedTime = msBarDuration * noOfBars
		}

		let skip
		switch (mixed) {
			case false:
				let tradeMultiplier = isTrade ? 2 : 1
				switch (playbackAs) {
					case Sounds.SNARE:
						mainSound = audio.snare
						break
					case Sounds.KICK:
						mainSound = audio.kick
						volume = kickLevel
						break
					case Sounds.GROOVE:
						//Playback as groove
						switch (mode) {
							case Modes.TRADE:
							case Modes.TRADEREADING:
								//Trade mode
								let grooveStartTime = startTime
								if (get(playback, 'tradeDirection')) {
									grooveStartTime += msRhythmDuration / 1000
								}
								playGrooveElements({
									straightTimings: get(timings, `straightTimings`, false),
									tripletTimings: get(timings, `tripletTimings`, false),
									straightClickTimings: get(timings, `straightClickTimings`, false),
									straightTapTimings: get(timings, `straightTapTimings`, false),
									tripletClickTimings: get(timings, `tripletClickTimings`, false),
									tripletTapTimings: get(timings, `tripletTapTimings`, false),
									muted,
									startTime: grooveStartTime,
									useTime,
									toPlay,
									// playThisOveride || playback.playThis,
									noOfBars,
								})
								break
							case Modes.LOOP:
							case Modes.LOOPREADING:
							case Modes.ONCE:
								playGrooveElements({
									straightTimings: get(timings, `straightTimings`, false),
									tripletTimings: get(timings, `tripletTimings`, false),
									straightClickTimings: get(timings, `straightClickTimings`, false),
									straightTapTimings: get(timings, `straightTapTimings`, false),
									tripletClickTimings: get(timings, `tripletClickTimings`, false),
									tripletTapTimings: get(timings, `tripletTapTimings`, false),
									muted,
									startTime,
									useTime,
									toPlay,
									noOfBars,
								})
								break
							case Modes.CLICK:
							case Modes.CLICKREADING:
								playGrooveElements({
									straightTimings: get(timings, `straightTimings`, false),
									tripletTimings: get(timings, `tripletTimings`, false),
									straightClickTimings: get(timings, `straightClickTimings`, false),
									straightTapTimings: get(timings, `straightTapTimings`, false),
									tripletClickTimings: get(timings, `tripletClickTimings`, false),
									tripletTapTimings: get(timings, `tripletTapTimings`, false),
									muted: true,
									startTime,
									useTime,
									toPlay,
									noOfBars,
								})
								break
							default:
								break
						}
						break
					default:
				}

				playClick({ muted, startTime, swingMultiplier, tradeMultiplier })
				if (playbackAs === Sounds.GROOVE) {
					break
				}

				//Main Rhythm
				let mainRhythmSwingMultiplier = swingMultiplier
				let mainRhythmStartTime = startTime
				skip = mode === Modes.CLICK || mode === Modes.CLICKREADING
				const swinging = mainRhythmSwingMultiplier !== 1
				const amount = toPlay.length / timeMultiplier
				const bars = noOfBars * tradeMultiplier
				const notesPerBar = amount / bars
				for (let i = 0; i < amount; i++) {
					const newBar = i % notesPerBar === 0 && i > 0
					if (swinging && isOdd && newBar) {
						//When swinging in an odd time signature - reset the
						//swing at the start of each bar
						const multiplier = i / notesPerBar
						swingOffset = (msBarDuration / 1000) * multiplier
						mainRhythmSwingMultiplier = swingMultiplier
					}
					const time = mainRhythmStartTime + swingOffset
					if (toPlay[i * timeMultiplier] === 1) {
						addGridTime({
							straightTimings: get(timings, `straightTimings`, false),
							tripletTimings: get(timings, `tripletTimings`, false),
							straightClickTimings: get(timings, `straightClickTimings`, false),
							straightTapTimings: get(timings, `straightTapTimings`, false),
							tripletClickTimings: get(timings, `tripletClickTimings`, false),
							tripletTapTimings: get(timings, `tripletTapTimings`, false),
							hit: true,
							time,
						})
						if (!skip) {
							playSound({ muted, buffer: mainSound, time, volume })
						}
					} else {
						if (!skip) {
							refs.ghostsRef.current && playSound({ muted, buffer: audio.snare, time, volume: snareGhostLevel })
						}
					}
					swingOffset += useTime * timeMultiplier * mainRhythmSwingMultiplier
					mainRhythmSwingMultiplier = 2 - mainRhythmSwingMultiplier
				}
				break
			default:
				//Mixed time
				let mixedTimeOffset = 0
				let withinBeatOffset = 0

				playClick({ muted, startTime, tradeMultiplier: mixedTradeMultiplier })

				switch (playbackAs) {
					case Sounds.SNARE:
						mainSound = audio.snare
						break
					case Sounds.KICK:
						mainSound = audio.kick
						volume = kickLevel
						break
					case Sounds.GROOVE:
						mainSound = audio.kick
						volume = kickLevel
						switch (mode) {
							case Modes.TRADE:
							case Modes.TRADEREADING:
								//Trade Mode
								playGrooveElements({
									muted,
									startTime: playback.tradeDirection ? startTime + noOfBars * (4 * crotchetTime) : startTime,
									useTime,
									toPlay,
									noOfBars,
									mixed: true,
								})
								break
							case Modes.ONCE:
							case Modes.LOOP:
							case Modes.LOOPREADING:
								playGrooveElements({ muted, startTime, useTime, toPlay, noOfBars, mixed: true })
								break
							default:
								break
						}
						break
					default:
						break
				}
				let sounds, backbeats
				sounds = backbeats = false
				if (playbackAs === Sounds.GROOVE) {
					sounds = refs.grooveLockRef.current
						? grooveOrderRef.current
							? grooveOrderRef.current
							: mainGrooveOrder({ length: countOccurrences(toPlay, 1) + countOccurrences(toPlay, 3) })
						: mainGrooveOrder({ length: countOccurrences(toPlay, 1) + countOccurrences(toPlay, 3) })
					backbeats = playBackbeats({
						noOfBars,
						play: false,
						startTime: (mode === Modes.TRADE || mode === Modes.TRADEREADING) && playback.tradeDirection ? noOfBars * (4 * crotchetTime) : 0,
					})
				}

				let count = 0
				const skipOverride = mode === Modes.CLICK || mode === Modes.CLICKREADING
				skip = false
				let t
				for (var l = 0; l < toPlay.length; l++) {
					//Play different sound based on the value at the current position in the array and keep track of the offsets
					switch (toPlay[l + 1]) {
						case 1:
							//Sixteenth core sound
							t = startTime + mixedTimeOffset + withinBeatOffset * sixteenthNoteTime
							addGridTime({
								straightTimings: get(timings, `straightTimings`, false),
								tripletTimings: get(timings, `tripletTimings`, false),
								straightClickTimings: get(timings, `straightClickTimings`, false),
								straightTapTimings: get(timings, `straightTapTimings`, false),
								tripletClickTimings: get(timings, `tripletClickTimings`, false),
								tripletTapTimings: get(timings, `tripletTapTimings`, false),
								hit: true,
								time: t,
							})
							skip =
								skipOverride ||
								backbeatClash({
									backbeats,
									time: t,
									sound: sounds.length ? sounds[count][1] : null,
								})
							if (!skip) {
								playSound({
									muted,
									buffer:
										playbackAs !== Sounds.GROOVE ? mainSound : groove.mix === 100 ? audio.snare : groove.mix === 0 ? audio.kick : sounds[count][0],
									time: t,
									volume: playbackAs !== Sounds.GROOVE ? volume : groove.mix === 100 ? false : groove.mix === 0 ? kickLevel : sounds[count][1],
								})
							}
							if (playbackAs === Sounds.GROOVE && groove.cymbal.pattern === CymbalPatterns.MATCH_KICK) {
								groove.cymbal.sound === Cymbal.HH
									? playSound({
											muted,
											buffer: audio.hihat,
											time: t,
											volume: hhAccentLevel,
									  })
									: playSound({
											muted,
											buffer: audio.ride,
											time: t,
											volume: rideAccentLevel,
									  })
							}
							if (playbackAs === Sounds.GROOVE && refs.ghostsRef.current && groove.ghosts === 1) {
								playSound({
									muted,
									buffer: audio.snare,
									time: t,
									volume: snareGhostLevel + 0.16,
								})
							}
							withinBeatOffset += 1
							count++
							break
						case 0:
							//Sixteenth ghost
							t = startTime + mixedTimeOffset + withinBeatOffset * sixteenthNoteTime
							if (refs.ghostsRef.current) {
								if (playbackAs < Sounds.GROOVE) {
									playSound({
										muted,
										buffer: audio.snare,
										time: t,
										volume: snareGhostLevel,
									})
								} else if (groove.ghosts === 0) {
									playSound({
										muted,
										buffer: audio.snare,
										time: t,
										volume: snareGhostLevel,
									})
								}
							}
							withinBeatOffset += 1
							break
						case 2:
							//Triplet ghost
							t = startTime + mixedTimeOffset + withinBeatOffset * tripletTime
							if (refs.ghostsRef.current) {
								if (playbackAs < Sounds.GROOVE) {
									playSound({ muted, buffer: audio.snare, time: t, volume: snareGhostLevel })
								} else if (groove.ghosts === 0) {
									playSound({ muted, buffer: audio.snare, time: t, volume: snareGhostLevel })
								}
							}
							withinBeatOffset += 1
							break
						case 3:
							//Triplet core sound
							t = startTime + mixedTimeOffset + withinBeatOffset * tripletTime
							addGridTime({
								straightTimings: get(timings, `straightTimings`, false),
								tripletTimings: get(timings, `tripletTimings`, false),
								straightClickTimings: get(timings, `straightClickTimings`, false),
								straightTapTimings: get(timings, `straightTapTimings`, false),
								tripletClickTimings: get(timings, `tripletClickTimings`, false),
								tripletTapTimings: get(timings, `tripletTapTimings`, false),
								hit: true,
								time: t,
							})
							skip =
								skipOverride ||
								backbeatClash({
									backbeats,
									time: t,
									sound: sounds.length ? sounds[count][1] : null,
								})
							if (!skip) {
								playSound({
									muted,
									buffer:
										playbackAs !== Sounds.GROOVE ? mainSound : groove.mix === 100 ? audio.snare : groove.mix === 0 ? audio.kick : sounds[count][0],
									time: t,
									volume: playbackAs !== Sounds.GROOVE ? volume : groove.mix === 100 ? false : groove.mix === 0 ? kickLevel : sounds[count][1],
								})
							}
							if (playbackAs === Sounds.GROOVE && groove.cymbal.pattern === CymbalPatterns.MATCH_KICK) {
								groove.cymbal.sound === 0
									? playSound({ muted, buffer: audio.hihat, time: t, volume: hhAccentLevel })
									: playSound({ muted, buffer: audio.ride, time: t, volume: rideAccentLevel })
							}
							if (playbackAs === Sounds.GROOVE && refs.ghostsRef.current && groove.ghosts === 1) {
								playSound({ muted, buffer: audio.snare, time: t, volume: snareGhostLevel + 0.16 })
							}
							withinBeatOffset += 1
							count++
							break
						case 4:
							//Silence
							mixedTimeOffset += crotchetTime
							withinBeatOffset = 0
							break
						default:
							break
					}
				}
				break
		}
		rhythmTimings({
			straight: {
				straightTimings: get(timings, `straightTimings`, false),
				straightClickTimings: get(timings, `straightClickTimings`, false),
				straightTapTimings: get(timings, `straightTapTimings`, false),
			},
			triplets: {
				tripletTimings: get(timings, `tripletTimings`, false),
				tripletClickTimings: get(timings, `tripletClickTimings`, false),
				tripletTapTimings: get(timings, `tripletTapTimings`, false),
			},
		})
		return elapsedTime
	}

	const playGrooveElements = ({
		straightTimings = false,
		tripletTimings = false,
		straightClickTimings = false,
		straightTapTimings = false,
		tripletClickTimings = false,
		tripletTapTimings = false,
		muted = false,
		startTime,
		useTime,
		toPlay,
		noOfBars,
		mixed = false,
	}) => {
		//Main rhythm and ghosts in mixed time are handled in playRhythm
		const backbeats = playBackbeats({ muted, noOfBars, play: true, startTime })

		let swingMultiplier = 1 + (playback.swing / 100) * 0.33
		let ghostsSwingMultiplier = isStraightTime ? swingMultiplier : 1
		let hhSwingMultipler = swingMultiplier
		let swingOffset = 0
		let timeMultiplier = 1
		if (!mixed) {
			//Set multipliers based on subdivision
			switch (get(playback, 'playbackTime.options')) {
				case Times.EIGHTH:
					timeMultiplier = 2
					break
				case Times.TRIPLETS:
					// No swing when in triplets
					swingMultiplier = 1
					break
				default:
					break
			}

			let tradeMultiplier = isTrade ? 2 : 1
			// Main rhythm - Groove mode
			let sounds
			if (refs.grooveLockRef.current && grooveOrderRef.current) {
				sounds = grooveOrderRef.current
			} else {
				sounds = mainGrooveOrder({ length: countOccurrences(toPlay, 1) })
			}

			let count = 0
			let skip = false
			let mainRhythmSwingMultiplier = swingMultiplier
			const swinging = mainRhythmSwingMultiplier !== 1
			const amount = toPlay.length / timeMultiplier
			const bars = noOfBars * tradeMultiplier
			const notesPerBar = amount / bars

			for (let i = 0; i < amount; i++) {
				const newBar = i % notesPerBar === 0 && i > 0
				if (swinging && isOdd && newBar) {
					//When swinging in an odd time signature - reset the
					//swing at the start of each bar
					const multiplier = i / notesPerBar
					swingOffset = (msBarDuration / 1000) * multiplier
					mainRhythmSwingMultiplier = swingMultiplier
				}
				const time = startTime + swingOffset

				if (toPlay[i * timeMultiplier] === 1) {
					addGridTime({ straightTimings, tripletTimings, straightClickTimings, straightTapTimings, tripletClickTimings, tripletTapTimings, hit: true, time })
					skip = backbeatClash({ backbeats, time, sound: sounds.length ? sounds[count][1] : null })
					if (!skip) {
						playSound({
							muted,
							buffer: groove.mix === 100 ? audio.snare : groove.mix === 0 ? audio.kick : sounds[count][0],
							time,
							volume: groove.mix === 100 ? false : groove.mix === 0 ? kickLevel : sounds[count][1],
						})
					}
					count++
				}
				swingOffset += useTime * timeMultiplier * mainRhythmSwingMultiplier
				mainRhythmSwingMultiplier = 2 - mainRhythmSwingMultiplier
			}
			//Ghosts
			if (refs.ghostsRef.current) {
				switch (groove.ghosts) {
					case 0: //Fill space
						swingOffset = 0
						if (groove.cymbal.pattern === CymbalPatterns.UP_DOWN) {
							// Fill in between HH when in up down HH mode
							let fillerGhosts = true
							for (let i = 0; i < amount; i++) {
								const newBar = i % notesPerBar === 0 && i > 0
								if (swinging && isOdd && newBar) {
									const multiplier = i / notesPerBar
									swingOffset = (msBarDuration / 1000) * multiplier
									ghostsSwingMultiplier = swingMultiplier
								}
								if (toPlay[i * timeMultiplier] === 1) {
									fillerGhosts = true
								}
								if (toPlay[i * timeMultiplier] === 0) {
									if (fillerGhosts) {
										playSound({ muted, buffer: audio.snare, time: startTime + swingOffset, volume: snareGhostLevel })
									}
									fillerGhosts = !fillerGhosts
								}
								swingOffset += useTime * timeMultiplier * ghostsSwingMultiplier
								ghostsSwingMultiplier = 2 - ghostsSwingMultiplier
							}
							break
						} else {
							for (let i = 0; i < amount; i++) {
								const newBar = i % notesPerBar === 0 && i > 0
								if (swinging && isOdd && newBar) {
									const multiplier = i / notesPerBar
									swingOffset = (msBarDuration / 1000) * multiplier
									ghostsSwingMultiplier = swingMultiplier
								}
								if (toPlay[i * timeMultiplier] === 0) {
									playSound({ muted, buffer: audio.snare, time: startTime + swingOffset, volume: snareGhostLevel })
								}
								swingOffset += useTime * timeMultiplier * ghostsSwingMultiplier
								ghostsSwingMultiplier = 2 - ghostsSwingMultiplier
							}
						}
						break
					case 1: //Stack with kick
						swingOffset = 0
						for (let i = 0; i < amount; i++) {
							const newBar = i % notesPerBar === 0 && i > 0
							if (swinging && isOdd && newBar) {
								const multiplier = i / notesPerBar
								swingOffset = (msBarDuration / 1000) * multiplier
								ghostsSwingMultiplier = swingMultiplier
							}
							if (toPlay[i * timeMultiplier] === 1) {
								playSound({ muted, buffer: audio.snare, time: startTime + swingOffset, volume: snareGhostLevel + 0.16 })
							}
							swingOffset += useTime * timeMultiplier * ghostsSwingMultiplier
							ghostsSwingMultiplier = 2 - ghostsSwingMultiplier
						}
						break
					default:
				}
				//Otherwise use this code
				// swingOffset = 0;
				// for (let i = 0; i < (toPlay.length / timeMultiplier); i++) {
				//     if (toPlay[i * timeMultiplier] === parseInt(groove.ghosts)) {
				//         playSound(audio.snare, startTime + (swingOffset), snareGhostLevel);
				//     }
				//     swingOffset += ((useTime * timeMultiplier) * ghostsSwingMultiplier);
				//     ghostsSwingMultiplier = 2 - ghostsSwingMultiplier;
				// }
			}
		}
		//HH - Different subdivisions have different possible HH values held in the constants file groove-elements
		let hhTimeMultipler, cymbalSound, cymbalAccent, cymbalGhost
		switch (get(groove, [`cymbal`, `sound`])) {
			case 0:
				cymbalSound = audio.hihat
				cymbalAccent = hhAccentLevel
				cymbalGhost = hhGhostLevel
				break
			case 1:
				cymbalSound = audio.ride
				cymbalAccent = rideAccentLevel
				cymbalGhost = rideGhostLevel
				break
			default:
				break
		}
		//TODO Is OTBL ever going to apply to HH ?
		const overTheBarLine = false
		const odd = isOdd && !overTheBarLine
		let initSwingMultiplier = hhSwingMultipler
		const swung = hhSwingMultipler !== 1
		let barResetTime = startTime + msBarDuration / 1000
		let hhStartTime = startTime
		let amount = 0
		let newBar, time, volume
		volume = cymbalAccent

		switch (get(groove, [`cymbal`, `pattern`])) {
			case CymbalPatterns.CROTCHETS:
				let modifier = 0
				amount = noOfBars * noOfBeatsPerBar
				for (var i = 0; i < amount; i++) {
					time = hhStartTime + (i - modifier) * crotchetTime
					if (odd) {
						newBar = i % noOfBeatsPerBar === 0 && i > 0
						if (newBar) {
							const multiplier = i / noOfBeatsPerBar
							hhStartTime = startTime + (msBarDuration / 1000) * multiplier
							modifier = i
							time = hhStartTime
						}
					}
					playSound({ muted, buffer: cymbalSound, time, volume })
				}
				break
			case CymbalPatterns.UP_BEATS:
				const toSwing = get(playback, 'playbackTime.options') === 4
				if (toSwing) {
					// In 8th time the upbeat can be pushed slightly
					swingOffset = eighthNoteTime * hhSwingMultipler
				} else {
					swingOffset = eighthNoteTime
				}
				volume = cymbalAccent
				amount = noOfBars * noOfBeatsPerBar
				for (let i = 0; i < amount; i++) {
					time = hhStartTime + swingOffset
					if (odd && i > 0) {
						newBar = isNewBar({ time, barResetTime })
						if (newBar) {
							if (swung) {
								swingOffset = eighthNoteTime * hhSwingMultipler
							} else {
								swingOffset = eighthNoteTime
							}
							time = barResetTime + swingOffset
							hhStartTime = barResetTime
							barResetTime += msBarDuration / 1000
						}
					}
					playSound({ muted, buffer: cymbalSound, time, volume })
					swingOffset += crotchetTime
				}
				break
			case CymbalPatterns.EIGHTH:
			case CymbalPatterns.EIGHTH_ACCENT_DOWN:
			case CymbalPatterns.EIGHTH_ACCENT_UP:
				const options = get(playback, 'playbackTime.options')
				if (options !== 4) {
					// If not ian eigth note groove, ignore all swing influence
					initSwingMultiplier = 1
					hhSwingMultipler = 1
				}
				if (options === 22) {
					useTime = sixteenthNoteTime
				}
				swingOffset = 0
				amount = noOfBars * get(playback, 'timeSignature.top') * (8 / get(playback, 'timeSignature.bottom'))
				hhTimeMultipler = 2
				for (let i = 0; i < amount; i++) {
					time = hhStartTime + swingOffset
					if (odd && i > 0) {
						newBar = isNewBar({ time, barResetTime })
						if (newBar) {
							swingOffset = 0
							hhSwingMultipler = initSwingMultiplier
							hhStartTime = barResetTime
							time = barResetTime
							barResetTime += msBarDuration / 1000
						}
					}
					volume = cymbalAccent
					if (groove.cymbal.pattern === CymbalPatterns.EIGHTH || i % 2 !== groove.cymbal.pattern - 3) {
						volume = cymbalGhost
					}
					playSound({ muted, buffer: cymbalSound, time, volume })
					swingOffset += useTime * hhTimeMultipler * hhSwingMultipler
					hhSwingMultipler = 2 - hhSwingMultipler
				}
				break
			case CymbalPatterns.SIXTEENTH:
			case CymbalPatterns.SIXTEENTH_ACCENT_EIGHTH:
				swingOffset = 0
				amount = get(toPlay, 'length')
				for (let i = 0; i < amount; i++) {
					time = hhStartTime + swingOffset
					if (odd && i > 0) {
						newBar = isNewBar({ time, barResetTime })
						if (newBar) {
							swingOffset = 0
							hhSwingMultipler = initSwingMultiplier
							hhStartTime = barResetTime
							time = barResetTime
							barResetTime += msBarDuration / 1000
						}
					}
					volume = cymbalAccent
					if (groove.cymbal.pattern === CymbalPatterns.SIXTEENTH || i % 2 === 1) {
						volume = cymbalGhost
					}
					playSound({ muted, buffer: cymbalSound, time, volume })
					swingOffset += useTime * hhSwingMultipler
					hhSwingMultipler = 2 - hhSwingMultipler
				}
				break
			case CymbalPatterns.SIXTEENTH_ACCENT_DOWN:
				swingOffset = 0
				amount = get(toPlay, 'length')
				for (let i = 0; i < toPlay.length; i++) {
					time = hhStartTime + swingOffset
					if (odd && i > 0) {
						newBar = isNewBar({ time, barResetTime })
						if (newBar) {
							swingOffset = 0
							hhSwingMultipler = initSwingMultiplier
							hhStartTime = barResetTime
							time = barResetTime
							barResetTime += msBarDuration / 1000
						}
					}
					volume = cymbalAccent
					if (i % 4 !== 0) {
						volume = cymbalGhost
					}
					playSound({ muted, buffer: cymbalSound, time, volume })
					swingOffset += useTime * hhSwingMultipler
					hhSwingMultipler = 2 - hhSwingMultipler
				}
				break
			case CymbalPatterns.SIXTEENTH_ACCENT_UP:
				swingOffset = 0
				amount = get(toPlay, 'length')
				for (let i = 0; i < amount; i++) {
					time = hhStartTime + swingOffset
					if (odd && i > 0) {
						newBar = isNewBar({ time, barResetTime })
						if (newBar) {
							swingOffset = 0
							hhSwingMultipler = initSwingMultiplier
							hhStartTime = barResetTime
							time = barResetTime
							barResetTime += msBarDuration / 1000
						}
					}
					volume = cymbalAccent
					if ((i - 2) % 4 !== 0) {
						volume = cymbalGhost
					}
					playSound({ muted, buffer: cymbalSound, time, volume })
					swingOffset += useTime * hhSwingMultipler
					hhSwingMultipler = 2 - hhSwingMultipler
				}
				break
			case CymbalPatterns.ONE_AND_A:
				swingOffset = 0
				amount = get(toPlay, 'length')
				for (let i = 0; i < amount; i++) {
					time = hhStartTime + swingOffset
					if (odd && i > 0) {
						newBar = isNewBar({ time, barResetTime })
						if (newBar) {
							swingOffset = 0
							hhSwingMultipler = initSwingMultiplier
							hhStartTime = barResetTime
							time = barResetTime
							barResetTime += msBarDuration / 1000
						}
					}
					if (i === 0 || i % 4 === 0) {
						playSound({ muted, buffer: cymbalSound, time, volume: cymbalAccent })
					}
					if ((i - 2) % 4 === 0 || (i - 3) % 4 === 0) {
						playSound({ muted, buffer: cymbalSound, time, volume: cymbalGhost })
					}
					swingOffset += useTime * hhSwingMultipler
					hhSwingMultipler = 2 - hhSwingMultipler
				}
				break
			case CymbalPatterns.ONE_E_AND:
				swingOffset = 0
				amount = get(toPlay, 'length')
				for (let i = 0; i < amount; i++) {
					time = hhStartTime + swingOffset
					if (odd && i > 0) {
						newBar = isNewBar({ time, barResetTime })
						if (newBar) {
							swingOffset = 0
							hhSwingMultipler = initSwingMultiplier
							hhStartTime = barResetTime
							time = barResetTime
							barResetTime += msBarDuration / 1000
						}
					}
					if ((i - 2) % 4 === 0) {
						playSound({ muted, buffer: cymbalSound, time, volume: cymbalAccent })
					}
					if (i % 4 === 0 || (i - 1) % 4 === 0) {
						playSound({ muted, buffer: cymbalSound, time, volume: cymbalGhost })
					}
					swingOffset += useTime * hhSwingMultipler
					hhSwingMultipler = 2 - hhSwingMultipler
				}
				break
			case CymbalPatterns.MATCH_KICK:
				if (!mixed) {
					swingOffset = 0
					amount = get(toPlay, 'length') / timeMultiplier
					for (let i = 0; i < amount; i++) {
						time = hhStartTime + swingOffset
						if (odd && swung && i > 0) {
							newBar = isNewBar({ time, barResetTime })
							if (newBar) {
								swingOffset = 0
								hhSwingMultipler = initSwingMultiplier
								hhStartTime = barResetTime
								time = barResetTime
								barResetTime += msBarDuration / 1000
							}
						}
						if (toPlay[i * timeMultiplier] === 1) {
							playSound({ muted, buffer: cymbalSound, time, volume: cymbalAccent })
						}
						swingOffset += useTime * timeMultiplier * hhSwingMultipler
						hhSwingMultipler = 2 - hhSwingMultipler
					}
				}
				break
			case CymbalPatterns.UP_DOWN:
				let fillerHH = true
				swingOffset = 0
				amount = get(toPlay, 'length') / timeMultiplier
				for (let i = 0; i < amount; i++) {
					time = hhStartTime + swingOffset
					if (odd && swung && i > 0) {
						newBar = isNewBar({ time, barResetTime })
						if (newBar) {
							swingOffset = 0
							hhSwingMultipler = initSwingMultiplier
							hhStartTime = barResetTime
							time = barResetTime
							barResetTime += msBarDuration / 1000
						}
					}
					if (toPlay[i * timeMultiplier] === 1) {
						playSound({ muted, buffer: cymbalSound, time, volume: cymbalAccent })
						fillerHH = true
					}
					if (toPlay[i * timeMultiplier] === 0 && (toPlay[i * timeMultiplier - timeMultiplier] === 0 || i === 0)) {
						if (fillerHH) {
							playSound({ muted, buffer: cymbalSound, time, volume: cymbalGhost })
						}
						fillerHH = !fillerHH
					}
					swingOffset += useTime * timeMultiplier * hhSwingMultipler
					hhSwingMultipler = 2 - hhSwingMultipler
				}
				break
			case CymbalPatterns.TRIP_ONE_A:
				amount = get(toPlay, 'length') / timeMultiplier
				for (let i = 0; i < amount; i++) {
					time = startTime + i * useTime
					if (i === 0 || i % 3 === 0) {
						playSound({ muted, buffer: cymbalSound, time, volume: cymbalAccent })
					}
					if ((i - 2) % 3 === 0) {
						playSound({ muted, buffer: cymbalSound, time, volume: cymbalGhost })
					}
				}
				break
			case CymbalPatterns.TRIP_ONE_AND:
				amount = get(toPlay, 'length') / timeMultiplier
				for (let i = 0; i < amount; i++) {
					time = startTime + i * useTime
					if (i === 0 || i % 3 === 0) {
						playSound({ muted, buffer: cymbalSound, time, volume: cymbalAccent })
					}
					if ((i - 1) % 3 === 0) {
						playSound({ muted, buffer: cymbalSound, time, volume: cymbalGhost })
					}
				}
				break
			case CymbalPatterns.TRIP_AND_A:
				amount = get(toPlay, 'length') / timeMultiplier
				for (let i = 0; i < amount; i++) {
					if ((i - 1) % 3 === 0) {
						playSound({ muted, buffer: cymbalSound, time, volume: cymbalGhost })
					}
					if ((i - 2) % 3 === 0) {
						playSound({ muted, buffer: cymbalSound, time, volume: cymbalAccent })
					}
				}
				break
			case CymbalPatterns.TRIP_SPANG_A_LANG:
				amount = get(toPlay, 'length') / timeMultiplier
				for (let i = 0; i < amount; i++) {
					time = startTime + i * useTime
					if (i === 0 || i % 6 === 0 || (i + 1) % 6 === 0) {
						playSound({ muted, buffer: cymbalSound, time, volume: cymbalGhost })
					}
					if ((i + 3) % 6 === 0) {
						playSound({ muted, buffer: cymbalSound, time, volume: cymbalAccent })
					}
				}
				break
			default:
		}
	}

	/**
	 * Determine whether in a new bar based on ms timestamps
	 * @param {number} time
	 * @param {number} barResetTime
	 * @returns
	 */
	const isNewBar = ({ time, barResetTime }) => {
		const barResetPrecision = 9
		return parseFloat(time.toFixed(barResetPrecision)) >= parseFloat(barResetTime.toFixed(barResetPrecision))
	}

	/**
	 * Produce the order of sounds for the main rhythm within a groove, handling
	 * whether it is all kick, all snare or somewhere in between
	 * @param {number} length the length of the main rhythm array
	 * @returns false if only one sound in the groove, otherwise an array of
	 * sounds to use
	 */
	const mainGrooveOrder = ({ length }) => {
		if (groove.mix === 0 || groove.mix === 100) {
			setGrooveOrder(false)
			return false
		}

		let probabilityMultiplier = 1
		const arr = []
		for (let i = 0; i < length; i++) {
			let sound =
				Math.floor(Math.random() * 100) < groove.mix ? [audio.snare, false, probabilityMultiplier * 1.12] : [audio.kick, kickLevel, probabilityMultiplier * 0.88]
			sound[2] < 0.5 ? (probabilityMultiplier = 0.5) : sound[2] > 1.5 ? (probabilityMultiplier = 1.5) : (probabilityMultiplier = sound[2])
			arr.push(sound)
		}
		setGrooveOrder(arr)
		return arr
	}

	/**
	 * Highlight bars as they play
	 * @param {boolean} on whether turning highlighting on or off
	 */
	const highlightBars = ({ on }) => {
		if (on) {
			let count = 0
			refs.highlightInterval.current = accurateInterval(
				() => {
					if (playback.isolated) {
						if (count === playback.isolatedArray[0].length) {
							count = 0
						}
						setHighlighted(playback.isolatedArray[0][count] + 1)
						count++
					} else {
						if (count === playback.bars) {
							count = 0
						}
						count++
						setHighlighted(count)
					}
				},
				msBarDuration,
				{ immediate: !get(playback, `click.countIn`) }
			)
			return
		}

		setHighlighted(0)
		refs.highlightInterval.current && refs.highlightInterval.current.clear()
	}

	const readingMode = (rhythmStartTime) => {
		const renderingMode = get(rendering, 'mode')
		const sixEightOverride = isSixTwelveEight
		const time = get(playback, 'playbackTime.options')
		const isolated = get(playback, 'isolated')
		const selectedBars = get(playback, [`isolatedArray`, 0])

		setGrooveLock(false)
		let rhythm = get(rendering, 'renderedArray')
		let previousRhythm = rhythm

		let rStartTime = rhythmStartTime
		let rAddition = msRhythmDuration / 1000

		let withinBarCounter = 0
		let currentBar = get(playback, `click.countIn`) ? 0 : 1
		if (isTrade) {
			rAddition *= 2
			currentBar -= noOfBars
		}

		let SeenBars = new Map([])
		let nextLoopTriggered = false
		let refsSet = !isPracticeModeOn
		const divider = 50
		refs.readingInterval.current = accurateInterval(
			() => {
				withinBarCounter++
				if (!refsSet && currentBar > 0) {
					refsSet = true
					refs.readingPracticePlayThisRef.current = get(refs, [`practicePlayThisRef`, `current`])
					refs.readingPracticeRenderedArrayRef.current = get(refs, [`practiceRenderedArrayRef`, `current`])
				}
				const takeAction = currentBar > 0 && !SeenBars.get(currentBar)
				const rhythmLock = get(refs, [`rhythmLockRef`, `current`])
				if (takeAction && !rhythmLock) {
					SeenBars.set(currentBar, true)
					previousRhythm = rhythm
					let barNo = currentBar
					if (isolated) {
						barNo = get(selectedBars, [currentBar - 1]) + 1
					}
					rhythm = regenerateBar({ barNo, currentRhythm: rhythm, toRender: false })
				} else if (rhythmLock) {
					SeenBars.set(currentBar, false)
					rhythm = previousRhythm
				}

				if (currentBar === noOfBars && !nextLoopTriggered && withinBarCounter > Math.floor(divider * 0.975)) {
					rStartTime += rAddition
					let onesAndZeros = populatePlay({ arr: rhythm, time, sixEightOverride, renderingMode })
					if (isolated) {
						onesAndZeros = isolate({ on: true, selectedBars, newArray: rhythm })
					}
					playRhythm({
						rhythmStartTime: rStartTime,
						playThisOveride: onesAndZeros,
					})
					nextLoopTriggered = true
				}
				if (withinBarCounter === divider) {
					render({ images: rhythm, preset: false, playbackTime: time, sixEightOverride, renderingMode })
					withinBarCounter = 0
					currentBar++
					if (currentBar > noOfBars) {
						nextLoopTriggered = false
						SeenBars = new Map([])
						refsSet = !isPracticeModeOn
						currentBar = 1
						if (isTrade) {
							currentBar -= noOfBars
						}
					}
				}
			},
			msBarDuration / divider,
			{ immediate: true }
		)
	}

	const rvPermutationsReadingMode = (rhythmStartTime) => {
		let rStartTime = rhythmStartTime
		let rAddition = msRhythmDuration / 1000
		let currentBar = get(playback, `click.countIn`) ? 0 : 1

		if (isTrade) {
			rAddition *= 2
			currentBar -= noOfBars
		}

		let nextLoopTriggered = false
		let SeenBars = new Map([])
		let builtStartingPoints = []
		let withinBarCounter = 0

		const divider = 50
		refs.readingInterval.current = accurateInterval(
			() => {
				withinBarCounter++

				const takeAction = currentBar > 0 && !SeenBars.get(currentBar)
				const isLastBar = currentBar === noOfBars
				const rhythmLock = get(refs, [`rhythmLockRef`, `current`])

				if (takeAction && !rhythmLock) {
					const currentBarStartingPoints = rvPermutationsStartingPointsRef.current[currentBar - 1]
					const groupingLength = rvOptions[RVGroupingIndex].onesAndZeros.length

					// TODO rhythms can't go over the bar line from the last bar
					const newStartingPoints = generateUniqueRandomArray(currentBarStartingPoints.length, 0, subdivisionBarDuration - 1 - (isLastBar ? groupingLength : 0))

					builtStartingPoints.push(newStartingPoints)
					SeenBars.set(currentBar, newStartingPoints)
				} else if (rhythmLock) {
					SeenBars.set(currentBar, false)
				}

				if (!nextLoopTriggered && isLastBar && withinBarCounter > Math.floor(divider * 0.975)) {
					rStartTime += rAddition
					rvPermutationsStartingPointsRef.current = builtStartingPoints
					playRhythm({
						rhythmStartTime: rStartTime,
					})
					nextLoopTriggered = true
				}

				if (withinBarCounter === divider) {
					if (SeenBars.get(currentBar)) {
						let newStartingPoints = [...rvPermutationsStartingPointsRef.current]
						newStartingPoints[currentBar - 1] = SeenBars.get(currentBar)
						rhythmicVocabPermutationsSetStartPoints(newStartingPoints)
					}

					withinBarCounter = 0
					currentBar++
					if (currentBar > noOfBars) {
						nextLoopTriggered = false
						SeenBars = new Map([])
						currentBar = 1
						builtStartingPoints = []
						if (isTrade) {
							currentBar -= noOfBars
						}
					}
				}
			},
			msBarDuration / divider,
			{ immediate: true }
		)

		return
	}

	/**
	 * establishRhythmTimings is used to generate all valid triplet and straight
	 * timings within the current rhythm.
	 * Different timings are saved for click and tap play along
	 * @param {Number} rhythmStartTime the start time of the rhythm
	 * @returns
	 */
	const establishRhythmTimings = ({ rhythmStartTime }) => {
		if (!isReadingMode && get(refs, [`currentLoopRef`, `current`]) > 1) {
			return
		}
		const straightTimings = []
		const tripletTimings = []

		// TODO
		// const tapAddition = 0.0634
		// const clickAddition = isIonic() ? 0.126 : 0.0385
		// const clickAddition = isIonic() ? 0.5 : 0.0385

		const numberOfSixteenths = msRhythmDuration / 1000 / (crotchetTime / 4)
		let addition = 0
		if (isTrade && !get(playback, `tradeDirection`)) {
			addition = msRhythmDuration / 1000
		}

		let sixteenthBar = 0
		let sixteenthBeat = 0
		let sixteenthBarPercentage = 0
		let beatPosition = 0
		const beatsPerBar = get(rendering, [`renderedArray`, 'length']) / noOfBars
		const beatLimit = isSixTwelveEight ? 6 : 4
		for (let k = 0; k < numberOfSixteenths; k++) {
			const t = rhythmStartTime + k * (crotchetTime / 4) + addition
			straightTimings.push({
				straight: true,
				bar: sixteenthBar,
				beat: sixteenthBeat,
				beatPosition,
				timeStamp: setPracticeTimeStamp(t),
				barPercentage: sixteenthBarPercentage,
			})
			beatPosition++
			sixteenthBarPercentage += (1 / (numberOfSixteenths / noOfBars)) * 100
			if (!isSixTwelveEight && shortBeatLength !== 0 && sixteenthBeat === noOfBeatsPerBar - 1 && beatPosition === shortBeatLength) {
				beatPosition = 0
				sixteenthBeat++
				sixteenthBar++
				sixteenthBeat = 0
				sixteenthBarPercentage = 0
				continue
			}
			if (beatPosition === beatLimit) {
				beatPosition = 0
				sixteenthBeat++
				if (sixteenthBeat === beatsPerBar) {
					sixteenthBar++
					sixteenthBarPercentage = 0
					sixteenthBeat = 0
				}
			}
		}
		const straightTapTimings = map(straightTimings, (st) => ({
			...st,
			timeStamp: setPracticeTimeStamp(get(st, `timeStamp`) + practiceTapAddition),
		}))
		const straightClickTimings = map(straightTimings, (st) => ({
			...st,
			timeStamp: setPracticeTimeStamp(get(st, `timeStamp`) + practiceClickAddition),
		}))

		if (isSixTwelveEight) {
			return {
				straightTimings,
				straightClickTimings,
				straightTapTimings,
				tripletTimings: [],
				tripletClickTimings: [],
				tripletTapTiming: [],
			}
		}

		if (shortBeatLength === 0) {
			const numberOfTriplets = msRhythmDuration / 1000 / (crotchetTime / 3)
			let tripletBar = 0
			let tripletBeat = 0
			let beatPosition = 0
			let tripletBarPercentage = 0
			for (let j = 0; j < numberOfTriplets; j++) {
				const t = rhythmStartTime + j * (crotchetTime / 3) + addition
				tripletTimings.push({
					straight: false,
					bar: tripletBar,
					beat: tripletBeat,
					beatPosition,
					timeStamp: setPracticeTimeStamp(t),
					barPercentage: tripletBarPercentage,
				})
				beatPosition++
				tripletBarPercentage += (1 / (numberOfTriplets / noOfBars)) * 100
				if (beatPosition === 3) {
					beatPosition = 0
					tripletBeat++
					if (tripletBeat === noOfBeatsPerBar) {
						tripletBar++
						tripletBeat = 0
						tripletBarPercentage = 0
					}
				}
			}
		} else {
			const tripletsPerBar = 3 * (noOfBeatsPerBar - 1)
			let tripletBar = 0
			let tripletBeat = 0
			let beatPosition = 0
			for (let x = 0; x < noOfBars; x++) {
				for (let y = 0; y < tripletsPerBar; y++) {
					const t = rhythmStartTime + y * (crotchetTime / 3) + addition
					tripletTimings.push({
						straight: false,
						bar: tripletBar,
						beat: tripletBeat,
						beatPosition,
						timeStamp: setPracticeTimeStamp(t),
						barPercentage: (y / tripletsPerBar) * 100,
					})
					beatPosition++
					if (beatPosition === 3) {
						beatPosition = 0
						tripletBeat++
					}
				}
				tripletBar++
				tripletBeat = 0
			}
		}
		const tripletTapTimings = map(tripletTimings, (tt) => ({
			...tt,
			timeStamp: setPracticeTimeStamp(get(tt, `timeStamp`) + practiceTapAddition),
		}))
		const tripletClickTimings = map(tripletTimings, (tt) => ({
			...tt,
			timeStamp: setPracticeTimeStamp(get(tt, `timeStamp`) + practiceClickAddition),
		}))

		return {
			straightTimings,
			straightClickTimings,
			straightTapTimings,
			tripletTimings,
			tripletClickTimings,
			tripletTapTimings,
		}
	}

	/**
	 * addGridTime updates the rhythm timings for all grid hits within the
	 * rhythm
	 * @param {Array} straightTimings a collection of straight timings to search through
	 * @param {Array} tripletTimings a collection of triplet timings to search through
	 * @param {Boolean} hit whether the note is a hit
	 * @param {Number} time the time of the grid note
	 * @returns
	 */
	const addGridTime = ({
		straightTimings = false,
		tripletTimings = false,
		straightClickTimings = false,
		straightTapTimings = false,
		tripletClickTimings = false,
		tripletTapTimings = false,
		hit = false,
		time = 0,
	}) => {
		if (!isReadingMode && get(refs, [`currentLoopRef`, `current`]) > 1) {
			return
		}
		if (!hit) {
			return
		}
		if (!straightTimings && !tripletTimings) {
			return errorOccurred(`RhythmBot.addGridTime, no straight or triplet timings provided`)
		}

		// Adjustments made for trade mode
		let addition
		if (!isTrade) {
			addition = 0
		} else if (get(playback, `tradeDirection`)) {
			addition = -(msRhythmDuration / 1000)
		} else {
			addition = msRhythmDuration / 1000
		}

		const t = setPracticeTimeStamp(time + addition)
		const straightIndex = findIndex(straightTimings, (timing) => get(timing, `timeStamp`, 0) === t)
		const tripletIndex = findIndex(tripletTimings, (timing) => get(timing, `timeStamp`, 0) === t)
		if (straightIndex !== -1) {
			straightTimings[straightIndex] = { ...straightTimings[straightIndex], hit: true }
			straightClickTimings[straightIndex] = { ...straightClickTimings[straightIndex], hit: true }
			straightTapTimings[straightIndex] = { ...straightTapTimings[straightIndex], hit: true }
		}
		if (tripletIndex !== -1) {
			tripletTimings[tripletIndex] = { ...tripletTimings[tripletIndex], hit: true }
			tripletClickTimings[tripletIndex] = { ...tripletClickTimings[tripletIndex], hit: true }
			tripletTapTimings[tripletIndex] = { ...tripletTapTimings[tripletIndex], hit: true }
		}
	}

	/**
	 * Add to `Practice Mode` history
	 */
	const addToRhythmHistory = () => {
		if (!isPracticeModeOn) {
			// Dont add anything if practice mode isn't on
			return
		}

		const practiceState = get(refs, [`practiceRef`, `current`])
		if (!hasInteractedWithLatestLoop(practiceState)) {
			// Dont add anything if the user hasn't interacted with this loop
			return
		}

		const practiceRhythm = get(practiceState, 'rhythm')
		const practiceUser = get(practiceState, 'user')

		let renderedArrayState = get(refs, [`practiceRenderedArrayRef`, `current`])
		let playThisState = get(refs, [`practicePlayThisRef`, `current`])
		let noOfBars = get(playback, 'bars')

		if (get(playback, 'isolated')) {
			noOfBars = get(refs, ['practiceIsolatedArrayRef', 'current', 0, 'length'], noOfBars)
		}

		if (isReadingMode) {
			playThisState = get(refs, [`readingPracticePlayThisRef`, `current`])
			renderedArrayState = get(refs, [`readingPracticeRenderedArrayRef`, `current`])
		}

		addRhythmHistory({
			practice: {
				rhythm: practiceRhythm,
				user: practiceUser,
			},
			appState: {
				imageKeys: renderedArrayState,
				noOfBars,
				beatsPerBar: get(renderedArrayState, 'length') / noOfBars,
				onesAndZeros: playThisState,
				subdivisionBarDuration,
				time: get(playback, 'playbackTime.options'),
				timeSignature: get(playback, 'timeSignature'),
				isSixTwelveEight,
				crotchetTime,
				barSettings: get(rendering, `barSettings`),
				shortBeatLength,
			},
		})
	}

	/**
	 * Resets the number of `user hits` with each loop
	 * @returns
	 */
	const setHitsInterval = ({ nextRhythmDue }) => {
		if (!isPracticeModeOn) {
			return
		}

		const tradeMultiplier = isTrade ? 2 : 1
		// useNextRhythmDue indicates whether to use a polling system or just
		// call the loop every `msRhythmDuration`
		const msRD = msRhythmDuration * tradeMultiplier
		let useNextRhythmDue = nextRhythmDue || false
		const msPollFrequency = useNextRhythmDue ? crotchetTime / 50 : msRD

		const reset = (practiceState) => {
			addToRhythmHistory()
			setRhythmStartTime({ startTime: get(practiceState, 'rhythm.start') + msRD / 1000 })
			setUserHits({ hits: [], misses: [] })
		}

		refs.hitsInterval.current = accurateInterval(() => {
			const practiceState = get(refs, [`practiceRef`, `current`])
			if (useNextRhythmDue) {
				if (get(audio, 'audioContext.currentTime') <= useNextRhythmDue - msPollFrequency / 1000) {
					return
				}
				reset(practiceState)
				useNextRhythmDue += msRD / 1000
				return
			}
			reset(practiceState)
		}, msPollFrequency)
	}

	/**
	 * Web audio API play function
	 * @returns if playing not possible
	 */
	const play = () => {
		if (!audioContextCheck()) {
			return errorOccurred('RhythmBot.play - Audio Context check failed')
		}

		const loopMode = ({ rhythmStartTime, countInDuration }) => {
			const rhythmDuration = playRhythm({ rhythmStartTime }) / 1000
			let nextRhythmDue = rhythmStartTime + rhythmDuration

			refs.countInTimeout.current = accurateInterval(() => {
				setHitsInterval({ nextRhythmDue })
				refs.countInTimeout.current.clear()
			}, countInDuration)

			const msPollFrequency = Math.max(200, crotchetTime / 10)
			refs.pollingInterval.current = accurateInterval(() => {
				// Ask every msPollFrequency whether the next
				// rhythm is due within the next msPollFrequency
				if (get(audio, 'audioContext.currentTime') <= nextRhythmDue - msPollFrequency / 1000) {
					return
				}
				playRhythm({ rhythmStartTime: nextRhythmDue })
				nextRhythmDue += rhythmDuration
			}, msPollFrequency)
		}

		try {
			const mode = get(playback, `mode`)
			const play = !isEmpty(get(rendering, `renderedArray`)) || [Modes.CLICK, Modes.CLICKREADING].includes(mode) || isRVPermutations

			if (play && audioContextCheck()) {
				if (isWakeLockAvailable) {
					turnScreenLockOn()
				}

				setPlaying(true)
				setMobileControlsDown({ down: true, manualReset: !get(rendering, `mobileControls.down.value`) })
				highlightBars({ on: true })

				const currentTime = get(audio, 'audioContext.currentTime')
				const countInDuration = playCountIn()
				const rhythmStartTime = currentTime + countInDuration / 1000

				if (isPracticeModeOn) {
					// `Practice Mode` reset
					setRhythmStartTime({ startTime: rhythmStartTime })
					clearRhythmHistory()
					setAccuracySummary(false)
					setAccuracy(0)
					setUserHits({ hits: [], misses: [] })
				}

				switch (mode) {
					case Modes.ONCE:
						refs.playOnceTimeout.current = accurateInterval(() => {
							stop({ timeout: true })
						}, playRhythm({ rhythmStartTime }) + countInDuration)
						break
					case Modes.LOOPREADING:
					case Modes.TRADEREADING:
					case Modes.CLICKREADING:
						if (isRVPermutations) {
							playRhythm({ rhythmStartTime })
							rvPermutationsReadingMode(rhythmStartTime)
							break
						}

						if (isEmpty(get(rendering, `renderedArray`)) && mode === Modes.CLICKREADING) {
							loopMode({ rhythmStartTime, countInDuration })
							break
						}

						const rhythmDuration = playRhythm({ rhythmStartTime })
						const nextRhythmDue = rhythmStartTime + rhythmDuration / 1000
						readingMode(rhythmStartTime)
						refs.countInTimeout.current = accurateInterval(() => {
							setHitsInterval({ nextRhythmDue })
							refs.countInTimeout.current.clear()
						}, countInDuration)
						break
					default:
						loopMode({ rhythmStartTime, countInDuration })
				}
			}
		} catch (error) {
			errorOccurred(`RhythmBot.play: ${error}`)
		}
	}

	/**
	 * Web audio API stop function
	 * @param {boolean} timeout whether stopped by a timeout
	 * @returns if stopping not possible
	 */
	const stop = async ({ timeout }) => {
		if (!audioContextCheck()) {
			return errorOccurred('RhythmBot.stop - Audio Context check failed')
		}
		try {
			set(refs, [`currentLoopRef`, `current`], 0)
			set(refs, [`originalBarsEnabledRef`, `current`], false)
			set(refs, [`nextClickOffsetRef`, `current`], 0)
			set(refs, [`gapClickRef`, `current`], false)

			refs.countInTimeout.current && refs.countInTimeout.current.clear()
			refs.playOnceTimeout.current && refs.playOnceTimeout.current.clear()

			if (!get(rendering, `mobileControls.down.manual`)) {
				setMobileControlsDown({ down: false })
			}
			highlightBars({ on: false })
			turnScreenLockOff()

			setPlaying(false)
			setGrooveLock(true)
			setGrooveOrder(false)
			setRhythmLock(false)

			if (isPracticeModeOn) {
				refs.practicePollingRef.current && refs.practicePollingRef.current.clear()

				refs.straightLockRef.current = false
				refs.tripletLockRef.current = false
				refs.latestHitBeatRef.current = 0

				const practiceState = get(refs, [`practiceRef`, `current`])
				addToRhythmHistory()
				rhythmTimings({ straight: false, triplets: false })
				if (hasInteractedWithPracticeState(practiceState)) {
					setAccuracySummary(true)
					if (isIonic()) {
						ionicToast(practiceSummary)
					}
				}
			}

			if (isRVPermutations) {
				rvHasLoopedRef.current = false
			}

			await audio.audioContext.close()
			setAudioContext(new audio.audioConstructor())

			if (!timeout) {
				//The function was triggered by button click not timeout
				switch (get(playback, `mode`)) {
					case Modes.LOOPREADING:
					case Modes.TRADEREADING:
					case Modes.CLICKREADING:
						const renderingMode = get(rendering, 'mode', RenderingModes.NORMAL)
						!playback.isolated
							? populatePlay({ arr: rendering.renderedArray, time: playback.playbackTime.options, sixEightOverride: isSixTwelveEight, renderingMode })
							: isolate({ on: true, selectedBars: playback.isolatedArray[0] })
						render({
							images: rendering.renderedArray,
							preset: false,
							playbackTime: playback.playbackTime.options,
							sixEightOverride: isSixTwelveEight,
							renderingMode,
						})
						break
					default:
						break
				}
				refs.hitsInterval.current && refs.hitsInterval.current.clear()
				refs.pollingInterval.current && refs.pollingInterval.current.clear()
				refs.readingInterval.current && refs.readingInterval.current.clear()
			}
		} catch (error) {
			errorOccurred(`RhythmBot.stop: ${error}`)
		}
	}

	/**
	 * Play a sound using the Web Audio API
	 * @param {ArrayBuffer} buffer the audio source
	 * @param {number} time the time at which to play
	 * @param {number} volume the volume at which to play
	 * @param {Boolean} muted if so the function will immediately return
	 * @returns
	 */
	const playSound = ({ buffer, time = 0, volume = false, muted = false }) => {
		if (muted) {
			return
		}
		if (isNaN(time)) {
			return
		}
		if (!audioContextCheck()) {
			return errorOccurred('RhythmBot.playSound Audio Context check failed')
		}
		if (audio.audioContext.state.includes('suspended')) {
			console.warn('RhythmBot.playSound: Resuming audioContext')
			audio.audioContext.resume().then(() => playSound({ buffer, time, volume }))
			return
		}
		let gainNode
		const source = audio.audioContext.createBufferSource()
		try {
			source.buffer = buffer
			if (volume) {
				gainNode = audio.audioContext.createGain()
				gainNode.gain.value = volume
				gainNode.connect(audio.audioContext.destination)
				source.connect(gainNode)
			} else {
				source.connect(audio.audioContext.destination)
			}
			source.start(time)
			return source
		} catch (error) {
			errorOccurred(`RhythmBot.playSound: ${error}`)
			setPlaying(false)
			if (get(playback, `mode`) === Modes.ONCE) {
				clearTimeout(refs.playOnceTimeout.current)
			} else {
				refs.hitsInterval.current && refs.hitsInterval.current.clear()
				refs.pollingInterval.current && refs.pollingInterval.current.clear()
				refs.readingInterval.current && refs.readingInterval.current.clear()
			}
		}
	}

	if (isEmpty(refs)) {
		errorOccurred("RhythmBot - `refs` can't be empty")
		return null
	}

	if (isIonic()) {
		return (
			<PracticePage
				stop={stop}
				play={play}
				practiceTap={practiceTap}
				shuffle={shuffle}
				generateRhythm={generateRhythm}
				turnOnGrooveMode={turnOnGrooveMode}
				changePreset={changePreset}
				changeTimeSignatureBottom={changeTimeSignatureBottom}
				changeTimeSignatureTop={changeTimeSignatureTop}
				practiceRefs={{
					pollingRef: refs.practicePollingRef,
					progressStatsRef: refs.practiceProgressStatsRef,
				}}
			/>
		)
	}

	/**
	 * @returns {JSX}
	 */
	const renderSettings = () => {
		if (!desktop(device)) {
			return <MobileSettings />
		}
		return <NavbarSettings />
	}

	/**
	 * @returns {JSX}
	 */
	const renderModals = () => {
		const grooveModal = get(rendering, 'showModals.groove')
		const loadModal = get(rendering, 'showModals.load')
		const feedbackModal = get(rendering, 'showModals.feedback')
		const practiceModal = get(rendering, 'showModals.practice')
		const helperModal = get(rendering, 'showModals.helper.show')

		switch (true) {
			case grooveModal:
				return <GrooveModal device={device} turnOnGrooveMode={turnOnGrooveMode} toggle={toggleGrooveModal} show={grooveModal}></GrooveModal>
			case loadModal:
				return <LoadModal device={device} toggle={toggleLoadModal} show={loadModal}></LoadModal>
			case feedbackModal:
				return <FeedbackModal device={device} toggle={toggleFeedbackModal} show={feedbackModal}></FeedbackModal>
			case helperModal:
				return <HelperModal device={device} toggle={toggleHelperModal} show={helperModal}></HelperModal>
			case practiceModal:
				return <PracticeModal playThisToImages={playThisToImages} device={device} toggle={togglePracticeModal} show={practiceModal}></PracticeModal>
			default:
				return
		}
	}

	return (
		<div className={`rhythmbot ${platformClass} ${themeName} ${device}`}>
			<Navbar />
			<div className={`main-content-wrapper ${themeName} ${device}`} {...MainSwipes}>
				{renderSettings()}
				<Filters
					loading={loading}
					saveRhythm={saveRhythm}
					device={device}
					stop={stop}
					toggleGrooveModal={toggleGrooveModal}
					turnOnGrooveMode={turnOnGrooveMode}
					playCountIn={playCountIn}
					generateRhythm={generateRhythm}
					updateBars={updateBars}
					playThisToImages={playThisToImages}
					changeTimeSignatureTop={changeTimeSignatureTop}
					changeTimeSignatureBottom={changeTimeSignatureBottom}
					resetTimeSignature={resetTimeSignature}
					settingsQueryParams={settingsQueryParams}
					shuffleAllPresetArray={shuffleAllPresetArray}
					changePreset={changePreset}
				/>
				<Canvas
					loading={loading}
					device={device}
					playSound={playSound}
					play={play}
					stop={stop}
					shuffle={shuffle}
					timeSignatureDifference={timeSignatureDifference}
					changeTimeSignatureTop={changeTimeSignatureTop}
					changeTimeSignatureBottom={changeTimeSignatureBottom}
					resetTimeSignature={resetTimeSignature}
					accuracySummary={accuracySummary}
					changePreset={changePreset}
				/>

				{renderModals()}
				<Error />
				<Analytics />
				<UpdatePrompt />
				<UpdateList />
				<Donations />
				{desktop(device) && <p className={`credits text light size-14 ${device}`}>{get(rendering, `text.credits`)}</p>}
			</div>
			<PracticeSurface practiceTap={practiceTap} />
		</div>
	)
}

const mapStateToProps = (state) => {
	const audio = getAudioState(state)
	const rendering = getRenderingState(state)
	const playback = getPlaybackState(state)
	const msRhythmDuration = getMsRhythmDuration(state)
	const msBarDuration = getMsBarDuration(state)
	const clickRate = getClickRate({})(state)
	const clickRateCountIn = getClickRate({ countIn: true })(state)
	const clickAccentPattern = getClickAccentPattern({})(state)
	const clickAccentPatternCountIn = getClickAccentPattern({ countIn: true })(state)
	const noOfBeatsToRender = getNoOfBeatsToRender(state)
	const noOfBeatsPerBar = getBeatsPerBar(state)
	const tripletTimeSignature = isTripletTimeSignature(state)
	const timeToUse = getTimeToUse(state)
	const swingClick = toSwingClick(state)
	const clickBoost = clickVolumeBoost(state)
	const isTrade = isTradeMode(state)
	const isOdd = getIsTimeSignatureOdd(state)
	const isStraightTime = getIsStraightTime(state)
	const isTripletTime = getIsTripletTime(state)
	const noOfBars = getNoOfBars(state)
	const eighthNoteTimeSignature = isEightNoteTime(state)
	const hasDefaultBackbeat = getTimeSignatureHasBackbeat(state)
	const isSwingable = swingable(state)
	const isSixTwelveEight = getIsSixTwelveEight(state)
	const is44 = getIsTimeSignature44(state)
	const subdivisionBarDuration = getSubdivisionBarDuration(state)
	const isApp = getIsApp(state)
	const themeName = getThemeName(state)
	const isPracticeModeOn = getIsPracticeModeOn(state)
	const isReadingMode = getIsReadingMode(state)
	const shortBeatLength = getShortBeatLength(state)
	const platformClass = getPlatformClass(state)
	const groove = getGrooveSettings(state)
	const isRVPermutations = getIsRhythmicVocabularyPermutations(state)
	const RVPermutationsStartingPoints = getRVPermutationsStartingPoints(state)
	const RVGroupingIndex = getRVPermutationsGroupingIndex(state)

	return {
		audio,
		rendering,
		playback,
		msRhythmDuration,
		clickRate,
		clickRateCountIn,
		clickAccentPattern,
		clickAccentPatternCountIn,
		noOfBeatsPerBar,
		noOfBeatsToRender,
		msBarDuration,
		tripletTimeSignature,
		timeToUse,
		swingClick,
		clickBoost,
		isTrade,
		isOdd,
		isStraightTime,
		isTripletTime,
		noOfBars,
		eighthNoteTimeSignature,
		hasDefaultBackbeat,
		isSwingable,
		isSixTwelveEight,
		is44,
		subdivisionBarDuration,
		isApp,
		themeName,
		isPracticeModeOn,
		isReadingMode,
		shortBeatLength,
		platformClass,
		groove,
		isRVPermutations,
		RVPermutationsStartingPoints,
		RVGroupingIndex,
	}
}

export default connect(mapStateToProps, {
	setBars,
	setTime,
	setTimeName,
	setPlaybackTime,
	setRandom,
	setRenderedArray,
	setPlayThis,
	setAnalyticsPrompt,
	setBpm,
	setMode,
	setPlaying,
	setUseMetronome,
	setUseCountIn,
	setUseGhosts,
	setPlaybackAs,
	setShowGrooveModal,
	setGrooveCymbalPattern,
	setGrooveCymbalSound,
	setGrooveGhosts,
	setGrooveBackbeat,
	setGrooveMix,
	setSwing,
	setIsolated,
	setHighlighted,
	setBarSettings,
	setSaveable,
	setLoadable,
	setShowLoadModal,
	setShowFeedbackModal,
	setDonationPrompt,
	setAudioContext,
	setShowFilters,
	setShowSettings,
	setFeaturesList,
	setRhythmLock,
	setGrooveLock,
	setTimeSignature,
	setTimeSignatureTop,
	setTimeSignatureBottom,
	setMobileControlsDown,
	setRhythmStartTime,
	setAccuracy,
	setRhythmTimings,
	setUserHits,
	userHit,
	userMiss,
	setCustom,
	setCustomArray,
	setRenderingMode,
	setApplicationText,
	setSpace,
	resetPreset,
	setPresetConstants,
	setPresetShuffleAll,
	setPreset,
	setExactClickRate,
	setExactClickOffset,
	setExactClickGap,
	setClickModalHasOpened,
	setShowHelperModal,
	addRhythmHistory,
	clearRhythmHistory,
	togglePracticeModal,
	rhythmicVocabPermutationsSetStartPoints,
})(RhythmBot)
