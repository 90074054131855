import React, { useEffect, useState } from 'react'
import get from 'lodash/get'
import map from 'lodash/map'
import { connect } from 'react-redux'

import { getPlaybackState } from '../redux/selectors/playback'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'

import { Sounds } from '../utils/constants/enum/sounds'
import Modes from '../utils/constants/enum/mode'

const Toggle = (props) => {
	const [radioValue, setRadioValue] = useState(props.defaultVal)

	useEffect(() => {
		setRadioValue(get(props, 'defaultVal'))
	}, [props.grooveModal, props.defaultVal])

	/**
	 * React to changes to the toggle button - a toggle buttons value can be
	 * either a boolean or integer which is reflected in the code
	 * @param {*} value
	 */
	const changeDetected = (value) => {
		if (get(props, 'disabled', false)) {
			return
		}
		const boolean = get(props, 'boolean')
		let change = get(value, 'value')
		if (!boolean) {
			change = parseInt(change)
		} else {
			change = change === 'true'
		}
		setRadioValue(change)
		props.callback(change)
	}

	/**
	 * Detect additional clicks to an already selected radio button - used for
	 * handling double clicks
	 * @param {*} value
	 */
	const clickDetected = (value) => {
		if (get(props, 'disabled', false)) {
			return
		}
		const intValue = parseInt(value.value)
		if (!get(props, `doubleClickCallback`)) {
			return
		}

		let doubleClickEnabled = false
		switch (intValue) {
			case Sounds.GROOVE:
			case Modes.CLICK:
			case Modes.CLICKREADING:
				doubleClickEnabled = true
				break
			default:
				break
		}
		if (!doubleClickEnabled) {
			return
		}

		switch (radioValue === intValue) {
			case false:
				break
			default:
				props.doubleClickCallback()
				break
		}
	}

	/**
	 * Determine whether a radio button is checked, returns differently based on
	 * the different possible types of radio buttons
	 * @param {Object} radio
	 * @returns
	 */
	const isChecked = (radio) => {
		const custom = get(props, 'custom')
		const time = get(props, 'time')
		const mode = get(props, 'mode')
		const boolean = get(props, 'boolean')
		const value = get(radio, 'value')
		if (custom) {
			return false
		}
		if (time) {
			return radioValue * 4 === parseInt(value) || radioValue === parseInt(value)
		}
		if (mode) {
			return radioValue - 2 === parseInt(value) || (radioValue > 3 && radioValue - 1 === parseInt(value)) || radioValue === parseInt(value)
		}
		if (boolean) {
			return radioValue === value
		}
		return radioValue === parseInt(value)
	}

	/**
	 * Returns JSX for a toggle button
	 * @param {object} radio
	 * @param {number} idx
	 * @returns
	 */
	const renderToggleButton = (radio, idx) => (
		<ToggleButton
			key={idx}
			type="radio"
			variant="info"
			name="radio"
			className={props.playback.playing && !props.playEnabled ? 'action-3 playing' : 'action-3'}
			value={radio.value}
			checked={isChecked(radio)}
			data-toggle="tooltip"
			data-placement="top"
			title={radio.tooltip}
			onChange={(e) => changeDetected(e.currentTarget)}
			onMouseDown={(e) => clickDetected(e.currentTarget.children[0])}
		>
			{get(radio, 'name')}
		</ToggleButton>
	)

	return (
		<>
			<ButtonGroup className={props.width} toggle>
				{map(get(props, 'radios'), renderToggleButton)}
			</ButtonGroup>
		</>
	)
}

const mapStateToProps = (state) => {
	const playback = getPlaybackState(state)
	return { playback: playback }
}

export default connect(mapStateToProps, {})(Toggle)
