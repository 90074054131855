import React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'

import Button from 'react-bootstrap/Button'

import { setUpdatePrompt } from '../../redux/actions'
import { getRenderingState, getThemeName } from '../../redux/selectors/rendering'

const UpdatePrompt = ({ rendering, themeName, setUpdatePrompt }) => {
	const render = get(rendering, 'updatePrompt')
	if (!render) {
		return null
	}
	return (
		<div className={'banner update flex ' + themeName}>
			<div className={'message ' + themeName}>
				<p className="text bold size-20">{`New Version Available`}</p>
				<p className="text medium size-16">{`Refresh to update RhythmBot`}</p>
			</div>
			<div className="actions">
				<Button
					onClick={() => {
						setUpdatePrompt(false)
					}}
					className="dismiss action-2"
				>
					{`Dismiss`}
				</Button>
				<Button onClick={() => window.location.reload()} className="refresh action">
					{`Refresh`}
				</Button>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const themeName = getThemeName(state)

	return {
		rendering,
		themeName,
	}
}

export default connect(mapStateToProps, { setUpdatePrompt })(UpdatePrompt)
