// U+E084

const timeSignatureFontMap = {
	1: "\uE081",
	2: "\uE082",
	3: "\uE083",
	4: "\uE084",
	5: "\uE085",
	6: "\uE086",
	7: "\uE087",
	8: "\uE088",
	9: "\uE089",
	10: "\uE081\uE080",
	11: "\uE081\uE081",
	12: "\uE081\uE082",
	13: "\uE081\uE083",
	14: "\uE081\uE084",
	15: "\uE081\uE085",
	16: "\uE081\uE086",
	17: "\uE081\uE087",
	18: "\uE081\uE088",
	19: "\uE081\uE089",
	20: "\uE082\uE080",
	21: "\uE082\uE081",
	22: "\uE082\uE082",
	23: "\uE082\uE083",
	24: "\uE082\uE084",
	25: "\uE082\uE085",
	26: "\uE082\uE086",
	27: "\uE082\uE087",
	28: "\uE082\uE088",
};

export default timeSignatureFontMap;
