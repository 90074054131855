import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import get from 'lodash/get'
import isNull from 'lodash/isNull'
import isNil from 'lodash/isNil'
import set from 'lodash/set'
import map from 'lodash/map'
import startCase from 'lodash/startCase'

import { useLongPress } from 'react-use'
import { useLongPress as longPressCopy } from 'use-long-press'

import {
	getPlaybackState,
	isTripletTimeSignature,
	getIsTimeSignature44,
	isTimeSignatureUpdateAllowed,
	getIsStraightTime,
	swingableGroove,
	getIsPracticeModeOn,
} from '../../redux/selectors/playback'
import { getAudioState } from '../../redux/selectors/audio'
import {
	getRenderingState,
	getIsApp,
	getIsDarkTheme,
	getThemeName,
	getSeenMobileTimeSignature,
	getIsRhythmicVocabularyPermutations,
} from '../../redux/selectors/rendering'
import {
	setModalHHArray,
	setShowSwing,
	setPlaybackAs,
	setRandom,
	setUseCountIn,
	setUseGhosts,
	setUseMetronome,
	setTime,
	setCustom,
	setMode,
	setRhythmName,
	setBpm,
	setLevelsArray,
	setAudioContext,
	setShowLoadModal,
	setSaveable,
	setShowSoundbankModal,
	setSwing,
	toggleTradeDirection,
	setSpace,
	setTimeSignatureTop,
	setTimeSignatureBottom,
	setTimeSignature,
	setPlaybackTime,
	setMobileTimeSignatureControls,
	setMobileControlsDown,
	setVolume,
	setPro,
	setCustomArray,
	setRenderingMode,
	setShowClickModal,
	setPresetConstants,
	setMobileTimeSignatureSeen,
} from '../../redux/actions'

import RBToggleButton from '../ToggleButton.js'
import CustomModal from '../Modals/CustomModal.js'
import ClickModal from '../Modals/ClickModal'
import Button from 'react-bootstrap/Button'
import Switch from '@mui/material/Switch'
import Slider from '@mui/material/Slider'
import ReactTooltip from 'react-tooltip'

import { spaceSliderMarks } from '../../utils/constants/sliders'
import { StraightPresets, TripletPresets } from '../../utils/constants/presets'
import { copyShareableURL } from '../../utils/functions'
import TimeEnum from '../../utils/constants/enum/time'
import { PlatformEnum } from '../../utils/constants/enum/platform'
import RenderingModes from '../../utils/constants/enum/renderingMode'
import ModesEnum from '../../utils/constants/enum/mode'
import { Sounds } from '../../utils/constants/enum/sounds'
import { renderBpmTooltip, renderClickTooltip } from '../../utils/tooltips'
import {
	Time,
	Time1,
	TimeLockEighth,
	TimeLockSixteenth,
	TimeLockTriplets,
	Modes,
	ModesLoop,
	ModesTrade,
	ModesClick,
	Levels,
	FirstGrooveLevels,
	PlaybackAs,
	GrooveLockPlaybackAs,
	TripletSDLevels,
} from '../../utils/constants/radio-buttons'
import timeSignatureMap from '../../utils/constants/time-signature-font-map'
import { arrayAverage, desktop } from '../../utils/functions'

import useKeyboard from '../../hooks/useKeyboard'
import useClearRhythm from '../../hooks/useClearRhythm'
import useComplexityLevel from '../../hooks/useComplexityLevel'
import useSwipeable from '../../hooks/Swipeable/useSwipeable'
import useBowser from '../../hooks/useBowser'

import arrow from '../../images/arrow.png'
import cog from '../../images/cog.png'
import swipeUp from '../../images/swipe-up.png'

const Filters = (props) => {
	const { isWebkit, isTablet, isDesktop, isSafari } = useBowser()

	const { clearRhythm } = useClearRhythm()
	const { updateLevel } = useComplexityLevel()
	const { MobileControlSwipes } = useSwipeable()

	// References to the bar buttons - the keyboard events need to trigger
	// clicks directly on these elements, weird bug
	const decreaseBarsRef = useRef(null)
	const increaseBarsRef = useRef(null)

	// Ref to metronome text for tooltip access
	const clickModeRef = useRef(null)
	const [seenClickTooltipMode, setSeenClickTooltipMode] = useState(0)
	const clickRef = useRef(null)
	const [seenClickTooltip, setSeenClickTooltip] = useState(false)

	// Ref to filters-container and hidden div, enables autoscrolling
	const [checks, setChecks] = useState(0)
	const filtersContainerRef = useRef(null)
	const filtersEndRef = useRef(null)

	//Conditional rendering constants
	const [randomRhythmBlocker, setRandomRhythmBlocker] = useState(false)
	const [renderPresets, setRenderPresets] = useState(true)
	const [renderComplexity, setRenderComplexity] = useState(true)
	const [renderLevels, setRenderLevels] = useState(true)
	const [showControls, setShowControls] = useState(false)
	const [showLevels, setShowLevels] = useState(false)
	const [showPresets, setShowPresets] = useState(false)
	const [showSaved] = useState(false)
	const [showDev, setShowDev] = useState(false)
	const [renderActionButtons, setRenderActionButtons] = useState(true)

	//Tap Tempo settings
	const [times, setTimes] = useState([])
	const [tapTime, setTapTime] = useState(false)
	const [mobileSelector, setMobileSelector] = useState(0)
	const mobileTapTimeout = useRef(null)
	const tapTempoTimeout = useRef(null)

	//Custom mode Modal
	const [showCustomModal, setShowCustomModal] = useState(false)

	// BPM
	const [bpmIncrement, setBpmIncrement] = useState(5)
	const [seenBpmTooltip, setSeenBpmTooltip] = useState(0)
	const bpmInterval = useRef(null)

	const accurateInterval = require('accurate-interval')

	/**
	 * Respond to a long press of the BPM selector
	 * This lowers the increment to 1
	 * @returns
	 */
	const bpmLongPress = ({ increment = true }) => {
		if (!isDesktop) {
			return
		}
		if (bpmIncrement > 1) {
			const additon = increment ? 1 : -1
			updateBpm({ override: get(props, 'playback.bpm') + additon })
			return setBpmIncrement(1)
		}
	}

	/**
	 * Clear the BPM interval
	 */
	const clearBpmInterval = () => bpmInterval.current && bpmInterval.current.clear()

	const bpmLongPressEventUp = useLongPress(() => bpmLongPress({ increment: true }), { isPreventDefault: false, delay: 1000 })
	const bpmLongPressEventDown = useLongPress(() => bpmLongPress({ increment: false }), { isPreventDefault: false, delay: 1000 })

	/**
	 * Scroll to the bottom of filters-container. Uses a reference to a hidden
	 * div at the bottom
	 */
	const scrollToBottom = () => filtersEndRef.current.scrollIntoView({ behavior: 'smooth' })

	// Keyboard controls
	// N = New rhythm
	useKeyboard({
		keyCodes: [78],
		props,
		callback: async () => {
			if (!props.playback.playing && !props.loading && !props.isRVPermutations) {
				props.generateRhythm({})
			}
		},
	})
	// C = Clear Rhythm
	useKeyboard({
		keyCodes: [67],
		props,
		callback: () => {
			if (props.rendering.renderedArray.length > 0 && !props.playback.playing && !props.loading && !props.isRVPermutations) {
				clearRhythm()
			}
		},
	})
	// B = Tap Tempo
	useKeyboard({
		keyCodes: [66],
		props: { loading: props.loading, setTapTime: setTapTime },
		callback: () => {
			if (!props.loading) {
				tapTempo()
			}
		},
	})
	// + = add bar
	useKeyboard({
		keyCodes: [187],
		props,
		callback: () => {
			if (!props.loading) {
				increaseBarsRef.current.click()
			}
		},
	})
	// - = remove bar
	useKeyboard({
		keyCodes: [189],
		props,
		callback: () => {
			if (!props.loading) {
				decreaseBarsRef.current.click()
			}
		},
	})

	useEffect(() => {
		const savedMobileTimeSignatureSeen = localStorage.getItem('mobileTimeSignatureSeen') || false
		try {
			props.setMobileTimeSignatureSeen(JSON.parse(savedMobileTimeSignatureSeen))
		} catch {
			props.setMobileTimeSignatureSeen(false)
		}
	}, [])

	// When rendering changes, check if content has overflown in
	// filters-container. Scroll to the bottom if it has
	useEffect(() => {
		const overflown = filtersContainerRef.current.offsetHeight < filtersContainerRef.current.scrollHeight
		if (overflown && checks > 0) {
			// Only scroll to the bottom if this isn't the first time the page
			// has loaded
			scrollToBottom()
		}
		setChecks(checks + 1)
	}, [showControls, showLevels, showPresets, showDev])

	useEffect(() => {
		const options = get(props, 'playback.time.options')
		switch (options) {
			case TimeEnum.STRAIGHT:
				props.setPresetConstants({ constants: StraightPresets() })
				setRenderPresets(true)
				setRenderLevels(true)
				// TODO what was this doing - do I need it?
				// updateLevel({ level: get(props, 'playback.level'), time: 16 })
				break
			case TimeEnum.EIGHTH:
				setRenderLevels(true)
				setRenderPresets(false)
				break
			case TimeEnum.TRIPLETS:
				setRenderPresets(true)
				props.setPresetConstants({ constants: TripletPresets })
				setRenderLevels(true)
				break
			default:
				setRenderPresets(false)
				setRenderLevels(true)
				break
		}
		const rMode = get(props, 'rendering.mode')
		switch (rMode) {
			case RenderingModes.DOWNUP.STRAIGHT:
			case RenderingModes.DOWNUP.TRIPLETS:
				setRenderPresets(false)
				break
			case RenderingModes.SD.TRIPLETS.ONE:
			case RenderingModes.SD.TRIPLETS.TWO:
			case RenderingModes.SD.TRIPLETS.THREE:
			case RenderingModes.SD.TRIPLETS.FOUR:
			case RenderingModes.SD.TRIPLETS.FIVE:
				setRenderPresets(false)
				setRenderLevels(true)
				break
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
				setRenderPresets(false)
				setRenderLevels(true)
				break
			default:
		}
	}, [props.playback.time.options, props.playback.timeSignature])

	useEffect(() => {
		// First Grooves
		const rMode = get(props, 'rendering.mode')
		switch (rMode) {
			case RenderingModes.DOWNUP.STRAIGHT:
			case RenderingModes.DOWNUP.TRIPLETS:
				setRenderPresets(false)
				setRenderComplexity(false)
				break
			case RenderingModes.SD.TRIPLETS.ONE:
			case RenderingModes.SD.TRIPLETS.TWO:
			case RenderingModes.SD.TRIPLETS.THREE:
			case RenderingModes.SD.TRIPLETS.FOUR:
			case RenderingModes.SD.TRIPLETS.FIVE:
				setRenderPresets(false)
				setRenderComplexity(true)
				break
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
				setRenderPresets(false)
				setRenderComplexity(true)
				break
			case RenderingModes.RHYTHMIC_VOCABULARY_PERMUTATIONS.STRAIGHT:
				setRenderActionButtons(false)
				setRenderComplexity(false)
				setRenderPresets(false)
				setRenderLevels(false)
				break
			default:
		}
	}, [props.rendering.mode])

	/**
	 * Auto show then hide the click tooltip once
	 */
	useEffect(() => {
		if (seenClickTooltip || !showControls) {
			return
		}
		const cR = get(clickRef, 'current')
		if (isNull(cR)) {
			return
		}
		setSeenClickTooltip(true)
		setTimeout(() => {
			ReactTooltip.show(cR)
			setTimeout(() => {
				ReactTooltip.hide(cR)
			}, 2000)
		}, 150)
	}, [clickRef, showControls])

	/**
	 * Auto show then hide the click tooltip once
	 */
	useEffect(() => {
		const mode = get(props, `playback.mode`)
		switch (mode) {
			case ModesEnum.CLICK:
			case ModesEnum.CLICKREADING:
				break
			default:
				return
		}
		if (seenClickTooltipMode > 0) {
			return
		}
		const cR = get(clickModeRef, 'current')
		if (isNull(cR)) {
			return
		}
		setTimeout(() => {
			ReactTooltip.show(cR)
			setTimeout(() => {
				ReactTooltip.hide(cR)
			}, 2500)
		}, 150)
	}, [props.playback.mode])

	/**
	 * Copy a link to retain the current settings
	 * Behaves differently if in normal mode or a custom mode (downup, s-d etc)
	 */
	const copySettingsLink = () => {
		copyShareableURL({ isApp: get(props, `isApp`, false), queryParams: props.settingsQueryParams({}) })
		setRandomRhythmBlocker(true)
		setTimeout(() => {
			setRandomRhythmBlocker(false)
		}, 2000)
	}

	const copyEvent = longPressCopy(
		() => {
			copySettingsLink()
		},
		{
			threshold: 1000,
			onFinish: () => {
				if (!isWebkit) {
					return
				}
				copySettingsLink()
			},
		}
	)

	/**
	 * Call the parent setSpace function
	 * @param {number} value new value for space
	 */
	const spaceSliderChange = (_, value) => props.setSpace(value)

	/**
	 * Call the parent setMode function
	 * @param {number} mode
	 */
	const updateMode = (mode) => {
		if (props.playback.bars === 1 && [ModesEnum.CLICKREADING, ModesEnum.LOOPREADING, ModesEnum.TRADEREADING].includes(mode)) {
			props.updateBars(true)
		}
		props.setMode(mode)
	}

	/**
	 * Update the tempo - handling thresholds and rounding to the nearest 5
	 * @param {boolean} increment true if incrementing false if decrementing
	 * @param {Number} override set the BPM directly from this value
	 */
	const updateBpm = ({ increment = true, override = false }) => {
		const playing = get(props, 'playback.playing')
		if (playing) {
			return
		}
		const bpm = get(props, 'playback.bpm')
		const useGhosts = get(props, 'playback.useGhosts')
		const upperLimit = 280
		const lowerLimit = 10

		if (override) {
			if (override > upperLimit) {
				return props.setBpm(upperLimit)
			}
			if (override < lowerLimit) {
				return props.setBpm(lowerLimit)
			}
			return props.setBpm(override)
		}

		if (increment && bpm < upperLimit) {
			if (bpm + bpmIncrement > 140 && useGhosts) {
				//Auto disable ghosts above this tempo
				props.setUseGhosts(false)
			}
			return props.setBpm(bpm > upperLimit - bpmIncrement ? upperLimit : Math.round((bpm + bpmIncrement) / bpmIncrement) * bpmIncrement)
		}
		if (!increment && bpm > lowerLimit) {
			return props.setBpm(bpm < lowerLimit + bpmIncrement ? lowerLimit : Math.round((bpm - bpmIncrement) / bpmIncrement) * bpmIncrement)
		}
	}

	/**
	 * Update the swing value
	 * @param {boolean} increment true if incrementing false if decrementing
	 */
	const updateSwing = (increment) => {
		const playing = get(props, 'playback.playing')
		if (playing) {
			return
		}
		const interval = 5
		const swing = get(props, 'playback.swing')
		let swingValue
		switch (increment) {
			case true:
				swingValue = swing > 95 ? 100 : Math.round((swing + interval) / interval) * interval
				break
			default:
				swingValue = swing < 5 ? 0 : Math.round((swing - interval) / interval) * interval
				break
		}
		return props.setSwing(swingValue)
	}

	/**
	 * Update the current time (subdivision) - handle rhythm & time signature
	 * changes if required
	 * @param {number} selected the new time selected
	 */
	const updateTime = (selected) => {
		const playing = get(props, 'playback.playing')
		const playbackTime = get(props, 'playback.playbackTime.options')
		const playbackAs = get(props, 'playback.playbackAs')
		if (playbackAs === Sounds.GROOVE) {
			// Clear rhythm if rendered rhythm won't be compatible with groove playback in new time
			if (playbackTime !== selected) {
				if (!(playbackTime / selected === 4) && !(selected / playbackTime === 4)) {
					clearRhythm()
					props.setPlaybackTime(selected)
					switch (selected) {
						case TimeEnum.TRIPLETS:
						case TimeEnum.MIXED:
							// Reset if the current time signature isn't compatible
							!props.tripletTimeSignature && props.setTimeSignature('4:4')
							break
						default:
							break
					}
					playing && props.stop({ timeout: false })
				}
				props.turnOnGrooveMode({ random: false, time: selected })
			}
		}
		props.setTime(selected)
		props.setCustomArray([])
		switch (selected) {
			//Each time change - decide what to render, set the time name and update the level
			case TimeEnum.STRAIGHT:
				updateLevel({ level: props.playback.level, time: selected, clearCustom: true })
				break
			case TimeEnum.EIGHTH:
				props.setLevelsArray(false)
				props.setCustom(false)
				break
			case TimeEnum.TRIPLETS:
				updateLevel({ level: props.playback.level, time: selected, clearCustom: true })
				break
			default:
				updateLevel({ level: props.playback.level, time: selected, clearCustom: true })
				break
		}
	}

	const updatePlayback = (selected) => {
		props.setPlaybackAs(selected)
		if (selected === Sounds.GROOVE) {
			props.turnOnGrooveMode({ random: false, time: get(props, 'playback.time.options'), fromClick: true })
		}
	}

	const tapTempo = () => {
		clearTimeout(tapTempoTimeout.current)
		tapTempoTimeout.current = setTimeout(() => {
			setTimes([])
			setTapTime(false)
		}, 1700)
		if (!tapTime) {
			return setTapTime(new Date())
		}

		let endTime = new Date()
		let tempo = endTime - tapTime
		times.push(tempo)
		let average = arrayAverage(times)
		let bpm = 60000 / average
		if (bpm < 281 && bpm > 29) {
			if (!desktop(props.device)) {
				mobileSelector !== 1 && setMobileSelector(1)
				clearTimeout(mobileTapTimeout.current)
				mobileTapTimeout.current = setTimeout(() => {
					setMobileSelector(0)
				}, 1700)
			}
			props.setBpm(Math.floor(bpm))
		} else {
			setTimes([])
			setTapTime(false)
		}
		setTapTime(endTime)
	}

	//Complexity levels modal functions
	const toggleCustomModal = () => setShowCustomModal(!showCustomModal)

	/**
	 * Render hint text on mobile
	 * @returns JSX
	 */
	const mobileHintText = () => {
		if (get(props, 'isPracticeModeOn', false) && get(props, 'playback.playing')) {
			return (
				<div className={`control-group`}>
					<div className="text light size-12">{`Tap to play along`}</div>
				</div>
			)
		}
		return (
			<div className={`control-group swipe-up-hint`}>
				<img
					alt={`up-arrow`}
					className={`img`}
					src={swipeUp}
					onClick={() => {
						if (!get(props, `rendering.mobileControls.down.value`)) {
							return
						}
						props.setMobileControlsDown({ down: false, manual: true })
					}}
				></img>
				{!props.seenMobileTimeSignature && <div className="text light size-12">Swipe up for time signatures</div>}
			</div>
		)
	}

	/**
	 * Time signature controls for mobile and tablet
	 * @returns JSX
	 */
	const mobileTimeSignatureControls = () => {
		const playing = get(props, 'playback.playing')
		const platform = get(props, 'rendering.platform')
		const straightTime = get(props, 'straightTime')
		const timeSignatureUpdateAllowed = get(props, 'timeSignatureUpdateAllowed')
		const bottomDisabled = !timeSignatureUpdateAllowed || !straightTime
		const topDisabled = !timeSignatureUpdateAllowed

		let inputSettings = {}
		if (isSafari || platform === PlatformEnum.IOS) {
			// Safari sucks, I don't want to do this
			set(inputSettings, 'type', 'number')
			set(inputSettings, 'value.top', props.playback.timeSignature.top)
			set(inputSettings, 'value.bottom', props.playback.timeSignature.bottom)
			set(inputSettings, 'additionalClassName', 'safari')
		} else {
			set(inputSettings, 'type', 'text')
			set(inputSettings, 'value.top', timeSignatureMap[props.playback.timeSignature.top])
			set(inputSettings, 'value.bottom', timeSignatureMap[props.playback.timeSignature.bottom])
			set(inputSettings, 'additionalClassName', '')
		}
		if (playing) {
			set(inputSettings, 'additionalClassName', `borderless ${get(inputSettings, 'additionalClassName')}`)
		}
		return (
			<>
				<div className="control-group time-signature">
					<div className="text bold size-18">{`Time Signature`}</div>
					<div className="input-group large top">
						<input
							onClick={(e) => {
								e.stopPropagation()
								get(props, 'changeTimeSignatureTop')({ up: false })
							}}
							type="button"
							value="-"
							className="button-minus action-2"
							data-field="quantity"
							disabled={topDisabled}
						/>
						<input
							type={get(inputSettings, 'type')}
							step="1"
							max=""
							onChange={() => {}}
							value={get(inputSettings, 'value.top')}
							name="bars"
							className={`${get(inputSettings, 'additionalClassName')} quantity-field time-signature-input`}
						/>
						<input
							onClick={(e) => {
								e.stopPropagation()
								get(props, 'changeTimeSignatureTop')({ up: true })
							}}
							type="button"
							value="+"
							className="button-plus action-2"
							data-field="quantity"
							disabled={topDisabled}
						/>
					</div>
					<div className="input-group large bottom">
						<input
							onClick={(e) => {
								e.stopPropagation()
								get(props, 'changeTimeSignatureBottom')({ up: false })
							}}
							type="button"
							value="-"
							className="button-minus action-2"
							data-field="quantity"
							disabled={bottomDisabled}
						/>
						<input
							contentEditable={false}
							type={get(inputSettings, 'type')}
							step="1"
							max=""
							onChange={() => {}}
							value={get(inputSettings, 'value.bottom')}
							name="bars"
							className={`${get(inputSettings, 'additionalClassName')} quantity-field time-signature-input`}
						/>
						<input
							onClick={(e) => {
								e.stopPropagation()
								get(props, 'changeTimeSignatureBottom')({ up: true })
							}}
							type="button"
							value="+"
							className="button-plus action-2"
							data-field="quantity"
							disabled={bottomDisabled}
						/>
					</div>
				</div>
				<div className="divider wide narrow"></div>
				{isTablet && (
					<div className="control-group full">
						<Button
							disabled={get(props, 'is44')}
							onClick={(e) => {
								e.stopPropagation()
								get(props, 'resetTimeSignature')()
							}}
							className="clear action-3 secondary"
						>
							Reset
						</Button>
					</div>
				)}
			</>
		)
	}

	/**
	 * Bar and rhythm controls for mobile
	 * @returns JSX
	 */
	const mobileBarControls = () => {
		return (
			<>
				<div className={`control-group bars no-padding`}>
					<div className="text bold size-18">{mobileSelector === 0 ? get(props, `rendering.text.filters.bars`, `Bars`) : 'Bpm'}</div>
					<div className="input-group large">
						<input
							disabled={mobileSelector === 1}
							onClick={(e) => {
								e.stopPropagation()
								props.updateBars(false)
							}}
							type="button"
							value="-"
							className="button-minus action-2"
							data-field="quantity"
						/>
						<input
							type="number"
							step="1"
							max=""
							onChange={() => {}}
							value={mobileSelector === 0 ? props.playback.bars : props.playback.bpm}
							name="bars"
							className={mobileSelector === 0 ? 'quantity-field bars' : 'quantity-field borderless'}
						/>
						<input
							disabled={mobileSelector === 1}
							onClick={(e) => {
								e.stopPropagation()
								props.updateBars(true)
							}}
							type="button"
							value="+"
							className="button-plus action-2"
							data-field="quantity"
						/>
					</div>
				</div>
				<div className="control-group spread">
					{props.rendering.renderedArray.length > 0 && !props.playback.playing ? (
						<Button
							onClick={(e) => {
								e.stopPropagation()
								clearRhythm()
							}}
							className="clear action-2 secondary"
						>
							{get(props, `rendering.text.filters.clear`, 'Clear')}
						</Button>
					) : (
						<Button className="clear action-2 secondary" disabled>
							{get(props, `rendering.text.filters.clear`, 'Clear')}
						</Button>
					)}

					<Button
						{...copyEvent}
						onClick={async (e) => {
							if (randomRhythmBlocker) {
								return
							}
							e.stopPropagation()
							props.generateRhythm({})
						}}
						className={`generate action${randomRhythmBlocker ? `-2` : ``}`}
						disabled={get(props, 'playback.playing')}
					>
						{rrButtonText()}
					</Button>
				</div>
			</>
		)
	}

	/**
	 * Subdivision toggle buttons
	 * @returns JSX
	 */
	const subdivisionButtons = () => {
		const rMode = get(props, 'rendering.mode')

		switch (rMode) {
			case RenderingModes.DOWNUP.STRAIGHT:
			case RenderingModes.RHYTHMIC_VOCABULARY_PERMUTATIONS.STRAIGHT:
				return (
					<div className="wide bottom">
						<RBToggleButton disabled={true} time={true} defaultVal={get(props, 'playback.time.options')} radios={TimeLockSixteenth()}></RBToggleButton>
					</div>
				)
			case RenderingModes.DOWNUP.TRIPLETS:
			case RenderingModes.SD.TRIPLETS.ONE:
			case RenderingModes.SD.TRIPLETS.TWO:
			case RenderingModes.SD.TRIPLETS.THREE:
			case RenderingModes.SD.TRIPLETS.FOUR:
			case RenderingModes.SD.TRIPLETS.FIVE:
				return (
					<div className="wide bottom">
						<RBToggleButton disabled={true} time={true} defaultVal={get(props, 'playback.time.options')} radios={TimeLockTriplets()}></RBToggleButton>
					</div>
				)
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
				return (
					<div className="wide bottom">
						<RBToggleButton time={true} defaultVal={get(props, 'playback.time.options')} radios={TimeLockEighth()}></RBToggleButton>
					</div>
				)
			default:
				break
		}
		if ([TimeEnum.STRAIGHT, TimeEnum.EIGHTH].includes(get(props, 'playback.time.options'))) {
			return (
				<>
					<div className="wide bottom">
						<RBToggleButton time={true} defaultVal={get(props, 'playback.time.options')} radios={Time()} callback={updateTime}></RBToggleButton>
					</div>
					<div className="narrow time">
						<RBToggleButton defaultVal={props.playback.time.options} radios={Time1()} callback={updateTime}></RBToggleButton>
					</div>
				</>
			)
		}
		return (
			<div className="wide bottom">
				<RBToggleButton time={true} defaultVal={get(props, 'playback.time.options')} radios={Time()} callback={updateTime}></RBToggleButton>
			</div>
		)
	}

	/**
	 * Playback control buttons
	 * @returns {JSX}
	 */
	const playbackButtons = () => {
		const rMode = get(props, 'rendering.mode')

		switch (rMode) {
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
			case RenderingModes.FIRSTGROOVES.ALL:
				return (
					<div className="control-group">
						<div className="text bold size-18">{get(props, `rendering.text.filters.playback.title`, 'Playback')}</div>
						<div className="wide">
							<RBToggleButton
								defaultVal={get(props, 'playback.playbackAs')}
								radios={GrooveLockPlaybackAs}
								doubleClickCallback={props.toggleGrooveModal}
								callback={updatePlayback}
							></RBToggleButton>
						</div>
					</div>
				)
			default:
				break
		}
		return (
			<div className="control-group">
				<div className="text bold size-18">{get(props, `rendering.text.filters.playback.title`, 'Playback')}</div>
				<div className="wide">
					<RBToggleButton
						defaultVal={get(props, 'playback.playbackAs')}
						radios={PlaybackAs()}
						doubleClickCallback={props.toggleGrooveModal}
						callback={updatePlayback}
					></RBToggleButton>
				</div>
			</div>
		)
	}

	/**
	 * Random Rhythm and Clear buttons
	 * @returns JSX
	 */
	const actionButtons = () => {
		if (!renderActionButtons) {
			return null
		}
		const playing = get(props, 'playback.playing')
		const rendered = get(props, 'rendering.renderedArray.length') > 0

		return (
			<>
				<div className="divider narrow"></div>
				<div className="control-group spread">
					<Button onClick={clearRhythm} className="clear action-2 secondary" disabled={playing || !rendered}>
						{get(props, `rendering.text.filters.clear`, 'Clear')}
					</Button>
					<Button
						{...copyEvent}
						onClick={() => {
							if (randomRhythmBlocker) {
								return
							}
							props.generateRhythm({})
						}}
						className={`generate action${randomRhythmBlocker ? `-2` : ``}`}
						disabled={playing}
					>
						{rrButtonText()}
					</Button>
				</div>
			</>
		)
	}

	/**
	 *
	 * @returns JSX
	 */
	const renderPresetsAndSaved = () => {
		if (!renderPresets) {
			return
		}
		let savedSettings = false
		let presetSettings = false
		if (renderPresets) {
			const presetTitleClick = () => {
				props.setPresetConstants({ constants: StraightPresets() })
				if (get(props, 'playback.time.options') !== TimeEnum.STRAIGHT) {
					props.setPresetConstants({ constants: TripletPresets })
				}
				setShowPresets(!showPresets)
			}
			presetSettings = {
				title: get(props, `rendering.text.filters.presets.title`, 'Presets'),
				titleClick: presetTitleClick,
			}
		}

		const multiple = !isNil(savedSettings) && !isNil(presetSettings) && !showPresets && !showSaved
		return (
			<>
				<div className="divider wide"></div>
				<div className={`control-group multiple-${multiple}`}>
					{!showSaved && presetSettings && (
						<div className="clickable-title clickable" onClick={get(presetSettings, 'titleClick')}>
							<p className="clickable text medium size-17">{get(presetSettings, 'title')}</p>
							<img alt="collapse" className={`${showPresets && 'collapse'}`} src={arrow}></img>
						</div>
					)}
					{!showPresets && savedSettings && (
						<div className="clickable-title clickable" onClick={get(savedSettings, 'titleClick')}>
							<p className="clickable text medium size-17">{get(savedSettings, 'title')}</p>
							<img alt="collapse" className={`${showSaved && 'collapse'}`} src={arrow}></img>
						</div>
					)}
					{showPresets && (
						<>
							<div className="grid-container presets">
								{map(get(props, `playback.preset.constants.presets`), (preset, idx) => (
									<Button key={idx} className={`action-3 ${preset.variant}`} value={preset.preset} onClick={() => props.changePreset({ preset })}>
										{preset.preset}
									</Button>
								))}
							</div>
							<div className="text medium size-14 clickable" onClick={() => window.open(`https://jpbouvetmethod.com`, '_blank')}>
								{get(
									props,
									`rendering.text.filters.presets.subtitle.${get(props, 'playback.time.options') === TimeEnum.STRAIGHT ? `sixteenth` : `triplets`}`,
									`From JPBouvetMethod.com's Big 10`
								)}
							</div>
						</>
					)}
					{showSaved && (
						<>
							<div className="grid-container saved">
								{map(get(savedSettings, 'rhythms'), (saved, idx) => {
									return (
										<Button key={idx} className={`action-3`} onClick={() => alert('TBD')}>
											{get(saved, 'rhythmName')}
										</Button>
									)
								})}
							</div>
							{get(props, 'rendering.renderedArray.length') > 0 && props.rendering.saveable[0] ? (
								<Button
									onClick={() => {
										props.setSaveable([false, 'Saved'])
										props.saveRhythm()
									}}
									className="save action-3"
								>
									{props.rendering.saveable[1]}
								</Button>
							) : (
								<Button disabled className="save action-3">
									{props.rendering.saveable[1]}
								</Button>
							)}
						</>
					)}
				</div>
			</>
		)
	}

	/**
	 * @returns {JSX}
	 */
	const renderComplexityLevels = () => {
		const renderingMode = get(props, 'rendering.mode')
		const time = get(props, 'playback.time.options')
		switch (true) {
			case renderingMode === RenderingModes.FIRSTGROOVES.ALL:
			case renderingMode === RenderingModes.FIRSTGROOVES.ONE:
			case renderingMode === RenderingModes.FIRSTGROOVES.TWO:
			case renderingMode === RenderingModes.FIRSTGROOVES.THREE:
				return (
					<>
						<div className="levels">
							<RBToggleButton
								playEnabled={true}
								custom={get(props, 'playback.custom')}
								defaultVal={renderingMode}
								radios={FirstGrooveLevels}
								callback={(e) => {
									get(props, 'setRenderingMode')(e)
								}}
							></RBToggleButton>
						</div>
						<div className="modal-control">
							<Button className={get(props, 'playback.custom') ? 'on' : 'action-3'} onClick={toggleCustomModal} variant="primary">
								{get(props, `rendering.text.filters.complexity.custom`, `Custom`)}
							</Button>
						</div>
					</>
				)
			case renderingMode === RenderingModes.SD.TRIPLETS.ONE:
			case renderingMode === RenderingModes.SD.TRIPLETS.TWO:
			case renderingMode === RenderingModes.SD.TRIPLETS.THREE:
			case renderingMode === RenderingModes.SD.TRIPLETS.FOUR:
			case renderingMode === RenderingModes.SD.TRIPLETS.FIVE:
				return (
					<>
						<div className="levels">
							<RBToggleButton
								playEnabled={true}
								custom={get(props, 'playback.custom')}
								defaultVal={renderingMode}
								radios={TripletSDLevels}
								callback={(e) => {
									get(props, 'setRenderingMode')(e)
								}}
							></RBToggleButton>
						</div>
					</>
				)
			case time === TimeEnum.EIGHTH:
				return (
					<div className="modal-control">
						<Button className={get(props, 'playback.custom') ? 'on' : 'action-3'} onClick={toggleCustomModal} variant="primary">
							{get(props, `rendering.text.filters.complexity.custom`, `Custom`)}
						</Button>
					</div>
				)
			default:
				return (
					<>
						<div className="levels">
							<RBToggleButton
								playEnabled={true}
								custom={get(props, 'playback.custom')}
								defaultVal={get(props, 'playback.level')}
								radios={Levels}
								callback={(e) => updateLevel({ level: e, clearCustom: true })}
							></RBToggleButton>
						</div>
						<div className="modal-control">
							<Button className={get(props, 'playback.custom') ? 'on' : 'action-3'} onClick={toggleCustomModal} variant="primary">
								{get(props, `rendering.text.filters.complexity.custom`, `Custom`)}
							</Button>
						</div>
					</>
				)
		}
	}

	/**
	 * @returns JSX
	 */
	const renderComplexityContent = () => {
		if (!renderComplexity) {
			return
		}
		const space = get(props, 'playback.space')
		let showSpaceSlider = true
		switch (get(props, 'rendering.mode')) {
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
			case RenderingModes.SD.TRIPLETS.ONE:
			case RenderingModes.SD.TRIPLETS.TWO:
			case RenderingModes.SD.TRIPLETS.THREE:
			case RenderingModes.SD.TRIPLETS.FOUR:
			case RenderingModes.SD.TRIPLETS.FIVE:
				showSpaceSlider = false
				break
			default:
				break
		}
		return (
			<>
				<div className="divider wide"></div>
				<div className="control-group">
					<div
						className="clickable-title clickable"
						onClick={() => {
							setShowLevels(!showLevels)
						}}
					>
						<p className="clickable text medium size-17">{get(props, `rendering.text.filters.complexity.title`, `Complexity`)}</p>
						<img alt="collapse" className={`${showLevels && 'collapse'}`} src={arrow} />
					</div>
					{showLevels && (
						<>
							{renderLevels && renderComplexityLevels()}
							{showSpaceSlider && (
								<Slider
									size={`${desktop(get(props, 'device')) ? `small` : `medium`}`}
									className="slider-space"
									key={`slider-${space}`}
									defaultValue={space}
									onChangeCommitted={spaceSliderChange}
									step={1}
									marks={spaceSliderMarks()}
								/>
							)}
						</>
					)}
				</div>
			</>
		)
	}

	/**
	 * Render different BPM buttons based on the current BPM interval
	 * @returns JSX
	 */
	const renderBpmButton = ({ up = true, modal }) => {
		if (bpmIncrement < 5) {
			let intervalSpeed = 80
			if (up) {
				return (
					<input
						onMouseDown={() => {
							const bpm = get(props, 'playback.bpm')
							let add = bpmIncrement
							bpmInterval.current = accurateInterval(
								() => {
									updateBpm({ override: bpm + add })
									add++
								},
								intervalSpeed,
								{ immediate: true }
							)
						}}
						onMouseUp={clearBpmInterval}
						type="button"
						value="+"
						className="button-plus action-2"
						data-field="quantity"
					/>
				)
			}
			return (
				<input
					onMouseDown={() => {
						const bpm = get(props, 'playback.bpm')
						let take = bpmIncrement
						bpmInterval.current = accurateInterval(
							() => {
								updateBpm({ override: bpm - take })
								take++
							},
							intervalSpeed,
							{ immediate: true }
						)
					}}
					onMouseUp={clearBpmInterval}
					type="button"
					value="-"
					className="button-minus action-2"
					data-field="quantity"
				/>
			)
		}
		if (up) {
			return (
				<>
					<input
						onClick={() => updateBpm({ increment: true })}
						{...bpmLongPressEventUp}
						type="button"
						value="+"
						className="button-plus action-2"
						data-field="quantity"
						data-tip="bpm-up"
						data-for="bpm-up"
						data-tip-disable={modal || seenBpmTooltip > 1 || !isDesktop}
					/>
					<ReactTooltip
						id="bpm-up"
						effect="solid"
						place="top"
						delayShow={500}
						delayHide={200}
						multipline
						type={get(props, 'isDarkTheme', false) ? 'light' : 'dark'}
						afterHide={() => setSeenBpmTooltip(seenBpmTooltip + 1)}
					>
						{renderBpmTooltip(true)}
					</ReactTooltip>
				</>
			)
		}
		return (
			<>
				<input
					onClick={() => updateBpm({ increment: false })}
					{...bpmLongPressEventDown}
					type="button"
					value="-"
					className="button-minus action-2"
					data-field="quantity"
					data-tip="bpm-down"
					data-for="bpm-down"
					data-tip-disable={modal || seenBpmTooltip > 1 || !isDesktop}
				/>
				<ReactTooltip
					id="bpm-down"
					effect="solid"
					place="top"
					delayShow={500}
					delayHide={200}
					multipline
					type={get(props, 'isDarkTheme', false) ? 'light' : 'dark'}
					afterHide={() => setSeenBpmTooltip(seenBpmTooltip + 1)}
				>
					{renderBpmTooltip(false)}
				</ReactTooltip>
			</>
		)
	}

	const toggleClickModal = () => props.setShowClickModal(!get(props, `rendering.showModals.click.open`))

	const renderBpmControls = ({ modal = false }) => {
		return (
			<div className="bpm">
				<div className="input-group small">
					{renderBpmButton({ up: false, modal })}
					<input
						readOnly
						type="number"
						step="1"
						max=""
						onClick={tapTempo}
						onChange={() => {}}
						value={props.playback.bpm}
						name="bpm"
						className="quantity-field bpm"
					/>
					{renderBpmButton({ up: true, modal })}
				</div>
			</div>
		)
	}

	/**
	 * @returns JSX
	 */
	const renderControls = () => {
		const showSwing = get(props, 'rendering.showSwing')
		const renderSwing = () => {
			if (!showSwing) {
				return
			}
			return (
				<>
					<div className="control">
						<p className="text medium size-14">{get(props, `rendering.text.filters.controls.swing`, `Swing`)}</p>
						<div className="swing">
							<div className="input-group small">
								<input
									onClick={() => {
										updateSwing(false)
									}}
									type="button"
									value="-"
									className="button-minus action-2"
									data-field="quantity"
									disabled={!get(props, 'isSwingable')}
								/>
								<input
									readOnly
									type="text"
									step="1"
									max=""
									onChange={() => {}}
									value={String(props.playback.swing) + '%'}
									name="swing"
									className={`quantity-field swing ${!get(props, 'isSwingable') && 'borderless'}`}
								/>
								<input
									onClick={() => {
										updateSwing(true)
									}}
									type="button"
									value="+"
									className="button-plus action-2"
									data-field="quantity"
									disabled={!get(props, 'isSwingable')}
								/>
							</div>
						</div>
					</div>
				</>
			)
		}

		const renderMetronome = () => {
			const classname = 'text medium size-14 clickable'
			const tipDisabled = false
			const cogImage = <img alt="cog" className="helper" src={cog} />
			const onClick = () => get(props, `setShowClickModal`)(!get(props, `rendering.showModals.click.open`))

			return (
				<div className="control">
					<p onClick={onClick} className={classname} data-tip="click" data-for="click" data-tip-disable={tipDisabled} ref={clickRef}>
						{get(props, `rendering.text.filters.controls.metronome`, `Metronome`)}
						{cogImage}
					</p>
					<ReactTooltip
						id="click"
						effect="solid"
						place="top"
						delayShow={200}
						delayHide={200}
						multipline
						type={get(props, 'isDarkTheme', false) ? 'light' : 'dark'}
					>
						{renderClickTooltip({ desktop: desktop(get(props, 'device')) })}
					</ReactTooltip>
					<Switch
						size={`small`}
						checked={get(props, `playback.click.on`)}
						onChange={() => {
							props.setUseMetronome(!get(props, `playback.click.on`))
						}}
						name="metronome"
					/>
				</div>
			)
		}
		let inner
		if (showControls) {
			inner = (
				<>
					<div className={`grid-container controls-number ${showSwing ? 'swing' : 'no-swing'}`}>
						<div className="control">
							<p className="text medium size-14">Bpm</p>
							{renderBpmControls({})}
						</div>
						{renderSwing()}
					</div>
					<div className="grid-container controls">
						<div className="control">
							<p className="text medium size-14">{get(props, `rendering.text.filters.controls.count`, `Count In`)}</p>
							<Switch
								disabled={get(props, `isPracticeModeOn`, false)}
								size={`small`}
								checked={get(props, `playback.click.countIn`)}
								onChange={() => {
									props.setUseCountIn(!get(props, `playback.click.countIn`))
								}}
								name="countin"
							/>
						</div>
						<div className="control">
							<p className="text medium size-14">{get(props, `rendering.text.filters.controls.ghost`, `Ghost Notes`)}</p>
							<Switch
								size={`small`}
								checked={props.playback.useGhosts}
								onChange={() => {
									props.setUseGhosts(!props.playback.useGhosts)
								}}
								name="ghosts"
							/>
						</div>
						{renderMetronome()}
					</div>
					<div className="divider narrow"></div>

					{/* <div className="volume">
					<div className="text medium center size-14">Volume</div>
					<Slider
						className="slider-volume"
						key={`slider-${50 * props.audio.volumeBoost}`}
						defaultValue={50 * props.audio.volumeBoost}
						onChangeCommitted={volumeSliderChange}
						step={1}
						valueLabelDisplay="auto"
						getAriaValueText={percentageValueText}
						valueLabelFormat={percentageValueText}
						marks={volumeSliderMarks}
					/>
				</div> */}
				</>
			)
		}
		return (
			<>
				<div className="divider wide"></div>
				<div className="control-group full">
					<>
						<div
							className="clickable-title clickable"
							onClick={() => {
								setShowControls(!showControls)
							}}
						>
							<p className="clickable text medium size-17">{get(props, `rendering.text.filters.controls.title`, `Controls`)}</p>
							<img alt="collapse" className={`${showControls && 'collapse'}`} src={arrow} />
						</div>
						{inner}
					</>
				</div>
			</>
		)
	}

	/**
	 * Text for the Random Rhythm button
	 * @returns {JSX}
	 */
	const rrButtonText = () => {
		if (randomRhythmBlocker) {
			return `Link Copied`
		}
		return get(props, `rendering.text.filters.random`, 'Random Rhythm')
	}

	/**
	 *
	 * @returns {JSX}
	 */
	const renderPlaybackGroup = () => {
		const render = get(props, `playback.mode`) !== ModesEnum.CLICK && get(props, `playback.mode`) !== ModesEnum.CLICKREADING
		if (!render) {
			return
		}
		const playbackAs = get(props, `playback.playbackAs`)
		const options = get(props, `playback.time.options`)
		let helperText
		if (playbackAs === Sounds.GROOVE) {
			let key = `short`
			if (options === TimeEnum.STRAIGHT || options === TimeEnum.EIGHTH) {
				key = `full`
			}
			helperText = (
				<div onClick={props.toggleGrooveModal} className="description text medium size-14 clickable clickable-title">
					{get(props, `rendering.text.filters.playback.groove.subtitle.${desktop(props.device) ? `desktop` : `mobile`}.${key}`)}
				</div>
			)
		}
		return (
			<>
				{playbackButtons()}
				{helperText}
				<div className="divider narrow"></div>
			</>
		)
	}

	/**
	 * @returns {JSX}
	 */
	const renderBarsGroup = () => {
		return (
			<div className="control-group">
				<div className="text bold size-18">{get(props, `rendering.text.filters.bars`, `Bars`)}</div>
				<div className="input-group large">
					<input
						onClick={() => {
							props.updateBars(false)
						}}
						ref={decreaseBarsRef}
						type="button"
						value="-"
						className="button-minus action-2"
						data-field="quantity"
					/>
					<input type="number" step="1" max="" onChange={() => {}} value={props.playback.bars} name="bars" className="quantity-field bars" />
					<input
						onClick={() => {
							props.updateBars(true)
						}}
						ref={increaseBarsRef}
						type="button"
						value="+"
						className="button-plus action-2"
						data-field="quantity"
					/>
				</div>
			</div>
		)
	}

	/**
	 * @returns {JSX}
	 */
	const renderSubdivisionGroup = () => {
		return (
			<div className="control-group">
				<div className="text bold size-18">{get(props, `rendering.text.filters.subdivision.title`, 'Subdivision')}</div>
				{subdivisionButtons()}
			</div>
		)
	}

	/**
	 * @returns {JSX}
	 */
	const renderModeGroup = () => {
		const mode = get(props, `playback.mode`)
		let secondRow, helperText, tooltip
		switch (mode) {
			case ModesEnum.LOOP:
			case ModesEnum.LOOPREADING:
				secondRow = (
					<div className="narrow mode">
						<RBToggleButton defaultVal={props.playback.mode} radios={ModesLoop()} callback={updateMode}></RBToggleButton>
					</div>
				)
				break
			case ModesEnum.TRADE:
			case ModesEnum.TRADEREADING:
				secondRow = (
					<div className="narrow mode">
						<RBToggleButton defaultVal={props.playback.mode} radios={ModesTrade()} callback={updateMode}></RBToggleButton>
					</div>
				)
				break
			case ModesEnum.CLICK:
			case ModesEnum.CLICKREADING:
				secondRow = (
					<div className="narrow mode">
						<RBToggleButton
							doubleClickCallback={toggleClickModal}
							defaultVal={props.playback.mode}
							radios={ModesClick()}
							callback={updateMode}
						></RBToggleButton>
					</div>
				)
				tooltip = (
					<ReactTooltip
						id="click-mode"
						effect="solid"
						place="right"
						delayShow={300}
						delayHide={100}
						multipline
						type={get(props, 'isDarkTheme', false) ? 'light' : 'dark'}
						afterHide={() => setSeenClickTooltipMode(seenClickTooltipMode + 1)}
					>
						{renderClickTooltip({ desktop: desktop(get(props, 'device')) })}
					</ReactTooltip>
				)
				break
			default:
				break
		}
		switch (mode) {
			case ModesEnum.LOOPREADING:
			case ModesEnum.CLICKREADING:
				helperText = <div className="description text medium size-14">{get(props, `rendering.text.filters.mode.infinite`, 'Infinite new rhythms')}</div>
				break
			case ModesEnum.TRADE:
				helperText = (
					<div
						className={!props.playback.playing ? 'clickable clickable-title description text medium size-14' : 'description text medium size-14'}
						onClick={() => {
							!props.playback.playing && props.toggleTradeDirection()
						}}
					>
						{get(
							props,
							`rendering.text.filters.mode.trade.standard.${desktop(props.device) ? `desktop` : `mobile`}.${props.playback.tradeDirection ? `you` : `rb`}`
						)}
					</div>
				)
				break
			case ModesEnum.TRADEREADING:
				helperText = (
					<div
						className="description text medium size-14 clickable clickable-title"
						onClick={() => {
							props.toggleTradeDirection()
						}}
					>
						{get(props, `rendering.text.filters.mode.trade.reading.${props.playback.tradeDirection ? `you` : `rb`}`)}
					</div>
				)
				break
			case ModesEnum.CLICK:
				let cName = `description text medium size-14 clickable clickable-title`
				helperText = (
					<div onClick={toggleClickModal} className={`${cName}`}>
						{get(props, `rendering.text.filters.mode.click.subtitle`, `Looping metronome`)}
					</div>
				)
				break
			default:
				break
		}
		return (
			<>
				<div className="control-group">
					<div className="text bold size-18">{startCase(get(props, `rendering.text.filters.mode.title`, 'Mode'))}</div>
					<div
						className="wide bottom"
						data-tip="click-mode"
						data-for="click-mode"
						// TODO data-tip-disable={!desktop(get(props, 'device')) || seenClickTooltipMode > 0}
						data-tip-disable={true}
						ref={clickModeRef}
					>
						<RBToggleButton
							doubleClickCallback={toggleClickModal}
							mode={true}
							defaultVal={get(props, `playback.mode`)}
							radios={Modes()}
							callback={updateMode}
						></RBToggleButton>
						{tooltip}
					</div>
					{secondRow}
				</div>
				{helperText}
			</>
		)
	}

	/**
	 *
	 * @returns {JSX}
	 */
	const renderModals = () => {
		const clickModal = get(props, 'rendering.showModals.click.open')
		switch (true) {
			case showCustomModal:
				return <CustomModal device={props.device} toggle={toggleCustomModal} settingsQueryParams={props.settingsQueryParams} show={showCustomModal}></CustomModal>
			case clickModal:
				return (
					<ClickModal
						device={props.device}
						renderBpmControls={renderBpmControls}
						toggle={toggleClickModal}
						show={get(props, 'rendering.showModals.click.open')}
					></ClickModal>
				)
			default:
				return
		}
	}

	const renderFiltersContainer = () => {
		const container = (
			<div className={`filters-container ${get(props, 'themeName', 'light')} ${props.device} ${props.rendering.showFilters}`} ref={filtersContainerRef}>
				{renderModeGroup()}
				<div className="divider narrow"></div>
				{renderSubdivisionGroup()}
				<div className="divider narrow"></div>
				{renderPlaybackGroup()}
				{renderBarsGroup()}
				{actionButtons()}
				{renderPresetsAndSaved()}
				{renderComplexityContent()}
				{renderControls()}
				{false && (
					<>
						<div className="divider wide"></div>
						<div className="control-group full">
							<>
								<div
									className="clickable-title clickable"
									onClick={() => {
										setShowDev(!showDev)
									}}
								>
									<p className="clickable text medium size-18">{`Dev Zone`}</p>
									{showDev ? <img alt="collapse" className="collapse" src={arrow}></img> : <img alt="expand" src={arrow}></img>}
								</div>
								{showDev && (
									<>
										<div className="control-group spread">
											{props.rendering.renderedArray.length > 0 && props.rendering.saveable[0] ? (
												<Button
													onClick={() => {
														props.setSaveable([false, 'Saved'])
														props.saveRhythm()
													}}
													className="save action-3"
												>
													{props.rendering.saveable[1]}
												</Button>
											) : (
												<Button disabled className="save action-3">
													{props.rendering.saveable[1]}
												</Button>
											)}
											{props.rendering.loadable ? (
												<Button
													onClick={() => {
														props.setShowLoadModal(true)
													}}
													className="load action-3"
												>
													{`Load`}
												</Button>
											) : (
												<Button disabled className="load action-3">
													{`Load`}
												</Button>
											)}
										</div>
									</>
								)}
							</>
						</div>
					</>
				)}

				{renderModals()}

				<div ref={filtersEndRef} />
			</div>
		)
		return container
	}

	return (
		<>
			{(!desktop(props.device) || props.rendering.mobileControls.timeSignature.show) && (
				<div
					onClick={() => {
						if (
							get(props, 'playback.playing') ||
							get(props, 'rendering.showFilters') ||
							get(props, [`rendering`, `mobileControls`, `timeSignature`, `show`])
						) {
							return
						}
						if (get(props, 'rendering.mobileControls.down.value')) {
							props.setMobileControlsDown({ down: false, manual: true })
							return
						}
						tapTempo()
					}}
					className={`mobile-controls android-${get(props, 'rendering.platform') === PlatformEnum.ANDROID} ios-${
						get(props, 'rendering.platform') === PlatformEnum.IOS
					} ${get(props, 'themeName', `light`)} ${props.rendering.showFilters} is-tablet-${isTablet} time-signature-controls-${
						props.rendering.mobileControls.timeSignature.show
					} down-${props.rendering.mobileControls.down.value}`}
					{...MobileControlSwipes}
				>
					{!get(props, [`rendering`, `mobileControls`, `timeSignature`, `show`]) && mobileHintText()}
					{get(props, [`rendering`, `mobileControls`, `timeSignature`, `show`]) && mobileTimeSignatureControls()}
					{!isTablet && !get(props, 'rendering.mobileControls.down.value') && mobileBarControls()}
				</div>
			)}
			{renderFiltersContainer()}
		</>
	)
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const playback = getPlaybackState(state)
	const audio = getAudioState(state)
	const tripletTimeSignature = isTripletTimeSignature(state)
	const is44 = getIsTimeSignature44(state)
	const straightTime = getIsStraightTime(state)
	const isSwingable = swingableGroove(state)
	const timeSignatureUpdateAllowed = isTimeSignatureUpdateAllowed(state)
	const isApp = getIsApp(state)
	const isDarkTheme = getIsDarkTheme(state)
	const themeName = getThemeName(state)
	const isPracticeModeOn = getIsPracticeModeOn(state)
	const seenMobileTimeSignature = getSeenMobileTimeSignature(state)
	const isRVPermutations = getIsRhythmicVocabularyPermutations(state)

	return {
		rendering,
		playback,
		audio,
		tripletTimeSignature,
		is44,
		timeSignatureUpdateAllowed,
		straightTime,
		isSwingable,
		isApp,
		isDarkTheme,
		themeName,
		isPracticeModeOn,
		seenMobileTimeSignature,
		isRVPermutations,
	}
}

export default connect(mapStateToProps, {
	setModalHHArray,
	setShowSwing,
	setPlaybackAs,
	setRandom,
	setUseCountIn,
	setUseGhosts,
	setUseMetronome,
	setTime,
	setCustom,
	setCustomArray,
	setMode,
	setRhythmName,
	setBpm,
	setLevelsArray,
	setAudioContext,
	setShowLoadModal,
	setSaveable,
	setShowSoundbankModal,
	setSwing,
	toggleTradeDirection,
	setSpace,
	setTimeSignatureTop,
	setTimeSignatureBottom,
	setTimeSignature,
	setPlaybackTime,
	setMobileTimeSignatureControls,
	setMobileTimeSignatureSeen,
	setMobileControlsDown,
	setVolume,
	setPro,
	setRenderingMode,
	setShowClickModal,
	setPresetConstants,
})(Filters)
