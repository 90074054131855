import React from 'react'
import { connect } from 'react-redux'

import { IonItem, IonIcon, IonLabel } from '@ionic/react'
import { refreshCircleOutline } from 'ionicons/icons'
import { resetClick } from '../../../../redux/actions'
import { getIsCustomClick } from '../../../../redux/selectors/playback'

const Reset = ({ customClick, resetClick }) => {
	return (
		<IonItem disabled={!customClick} onClick={resetClick} button>
			<IonIcon slot="end" icon={refreshCircleOutline} />
			<IonLabel>{`Reset`}</IonLabel>
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	return {
		customClick: getIsCustomClick(state),
	}
}

export default connect(mapStateToProps, { resetClick })(Reset)
