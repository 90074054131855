import get from 'lodash/get'

import { ION_SET_COMPLEXITY_CUSTOM_OPEN, ION_SET_COMPLEXITY_OPEN, ION_SET_FILTERS_OPEN, ION_SET_PRESETS_OPEN, ION_SET_TIMESIGNATURE_OPEN } from '../actionTypes'
import { initIonic } from './initStates'

export default function ionic(state = initIonic, action) {
	switch (action.type) {
		case ION_SET_FILTERS_OPEN: {
			const { open } = action.payload
			if (!open) {
				return {
					...state,
					pages: {
						...state.pages,
						practice: {
							...state.pages.practice,
							filters: {
								...get(initIonic, [`pages`, `practice`, `filters`]),
							},
						},
					},
				}
			}
			return {
				...state,
				pages: {
					...state.pages,
					practice: {
						...state.pages.practice,
						filters: {
							...state.pages.practice.filters,
							open,
						},
					},
				},
			}
		}
		case ION_SET_PRESETS_OPEN: {
			const { open } = action.payload
			return {
				...state,
				pages: {
					...state.pages,
					practice: {
						...state.pages.practice,
						filters: {
							...state.pages.practice.filters,
							timeSignature: {
								open: open ? false : get(state, [`pages`, `practice`, `filters`, `timeSignature`, `open`]),
							},
							complexity: {
								...state.pages.practice.complexity,
								open: open ? false : get(state, [`pages`, `practice`, `filters`, `complexity`, `open`]),
							},
							presets: {
								open,
							},
						},
					},
				},
			}
		}
		case ION_SET_COMPLEXITY_OPEN: {
			const { open } = action.payload
			return {
				...state,
				pages: {
					...state.pages,
					practice: {
						...state.pages.practice,
						filters: {
							...state.pages.practice.filters,
							timeSignature: {
								open: open ? false : get(state, [`pages`, `practice`, `filters`, `timeSignature`, `open`]),
							},
							presets: {
								open: open ? false : get(state, [`pages`, `practice`, `filters`, `presets`, `open`]),
							},
							complexity: {
								...state.pages.practice.complexity,
								open,
							},
						},
					},
				},
			}
		}
		case ION_SET_COMPLEXITY_CUSTOM_OPEN: {
			const { open } = action.payload
			return {
				...state,
				pages: {
					...state.pages,
					practice: {
						...state.pages.practice,
						filters: {
							...state.pages.practice.filters,
							timeSignature: {
								open: open ? false : get(state, [`pages`, `practice`, `filters`, `timeSignature`, `open`]),
							},
							presets: {
								open: open ? false : get(state, [`pages`, `practice`, `filters`, `presets`, `open`]),
							},
							complexity: {
								...state.pages.practice.complexity,
								open: true,
								custom: {
									open,
								},
							},
						},
					},
				},
			}
		}
		case ION_SET_TIMESIGNATURE_OPEN: {
			const { open } = action.payload
			return {
				...state,
				pages: {
					...state.pages,
					practice: {
						...state.pages.practice,
						filters: {
							...state.pages.practice.filters,
							presets: {
								open: open ? false : get(state, [`pages`, `practice`, `filters`, `presets`, `open`]),
							},
							complexity: {
								...state.pages.practice.complexity,
								open: open ? false : get(state, [`pages`, `practice`, `filters`, `complexity`, `open`]),
							},
							timeSignature: {
								open,
							},
						},
					},
				},
			}
		}
		default:
			return state
	}
}
