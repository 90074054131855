import React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'

import Button from 'react-bootstrap/Button'

import { getRenderingState } from '../../redux/selectors/rendering'
import { setShowFeedbackModal } from '../../redux/actions'

const Feedback = ({ setShowFeedbackModal, rendering }) => {
	const toggleFeedbackModal = () => setShowFeedbackModal(!get(rendering, [`showModals`, `feedback`]))

	return (
		<Button onClick={toggleFeedbackModal} className={'feedback action-3'}>
			{`Feedback`}
		</Button>
	)
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	return {
		rendering,
	}
}

export default connect(mapStateToProps, { setShowFeedbackModal })(Feedback)
