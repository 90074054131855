import * as React from 'react'
import { Carousel } from 'react-responsive-carousel'

import rhythmBotLogo from '../../images/title-dark.png'
import appleAppStoreLink from '../../images/app/apple-app-store.svg'
import googlePlayStoreLink from '../../images/app/google-play-store.png'
import promoSightReading from '../../images/app/promo-sight-reading.png'
import promoPlayAlong from '../../images/app/promo-play-along.png'
import promoMetronome from '../../images/app/promo-metronome.png'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './style.sass'

export const MobileAppHomePage = () => {
	return (
		<div className="app-promo-container">
			<div className="app-promo-card">
				<img src={rhythmBotLogo} alt="RhythmBot Logo" className="app-logo" />
				<p className="app-subtitle">{`Now available for iOS and Android.`}</p>

				<Carousel
					autoPlay
					infiniteLoop
					showThumbs={false}
					showStatus={false}
					dynamicHeight={false}
					showArrows={false}
					showIndicators={false}
					emulateTouch={true}
					stopOnHover={false}
					interval={3000}
					className="app-carousel"
				>
					<img src={promoSightReading} alt="Sight Reading" className="carousel-image" />
					<img src={promoPlayAlong} alt="Play Along" className="carousel-image" />
					<img src={promoMetronome} alt="Metronome" className="carousel-image" />
				</Carousel>
			</div>

			<footer className="footer">
				<div className="store-buttons">
					<a href="https://apps.apple.com" className="store-button" target="_blank" rel="noopener noreferrer">
						<img src={appleAppStoreLink} alt="Download on the App Store" />
					</a>
					<a href="https://play.google.com/store/apps?hl=en_GB" className="store-button" target="_blank" rel="noopener noreferrer">
						<img src={googlePlayStoreLink} alt="Get it on Google Play" />
					</a>
				</div>
				<div className="footer-links">
					<a href="https://privacy.rhythmbot.app" className="footer-link" target="_blank" rel="noopener noreferrer">
						{`Privacy Policy`}
					</a>
					<a href="mailto:rhythmbot@jpbouvetmethod.com" className="footer-link">
						{`Contact Us`}
					</a>
				</div>
			</footer>
		</div>
	)
}
