export const tabs = {
	practice: {
		title: `Practice`,
		href: `/practice`,
	},
	metronome: {
		title: `Metronome`,
		href: `/metronome`,
	},
	groove: {
		title: `Groove`,
		href: `/groove`,
	},
	feedback: {
		title: `Feedback`,
		href: `/feedback`,
	},
	filters: {
		title: `Settings`,
		href: `/filters`,
	},
	courses: {
		title: `Courses`,
		href: `/courses`,
	},
	arcade: {
		title: `Arcade`,
		href: `/arcade`,
	},
}
