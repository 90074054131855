import get from 'lodash/get'
import { useDispatch, useSelector } from 'react-redux'

import { setBarSettings, setRandom, setRenderedArray } from '../redux/actions'
import RenderingModes from '../utils/constants/enum/renderingMode'
import Times from '../utils/constants/enum/time'
import { getTime } from '../redux/selectors/playback'

const useRenderer = () => {
	const dispatch = useDispatch()
	const time = useSelector(getTime)

	const dispatchAction = (value, action) => dispatch(action(value))

	/**
	 * Set renderedArray - displaying notation images on bars
	 * @param {array} images array of image IDs being rendered
	 * @param {boolean} preset whether this rhythm is a preset
	 * @param playbackTime force a rhythm to be rendered in this time
	 */
	const render = ({ images, preset = false, playbackTime = false, sixEightOverride = false, renderingMode = RenderingModes.NORMAL }) => {
		preset && dispatchAction(false, setRandom)
		const timeToUse = playbackTime || get(time, 'options')

		if (sixEightOverride) {
			dispatchAction(
				{
					path: 'six-eight',
					style: 'straight',
				},
				setBarSettings
			)
			dispatchAction(images, setRenderedArray)
			return
		}

		switch (renderingMode) {
			case RenderingModes.DOWNUP.STRAIGHT:
				dispatchAction(
					{
						path: 'down-ups/straight',
						style: 'straight',
					},
					setBarSettings
				)
				dispatchAction(images, setRenderedArray)
				return
			case RenderingModes.DOWNUP.TRIPLETS:
				dispatchAction(
					{
						path: 'down-ups/swung',
						style: 'swung',
					},
					setBarSettings
				)
				dispatchAction(images, setRenderedArray)
				return
			case RenderingModes.SD.TRIPLETS.ONE:
			case RenderingModes.SD.TRIPLETS.TWO:
			case RenderingModes.SD.TRIPLETS.THREE:
			case RenderingModes.SD.TRIPLETS.FOUR:
			case RenderingModes.SD.TRIPLETS.FIVE:
				dispatchAction(
					{
						path: 's-d/swung',
						style: 'swung',
					},
					setBarSettings
				)
				dispatchAction(images, setRenderedArray)
				return
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
				dispatchAction(
					{
						path: 'first-grooves',
						style: 'straight',
					},
					setBarSettings
				)
				dispatchAction(images, setRenderedArray)
				return
			default:
				break
		}

		switch (timeToUse) {
			case Times.STRAIGHT:
				dispatchAction(
					{
						path: 'straight',
						style: 'straight',
					},
					setBarSettings
				)
				break
			case Times.EIGHTH:
				dispatchAction(
					{
						path: 'straight-8',
						style: 'straight',
					},
					setBarSettings
				)
				break
			case Times.TRIPLETS:
				dispatchAction(
					{
						path: 'swung',
						style: 'swung',
					},
					setBarSettings
				)
				break
			default:
				dispatchAction(
					{
						path: 'mixed',
						style: 'mixed',
					},
					setBarSettings
				)
				break
		}
		dispatchAction(images, setRenderedArray)
	}

	return { render }
}

export default useRenderer
