import get from 'lodash/get'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { initPlayback } from '../redux/reducers/initStates'
import {
	getClickSettings,
	getGrooveLock,
	getIsIsolated,
	getIsolatedArray,
	getIsPracticeModeOn,
	getPlayThis,
	getPracticeState,
	getRhythmLock,
	getUsingGhosts,
} from '../redux/selectors/playback'
import { getRenderedArray } from '../redux/selectors/rendering'

/**
 * Set up global refs to be used across RhythmBot
 */
const useGlobalRefs = () => {
	/**
	 * State
	 */
	const renderedArray = useSelector(getRenderedArray)
	const rhythmLock = useSelector(getRhythmLock)
	const grooveLock = useSelector(getGrooveLock)
	const useGhosts = useSelector(getUsingGhosts)
	const click = useSelector(getClickSettings)
	const practice = useSelector(getPracticeState)
	const playThis = useSelector(getPlayThis)
	const isolatedArray = useSelector(getIsolatedArray)
	const isPracticeModeOn = useSelector(getIsPracticeModeOn)
	const isIsolated = useSelector(getIsIsolated)

	//Click
	const nextClickOffsetRef = useRef(0)
	const gapClickRef = useRef(false)
	const originalBarsEnabledRef = useRef(false)
	//Timers and intervals
	const countInTimeout = useRef(null)
	const playOnceTimeout = useRef(null) // Used to stop play back in play once mode
	const hitsInterval = useRef(null) // Used when looping playback in loop and trade mode
	const pollingInterval = useRef(null) // Used when looping playback in loop and trade mode
	const highlightInterval = useRef(null)
	const readingInterval = useRef(null)

	/**
	 * References to changes available during playback
	 */
	const renderedArrayRef = useRef(renderedArray)
	const rhythmLockRef = useRef(rhythmLock)
	const grooveLockRef = useRef(grooveLock)
	const ghostsRef = useRef(useGhosts)
	const metronomeRef = useRef(get(click, 'on', get(initPlayback, ['click', 'on'], true)))
	const clickVolumeRef = useRef(get(click, 'volume', get(initPlayback, ['click', 'volume'], 0.8)))

	/**
	 * References needed for `Practice Mode`: maintaining rhythm history and keeping track of play along stats
	 */
	// TODO clean up practice mode refs
	const currentLoopRef = useRef(0)
	const straightLockRef = useRef(false)
	const tripletLockRef = useRef(false)
	const latestHitBeatRef = useRef(0)
	const practiceRef = useRef(practice || get(initPlayback, 'practice'))
	const practicePlayThisRef = useRef(playThis || [])
	const practiceRenderedArrayRef = useRef(renderedArray || false)
	const practiceIsolatedArrayRef = useRef(isolatedArray || false)
	const readingPracticeRenderedArrayRef = useRef(null)
	const readingPracticePlayThisRef = useRef(playThis || [])

	const practicePollingRef = useRef(null)
	const practiceProgressStatsRef = useRef({ loop: 0, bar: 0, percentage: 0 })

	/**
	 * Reference updating effects
	 */
	useEffect(() => {
		renderedArrayRef.current = renderedArray
	}, [renderedArray])
	useEffect(() => {
		rhythmLockRef.current = rhythmLock
	}, [rhythmLock])
	useEffect(() => {
		grooveLockRef.current = grooveLock
	}, [grooveLock])
	useEffect(() => {
		ghostsRef.current = useGhosts
	}, [useGhosts])
	useEffect(() => {
		metronomeRef.current = click.on
	}, [click.on])
	useEffect(() => {
		clickVolumeRef.current = click.volume
	}, [click.volume])
	// Update references needed for `Practice Mode`
	useEffect(() => {
		if (!isPracticeModeOn) return
		if (isIsolated) {
			practiceRenderedArrayRef.current = get(isolatedArray, [1], renderedArray)
			return
		}
		practiceRenderedArrayRef.current = renderedArray
	}, [renderedArray, isIsolated])
	useEffect(() => {
		if (!isPracticeModeOn) return
		practicePlayThisRef.current = playThis
	}, [playThis])
	useEffect(() => {
		if (!isPracticeModeOn) return
		practiceIsolatedArrayRef.current = isolatedArray
	}, [isolatedArray])
	useEffect(() => {
		if (!isPracticeModeOn) return
		practiceRef.current = practice
	}, [practice])

	return {
		nextClickOffsetRef,
		gapClickRef,
		originalBarsEnabledRef,
		countInTimeout,
		playOnceTimeout,
		hitsInterval,
		pollingInterval,
		highlightInterval,
		readingInterval,
		renderedArrayRef,
		rhythmLockRef,
		grooveLockRef,
		ghostsRef,
		metronomeRef,
		clickVolumeRef,
		currentLoopRef,
		straightLockRef,
		tripletLockRef,
		latestHitBeatRef,
		practiceRef,
		practicePlayThisRef,
		practiceRenderedArrayRef,
		practiceIsolatedArrayRef,
		readingPracticeRenderedArrayRef,
		readingPracticePlayThisRef,
		practicePollingRef,
		practiceProgressStatsRef,
	}
}

export default useGlobalRefs
