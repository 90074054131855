import React from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'

import { IonItem, IonLabel } from '@ionic/react'

import { ionSetTimeSignatureOpen } from '../../../../../../../redux/ionicActions'
import { getIsPlaying, getTime, getTimeSignature, isTimeSignatureUpdateAllowed } from '../../../../../../../redux/selectors/playback'

const TimeSignature = ({ ts, playing, ionSetTimeSignatureOpen, timeSignatureUpdateAllowed }) => {
	const getLabel = () => `${get(ts, `top`)}/${get(ts, `bottom`)} Time Signature`

	return (
		<IonItem
			detail
			button
			disabled={playing || !timeSignatureUpdateAllowed}
			onClick={() => {
				ionSetTimeSignatureOpen(true)
			}}
		>
			<IonLabel>{getLabel()}</IonLabel>
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const time = getTime(state)
	const playing = getIsPlaying(state)
	const timeSignatureUpdateAllowed = isTimeSignatureUpdateAllowed(state)
	const ts = getTimeSignature(state)

	return {
		playing,
		timeSignatureUpdateAllowed,
		ts,
		time,
	}
}

export default connect(mapStateToProps, { ionSetTimeSignatureOpen })(TimeSignature)
