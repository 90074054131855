/**
 * Set document style variables for use in sass to style the time signature
 * @param beatsPerBar the number of beats per bar
 */
export const timeSignatureStyler = (beatsPerBar) => {
	switch (true) {
		case beatsPerBar < 1.5:
			document.documentElement.style.setProperty('--barline-width', `55%`)
			document.documentElement.style.setProperty('--beat-height', `105%`)
			document.documentElement.style.setProperty('--notes-width', `72.5%`)
			break
		case beatsPerBar < 2:
			document.documentElement.style.setProperty('--barline-width', `58%`)
			document.documentElement.style.setProperty('--beat-height', `100%`)
			document.documentElement.style.setProperty('--notes-width', `75.5%`)
			break
		case beatsPerBar === 2:
			document.documentElement.style.setProperty('--barline-width', `61%`)
			document.documentElement.style.setProperty('--beat-height', `98%`)
			document.documentElement.style.setProperty('--notes-width', `80.25%`)
			break
		case beatsPerBar < 2.5:
			document.documentElement.style.setProperty('--barline-width', `63%`)
			document.documentElement.style.setProperty('--beat-height', `96%`)
			document.documentElement.style.setProperty('--notes-width', `84.25%`)
			break
		case beatsPerBar < 3:
			document.documentElement.style.setProperty('--barline-width', `66%`)
			document.documentElement.style.setProperty('--beat-height', `96%`)
			document.documentElement.style.setProperty('--notes-width', `90%`)
			break
		case beatsPerBar === 3:
			document.documentElement.style.setProperty('--barline-width', `71%`)
			document.documentElement.style.setProperty('--beat-height', `94%`)
			document.documentElement.style.setProperty('--notes-width', `90%`)
			break
		case beatsPerBar < 4:
			document.documentElement.style.setProperty('--barline-width', `76%`)
			document.documentElement.style.setProperty('--beat-height', `92%`)
			document.documentElement.style.setProperty('--notes-width', `90%`)
			break
		case beatsPerBar === 4:
			document.documentElement.style.setProperty('--barline-width', `91%`)
			document.documentElement.style.setProperty('--beat-height', `91.50%`)
			document.documentElement.style.setProperty('--notes-width', `92%`)
			break
		case beatsPerBar < 5:
			document.documentElement.style.setProperty('--barline-width', `91%`)
			document.documentElement.style.setProperty('--beat-height', `89.25%`)
			document.documentElement.style.setProperty('--notes-width', `93%`)
			break
		case beatsPerBar === 5:
			document.documentElement.style.setProperty('--barline-width', `94%`)
			document.documentElement.style.setProperty('--beat-height', `88.50%`)
			document.documentElement.style.setProperty('--notes-width', `94%`)
			break
		case beatsPerBar < 6:
			document.documentElement.style.setProperty('--barline-width', `94%`)
			document.documentElement.style.setProperty('--beat-height', `87%`)
			document.documentElement.style.setProperty('--notes-width', `94%`)
			break
		case beatsPerBar < 7:
			document.documentElement.style.setProperty('--barline-width', `96%`)
			document.documentElement.style.setProperty('--beat-height', `86.25%`)
			document.documentElement.style.setProperty('--notes-width', `94%`)
			break
		case beatsPerBar === 7:
			document.documentElement.style.setProperty('--barline-width', `98%`)
			document.documentElement.style.setProperty('--beat-height', `84.75%`)
			document.documentElement.style.setProperty('--notes-width', `94%`)
			break
		default:
			break
	}
}
