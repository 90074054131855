import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import chunk from 'lodash/chunk'
import fill from 'lodash/fill'
import isUndefined from 'lodash/isUndefined'
import includes from 'lodash/includes'
import map from 'lodash/map'
import forEach from 'lodash/forEach'

import { useLongPress } from 'react-use'

import ReactTooltip from 'react-tooltip'
import Button from 'react-bootstrap/Button'
import RVPermutationsModal from './Modals/RVPermutationsModal'
import Bar from './Bar.js'

import {
	getPlaybackState,
	getBeatsPerBar,
	getNoOfBeatsToRender,
	getIsSixTwelveEight,
	getIsStraightTime,
	isTimeSignatureUpdateAllowed,
	getHasInteractedWithPractice,
	getGrooveMix,
	getGrooveLock,
	getRVPermutationsGroupingIndex,
	getRVPermutationsStartingPoints,
} from '../redux/selectors/playback'
import { getAudioState } from '../redux/selectors/audio'
import { getRenderingState, getThemeName, getIsDarkTheme, getIsRhythmicVocabularyPermutations } from '../redux/selectors/rendering'
import {
	setAudioContext,
	toggleShowFilters,
	toggleShowSettings,
	setSelected,
	setSelectedBars,
	toggleRhythmLock,
	toggleGrooveLock,
	setGrooveLock,
	setTimeSignatureTop,
	setTimeSignatureBottom,
	setShowClickModal,
	setMobileTimeSignatureControls,
	setShowPracticeModal,
	setShowRVPermutationsModal,
} from '../redux/actions'

import timeSignatureMap from '../utils/constants/time-signature-font-map'
import { desktop, arrayEquals, errorOccurred, beta } from '../utils/functions'
import { renderPracticeTooltip, renderTimeSignatureToolTip } from '../utils/tooltips'
import Time from '../utils/constants/enum/time'
import Modes from '../utils/constants/enum/mode'
import { Sounds } from '../utils/constants/enum/sounds'
import RenderingModes from '../utils/constants/enum/renderingMode'
import { getRvPermutationsCoveredIndexes, straightOptions } from '../utils/rhythmic-vocabulary-permutations'
import { getBarImage } from '../utils/images'
import { isClickable, isFaded, isHighlighted } from '../utils/bars'

import useClearRhythm from '../hooks/useClearRhythm'
import useIsolate from '../hooks/useIsolate'
import useBowser from '../hooks/useBowser'
import useKeyboard from '../hooks/useKeyboard'

import metronome from '../images/metronome.png'
import lock from '../images/lock.png'
import unlocked from '../images/unlocked.png'

const Canvas = (props) => {
	const { clearRhythm } = useClearRhythm()
	const { isolate } = useIsolate()

	const { isFirefox, isSafari, isTablet } = useBowser()
	const playDelay = isSafari || isFirefox ? 200 : 0 //Handle difficult browsers with an Xms delay when playing

	// Determine how each bar should be rendered and styled
	const [barArray, setBarArray] = useState(false)

	const [allowIsolation, setAllowIsolation] = useState(true)

	// Time signature settings
	const [timeSignatureClasses, setTimeSignatureClasses] = useState('')
	const [disableTimeSignature, setDisableTimeSignature] = useState(false)
	const [seenTimeSignatureTooltip, setSeenTimeSignatureTooltip] = useState(0)

	/**
	 * Reset the time signature to 4:4. Handled through parent function, disable
	 * time signature interaction while resetting
	 */
	const resetTimeSignature = () => {
		setDisableTimeSignature(true)
		setTimeout(() => {
			setDisableTimeSignature(false)
		}, 700)
		props.resetTimeSignature()
	}
	const longPressEvent = useLongPress(resetTimeSignature, { isPreventDefault: !isTablet, delay: 1000 })

	// Reset the time signature class every time it changes - this is used for
	// handling the 'shake' state
	useEffect(() => {
		setTimeout(() => {
			setTimeSignatureClasses(getTimeSignatureClass())
		}, 600)
	}, [timeSignatureClasses])

	// As the playbackTime or playing state change, get a new class for the time
	// signature
	useEffect(() => {
		setTimeSignatureClasses(getTimeSignatureClass())
	}, [props.playback.playbackTime, props.playback.playing])

	// Redraw notation as the bars or renderedArray change,
	useEffect(() => {
		const path = get(props, 'rendering.barSettings.path')
		const style = get(props, 'rendering.barSettings.style')
		const renderedArrayLength = get(props, 'rendering.renderedArray.length')
		const normalRenderingMode = get(props, 'rendering.mode') === RenderingModes.NORMAL
		const bars = get(props, 'playback.bars')
		let barsArray = []

		const empty = !get(props, 'rendering.renderedArray') || renderedArrayLength === 0
		const emptyBars = fill(Array(bars), {
			beats: false,
			style: get(props, 'rendering.barSettings.style'),
		})
		if (empty) {
			setBarArray(emptyBars)
			return
		}
		if (normalRenderingMode && !get(props, `sixTwelveEight`) && renderedArrayLength !== get(props, `noOfBeats`)) {
			setBarArray(emptyBars)
			clearRhythm()
			return
		}

		const bPerB = renderedArrayLength / bars
		for (let i = 0; i < renderedArrayLength; i += bPerB) {
			let beats = []
			for (let k = 0; k < bPerB; k++) {
				const imageNumber = get(props, `rendering.renderedArray[${i + k}]`)
				if (isUndefined(imageNumber)) {
					barsArray = emptyBars
					setBarArray(barsArray)
					clearRhythm()
					return
				}
				beats.push(getBarImage({ path, imageNumber }))
			}
			barsArray.push({ beats, style })
		}
		setBarArray(barsArray)
	}, [props.playback.bars, props.rendering.renderedArray])

	/**
	 * Evaluate current state of audio context and call parent play
	 */
	const play = async () => {
		const audioContext = get(props, 'audio.audioContext', false)
		if (!audioContext) {
			return errorOccurred(`Canvas.play - null AudioContext`)
		}
		const state = get(props, 'audio.audioContext.state', ``)
		if (state === 'suspended') {
			await audioContext.resume()
			setTimeout(() => {
				props.play()
			}, playDelay)
			return
		}
		await audioContext.suspend()
		await audioContext.resume()
		setTimeout(() => {
			props.play()
		}, playDelay)
	}

	/**
	 * Call parent stop
	 */
	const stop = () => {
		props.stop({ timeout: false })
	}

	// Keyboard control hooks
	// Play/Stop audio = Space bar(32)
	useKeyboard({
		keyCodes: [32],
		props,
		callback: () => {
			const playEnabled = get(props, 'playback.playEnabled')
			if (!playEnabled) {
				return
			}

			const mode = get(props, 'playback.mode')
			const allowed = !isEmpty(get(props, 'rendering.renderedArray')) || [Modes.CLICK, Modes.CLICKREADING].includes(mode) || props.isRVPermutations
			if (!allowed) {
				return
			}

			if (!props.playback.playing && !props.loading) {
				play()
				return
			}
			stop()
		},
	})

	// < = Rhythm Lock
	useKeyboard({
		keyCodes: [188],
		props,
		callback: () => {
			const mode = get(props, 'playback.mode')
			switch (mode) {
				case Modes.LOOPREADING:
				case Modes.TRADEREADING:
				case Modes.CLICKREADING:
					break
				default:
					return
			}
			const enabled = props.playback.playing && !props.loading
			if (!enabled) {
				return
			}
			props.toggleRhythmLock()
			if (!props.playback.rhythmLock) {
				props.setGrooveLock(true)
				return
			}
			props.setGrooveLock(false)
		},
	})

	// > = Groove Lock
	useKeyboard({
		keyCodes: [190],
		props,
		callback: () => {
			const grooveMix = get(props, [`groove`, `mix`])
			switch (grooveMix) {
				case 0:
				case 100:
					return
				default:
					break
			}
			const enabled = props.playback.playing && !props.loading
			if (!enabled) {
				return
			}
			props.toggleGrooveLock()
		},
	})

	/**
	 * Select a bar
	 * @param {number} barNo the bar to be selected
	 */
	const barSelected = (barNo) => {
		if (get(props, 'playback.isolated') || get(props, 'playback.playing')) {
			return
		}
		switch (!includes(get(props, 'playback.selectedBars'), barNo)) {
			case true:
				props.playback.selectedBars.push(barNo)
				break
			default:
				props.playback.selectedBars.splice(props.playback.selectedBars.indexOf(barNo), 1)
				break
		}

		props.setSelected(get(props, 'playback.selectedBars.length') > 0)
		setAllowIsolation(get(props, 'playback.selectedBars.length') !== get(props, 'playback.bars'))
	}

	/**
	 * Clear any current bar selection
	 */
	const clearBarSelection = () => {
		props.setSelected(false)
		props.setSelectedBars([])
		isolate({ on: false, selectedBars: false })
	}

	/**
	 * Change the the time signature, handles change through
	 * parent functions
	 * @param e the click event
	 * @param {boolean} up true if incrementing, false if decrementing
	 * @param {boolean} top true if changing top, false if bottom
	 * @returns if time signature cannot be updated
	 */
	const changeTimeSignature = (e, up, top) => {
		e.preventDefault()
		if (disableTimeSignature) {
			return
		}
		if (isTablet) {
			return props.setMobileTimeSignatureControls(true)
		}
		const bottomNotAllowed = !top && (!get(props, 'straightTime') || get(props, 'rendering.mode') !== RenderingModes.NORMAL)
		if (!get(props, 'timeSignatureUpdateAllowed') || bottomNotAllowed) {
			setTimeSignatureClasses(timeSignatureClasses + ' shake')
			return
		}
		if (top) {
			return props.changeTimeSignatureTop({ up })
		}
		props.changeTimeSignatureBottom({ up })
	}

	/**
	 * Determines what the accuracy button should display
	 * @returns {String}
	 */
	const accuracyButtonContents = () => {
		const misses = get(props, 'playback.practice.user.misses.length')
		const accuracy = get(props, 'playback.practice.user.accuracy')
		const accuracySummary = get(props, 'accuracySummary')
		if (accuracySummary && get(props, 'hasInteractedWithPractice')) {
			if (misses === 0) {
				return `${Math.floor(accuracy)}% accuracy`
			} else {
				return `${Math.floor(accuracy)}%, ${misses} missed`
			}
		}
		if (!get(props, 'hasInteractedWithPractice') && accuracy === 0 && !accuracySummary) {
			return 'Accuracy...'
		}
		return `${Math.floor(accuracy)}%`
	}

	/**
	 * Determine the current time signatures class based on application state
	 * @returns {string} the className to use for the time signature
	 */
	const getTimeSignatureClass = () => {
		let className = 'time-signature'
		if (!props.straightTime) {
			className = className.concat(' swung')
		}
		if (!get(props, 'timeSignatureUpdateAllowed')) {
			className = className.concat(' disabled')
		}
		return className
	}

	/**
	 * Determine whether the time signature tooltip is disabled
	 */
	const isTSTooltipDisabled = () =>
		isTablet ||
		!props.timeSignatureUpdateAllowed ||
		(seenTimeSignatureTooltip > 1 && (get(props, 'playback.time.options') === Time.MIXED || get(props, 'rendering.mode') !== RenderingModes.NORMAL))

	/**
	 * Render a Bar
	 * @param {*} barSettings
	 * @param {*} barNo
	 * @returns
	 */
	const renderBar = (barSettings, barNo) => {
		const isPlaying = get(props, 'playback.playing')
		const highlighted = get(props, 'rendering.highlighted')
		const isIsolated = get(props, 'playback.isolated')
		const noOfBars = get(props, 'playback.bars')
		const selectedBars = get(props, 'playback.selectedBars')
		return (
			<Bar
				key={barNo}
				barNo={barNo}
				highlighted={isHighlighted({
					barNo,
					isPlaying,
					highlighted,
					isIsolated,
				})}
				beats={get(barSettings, 'beats')}
				className={get(barSettings, 'style')}
				clickable={isClickable({
					noOfBars,
					isIsolated,
					isPlaying,
				})}
				faded={isFaded({ barNo, selectedBars, isIsolated })}
				barSelected={barSelected}
				getCoveredIndexes={
					!props.isRVPermutations
						? () => []
						: () =>
								getRvPermutationsCoveredIndexes(
									props.RVPermutationsStartingPoints,
									props.RVPermutationsGroupingIndex,
									props.playback.bars,
									props.playback.mode
								)
				}
			></Bar>
		)
	}

	/**
	 * @returns {JSX}
	 */
	const renderRhythmName = () => {
		if (!get(props, 'rendering.rhythmName.name')) {
			return
		}
		const renderMetronome =
			get(props, 'playback.click.volume') === 0 ||
			get(props, 'playback.click.gap.on') ||
			get(props, 'playback.click.rate.custom') ||
			get(props, 'playback.click.offset.amount') > 0
		return (
			<div
				onClick={() => {
					if (!renderMetronome) {
						return
					}
					props.setShowClickModal(!get(props, `rendering.showModals.click.open`))
				}}
				className={`rhythm-description ${get(props, `themeName`)} ${renderMetronome ? `clickable-title` : ``}`}
			>
				<p className={`text bold size-18 visible`}>
					{renderMetronome && <img alt="metronome" src={metronome}></img>}
					{get(props, `rendering.rhythmName.name`)}
				</p>
			</div>
		)
	}

	/**
	 * @returns {JSX}
	 */
	const renderRVPermutationGrouping = () => {
		if (!props.isRVPermutations) {
			return
		}

		const images = straightOptions[props.RVPermutationsGroupingIndex].images.map((image, index) => {
			return (
				<img
					onClick={() => {
						if (props.playback.playing) {
							return
						}

						props.setShowRVPermutationsModal(true)
					}}
					style={{ backgroundColor: 'white' }}
					key={index}
					alt="notation"
					src={require(`../images/rhythms/straight/${image}.png`)}
				/>
			)
		})

		return <div className="rv-permutation-grouping">{images}</div>
	}

	/**
	 *
	 * @returns {JSX}
	 */
	const renderModals = () => {
		switch (props.rendering.showModals.rhythmicVocabularyPermutations) {
			case true:
				return (
					<RVPermutationsModal
						toggle={() => props.setShowRVPermutationsModal(false)}
						device={props.device}
						show={props.rendering.showModals.rhythmicVocabularyPermutations}
					></RVPermutationsModal>
				)
			default:
				return
		}
	}

	/**
	 * @returns {JSX}
	 */
	const renderPlayButton = () => {
		let inner
		const playing = get(props, 'playback.playing')
		const mode = get(props, 'playback.mode')
		const renderingMode = get(props, ['rendering', 'mode'])

		switch (true) {
			case !get(props, 'playback.playEnabled'):
				inner = (
					<div className="control-button-outer disabled play">
						<div className="control-button play"></div>
					</div>
				)
				break
			case playing:
				inner = (
					<div className="control-button-outer stop" onClick={stop}>
						<div className="control-button stop"></div>
					</div>
				)
				break
			case renderingMode === RenderingModes.RHYTHMIC_VOCABULARY_PERMUTATIONS.STRAIGHT:
			case (!isEmpty(get(props, 'rendering.renderedArray')) || [Modes.CLICK, Modes.CLICKREADING].includes(mode)) && !playing:
				inner = (
					<div className="control-button-outer enabled play" onClick={play}>
						<div className="control-button play"></div>
					</div>
				)
				break
			default:
				inner = (
					<div className="control-button-outer disabled play">
						<div className="control-button play"></div>
					</div>
				)
				break
		}
		return <div className="play-button">{inner}</div>
	}

	/**
	 * Shuffle, RhythmLock
	 * @returns {JSX}
	 */
	const renderButtonOne = () => {
		const random = get(props, 'playback.random')
		const mode = get(props, 'playback.mode')
		const bars = get(props, 'playback.bars')
		const rhythmLock = get(props, 'playback.rhythmLock')
		const playing = get(props, 'playback.playing')
		const renderedArray = get(props, 'rendering.renderedArray')
		const selected = get(props, 'rendering.selected')
		const isolated = get(props, 'playback.isolated')
		const shuffleAble = !selected || (isolated && !isEmpty(get(props, 'playback.selectedBars')))

		let inner
		switch (true) {
			case !playing:
				let b = bars
				let rArray = renderedArray
				if (get(props, `playback.isolated`)) {
					b = get(props, [`playback`, `isolatedArray`, 0, `length`])
					rArray = get(props, [`playback`, `isolatedArray`, 1])
				}
				const notesInBars = chunk(rArray, get(rArray, 'length') / b)
				let previousNotes = notesInBars[0]
				let forceDisabled = true
				// Run a check to see if all the bars are the same, disable
				// shuffle if so
				forEach(notesInBars, (a) => {
					if (!arrayEquals(a, previousNotes)) {
						forceDisabled = false
						return
					}
					previousNotes = a
				})
				const disabled = forceDisabled || !(random && !isEmpty(renderedArray) && bars > 1 && shuffleAble)
				inner = (
					<Button disabled={disabled} onClick={props.shuffle} className="shuffle action-2">
						{get(props, `rendering.text.canvas.shuffle`, `Shuffle`)}
					</Button>
				)
				break
			case playing && isEmpty(renderedArray):
				inner = (
					<Button disabled className="shuffle action-2">
						{get(props, `rendering.text.canvas.rhythmLock`, `Rhythm Lock`)}
					</Button>
				)
				break
			case mode === Modes.LOOPREADING:
			case mode === Modes.TRADEREADING:
			case mode === Modes.CLICKREADING:
				inner = (
					<Button
						onClick={() => {
							props.toggleRhythmLock()
							props.setGrooveLock(false)
						}}
						className={`rhythm-lock ${rhythmLock ? `action-3` : `action-2`}`}
					>
						Rhythm <img alt="lock" src={rhythmLock ? lock : unlocked}></img>
					</Button>
				)
				break
			default:
				inner = (
					<Button disabled className="shuffle action-2">
						{get(props, `rendering.text.canvas.rhythmLock`, `Rhythm Lock`)}
					</Button>
				)
				break
		}
		return <div className="button">{inner}</div>
	}

	/**
	 * Isolate / Groove lock / New set
	 * @returns {JSX}
	 */
	const renderButtonTwo = () => {
		const mode = get(props, 'playback.mode')
		const shuffledPreset = get(props, 'playback.preset.shuffleAll.on')
		const rhythmLock = get(props, 'playback.rhythmLock')
		const playbackAs = get(props, 'playback.playbackAs')
		const playing = get(props, 'playback.playing')
		const renderedArray = get(props, 'rendering.renderedArray')
		const selected = get(props, 'rendering.selected')
		const grooveMix = get(props, [`groove`, `mix`])
		const grooveLock = get(props, [`groove`, `lock`])
		const isolated = get(props, 'playback.isolated')

		let inner
		switch (true) {
			case shuffledPreset && !isEmpty(renderedArray) && !playing && !selected:
				inner = (
					<Button className="newset action-2" onClick={() => props.changePreset({ newSet: true })}>
						{`New Set`}
					</Button>
				)
				break
			case allowIsolation && !isEmpty(renderedArray) && selected && !isolated && !playing:
				inner = (
					<Button className="isolate action-2" onClick={() => isolate({ on: true, selectedBars: props.playback.selectedBars })}>
						{get(props, `rendering.text.canvas.isolate`, `Isolate`)}
					</Button>
				)
				break
			case allowIsolation && !isEmpty(renderedArray) && isolated && !playing:
				inner = (
					<Button className="unisolate action-2" onClick={() => isolate({ on: false, selectedBars: props.playback.selectedBars })}>
						{get(props, `rendering.text.canvas.unisolate`, `Un-isolate`)}
					</Button>
				)
				break
			case !playing:
				inner = (
					<Button disabled className="isolate action-2">
						{get(props, `rendering.text.canvas.isolate`, `Isolate`)}
					</Button>
				)
				break
			case playbackAs === Sounds.GROOVE && grooveMix > 0 && grooveMix < 100 && (mode === Modes.LOOP || mode === Modes.TRADE || mode === Modes.CLICK || rhythmLock):
				inner = (
					<Button onClick={props.toggleGrooveLock} className={`groove-lock ${grooveLock ? `action-3` : `action-2`}`}>
						Groove <img alt="lock" src={grooveLock ? lock : unlocked}></img>
					</Button>
				)
				break
			default:
				inner = (
					<Button disabled className="shuffle action-2">
						{get(props, `rendering.text.canvas.grooveLock`, `Groove Lock`)}
					</Button>
				)
				break
		}
		return <div className="button">{inner}</div>
	}

	/**
	 * Clear selection / Accuracy
	 * @returns {JSX}
	 */
	const renderButtonThree = () => {
		const playing = get(props, 'playback.playing')
		const renderedArray = get(props, 'rendering.renderedArray')
		const selected = get(props, 'rendering.selected')
		const isolated = get(props, 'playback.isolated')
		const practice = get(props, 'playback.practice.on')
		let inner

		switch (true) {
			case !isEmpty(renderedArray) && !isolated && selected && !playing:
				inner = (
					<Button onClick={clearBarSelection} className="clear-selection action-2">
						{get(props, `rendering.text.canvas.clear`, `Clear Selection`)}
					</Button>
				)
				break
			case beta() && !isEmpty(renderedArray) && practice && (playing || get(props, 'accuracySummary')):
				inner = (
					<Button
						onClick={() => {
							if (playing) return
							return get(props, `setShowPracticeModal`)(true)
						}}
						className={`playback-accuracy action-2 ${!get(props, 'accuracySummary') ? `gradient` : `action-3`}`}
						data-tip="practice"
						data-for="practice"
					>
						{accuracyButtonContents()}
						<ReactTooltip
							disable={true}
							id="practice"
							effect="solid"
							place="top"
							delayShow={200}
							delayHide={200}
							multipline
							type={get(props, `isDarkTheme`) ? 'light' : 'dark'}
						>
							{renderPracticeTooltip({ desktop: desktop(get(props, 'device')) })}
						</ReactTooltip>
					</Button>
				)
				break
			default:
				inner = (
					<Button disabled className="clear-selection action-2">
						{get(props, `rendering.text.canvas.clear`, `Clear Selection`)}
					</Button>
				)
				break
		}
		return <div className="button">{inner}</div>
	}

	/**
	 * @returns {JSX}
	 */
	const renderButtons = () => {
		return (
			<div className="canvas-buttons">
				{renderPlayButton()}
				{renderButtonOne()}
				{renderButtonTwo()}
				{renderButtonThree()}
			</div>
		)
	}

	/**
	 * @returns {JSX}
	 */
	const renderTimeSignature = () => {
		const render = desktop(props.device)
		if (!render) {
			return
		}
		return (
			<>
				<span
					className={`${timeSignatureClasses} top text is-tablet-${isTablet}`}
					data-tip-disable={isTSTooltipDisabled()}
					data-tip="time-signature"
					data-for="time-signature"
					{...longPressEvent}
					onClick={(e) => changeTimeSignature(e, true, true)}
					onContextMenu={(e) => changeTimeSignature(e, false, true)}
				>
					{timeSignatureMap[props.playback.timeSignature.top]}
				</span>
				<span
					className={`${timeSignatureClasses} bottom text is-tablet-${isTablet}`}
					data-tip-disable={isTSTooltipDisabled()}
					data-tip="time-signature"
					data-for="time-signature"
					{...longPressEvent}
					onClick={(e) => changeTimeSignature(e, true, false)}
					onContextMenu={(e) => changeTimeSignature(e, false, false)}
				>
					{timeSignatureMap[props.playback.timeSignature.bottom]}
				</span>
				<ReactTooltip
					id="time-signature"
					effect="solid"
					place="top"
					delayShow={200}
					delayHide={200}
					overridePosition={(currentTarget, currentEvent) => {
						const top = get(currentTarget, 'top') + 12
						const left = get(currentTarget, 'left')
						const bottom = includes(get(currentEvent, 'target.className'), 'bottom')
						if (bottom) {
							return { left, top: top - 28 }
						}
						return { left, top }
					}}
					multipline
					type={get(props, `isDarkTheme`) ? 'light' : 'dark'}
					afterHide={() => {
						if (get(props, 'rendering.mode') !== RenderingModes.NORMAL || get(props, 'playback.time.options') === Time.MIXED) {
							setSeenTimeSignatureTooltip(seenTimeSignatureTooltip + 1)
						}
					}}
				>
					{renderTimeSignatureToolTip({ options: get(props, 'playback.time.options'), renderingMode: get(props, 'rendering.mode') })}
				</ReactTooltip>
			</>
		)
	}

	return (
		<>
			<div
				className={`canvas-container ${get(props, 'themeName', 'light')} ${props.device} filters-${props.rendering.showFilters} settings-${
					props.rendering.showSettings
				} mobile-controls-down-${props.rendering.mobileControls.down.value} time-signature-${props.rendering.mobileControls.timeSignature.show}`}
				onClick={(e) => {
					if (!desktop(props.device)) {
						if (get(props, 'rendering.showFilters')) {
							props.toggleShowFilters()
						} else if (get(props, 'rendering.showSettings')) {
							props.toggleShowSettings()
						}
					}
					if (get(props, [`rendering`, `mobileControls`, `timeSignature`, `show`])) {
						e.preventDefault()
						props.setMobileTimeSignatureControls(false)
					}
				}}
			>
				{renderRhythmName()}
				{renderRVPermutationGrouping()}
				{renderButtons()}
				<div className="bars-container">
					{renderTimeSignature()}
					{map(barArray, renderBar)}
				</div>
			</div>
			{renderModals()}
		</>
	)
}

const mapStateToProps = (state) => {
	const audio = getAudioState(state)
	const rendering = getRenderingState(state)
	const playback = getPlaybackState(state)
	const beatsPerBar = getBeatsPerBar(state)
	const sixTwelveEight = getIsSixTwelveEight(state)
	const noOfBeats = getNoOfBeatsToRender(state)
	const straightTime = getIsStraightTime(state)
	const timeSignatureUpdateAllowed = isTimeSignatureUpdateAllowed(state)
	const hasInteractedWithPractice = getHasInteractedWithPractice(state)
	const themeName = getThemeName(state)
	const isDarkTheme = getIsDarkTheme(state)
	const grooveMix = getGrooveMix(state)
	const grooveLock = getGrooveLock(state)
	const isRVPermutations = getIsRhythmicVocabularyPermutations(state)
	const RVPermutationsGroupingIndex = getRVPermutationsGroupingIndex(state)
	const RVPermutationsStartingPoints = getRVPermutationsStartingPoints(state)

	return {
		audio,
		rendering,
		playback,
		sixTwelveEight,
		noOfBeats,
		beatsPerBar,
		straightTime,
		timeSignatureUpdateAllowed,
		hasInteractedWithPractice,
		themeName,
		isDarkTheme,
		groove: {
			lock: grooveLock,
			mix: grooveMix,
		},
		isRVPermutations,
		RVPermutationsGroupingIndex,
		RVPermutationsStartingPoints,
	}
}

export default connect(mapStateToProps, {
	setAudioContext,
	toggleShowFilters,
	toggleShowSettings,
	setSelected,
	setSelectedBars,
	toggleRhythmLock,
	setGrooveLock,
	toggleGrooveLock,
	setTimeSignatureTop,
	setTimeSignatureBottom,
	setMobileTimeSignatureControls,
	setShowClickModal,
	setShowPracticeModal,
	setShowRVPermutationsModal,
})(Canvas)
