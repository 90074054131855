const TripletLevels = {
  levels: [
    {
      id: 1,
      arr: [2, 3],
    },
    {
      id: 2,
      arr: [1, 4],
    },
    {
      id: 3,
      arr: [7],
    },
    {
      id: 4,
      arr: [5, 6],
    },
  ],
};

//backup
// levels: [
// 	{
// 		id: 1,
// 		arr: [1, 7], [2,3]
// 	},
// 	{
// 		id: 2,
// 		arr: [1, 8, 6, 7],
// 	},
// 	{
// 		id: 3,
// 		arr: [1, 8, 3, 6, 7],
// 	},
// 	{
// 		id: 4,
// 		arr: [1, 8, 4, 5, 6, 7],
// 	},
// ],
// };

export default TripletLevels;
