import { useDispatch } from 'react-redux'

import { setIsolated, setRenderedArray, setSelected, setSelectedBars } from '../redux/actions'
import usePopulatePlay from './usePopulatePlay'
import useRenderer from './useRenderer'
import useRhythmName from './useRhythmName'

const useClearRhythm = () => {
	const dispatch = useDispatch()
	const { populatePlay } = usePopulatePlay()
	const { render } = useRenderer()
	const { set: setRhythmName, getClickDescription } = useRhythmName()

	const dispatchAction = ({ value, action }) => {
		if (!value || !action) {
			return
		}
		dispatch(action(value))
	}

	const clearRhythm = () => {
		dispatchAction({ value: false, action: setIsolated })
		dispatchAction({ value: false, action: setSelected })
		dispatchAction({ value: [], action: setSelectedBars })
		dispatchAction({ value: [], action: setRenderedArray })

		setRhythmName({ name: getClickDescription() })
		populatePlay({ arr: [] })
		render({ images: [] })
	}

	return { clearRhythm }
}

export default useClearRhythm
