// Straight time down ups are restricted to certain notation
export const straightDownUpsOptions = [3, 6, 8, 12, 14]

// In down up mode only certain notation can follow others
export const straightDownUpsKey = {
	3: [6, 3, 8],
	6: [12, 14],
	8: [12, 14, 3, 6],
	12: [3, 6, 8],
	14: [6, 3, 12, 14],
}

// What each image corresponds to as a sound
export const straightDownUpsImageToSoundKey = {
	1: 1,
	2: 1,
	3: 0,
}
