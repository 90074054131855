const PlatformEnum = {
	WEB: 0,
	IOS: 1,
	ANDROID: 2,
}

const PlatformMap = new Map([
	[PlatformEnum.WEB, 'Web'],
	[PlatformEnum.IOS, 'iOS'],
	[PlatformEnum.ANDROID, 'Android'],
])

const getPlatformName = (platform) => PlatformMap.get(platform)

export { PlatformEnum, getPlatformName }
