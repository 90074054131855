import RenderingModes from '../constants/enum/renderingMode'

/**
 * Get the number of beats per bar in down up mode
 * @returns {Number}
 */
export const downUpNoOfBeats = ({ renderingMode, timeSignatureTop }) => {
	const multiplier = renderingMode === RenderingModes.DOWNUP.STRAIGHT ? 4 : 2
	return timeSignatureTop * multiplier
}
