import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonList } from '@ionic/react'
import { getPlatformClass, getThemeName } from '../../../../redux/selectors/rendering'
import useWindowResize from '../../../../hooks/useWindowResize'
import { tabs } from '../../../../utils/ionic/tabs'

import Volume from './volume'
import Bpm from '../Practice/Filters/Controls/Pickers/Bpm'
import Reset from './reset'

const Metronome = ({ themeName, platformClass }) => {
	const [device] = useWindowResize()
	return (
		<IonPage className={`rhythmbot ion-metronome ${platformClass} ${themeName} ${device}`}>
			<IonHeader>
				<IonToolbar color={`dark`}>
					<IonTitle color={`canvas`}>{get(tabs, [`metronome`, `title`])}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent color={`white`} fullscreen>
				<IonList className="ion-metronome-list">
					<Volume />
					<Bpm />
					<Reset />
				</IonList>
			</IonContent>
		</IonPage>
	)
}

const mapStateToProps = (state) => {
	const themeName = getThemeName(state)
	const platformClass = getPlatformClass(state)
	return {
		themeName,
		platformClass,
	}
}

export default connect(mapStateToProps, {})(Metronome)
