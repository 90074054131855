import get from 'lodash/get'
import store from '../../redux/store'

const swingSliderMarks = [
	{
		value: 0,
		label: 'Straight',
	},
	{
		value: 90,
		label: 'Quintuplet',
	},
	{
		value: 100,
		label: 'Triplet',
	},
]
const swingSliderMarksMobile = [
	{
		value: 0,
		label: 'Straight',
	},
	{
		value: 100,
		label: 'Triplet',
	},
]

const mainSliderMarks = [
	{
		value: 0,
		label: 'All Kick',
	},
	{
		value: 100,
		label: 'All Snare',
	},
]

const spaceSliderMarks = () => [
	{
		value: 0,
		label: get(store.getState(), `rendering.text.filters.complexity.normal`, `Normal`),
	},
	{
		value: 100,
		label: get(store.getState(), `rendering.text.filters.complexity.moreRests`, `More Rests`),
	},
]

const volumeSliderMarks = [
	{
		value: 0,
		label: '0%',
	},
	{
		value: 100,
		label: '100%',
	},
]

export { spaceSliderMarks, swingSliderMarks, swingSliderMarksMobile, mainSliderMarks, volumeSliderMarks }
