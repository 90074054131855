import { useDispatch, useSelector } from 'react-redux'
import slice from 'lodash/slice'
import get from 'lodash/get'
import forEach from 'lodash/forEach'

import { setIsolated, setIsolatedArray } from '../redux/actions'
import { getBeatsPerBar, getIsSixTwelveEight, getPlaybackTimeOptions, getTimeSignature } from '../redux/selectors/playback'
import { getRenderedArray, getRenderingMode } from '../redux/selectors/rendering'
import usePopulatePlay from './usePopulatePlay'

import RenderingModes from '../utils/constants/enum/renderingMode'
import { downUpNoOfBeats } from '../utils/downups/utils'

const useIsolate = () => {
	const dispatch = useDispatch()
	const { populatePlay } = usePopulatePlay()

	const playbackTimeOptions = useSelector(getPlaybackTimeOptions)
	const renderedArray = useSelector(getRenderedArray)
	const renderingMode = useSelector(getRenderingMode)
	const timeSignature = useSelector(getTimeSignature)
	const isSixTwelveEight = useSelector(getIsSixTwelveEight)
	const noOfBeatsPerBar = useSelector(getBeatsPerBar)

	const isolate = ({ on, selectedBars, newArray = false }) => {
		let beatsPerBar = noOfBeatsPerBar

		const sixEightOverride = isSixTwelveEight
		if (sixEightOverride) {
			beatsPerBar = 2
			if (get(timeSignature, 'top') === 12) {
				beatsPerBar = 4
			}
		}

		switch (renderingMode) {
			case RenderingModes.DOWNUP.STRAIGHT:
			case RenderingModes.DOWNUP.TRIPLETS:
				beatsPerBar = downUpNoOfBeats({ renderingMode })
				break
			case RenderingModes.SD.TRIPLETS.ONE:
			case RenderingModes.SD.TRIPLETS.TWO:
			case RenderingModes.SD.TRIPLETS.THREE:
			case RenderingModes.SD.TRIPLETS.FOUR:
			case RenderingModes.SD.TRIPLETS.FIVE:
			case RenderingModes.FIRSTGROOVES.ALL:
			case RenderingModes.FIRSTGROOVES.ONE:
			case RenderingModes.FIRSTGROOVES.TWO:
			case RenderingModes.FIRSTGROOVES.THREE:
				beatsPerBar = 1
				break
			default:
		}

		if (on) {
			dispatch(setIsolated(true))

			let tempArray = renderedArray
			if (newArray) {
				tempArray = newArray
			}

			let tempIsolatedArray = []
			const sortedArray = selectedBars.sort(function (a, b) {
				return a - b
			})
			forEach(sortedArray, (entry) => {
				const start = entry * beatsPerBar
				const add = slice(tempArray, start, start + beatsPerBar)
				tempIsolatedArray.push(...add)
			})

			dispatch(setIsolatedArray([sortedArray, tempIsolatedArray]))
			return populatePlay({ arr: tempIsolatedArray, time: playbackTimeOptions, sixEightOverride, renderingMode })
		}

		dispatch(setIsolated(false))
		return populatePlay({ arr: renderedArray, time: playbackTimeOptions, sixEightOverride, renderingMode })
	}

	return { isolate }
}

export default useIsolate
