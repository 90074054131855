import React from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'

import { IonItem, IonLabel } from '@ionic/react'
import { getIsPlaying } from '../../../../../../redux/selectors/playback'

const PresetItem = ({ dismiss, playing, value, preset, changePreset }) => {
	return (
		<IonItem
			detail={get(preset, `helper`) ? false : true}
			button={get(preset, `helper`) ? false : true}
			disabled={playing}
			onClick={() => {
				if (get(preset, `helper`)) return
				changePreset({ preset })
				if (get(preset, `final`)) {
					dismiss()
				}
			}}
		>
			<IonLabel>{value}</IonLabel>
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const playing = getIsPlaying(state)
	return {
		playing,
	}
}

export default connect(mapStateToProps, {})(PresetItem)
