import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import fill from 'lodash/fill'
import isUndefined from 'lodash/isUndefined'
import includes from 'lodash/includes'
import map from 'lodash/map'

import Bar from '../../Bar'

import { getBars, getIsIsolated, getIsPlaying, getIsSixTwelveEight, getNoOfBeatsToRender, getSelectedBars } from '../../../redux/selectors/playback'
import { getBarSettings, getHighlighted, getRenderedArray, getRenderingMode } from '../../../redux/selectors/rendering'
import { setSelected } from '../../../redux/actions'

import useClearRhythm from '../../../hooks/useClearRhythm'
import { getBarImage } from '../../../utils/images'
import RenderingModes from '../../../utils/constants/enum/renderingMode'
import { isHighlighted, isClickable, isFaded } from '../../../utils/bars'

const Bars = ({ selectedBars, playing, highlighted, isolated, bars, renderedArray, barSettings, renderingMode, sixTwelveEight, noOfBeats, setSelected }) => {
	const { clearRhythm } = useClearRhythm()

	const [barArray, setBarArray] = useState(false)

	useEffect(() => {
		const path = get(barSettings, 'path')
		const style = get(barSettings, 'style')
		const renderedArrayLength = get(renderedArray, 'length')
		const normalRenderingMode = renderingMode === RenderingModes.NORMAL

		const empty = !renderedArray || renderedArrayLength === 0
		const emptyBars = fill(Array(bars), {
			beats: false,
			style: get(barSettings, 'style'),
		})

		if (empty) {
			return setBarArray(emptyBars)
		}
		if (normalRenderingMode && !sixTwelveEight && renderedArrayLength !== noOfBeats) {
			// setBarArray(emptyBars)
			// return clearRhythm()
		}

		const beatsPerBar = renderedArrayLength / bars
		if (beatsPerBar % 1 !== 0) {
			return
		}

		const barsArray = []
		for (let i = 0; i < renderedArrayLength; i += beatsPerBar) {
			const beats = []
			for (let k = 0; k < beatsPerBar; k++) {
				const imageNumber = get(renderedArray, `[${i + k}]`)
				if (isUndefined(imageNumber)) {
					setBarArray(emptyBars)
					return clearRhythm()
				}
				beats.push(getBarImage({ path, imageNumber }))
			}
			barsArray.push({ beats, style })
		}
		setBarArray(barsArray)
	}, [bars, renderedArray])

	/**
	 * Select a bar
	 * @param {number} barNo the bar to be selected
	 */
	const barSelected = (barNo) => {
		if (isolated || playing) {
			return
		}
		switch (!includes(selectedBars, barNo)) {
			case true:
				selectedBars.push(barNo)
				break
			default:
				selectedBars.splice(selectedBars.indexOf(barNo), 1)
				break
		}
		setSelected(get(selectedBars, 'length') > 0)
	}

	/**
	 * @param {Object} barSettings
	 * @param {Number} barNo
	 * @returns
	 */
	const renderBar = (barSettings, barNo) => (
		<Bar
			key={barNo}
			barNo={barNo}
			highlighted={isHighlighted({ barNo, highlighted, isPlaying: playing, isIsolated: isolated, selectedBars })}
			beats={get(barSettings, 'beats')}
			className={get(barSettings, 'style')}
			clickable={isClickable({ noOfBars: bars, isPlaying: playing, isIsolated: isolated })}
			faded={isFaded({ barNo, selectedBars, isIsolated: isolated })}
			barSelected={barSelected}
		></Bar>
	)
	return <div className="bars-container">{map(barArray, renderBar)}</div>
}

const mapStateToProps = (state) => {
	const bars = getBars(state)
	const renderedArray = getRenderedArray(state)
	const barSettings = getBarSettings(state)
	const renderingMode = getRenderingMode(state)
	const sixTwelveEight = getIsSixTwelveEight(state)
	const noOfBeats = getNoOfBeatsToRender(state)
	const playing = getIsPlaying(state)
	const highlighted = getHighlighted(state)
	const isolated = getIsIsolated(state)
	const selectedBars = getSelectedBars(state)

	return {
		bars,
		renderedArray,
		barSettings,
		renderingMode,
		sixTwelveEight,
		noOfBeats,
		playing,
		highlighted,
		isolated,
		selectedBars,
	}
}

export default connect(mapStateToProps, { setSelected })(Bars)
