import { ION_SET_FILTERS_OPEN, ION_SET_PRESETS_OPEN, ION_SET_COMPLEXITY_OPEN, ION_SET_TIMESIGNATURE_OPEN, ION_SET_COMPLEXITY_CUSTOM_OPEN } from './actionTypes'

export const ionSetFiltersOpen = (open) => ({
	type: ION_SET_FILTERS_OPEN,
	payload: {
		open,
	},
})

export const ionSetPresetsOpen = (open) => ({
	type: ION_SET_PRESETS_OPEN,
	payload: {
		open,
	},
})

export const ionSetComplexityOpen = (open) => ({
	type: ION_SET_COMPLEXITY_OPEN,
	payload: {
		open,
	},
})

export const ionSetComplexityCustomOpen = (open) => ({
	type: ION_SET_COMPLEXITY_CUSTOM_OPEN,
	payload: {
		open,
	},
})

export const ionSetTimeSignatureOpen = (open) => ({
	type: ION_SET_TIMESIGNATURE_OPEN,
	payload: {
		open,
	},
})
