import React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'

import Button from 'react-bootstrap/Button'

import { getRenderingState, getThemeName } from '../../redux/selectors/rendering'

import { FeaturesListEnum } from '../../utils/constants/enum/featuresList'
import { donationsReject, donationsText, donationsTitle } from '../../utils/text/donations'
import { donationsResponse } from '../../utils/donations'
import { openDonations } from '../../utils/functions'

const Donations = ({ rendering, themeName }) => {
	const render =
		get(rendering, 'donationPrompt') &&
		!get(rendering, 'showError') &&
		get(rendering, 'featuresList') !== FeaturesListEnum.ON &&
		!get(rendering, 'analyticsPrompt') &&
		!get(rendering, 'updatePrompt')

	if (!render) {
		return null
	}
	return (
		<div className={`banner donations flex ${themeName}`}>
			<div className={`message ${themeName}`}>
				<p className="text bold size-20">{donationsTitle}</p>
				<p className="text medium size-16">{donationsText}</p>
			</div>
			<div className="actions">
				<Button
					onClick={() => {
						donationsResponse(false)
					}}
					className="reject action-2"
				>
					{donationsReject}
				</Button>
				<Button
					onClick={() => {
						donationsResponse(true)
						openDonations()
					}}
					className="consent action"
				>
					{donationsTitle}
				</Button>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const themeName = getThemeName(state)
	return {
		rendering,
		themeName,
	}
}

export default connect(mapStateToProps, {})(Donations)
