import { get as idbGet, set as idbSet } from 'idb-keyval'

import store from '../../redux/store'
import { setDonationPrompt } from '../../redux/actions'
import { mobileTimeSignatureAnimation } from '../functions'

/**
 * Handle a users donation response.
 * Bother them less often if they choose to donate
 */
export const donationsResponse = (ack) => {
	idbGet('pageViewMultiplier').then((value) => {
		if (ack) {
			idbSet('pageViewMultiplier', Math.min(value + 3.0, 8.0))
			return
		}
		idbSet('pageViewMultiplier', Math.min(value + 0.7, 8.0))
	})
	idbSet('pageViews', 0)
	store.dispatch(setDonationPrompt(false))
	mobileTimeSignatureAnimation()
}
