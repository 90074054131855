import get from 'lodash/get'

import { useDispatch, useSelector } from 'react-redux'
import { useSwipeable as useReactSwipeable } from 'react-swipeable'

import { setMobileControlsDown, setMobileTimeSignatureControls, setMobileTimeSignatureSeen, setShowFilters, setShowSettings } from '../../redux/actions'
import { getMobileControls, getShowingFilters, getShowingSettings } from '../../redux/selectors/rendering'

const useSwipeable = () => {
	const dispatch = useDispatch()
	const showFilters = useSelector(getShowingFilters)
	const showSettings = useSelector(getShowingSettings)
	const mobileControls = useSelector(getMobileControls)

	// Respond to swipes of the screen on mobile, attached to the `main-content-wrapper`
	const MainSwipes = useReactSwipeable({
		delta: 90,
		onSwipedRight: () => {
			if (showFilters) {
				return
			}
			if (!showSettings) {
				dispatch(setShowFilters(true))
				return
			}
			dispatch(setShowSettings(false))
		},
		onSwipedLeft: () => {
			if (showSettings) {
				return
			}
			if (!showFilters) {
				dispatch(setShowSettings(true))
				return
			}
			dispatch(setShowFilters(false))
		},
		onSwipedDown: () => {
			if (showSettings || showFilters) {
				return
			}
			if (get(mobileControls, [`timeSignature`, `show`])) {
				dispatch(setMobileTimeSignatureControls(false))
				return
			}
		},
	})

	const MobileControlSwipes = useReactSwipeable({
		delta: 90,
		onSwipedUp: () => {
			if (showSettings || showFilters) {
				return
			}
			if (get(mobileControls, [`down`, `value`])) {
				dispatch(setMobileControlsDown({ down: false, manual: true }))
				return
			}
			if (!get(mobileControls, [`timeSignature`, `show`])) {
				localStorage.setItem('mobileTimeSignatureSeen', true)
				dispatch(setMobileTimeSignatureSeen(true))
				dispatch(setMobileTimeSignatureControls(true))
			}
		},
		onSwipedDown: () => {
			if (showSettings || showFilters) {
				return
			}
			if (get(mobileControls, [`timeSignature`, `show`])) {
				dispatch(setMobileTimeSignatureControls(false))
				return
			}
			if (!get(mobileControls, [`down`, `value`])) {
				dispatch(setMobileControlsDown({ down: true, manual: true }))
			}
		},
	})

	const PracticeSurfaceSwipes = useReactSwipeable({
		delta: 90,
		onSwipedRight: () => {
			if (showFilters) {
				return
			}
			if (!showSettings) {
				dispatch(setShowFilters(false))
				return
			}
			dispatch(setShowSettings(false))
		},
		onSwipedLeft: () => {
			if (showSettings) {
				return
			}
			if (!showFilters) {
				dispatch(setShowSettings(false))
				return
			}
			dispatch(setShowFilters(false))
		},
	})

	return { MainSwipes, PracticeSurfaceSwipes, MobileControlSwipes }
}

export default useSwipeable
