import React from 'react'
import { findIndex, get } from 'lodash'
import { connect } from 'react-redux'

import { useIonPicker, IonItem, IonLabel } from '@ionic/react'

import { getIsPlaying, getIsReadingMode, getMode } from '../../../../../../../redux/selectors/playback'
import { setMode } from '../../../../../../../redux/actions'
import ModeEnum from '../../../../../../../utils/constants/enum/mode'

const Mode = ({ playing, mode, setMode, isReadingMode }) => {
	const [present] = useIonPicker()

	const getLabel = () => {
		switch (mode) {
			case ModeEnum.ONCE:
				return `Play Once`
			case ModeEnum.LOOP:
			case ModeEnum.LOOPREADING:
				return `Looped Playback`
			case ModeEnum.TRADE:
			case ModeEnum.TRADEREADING:
				return `Trade Playback`
			case ModeEnum.CLICK:
			case ModeEnum.CLICKREADING:
				return `Click Playback`
			default:
				return ``
		}
	}

	const openPicker = async () => {
		if (playing) {
			return
		}
		let options = [
			{
				text: `Once`,
				value: ModeEnum.ONCE,
			},
			{
				text: `Loop`,
				value: isReadingMode ? ModeEnum.LOOPREADING : ModeEnum.LOOP,
			},
			{
				text: `Trade`,
				value: isReadingMode ? ModeEnum.TRADEREADING : ModeEnum.TRADE,
			},
			{
				text: `Only Click`,
				value: isReadingMode ? ModeEnum.CLICKREADING : ModeEnum.CLICK,
			},
		]

		const selectedIndex = findIndex(options, (o) => get(o, `value`) === mode)

		present({
			columns: [
				{
					name: 'mode',
					selectedIndex,
					options,
				},
			],
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'Confirm',
					handler: (value) => {
						updateMode(get(value, [`mode`, `value`]))
					},
				},
			],
		})
	}

	const updateMode = (mode) => {
		setMode(mode)
	}

	return (
		<IonItem detail button disabled={playing} onClick={openPicker}>
			<IonLabel>{getLabel()}</IonLabel>
		</IonItem>
	)
}

const mapStateToProps = (state) => {
	const playing = getIsPlaying(state)
	const isReadingMode = getIsReadingMode(state)
	const mode = getMode(state)
	return {
		playing,
		isReadingMode,
		mode,
	}
}

export default connect(mapStateToProps, { setMode })(Mode)
