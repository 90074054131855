import React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'

import { isMobileOnly } from 'react-device-detect'

import { getRenderingState } from '../redux/selectors/rendering'
import { getIsPracticeModeOn, getPlaybackState } from '../redux/selectors/playback'
import { toggleShowFilters, toggleShowSettings } from '../redux/actions'
import { getAudioContextCurrentTime } from '../redux/selectors/audio'
import useSwipeable from '../hooks/Swipeable/useSwipeable'

const PracticeSurface = (props) => {
	const { PracticeSurfaceSwipes: Swipes } = useSwipeable

	const render = get(props, `isPracticeModeOn`) && get(props, [`playback`, `playing`]) && isMobileOnly
	if (!render) {
		return null
	}
	return (
		<div
			className={`practice-surface`}
			onPointerDown={() => {
				if (get(props, [`rendering`, `showFilters`])) {
					return get(props, `toggleShowFilters`)()
				}
				if (get(props, [`rendering`, `showSettings`])) {
					return get(props, `toggleShowSettings`)()
				}
				get(props, `practiceTap`)({ click: true, timeOverride: get(props, [`audioContextCurrentTime`]) })
			}}
			{...Swipes}
		/>
	)
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const playback = getPlaybackState(state)
	const isPracticeModeOn = getIsPracticeModeOn(state)
	const audioContextCurrentTime = getAudioContextCurrentTime(state)
	return {
		rendering,
		playback,
		isPracticeModeOn,
		audioContextCurrentTime,
	}
}

export default connect(mapStateToProps, { toggleShowFilters, toggleShowSettings })(PracticeSurface)
